import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { JAR_NAME_GROUP } from 'lib/constants'
import { lowerCase } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { GroupSchemeEnrolmentContributionForecastTreatment, GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { GroupSchemeEnrolmentPayrollFrequency } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSetupSchemeEnrolment, workingSetupSchemeEnrolment } from 'store/tempDataSlice'

export const SchemeEnrolmentChangeForecastTreatment_01_Choice = ({ route, navigation }) => {
  const { nextScreen, enrolment }: { nextScreen: string, enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto }  = route?.params || {}
  const { contributionForecastTreatment, groupScheme } = enrolment || {}
  const { defaultContributionFrequency } = groupScheme || {}

  //Temp state
  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)

  const dispatch = useAppDispatch()

  //Setup form
  const formObj = useForm<{ contributionForecastTreatment: GroupSchemeEnrolmentContributionForecastTreatment }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      contributionForecastTreatment: workingSetupSchemeEnrolmentData?.contributionForecastTreatment
        ? workingSetupSchemeEnrolmentData?.contributionForecastTreatment
        : contributionForecastTreatment
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    const { contributionForecastTreatment } = attributes
    const workingUpdate = GroupSchemeEnrolmentContributionForecastTreatment.CUSTOM
      ? {
          contributionForecastTreatment
        }
      : {
          contributionForecastTreatment,
          customEmployerContributionAmount: null,
          customPayrollFrequency: null,
        }
    dispatch(updateWorkingSetupSchemeEnrolment(workingUpdate))
    //Navigate based on choice
    if (contributionForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.CUSTOM) {
      navigation.navigate(nextScreen) //Go to next screen to customise
    } else {
      navigation.navigate('Results') //Skip to results
    } 
  }

  const currentContributionForecastTreatment = watch('contributionForecastTreatment')

  const defaultEachPhrase =
    defaultContributionFrequency === GroupSchemeEnrolmentPayrollFrequency.ANNUALLY ? ' each year'
    : defaultContributionFrequency === GroupSchemeEnrolmentPayrollFrequency.BI_ANNUALLY ? ' every 6 months'
    : defaultContributionFrequency === GroupSchemeEnrolmentPayrollFrequency.QUARTERLY ? ' each quarter'
    : defaultContributionFrequency === GroupSchemeEnrolmentPayrollFrequency.MONTHLY ? ' each month'
    : defaultContributionFrequency === GroupSchemeEnrolmentPayrollFrequency.FOUR_WEEKLY ? ' every 4 weeks'
    : defaultContributionFrequency === GroupSchemeEnrolmentPayrollFrequency.FORTNIGHTLY ? ' each fortnight'
    : defaultContributionFrequency === GroupSchemeEnrolmentPayrollFrequency.WEEKLY ? ' each week'
    : ''

  const contributionForecastTreatmentOptions: ManagedSimpleChoiceItem[] = [
    {
      value: GroupSchemeEnrolmentContributionForecastTreatment.NONE,
      label: `Ad-hoc income`
    },
    {
      value: GroupSchemeEnrolmentContributionForecastTreatment.LAST_PERIOD,
      label: `Regular ${lowerCase(defaultContributionFrequency)} income`
    },
    {
      value: GroupSchemeEnrolmentContributionForecastTreatment.CUSTOM,
      label: `I need to customize`,
    },
  ]

  return (
    <ModalProcessScreen
      buttonTitle={currentContributionForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.CUSTOM
        ? 'Customize'
        : 'Confirm'
      }
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Ok, so what sort of income is this?`}
      subHeading={`This will decide how it is treated in forecasting`}
    >
    <ManagedSimpleChoiceInput
      name={'contributionForecastTreatment'}
      formObj={formObj}
      options={contributionForecastTreatmentOptions}
      required={true}
    />
    <Subheading>{'This means:'}</Subheading>
    {
      currentContributionForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.CUSTOM
        ? <View style={{ alignSelf: 'center' }}>
            <BulletItem style={{ textAlign: 'left' }}>{`Pension contributions will be treated as regular contributions until you retire.`}</BulletItem>
            <BulletItem style={{ textAlign: 'left' }}>{`You estimate an average pension contribution amount and frequency.`}</BulletItem>
          </View>
        : currentContributionForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.LAST_PERIOD
        ? <View style={{ alignSelf: 'center' }}>
            <BulletItem style={{ textAlign: 'left' }}>{`Pension contributions will be treated as regular contributions until you retire.`}</BulletItem>
            <BulletItem style={{ textAlign: 'left' }}>{`Forecasting will use the last contribution amount received ${defaultEachPhrase}.`}</BulletItem>
          </View>
        : currentContributionForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.NONE
        ? <View style={{ alignSelf: 'center' }}>
            <BulletItem style={{ textAlign: 'left' }}>{`Pension contributions from this employer will be treated as ad-hoc.`}</BulletItem>
            <BulletItem style={{ textAlign: 'left' }}>{`Forecasting will only consider contributions that have already arrived in your ${JAR_NAME_GROUP}.`}</BulletItem>
          </View>
        : <></>
    }
    </ModalProcessScreen>
  )
}
