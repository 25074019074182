import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import React, { useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useGetGroupSchemeRequestsQuery, useLazyGetGroupSchemeRequestsQuery } from 'store/apiSlice'
import { GroupSchemeRequestStatus, GroupSchemeRequestType } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentEmployerRequestDataId } from 'store/uxSlice'
import { Flex, Paper } from 'styles'
import { layoutStyles } from 'styles/common'
import { EmployerRequestListItem } from '../Components/EmployerRequestListItem'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

export const EmployerRequestsListScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)
  const { data: requests, isLoading: requestsIsLoading, error: requestsError, isFetching: requestsIsFetching, refetch: refetchRequests } = useGetGroupSchemeRequestsQuery({
    groupSchemeId: currentScheme?.id,
    status: GroupSchemeRequestStatus.PENDING, 
  }, { skip: !currentScheme })
  const [refreshing, setRefreshing] = useState(false)

  const { colors: themeColors } = Paper.useAppTheme()
  const dispatch = useAppDispatch()

  const handleShowRequestDetails = (id: string) => {
    dispatch(setCurrentEmployerRequestDataId(id))
  }

  const requestsList = () => {
    let optOut = []
    let ceaseMembership = []
    let optIn = []
    let setContributions = []
    let addNino = []
    let other = []
    if (requests) {
      requests.map((data, id) => {
        if (data.requestType === GroupSchemeRequestType.OPT_OUT) {
          optOut.push(data)
        } else if (data.requestType === GroupSchemeRequestType.CEASE_MEMBERSHIP) {
          ceaseMembership.push(data)
        } else if (data.requestType === GroupSchemeRequestType.OPT_IN) {
          optIn.push(data)
        } else if (data.requestType === GroupSchemeRequestType.SET_GROSS_CONTRIBUTION) {
          setContributions.push(data)
        } else if (data.requestType === GroupSchemeRequestType.ADD_NINO) {
          addNino.push(data)
        } else {
          other.push(data)
        } 
      })
      return [
        { title: 'Opt Out', data: optOut },
        { title: 'Cease Membership', data: ceaseMembership },
        { title: 'Opt In', data: optIn },
        { title: 'Set Contribution Amount', data: setContributions },
        { title: 'Add NI Number', data: addNino },
        { title: 'Other Requests', data: other },
      ]
    }
    return []
  }

  const renderItem = ({ item }) => {
    return (
      <EmployerRequestListItem
        request={item}
        onPressFunction={() => handleShowRequestDetails(item?.id)}
      />
    )
  }

  const isLoading = requestsIsLoading || requestsIsFetching
  const error: any = requestsError

  const onRefresh = () => {
    setRefreshing(true)
    refetchRequests()
    setRefreshing(false)
  }

  return (
    <MainAppScreen>
      {
        isLoading ? <Loading message={getLookingLoadingMessages('Finding requests...')} /> :
        error ?  <ErrorScreen errorTryAgain={refetchRequests} error={error?.data} /> : 
          <View style={ Flex.column.start } >
            <ScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={true}
            >
              <OptionalSectionList
                sections={requestsList()}
                renderItem={renderItem}
                onRefresh={onRefresh}
                refreshing={refreshing}
                noDataMessage={`No current requests`}
              />
            </ScrollView>
          </View>
      }
    </MainAppScreen>
  )
}
