import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/Layout'
import { getNationalityAutocompleteOptions } from 'lib/referenceDataHelpers'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useGetNationalitiesQuery } from 'store/apiSlice'
import { Address } from 'store/dto/base.dto'
import { ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'

export const ClientVerification_04_SetAddress = ({ route, navigation }) => {
  const { nextScreen, manualEntry, client }: { nextScreen: string, manualEntry: boolean, client: ClientMeDto } = route?.params || {}
  const workingClientData = useAppSelector(workingClient)
  
  const formObj = useForm<Address>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      building: workingClientData?.addressDetail?.building || client?.addressDetail?.building,
      street1: workingClientData?.addressDetail?.street1 || client?.addressDetail?.street1,
      town: workingClientData?.addressDetail?.town || client?.addressDetail?.town,
      postCode: workingClientData?.addressDetail?.postCode || client?.addressDetail?.postCode,
      country: 'GB',
    },
  })
  const { data: countries, error, isLoading } = useGetNationalitiesQuery()
  const countryOptions = getNationalityAutocompleteOptions(countries)
  const { handleSubmit, formState: { isValid } } = formObj

  const dispatch = useAppDispatch()
  
  const onSubmit = attributes => {
    const addressDetail = attributes
    dispatch(updateWorkingClient({
      addressDetail,
    }))
    navigation.navigate(nextScreen)
  }
  
  const buildingRef = useRef()
  const street1Ref = useRef()
  const townRef = useRef()
  const postCodeRef = useRef()

  const clearValue = () => {
    navigation.goBack()
  }

  return (
    <ProcessScreen
      buttonTitle={manualEntry ? 'Next' : 'Confirm'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={manualEntry ? `Please enter your address` : `Please check your address`}
      subHeading={manualEntry ? `This must be in the United Kingdom` : undefined}
      allowTextButton={true}
      textButtonTitle={'Start Search again'}
      textButtonAction={clearValue}
    >
      <>
        <ManagedTextInput
          ref={buildingRef}
          name={`building`}
          formObj={formObj}
          label={'Building Number/Name'}
          placeholder={'Enter building number/name'}
          returnKeyType={'next'}
          autoCapitalize={'words'}
          submitHandler={() => street1Ref.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: true,
            maxLength: 35,
          }} />
        <ManagedTextInput
          ref={street1Ref}
          name={`street1`}
          formObj={formObj}
          label={'Street'}
          placeholder={'Enter street'}
          returnKeyType={'next'}
          autoCapitalize={'words'}
          submitHandler={() => townRef.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: true,
            maxLength: 35,
          }} />
        <ManagedTextInput
          ref={townRef}
          name={`town`}
          formObj={formObj}
          label={'Town'}
          placeholder={'Enter town/locality'}
          returnKeyType={'next'}
          autoCapitalize={'words'}
          submitHandler={() => postCodeRef.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: true,
            maxLength: 35,
          }} />
        <ManagedTextInput
          ref={postCodeRef}
          name={`postCode`}
          formObj={formObj}
          label={'Post Code'}
          placeholder={'Enter postcode'}
          autoCapitalize={'characters'}
          returnKeyType={'next'}
          rules={{
            required: true,
            maxLength: 10,
          }} />
      </>
    </ProcessScreen>
  )
}

