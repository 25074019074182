//MUST BE AT TOP
import React, { useEffect, useState } from 'react'
import 'react-native-gesture-handler'
//MUST BE AT TOP
import { JarvisIcon } from 'components/Brand/JarvisIcon'
import { Environment, envVariables } from 'environment'
import { useFonts } from 'expo-font'
import * as NavigationBar from 'expo-navigation-bar'
// import * as SplashScreen from 'expo-splash-screen'
import { platformIsWeb } from 'lib/platformHelpers'
import { Logger } from 'lib/logger'
import { pick } from 'lodash'
import { RootStack } from 'navigation/stacks/RootStack'
import { addBreadcrumb, Severity } from 'platform-lib/sentry'
import { Platform, StatusBar, StyleSheet, View } from 'react-native'
import { Provider as PaperProvider } from 'react-native-paper'
import { enGB, registerTranslation } from 'react-native-paper-dates'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Provider } from 'react-redux'
import { Colors, Paper, Sizing } from 'styles'
import './ignoreWarnings'
import { NetworkUnavailableModal } from 'components/Layout/NetworkUnavailableModal'
import { ampli } from 'src/ampli'
import { store } from './store'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
//Enable as part of PA-2200
import { unlockAsync } from 'expo-screen-orientation'
import { DeviceType, getDeviceTypeAsync } from 'expo-device'

// import { PersistGate } from 'redux-persist/integration/react'

// SplashScreen.preventAutoHideAsync()
const IS_PLATFORM_ANDROID = Platform.OS === 'android'

export default function App() {
  //Enable as part of PA-2200
  const [deviceType, setDeviceType] = useState(undefined)
  // const [deviceType, setDeviceType] = useState(DeviceType.UNKNOWN)
  const [appIsReady, setAppIsReady] = useState(false)
  const [amplitudeLoaded, setAmplitudeLoaded] = useState(false)

  const isWeb = platformIsWeb()

  const { envName, analyticsEnabled } = envVariables

  //Resolve device type
  //Enable as part of PA-2200
  useEffect(() => {
    const resolveDeviceType = async () => {
      const deviceType = await getDeviceTypeAsync()
      Logger.debug({ deviceType }, '### Resolved device type')
      setDeviceType(deviceType)
    }
    resolveDeviceType()
  }, [])

  //Unlock orientation unless a phone or unknown device type
  //Enable as part of PA-2200
  useEffect(() => {
    if (deviceType !== undefined && deviceType !== DeviceType.PHONE && deviceType !== DeviceType.UNKNOWN) {
      Logger.debug('### Not a phone/unknown - would allow rotation when enabled')
      //Enable for PA-2200
      // unlockAsync()
    } else {
      Logger.debug('### Phone/unknown - not allowing rotation')
    }
  }, [deviceType])

  //Load relevant amplitude environment
  useEffect(() => {
    if (!amplitudeLoaded) {
      ampli.load({
        environment: envName === Environment.PRODUCTION ? 'production' : 'development',
        disabled: !analyticsEnabled,
      })
      setAmplitudeLoaded(true)
    }
  }, [])
  
  // Logger.debug(`Environent logging...`)
  // Logger.debug(`EXPO_RELEASE_CHANNEL`, EXPO_RELEASE_CHANNEL)
  // Logger.debug(`APP_ENV`, APP_ENV)
  // Logger.debug(`Derived working environment`, envVariables)

  if (Platform.OS === 'ios') {
    // Polyfills required to use Intl with Hermes engine
    require('@formatjs/intl-getcanonicallocales/polyfill').default;
    require('@formatjs/intl-locale/polyfill').default;
    require('@formatjs/intl-pluralrules/polyfill').default;
    require('@formatjs/intl-pluralrules/locale-data/en').default;
    require('@formatjs/intl-numberformat/polyfill').default;
    require('@formatjs/intl-numberformat/locale-data/en').default;
    require('@formatjs/intl-datetimeformat/polyfill').default;
    require('@formatjs/intl-datetimeformat/locale-data/en').default;
    require('@formatjs/intl-datetimeformat/add-all-tz').default;
  }

  const [fontsLoaded, fontsError] = useFonts({
    'LabGrotesque-Light': require('./assets/fonts/LabGrotesque-Light.ttf'),
    'LabGrotesque-Regular': require('./assets/fonts/LabGrotesque-Regular.ttf'),
    'LabGrotesque-Medium': require('./assets/fonts/LabGrotesque-Medium.ttf'),
    'LabGrotesque-Bold': require('./assets/fonts/LabGrotesque-Bold.ttf'),
  })

  useEffect(() => {
    if (fontsLoaded && deviceType !== undefined) {
      Logger.debug('App ready...')
      setAppIsReady(true)
    }
  }, [fontsLoaded, deviceType])

  useEffect(() => {
    // async function hideSplash() {
    //   try {
    //     await SplashScreen.hideAsync()
    //   } catch {
    //     console.log('SplashScreen.hideAsync failed...')
    //   }
    // }
    if (appIsReady) {
      Logger.debug('App is ready...')
      // Logger.debug('Hiding splash...')
      // hideSplash()
    }
  }, [appIsReady])

  registerTranslation('en-GB', enGB)

  //Record the working environment as a breadcrumb
  useEffect(() => {
    Logger.debug(`Capturing working environment for Sentry...`)
    const workingEnvironment = {
      ...pick(envVariables, [
        'envName',
        'apiUrl',
        'appUrl',
        ]),
    }

    addBreadcrumb({
      level: Severity.Info,
      message: 'Working environment',
      data: workingEnvironment,
    })
  }, [])

  //Enable edge-to-edge on android
  useEffect(() => {
    const enableEdgeToEdge = async () => {
      await NavigationBar.setPositionAsync('absolute')
      await NavigationBar.setBackgroundColorAsync('#ffffff00')
    }

    if (IS_PLATFORM_ANDROID) {
      enableEdgeToEdge()      
    }
  }, [])

  function InitialLoading() {
    return (
      <PaperProvider>
        <View style={localStyles.appWrapOuter}>
          <View style={localStyles.appWrapInner}>
            <View style={localStyles.fontsLoadingContainer}>
              <JarvisIcon size={Sizing.x70} color={'black'} />
            </View>
          </View>
        </View>
      </PaperProvider>   
    )
  }

  return (
      <SafeAreaProvider>
        {
          !fontsLoaded ? <InitialLoading />
          :
          <PaperProvider theme={Paper.lightTheme}>
            <Provider store={store}>
              {/* <PersistGate loading={<InitialLoading />} persistor={persistor}> */}
              <GestureHandlerRootView style={{ flex: 1 }}>
                <View style={localStyles.appWrapOuter}>
                  <View style={localStyles.appWrapInner}>
                    <StatusBar
                      backgroundColor={'transparent'}
                      translucent
                      barStyle={'light-content'}
                    />
                    <RootStack />
                    <NetworkUnavailableModal />
                  </View>
                </View>
              </GestureHandlerRootView> 
              {/* </PersistGate> */}
            </Provider>
          </PaperProvider>
        }
      </SafeAreaProvider>
  )
}

const localStyles = StyleSheet.create({
  appWrapOuter: {
    ...Platform.select({
      web: {
        flex: 1,
        backgroundColor: Colors.brand.grey4,
        height: '100vh',
        width: '100%',
        // paddingVertical: Sizing.x5,
        flexDirection: 'column',
        justifyContent: 'center',
      },
      default: {
        flex: 1,
      }
    })
  },
  appWrapInner: {
    ...Platform.select({
      web: {
        flex: 1,
        width: '100%',
        alignSelf: 'center',
      },
      default: {
        flex: 1,
      }
    })
  },
  fontsLoadingContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  }
})