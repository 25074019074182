import { ProcessScreen } from 'components/Layout'
import { Paragraph, Subheading } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { SchemeEnrolmentAddNinoRequestAddProcessStack } from 'features/ModalProcesses/SchemeEnrolmentAddNinoRequestAdd/SchemeEnrolmentAddNinoRequestAddProcessStack'
import { JAR_NAME_ALL, JAR_NAME_GROUP } from 'lib/constants'
import { concat } from 'lodash'
import React, { useState } from 'react'
import { GroupSchemeEnrolmentDto, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { WorkingSchemeEnrolmentRequest, updateWorkingSetupSchemeEnrolment, workingSetupSchemeEnrolment } from 'store/tempDataSlice'
import { Sizing } from 'styles'

export const SchemeEnrolmentSetup_11_AddNinoRequest = ({ route, navigation }) => {
  const { nextScreen, enrolment, client }: { nextScreen: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}

  const [showDialog, setShowDialog] = useState(false)

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)
  const currentRequests = workingSetupSchemeEnrolmentData?.requests || []
  const currentAddNinoRequest = currentRequests.find(request => {
    return request.requestType === GroupSchemeRequestType.ADD_NINO
  })

  const [addRequest, setAddRequest] = useState(false)

  const { groupScheme } = enrolment || {}
  const { enabledRequestTypes } = groupScheme || {}

  const contributionAmountRequestEnabled = enabledRequestTypes && enabledRequestTypes.includes(GroupSchemeRequestType.SET_GROSS_CONTRIBUTION)

  const dispatch = useAppDispatch()

  const handleAddRequest = (request: Partial<WorkingSchemeEnrolmentRequest>) => {
    const otherRequests = workingSetupSchemeEnrolmentData?.requests ? workingSetupSchemeEnrolmentData?.requests.filter(request => {
      return request.requestType !== GroupSchemeRequestType.ADD_NINO
    }) : []
    const newRequest: WorkingSchemeEnrolmentRequest = {
      requestType: request.requestType,
      data: request.data,
    }
    const requests = concat(otherRequests, newRequest)
    dispatch(updateWorkingSetupSchemeEnrolment({
      requests,
    }))
    setAddRequest(false)
  }

  const handleRemoveRequest = () => {
    const otherRequests = workingSetupSchemeEnrolmentData?.requests ? workingSetupSchemeEnrolmentData?.requests.filter(request => {
      return request.requestType !== GroupSchemeRequestType.ADD_NINO
    }) : []
    dispatch(updateWorkingSetupSchemeEnrolment({
      requests: otherRequests,
    }))
  }

  const handleConfirm = () => {
    setShowDialog(false)
    next()
  }

  const next = () => {
    navigation.navigate(nextScreen)
  }

  return (
    <>
      {
        addRequest ?
          <SchemeEnrolmentAddNinoRequestAddProcessStack
            onDismiss={() => setAddRequest(false)}
            resultHandlerFunction={handleAddRequest}
            enrolment={enrolment}
            client={client}
          />
        :
          <ProcessScreen
            isLoading={false}
            error={undefined}
            buttonTitle={currentAddNinoRequest ? 'Continue' : 'Add It Now'}
            buttonAction={currentAddNinoRequest ? next : () => setAddRequest(true)}
            enableButton={true}
            headline={currentAddNinoRequest
              ? `Thanks for providing your National Insurance Number!`
              : `Do you now have your National Insurance Number?`
            }
            subHeading={currentAddNinoRequest
              ? `We will ask your employer to add it to their records`
              : `If you have found or received it, please provide it now`
            }
            allowTextButton={true}
            textButtonTitle={currentAddNinoRequest
              ? `Cancel Your Request`
              : `I don't know it yet`}
            textButtonAction={currentAddNinoRequest
              ? handleRemoveRequest
              : () => setShowDialog(true)}
          >
            {
              currentAddNinoRequest
                ? <>
                    <AppIllustration
                      filename={'thanks.png'} style={[
                      {
                        width: Sizing.x100,
                        height: Sizing.x100,
                        resizeMode: 'contain',
                        alignSelf: 'center'
                      },
                    ]} />
                    <Paragraph>{`Once they process your request and add it, you will unlock all Jarvis features for your ${JAR_NAME_ALL} ${JAR_NAME_GROUP}.`}</Paragraph>
                  </>
                : <>
                    <AppIllustration
                      filename={'consolidate.png'} style={[
                      {
                        width: Sizing.x100,
                        height: Sizing.x100,
                        resizeMode: 'contain',
                        alignSelf: 'center'
                      },
                    ]} />
                    <Paragraph>{`We can send it to your employer to add to their records, and this will unlock all Jarvis features for your ${JAR_NAME_ALL} ${JAR_NAME_GROUP}.`}</Paragraph>
                  </>
            }
            <ConfirmationDialog
              visible={showDialog}
              onCancel={() => setShowDialog(false)}
              title={'Please confirm'}
              content={`I don't know my National Insurance Number, or have not yet received it from HMRC.\n\nI understand that until I obtain one, and provide it to my employer, I will have limited functionality for my ${JAR_NAME_ALL} ${JAR_NAME_GROUP}`}
              confirmLabel={'Confirm'}
              onConfirm={handleConfirm}
            />
          </ProcessScreen>
      }
    </>
  )
}
