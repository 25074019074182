import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { addYears, format, startOfYear } from 'date-fns'
import { calculateAgeAtDate } from 'lib/dateHelpers'
import { max } from 'lodash'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { ampli } from 'src/ampli'
import { ClientMeDto } from 'store/dto/client.dto'
import { RetirementAssetDto } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'
import { Colors, Sizing } from 'styles'

const MIN_START_YEAR = 1990
export const RetirementAssetTransfer_34_TracingEmployerEndDate = ({ route, navigation }) => {
  const { nextScreen, asset, client }: { nextScreen: string, asset: RetirementAssetDto, client: ClientMeDto }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()

  const [showDialog, setShowDialog] = useState(false)

  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  //PA-1943 - removing this as can create a situation in which there are no end years available, if e.g. client is currently aged 20
  // const client23BirthdayYear = parseInt(format(startOfYear(addYears(new Date(client?.birthDate), 23)), 'yyyy'))
  const startEmploymentYear = workingRetirementAssetData?.employerStartDate ? parseInt(format(new Date(workingRetirementAssetData?.employerStartDate), 'yyyy')) : 0
  // const startYear = max([client23BirthdayYear, startEmploymentYear, MIN_START_YEAR])
  const startYear = max([startEmploymentYear, MIN_START_YEAR])

  //Build autocomplete options
  const yearOptions: AutoCompleteItem[] = []
  const currentYear = parseInt(format(new Date(), 'yyyy'))
  for (let i = startYear; i <= currentYear; i++) {
    yearOptions.push({
      value: i,
      label: i.toString(),
    })
  }

  const bestEndDate = workingRetirementAssetData?.employerEndDate || asset?.employerEndDate

  //Setup form
  const formObj = useForm<{ employerEndDate: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      employerEndDate: bestEndDate ? parseInt(format(new Date(bestEndDate), 'yyyy')) : undefined,
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const employerEndDate = watch('employerEndDate')
  const sameYear = `${employerEndDate}-01-01` === workingRetirementAssetData?.employerStartDate
  const ageAtEndDate = employerEndDate ? calculateAgeAtDate(`${employerEndDate}-01-01`, client?.birthDate) : 0


  const onSubmit = attributes => {
    setShowDialog(false)
    const employerEndDate = `${attributes?.employerEndDate}-01-01`
    dispatch(updateWorkingRetirementAsset({
      employerEndDate,
      tracingRequested: true,
    }))
    
    //PA-2009 Analytics
    ampli.transferUsage({
      processStep: 'prep_tracing_employment',
      isRetransfer: false,
      outcome: sameYear ? 'potential_short_period' : 'success',
      retirementAssetId: asset?.id
    })
    
    navigation.navigate(sameYear ? nextScreen : 'Result')
  }

  const handleStillWork = () => {
    setShowDialog(false)
    dispatch(updateWorkingRetirementAsset({
      isActiveEmployer: true,
    }))
    navigation.navigate('Result')
  }

  return (
    <ModalProcessScreen
      buttonTitle={sameYear ? `Next` : `Finish`}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`And when did you finish?`}
      subHeading={`The approximate year you stopped working for ${asset?.employerName ? asset?.employerName : 'them'}`}
      allowTextButton={!employerEndDate}
      textButtonTitle={`I still work for them`}
      textButtonAction={() => setShowDialog(true)}
    >
      <ManagedAutoCompleteInput
        name={'employerEndDate'}
        placeholder={'Tap to enter year...'}
        formObj={formObj}
        modalTitle={'Enter Finish Year'}
        required={true}
        dataSet={yearOptions}
        informationMessage={employerEndDate
          ? `When you were ${ageAtEndDate} or ${ageAtEndDate + 1} years old`
          : undefined
        }
      />
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'We can only find workplace pension where you have left employment'}
        content={`If you still work there, it is likely your employer is still making contributions on your behalf, which you would lose by transferring to your Jarvis plan.\n\nYou can save your answers so far, but we can only proceed once you confirm you no longer work there.`}
        confirmLabel={`Save`}
        cancelLabel={`Go Back`}
        onConfirm={handleStillWork}
      />
      <View style={{ paddingTop: Sizing.x10 }}>
        <Subheading style={{ textAlign: 'left', color: Colors.brand.purple1 }}>{`We can find pensions where:`}</Subheading>
        <View style={{ alignSelf: 'center' }}>
          <BulletItem style={{ textAlign: 'left' }}>{`Your employment began during or after ${MIN_START_YEAR}`}</BulletItem>
          <BulletItem style={{ textAlign: 'left' }}>{`Your employment has now ceased`}</BulletItem>
          <BulletItem style={{ textAlign: 'left' }}>{`You were at least 22 years old when your employment ceased`}</BulletItem>
          <BulletItem style={{ textAlign: 'left' }}>{`You were employed for more than 3 months`}</BulletItem>
        </View>
        <Subheading style={{ textAlign: 'left', color: Colors.brand.purple1 }}>{`We are unable to find pensions where:`}</Subheading>
        <View style={{ alignSelf: 'center' }}>
          <BulletItem style={{ textAlign: 'left' }}>{`Your employment was in the Public Sector`}</BulletItem>
          <BulletItem style={{ textAlign: 'left' }}>{`You worked there for less than 2 years before the year 2000`}</BulletItem>
        </View>
      </View>

    </ModalProcessScreen>
  )
}
