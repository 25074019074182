import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph } from 'components/Typography'
import { JAR_NAME_ALL, MAX_CLIENT_AGE, MIN_CLIENT_AGE } from 'lib/constants'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { Logger } from 'lib/logger'
import { pick } from 'lodash'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { api, useDeleteSpouseMutation, useGetSpouseQuery, useUpdateSpouseMutation } from 'store/apiSlice'
import { Gender, Title } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { editSpouseVisible, setEditSpouseVisible } from 'store/uxSlice'

export type SpouseEditModalProps = {
  onDismiss?: any
}

export const SpouseEditModal = (props: SpouseEditModalProps) => {
  return (
    <ModalEditWrap
      screen={<ScreenContent
        {...props}
      />}
    />
  )
}

const ScreenContent = (props: SpouseEditModalProps) => {

  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setEditSpouseVisible(false))

  const onDismiss = props.onDismiss ? props.onDismiss : globalDismiss
  
  const [ deleteSpouse ] = useDeleteSpouseMutation()

  const handleDelete = async () => {
    Logger.info(`Deleting spouse...`)
    const copy = {...spouse}
    deleteSpouse()
    //IMPORTANT NOTE
    //This call to resetApiState is overkill but is the only way that has currently been found to
    //forcibly clear the RTK query cache and make sure we remove the cached spouse representation
    //Attempts have been made using api.util.updateQueryData and api.util.prefetch, both here
    //and in apiSlice (where this would ideally be located). None of them appear to remove the
    //cached spouse entry
    //Hence this call destroys all cached data, which will then be re-pulled from the backend.
    //Given spouse deletion should be a rare occurrence, this is acceptable for now
    ampli.spouseDelete(pick(copy, [
      'gender',
      'birthDate',
      'age',
    ]))
    Logger.debug(`Resetting API state to clear caches...`)
    dispatch(api.util.resetApiState())
    onDismiss()
  }

  const { data: spouse, error: spouseError, isLoading: spouseIsLoading } = useGetSpouseQuery()
  const [ updateSpouse, { data: updatedSpouse, isLoading: updateIsLoading, error: updateError, reset: updateReset } ] = useUpdateSpouseMutation()

  const disabled = !!spouse?.userId

  //Form refs for focussing
  const titleRef = useRef()
  const firstNameRef = useRef()
  const surnameRef = useRef()
  const genderRef = useRef()
  const birthDateRef = useRef()

  //Setup form
  const formObj = useForm<{
    title: Title
    firstName: string
    surname: string
    gender: Gender
    birthDate: string
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, watch, reset,formState: { isDirty, isValid} } = formObj
  
  //Re-initialize form when have asset
  useEffect(() => {
    if (spouse) {
      reset({
        title: spouse?.title,
        firstName: spouse?.firstName,
        surname: spouse?.surname,
        gender: spouse?.gender,
        birthDate: spouse?.birthDate,
      })
    }
  }, [spouse])
  
  const onSubmit = attributes => {
    Logger.info(`Updating spouse...`)
    updateSpouse({
      id: spouse?.id,
      ...attributes,
    })
    
  }

  useEffect(() => {
    if (updatedSpouse) {
      ampli.spouseUpdate(pick(updatedSpouse, [
        'gender',
        'birthDate',
        'age',
      ]))
      onDismiss()
    }
  }, [updatedSpouse])

  const isLoading = spouseIsLoading || updateIsLoading
  const error: any = spouseError || updateError

  return (
      <ModalEditScreen
        error={error}
        isLoading={isLoading}
        formTitle={'Update Partner'}
        onDismiss={onDismiss}
        //Deleting not yet working - it deletes at the backend, but the rtk-query caching is not being invalidated
        //so the UI is not updating and thinks there is still a client
        //TODO - needs fixing to enable spouse deletion
        onDelete={handleDelete}
        deleteDialogText={spouse?.userId
          ? `This will disconnect you and your partner, and neither or you will have any visibility on the other's ${JAR_NAME_ALL}, other pensions/assets and retirement incomes. This may have a significant affect on financial forecasting and your predicted retirement age!`
          : `This will delete your partner along with all retirement incomes and other pensions/assets. This may have a significant affect on financial forecasting and your predicted retirement age!`
        }
        loadingMessage={['Saving partner...']}
        errorTryAgain={updateError ? handleSubmit(onSubmit) : undefined}
        errorCancel={updateError ? updateReset : onDismiss}
        buttonTitle={disabled ? 'Close' : 'Save'}
        buttonAction={disabled ? onDismiss : handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        {
          disabled
          ? <Paragraph>{`This information is read only because you have linked your account with ${spouse?.firstName}.`}</Paragraph>
          : <></>
        }
        
        <ManagedAutoCompleteInput
          name={'title'}
          formObj={formObj}
          label={'Title'}
          selectOnlyMode={true}
          dataSet={enumToAutocompleteOptions(Title)}
          disabled={disabled}
          required={true}
        />
        <ManagedTextInput
          ref={firstNameRef}
          name={'firstName'}
          formObj={formObj}
          label={'First Name'}
          placeholder={'Their legal first name'}
          returnKeyType={'next'}
          //@ts-ignore
          submitHandler={() => surnameRef.current?.getElement().focus()}
          blurOnSubmit={false}
          disabled={disabled}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 40,
        }} />
        <ManagedTextInput
          ref={surnameRef}
          name={'surname'}
          formObj={formObj}
          label={'Last Name'}
          placeholder={'Their legal surname'}
          returnKeyType={'next'}
          blurOnSubmit={true}
          disabled={disabled}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 40,
        }} />
        <ManagedAutoCompleteInput
          name={'gender'}
          formObj={formObj}
          label={'Gender'}
          selectOnlyMode={true}
          dataSet={enumToAutocompleteOptions(Gender)}
          disabled={disabled}
          required={true}
        />
        <ManagedDateInput
          ref={birthDateRef}
          name={'birthDate'}
          formObj={formObj}
          label={'Date of Birth'}
          blurOnSubmit={false}
          required={true}
          disabled={disabled}
          notOlderThanYears={MAX_CLIENT_AGE}
          notYoungerThanYears={MIN_CLIENT_AGE}
          tryParseTwoDigitYear={true}
          showCurrentAgeMessage={true}
        />
      </ModalEditScreen>
  )
}
