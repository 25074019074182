import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { RefreshableScrollView } from 'components/ScreenTemplates/RefreshableScrollView'
import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { ActionCard } from 'components/Utility/ActionCard'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { GroupSchemeInfoModal } from 'components/Utility/GroupSchemeInfoModal'
import { LegalDocumentsModal } from 'components/Utility/LegalDocumentsModal'
import { Loading } from 'components/Utility/Loading'
import { PensionInvestmentInformationCard } from 'components/Utility/PensionInvestmentInformationCard'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { format } from 'date-fns'
import { EnrolmentListItem } from 'features/FunctionalAreas/Enrolments/Components/EnrolmentListItem'
import { SchemeInviteListItem } from 'features/FunctionalAreas/Enrolments/Components/SchemeInviteListItem'
import { goToDashboard, mainAppNavigate, rootNavigate } from 'lib/RootNavigation'
import { getGroupPension, getGroupReserveAccounts, getInvestmentChoiceDataForAccount } from 'lib/accountHelpers'
import { JAR_NAME_GROUP, WORKPLACE_SCHEME_NAME } from 'lib/constants'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { Logger } from 'lib/logger'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { orderBy, concat, sumBy, minBy, maxBy } from 'lodash'
import React, { useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { openInbox } from 'react-native-email-link'
import { Portal } from 'react-native-paper'
import { useGetCurrentUserQuery, useGetGroupSchemeEnrolmentsQuery, useGetMeQuery, useGetMeSchemeInvitesQuery, useGetOpenSchemeInviteByIdQuery, useGetPendingEnrolmentByIdQuery, useGetUserAccountsQuery } from 'store/apiSlice'
import { AccountDto, GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { GroupSchemeStatus } from 'store/dto/group-scheme.dto'
import { InviteStatus } from 'store/dto/invite.dto'
import { useAppDispatch } from 'store/hooks'
import { setStatementRequestVisible } from 'store/uxSlice'
import { Flex, Paper, Sizing, Typography } from 'styles'
import { layoutStyles } from 'styles/common'

const isWeb = platformIsWeb()

export const GroupPensionScreen = () => {

  const dispatch = useAppDispatch()

  const [showGroupSchemeInfoModal, setShowGroupSchemeInfoModal] = useState(false)
  const [showDocsModal, setShowDocsModal] = useState(false)

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery()
  const { data: enrolments, error: enrolmentsError, isLoading: enrolmentsIsLoading, refetch: refetchEnrolments } = useGetGroupSchemeEnrolmentsQuery()
  const { data: client, error: clientError, isLoading: clientIsLoading, refetch: refetchClient } = useGetMeQuery()
  const { data: user, isLoading: userIsLoading, isFetching: userIsFetching, error: userError } = useGetCurrentUserQuery()
  const { data: openSchemeInvite, isLoading: openSchemeInviteIsLoading, isFetching: openSchemeInviteIsFetching, error: openSchemeInviteError } = useGetOpenSchemeInviteByIdQuery(user?.preferredSchemeInviteId, { skip: !user?.preferredSchemeInviteId })
  const { data: pendingEnrolment, isSuccess: pendingEnrolmentIsSuccess, error: pendingEnrolmentError, isLoading: pendingEnrolmentIsLoading, refetch: refetchPendingEnrolment } = useGetPendingEnrolmentByIdQuery(user?.preferredSchemeEnrolmentId, { skip: !user?.preferredSchemeEnrolmentId })
  const { data: schemeInvites, isLoading: schemeInvitesIsLoading, error: schemeInvitesError } = useGetMeSchemeInvitesQuery()

  const refetchAll = () => {
    refetchClient()
    refetchAccounts()
    refetchEnrolments()
  }

  const groupPension = getGroupPension(accounts)
  const groupReservedAccounts = getGroupReserveAccounts(accounts)
  const allGroupAccounts: AccountDto[] = concat(groupPension ? [groupPension] : [], groupReservedAccounts)

  const workplaceIsActive = !!groupPension || enrolments?.length > 0
  const hasMultipleEnrolments = enrolments?.length > 1
  
  let totalReservedBalance = 0
  if (enrolments) {
    enrolments.forEach(enrolment => {
      totalReservedBalance += enrolment?.reservedBalance?.currentValue || 0
    })
  }

  const totalGroupBalance = (groupPension?.currentValue || 0) + totalReservedBalance


  //Sort everything
  const sortedEnrolments: (GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto)[] = enrolments
    ? orderBy(enrolments, [
      (i: GroupSchemeEnrolmentDto) => { return i.isVerified ? 1 : 0 }, //Unverified first
      (i: GroupSchemeEnrolmentDto) => { return i.isLinked ? 1 : 0 }, //Without NINO first
      (i: GroupSchemeEnrolmentDto) => { return i.enrolmentStatus }, //Active, Inactive, Opted Out
      (i: GroupSchemeEnrolmentDto) => { return i.enrolmentEndDate || '' }, //Most recently ended
      (i: GroupSchemeEnrolmentDto) => { return i.enrolmentDate || '' }, //Most recently enrolled
    ],
    [
      'asc',
      'desc',
      'asc',
      'desc',
      'desc'
    ])
    : []

  //See if the user preferred pending enrolment is already included (any enrolment for the groupPension)
  const pendingEnrolmentAlreadyPresent = pendingEnrolment ? sortedEnrolments.find(enrolment => {
    return enrolment.id === pendingEnrolment.id
  }) : undefined

  //If not, insert it
  if (pendingEnrolment && !pendingEnrolmentAlreadyPresent) {
    sortedEnrolments.unshift(pendingEnrolment)
  }

  //Filter out currently-onboarding schemes
  const filteredSortedEnrolments = sortedEnrolments.filter(enrolment => {
    return enrolment.groupScheme?.status !== GroupSchemeStatus.ONBOARDING
  })

  //Collect the enrolled groupSchemeIds
  const enrolledGroupSchemeIds = filteredSortedEnrolments.map(enrolment => {
    return enrolment.groupScheme?.id
  })

  //Find any invites that are open or accepted but NOT already covered by enrolments
  const visibleStatuses: InviteStatus[] = [
    InviteStatus.OPEN,
    InviteStatus.ACCEPTED,
  ]
  const nonEnrolledInvites = groupPension && schemeInvites ? schemeInvites.filter(schemeInvite => {
    return visibleStatuses.includes(schemeInvite.status) && !enrolledGroupSchemeIds.includes(schemeInvite?.groupScheme?.id)
  }) : []

  const isLoading = clientIsLoading || accountsIsLoading || enrolmentsIsLoading || userIsLoading || pendingEnrolmentIsLoading || openSchemeInviteIsLoading
  const error: any = clientError || accountsError || enrolmentsError || userError
  
  const { colors: themeColors } = Paper.useAppTheme()

  // const { valueReport: groupPensionValueReport } = groupPension || {}
  // const {
  //   realisedGrowthTotal,
  //   retainedGrowthTotal,
  //   contributionsTotal,
  //   taxReclaimTotal,
  //   incomeTotal,
  //   chargesTotal,
  //   closingValue,
  // } = groupPensionValueReport || {}

  const allValueReports = allGroupAccounts.map(account => {
    return account.valueReport
  })

  const earliestAccount = minBy(allGroupAccounts, 'createdAt')
  const latestAccount = maxBy(allGroupAccounts, 'updatedAt')
  const realisedGrowthTotal = sumBy(allValueReports, 'realisedGrowthTotal') || 0
  const retainedGrowthTotal = sumBy(allValueReports, 'retainedGrowthTotal') || 0
  const totalGrowth = realisedGrowthTotal + retainedGrowthTotal
  const contributionsTotal = sumBy(allValueReports, 'contributionsTotal') || 0
  const taxReclaimTotal = sumBy(allValueReports, 'taxReclaimTotal') || 0
  const incomeTotal = sumBy(allValueReports, 'incomeTotal') || 0
  const chargesTotal = sumBy(allValueReports, 'chargesTotal') || 0
  const closingValue = sumBy(allValueReports, 'closingValue') || 0

  const totalPendingContributionValue = groupPension?.pendingContributionValue || 0
  const totalPendingTaxReclaimValue = groupPension?.pendingTaxReclaimValue || 0
  const totalPendingValue = totalPendingContributionValue + totalPendingTaxReclaimValue

  let balanceTableData: UnborderedTableRow[] = []

  //Charges
  balanceTableData.push({
    label: `Opening Balance`,
    subLabel: earliestAccount?.createdAt ? format(new Date(earliestAccount?.createdAt), 'do MMMM yyyy') : undefined,
    value: formatCurrencyAmount(0, 2),
    isTotal: true,
  }) 

  //Contributions
  balanceTableData.push({
    label: `Contributions`,
    iconSource: <MaterialCommunityIcons name={'bank-transfer-in'} size={Sizing.x20} color={themeColors.primary} />,
    value: formatCurrencyAmount(contributionsTotal || 0, 2),
    linkFunction: () => mainAppNavigate(MainAppNavScreen.CONTRIBUTIONS, { accountId: groupPension.id }),
  })

  //Tax Relief
  balanceTableData.push({
    label: `HMRC Tax Relief`,
    iconSource: <MaterialCommunityIcons name={'town-hall'} size={Sizing.x20} color={themeColors.primary} />,
    value: formatCurrencyAmount(taxReclaimTotal || 0, 2),
    linkFunction: () => mainAppNavigate(MainAppNavScreen.CONTRIBUTIONS, { accountId: groupPension.id }),
  })

  //Investment Growth
  balanceTableData.push({
    label: <Text style={{textAlign: 'left', color: totalGrowth < 0 ? themeColors.accent : themeColors.primary }}>{`Investment Growth`}</Text>,
    iconSource: <MaterialCommunityIcons name={'chart-areaspline-variant'} size={Sizing.x20} color={totalGrowth < 0 ? themeColors.accent : themeColors.primary} />,
    value: <Text style={{color: totalGrowth < 0 ? themeColors.accent : themeColors.primary }}>{`${totalGrowth < 0 ? '-' : ''}${formatCurrencyAmount(totalGrowth, 2)}`}</Text>,
    linkFunction: () => mainAppNavigate(MainAppNavScreen.STATEMENTS),
  })

  //Other Income
  if (incomeTotal > 0) {
    balanceTableData.push({
      label: `Other Income`,
      iconSource: <MaterialCommunityIcons name={'cash-plus'} size={Sizing.x20} color={themeColors.primary} />,
      value: formatCurrencyAmount(incomeTotal || 0, 2),
      linkFunction: () => mainAppNavigate(MainAppNavScreen.STATEMENTS),
    })
  }

  //Charges
  balanceTableData.push({
    label: <Text style={{ textAlign: 'left', color: chargesTotal < 0 ? themeColors.accent : themeColors.primary }}>{`Total Charges`}</Text>,
    iconSource: <MaterialCommunityIcons name={'cash-register'} size={Sizing.x20} color={chargesTotal < 0 ? themeColors.accent : themeColors.primary} />,
    value: <Text style={{color: chargesTotal < 0 ? themeColors.accent : themeColors.primary }}>{`${chargesTotal < 0 ? `-` : ``}${formatCurrencyAmount(chargesTotal || 0, 2)}`}</Text>,
    linkFunction: () => mainAppNavigate(MainAppNavScreen.STATEMENTS),
  }) 

  //Overall Balance
  balanceTableData.push({
    label: `Current Balance`,
    subLabel: latestAccount?.updatedAt ? format(new Date(latestAccount?.updatedAt), 'do MMMM yyyy') : undefined,
    value: formatCurrencyAmount(closingValue || 0, 2),
    isTotal: true,
  })
  
  const investmentChoiceDataForAccount = groupPension && accounts ? getInvestmentChoiceDataForAccount(groupPension, accounts) : undefined

  return (
    <MainAppScreen>
      {
        isLoading
        ? <Loading message={getLookingLoadingMessages('Loading your pension...')} />
        : error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> :
          workplaceIsActive ?
          <>
            <View style={[
              layoutStyles.mainContentContainer,
              localStyles.pageContainer,
              ]} >
              <RefreshableScrollView
                contentContainerStyle={layoutStyles.scrollContainerContent}
                showsVerticalScrollIndicator={true}
                refreshFunctions={[
                  refetchClient,
                  refetchAccounts,
                  refetchEnrolments,
                ]}
              >
                <Subheading style={Typography.defined.sectionListHeader}>{'Current Balance'} </Subheading>
                <View style={{ paddingTop: Sizing.x10 }}>
                  <Paragraph
                    adjustsFontSizeToFit={true}
                    numberOfLines={1}
                    style={{
                      fontFamily: 'LabGrotesque-Medium',
                      fontWeight: '400',
                      lineHeight: scaleNormalizer(40),
                      fontSize: scaleNormalizer(40),
                      textAlign: 'center',
                    }}>
                      {formatCurrencyAmount(totalGroupBalance, 2)}
                  </Paragraph>
                  {
                    totalPendingValue > 0
                      ? <Paragraph>{`Pending Contributions: ${formatCurrencyAmount(totalPendingValue, 2)}`}</Paragraph>
                      : <></>
                  }
                  {
                    groupPension
                      ? <Button mode='text' color={themeColors.accent} onPress={() => mainAppNavigate(MainAppNavScreen.CONTRIBUTIONS, { accountId: groupPension.id })}>{`View all Contributions`}</Button>
                      : <></>
                  }
                  
                </View>

                <ContentDivider />
                <Subheading style={Typography.defined.sectionListHeader}>{'Contributing Employers'} </Subheading>
                <View style={{ paddingVertical: Sizing.x10 }}>
                  {
                    nonEnrolledInvites.map((schemeInvite, idx) => {
                      return (
                        <SchemeInviteListItem
                          key={idx}
                          schemeInvite={schemeInvite}
                          onPressFunction={schemeInvite.status === InviteStatus.OPEN
                            ? () => {}
                            : () => rootNavigate('SchemeInviteAcceptIntroScreen', { schemeInvite })
                          }
                        />
                      )
                    })
                  }
                  {
                    filteredSortedEnrolments.map((enrolment, idx) => {
                      return (
                        <EnrolmentListItem
                          key={idx}
                          enrolment={enrolment}
                          onPressFunction={enrolment.isVerified
                            ? () => rootNavigate('EnrolmentsFunctionalAreaStack', { screen: 'Main', enrolmentId: enrolment?.id, organizationDisplayName: enrolment?.groupScheme?.organizationDisplayName })
                            : () => rootNavigate('SchemeEnrolmentSetupIntroScreen', { enrolment })
                          }
                        />
                      )
                    })
                  }
                </View>

                <ContentDivider />
                <Subheading style={Typography.defined.sectionListHeader}>{'Investment Plan'} </Subheading>
                {
                  groupPension
                    ? <>
                        <PensionInvestmentInformationCard
                          account={investmentChoiceDataForAccount?.account}
                          investmentReferenceAccount={investmentChoiceDataForAccount?.investmentReferenceAccount}
                          showAsGlobalDecision={investmentChoiceDataForAccount?.showAsGlobalDecision}
                          client={client}
                          includePreamble={true}
                        />
                        <View style={{ paddingVertical: Sizing.x10 }}>
                          <Button mode='text' color={themeColors.accent} onPress={() => rootNavigate('InvestmentChoiceIntroScreen', { contextAccount: groupPension, investmentReferenceAccount: investmentChoiceDataForAccount.investmentReferenceAccount })}>{`Change Investment Strategy`}</Button>
                        </View>
                      </>
                    : <>
                        <Paragraph>{`You will be able to view and manage the investment strategy for your ${JAR_NAME_GROUP} once you have at least one connected employer who has enrolled you with your National Insurance Number.`}</Paragraph>                        
                      </>
                }

                <ContentDivider />
                <Subheading style={Typography.defined.sectionListHeader}>{'Balance Summary'} </Subheading>
                <Paragraph>{`The below table summarises how your current balance is calculated.`}</Paragraph>
                <UnborderedTable
                  isLoading={false}
                  isFetching={false}
                  error={false}
                  data={balanceTableData}
                />
                <Button mode='text' color={themeColors.accent} onPress={() => dispatch(setStatementRequestVisible(true))}>{`Request a Statement`}</Button>

                <ContentDivider />
                <Subheading style={Typography.defined.sectionListHeader}>{'Legal Documents'} </Subheading>
                <Paragraph>{`Access all the legal documents and information related to your ${JAR_NAME_GROUP}.`}</Paragraph>
                {
                  groupPension
                    ? <>
                        <Button
                          mode='text'
                          color={themeColors.accent} 
                          onPress={() => mainAppNavigate(MainAppNavScreen.DOCUMENTS, { accountId: groupPension.id })}>
                          {'View Messages & Documents'}
                        </Button>
                      </>
                    : <></>
                }
                <Button
                  mode='text'
                  color={themeColors.accent} 
                  onPress={() => setShowDocsModal(true)}>
                  {'View Legal Documents'}
                </Button>
                <Portal>
                  <LegalDocumentsModal
                    client={client}
                    visible={showDocsModal}
                    onDismiss={() => setShowDocsModal(false)}
                  />
                </Portal>

                <View style={{ paddingTop: Sizing.x30 }}></View>
              </RefreshableScrollView>
            </View>
          </>
          : pendingEnrolment ?
          <>
            <ScrollView>
              <Subheading style={{
                paddingVertical: Sizing.x30,
                paddingHorizontal: Sizing.x30,
              }}>
                {`You have been enrolled by an employer in their ${WORKPLACE_SCHEME_NAME}.`}
              </Subheading>
              <View>
                <View style={{ paddingHorizontal: Sizing.x30 }}>
                  <ActionCard
                    imageSource={{ uri: pendingEnrolment?.groupScheme?.logo }}
                    title={`New Scheme Enrolment`}
                    description={`You have been enrolled by ${pendingEnrolment?.groupScheme?.organizationDisplayName} in their workplace pension scheme.`}
                    size={'large'}
                    onPress={() => rootNavigate('SchemeEnrolmentSetupIntroScreen', { enrolment: pendingEnrolment })}
                    buttonLabel={`Connect Employer Now`}
                  />
                </View>
              </View>
            </ScrollView>
          </>
          : client?.onboardingFlags?.aceptedschemeinvite ?
          <>
            <ScrollView>
              <Subheading style={{
                paddingVertical: Sizing.x30,
                paddingHorizontal: Sizing.x30,
              }}>
                {`When your employer enrols you in their ${WORKPLACE_SCHEME_NAME}, this screen will show you all the details for your ${JAR_NAME_GROUP}.`}
              </Subheading>
              <View>
                <View style={{ paddingHorizontal: Sizing.x30 }}>
                  <ActionCard
                    illustrationFilename={'clock.png'}
                    title={`Pending Employer Enrolment`}
                    description={`We'll send you a message when your employer enrols you in their scheme.`}
                    size={'large'}
                    onPress={goToDashboard}
                    buttonLabel={`Back to Dashboard`}
                  />
                </View>
              </View>
            </ScrollView>
            <FooterButton
              mode='text'
              color={themeColors.accent}
              onPress={goToDashboard}
            >
              {'Back to Dashboard'}
            </FooterButton>
          </>
          : openSchemeInvite ?
          <>
            <ScrollView>
              <Subheading style={{
                paddingVertical: Sizing.x30,
                paddingHorizontal: Sizing.x30,
              }}>
                {`You have been invited by an employer to join their ${WORKPLACE_SCHEME_NAME}.`}
              </Subheading>
              <View>
                <View style={{ paddingHorizontal: Sizing.x30 }}>
                  <ActionCard
                    imageSource={{ uri: openSchemeInvite?.groupScheme?.logo }}
                    title={`New Scheme Invite`}
                    description={`You have been invited by ${openSchemeInvite?.groupScheme?.organizationDisplayName} to join their workplace pension scheme.`}
                    size={'large'}
                    onPress={() => rootNavigate('SchemeInviteAcceptIntroScreen', { schemeInvite: openSchemeInvite })}
                    buttonLabel={`Respond to Invite Now`}
                  />
                </View>
              </View>
            </ScrollView>
            <FooterButton
              mode='text'
              color={themeColors.accent}
              onPress={goToDashboard}
            >
              {'Back to Dashboard'}
            </FooterButton>
          </>
          :
          <>
            <ScrollView>
              <Subheading style={{
                paddingVertical: Sizing.x30,
                paddingHorizontal: Sizing.x30,
              }}>
                {`If an employer enrols you in their ${WORKPLACE_SCHEME_NAME}, this screen will show you all the details for your ${JAR_NAME_GROUP}.`}
              </Subheading>
              <View>
                <View style={{ paddingHorizontal: Sizing.x30 }}>
                  <ActionCard
                    illustrationFilename={'simplify_your_retirement.png'}
                    title={WORKPLACE_SCHEME_NAME}
                    description={`Designed specifically for contractors, freelancers and umbrellas`}
                    size={'large'}
                    onPress={() => setShowGroupSchemeInfoModal(true)}
                    buttonLabel={`Find Out More`}
                  />
                </View>
              </View>
            </ScrollView>
            <View style={{
              ...Flex.override.bottom,
              paddingHorizontal: Sizing.x30,
            }}>
              <Paragraph>{'Aready have an invite or enrolment email? Follow the link in the email to get started.'}</Paragraph>
            </View>
            <FooterButton
              mode='text'
              color={themeColors.accent}
              //@ts-ignore
              onPress={openInbox}
            >
              {'Open Email'}
            </FooterButton>
          </>
        }
        <Portal>
          <GroupSchemeInfoModal
            visible={showGroupSchemeInfoModal}
            onDismiss={() => setShowGroupSchemeInfoModal(false)}
          />
        </Portal>
    </MainAppScreen>
  )
}

const localStyles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: Sizing.x30,
  },
  pageContainer: {
    paddingHorizontal: Sizing.x30,
  },
})