import { ProcessScreen } from 'components/Layout'
import { Headline, Paragraph } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { Button } from 'components/Utility/Button'
import { EmailLinkButton } from 'components/Utility/EmailLinkButton'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { composeIntercomMessage } from 'lib/intercomHelpers'
import { goToMainAppStack } from 'lib/RootNavigation'
import React from 'react'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { AmlStatus, ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch } from 'store/hooks'
import { Paper } from 'styles'

export const SchemeEnrolmentSetup_40_Mismatch = ({ route, navigation }) => {
  const { nextScreen, enrolment, client }: { nextScreen: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}

  const { colors: themeColors } = Paper.useAppTheme()

  const { groupScheme } = enrolment || {}
  const { schemeName, primaryContactUser } = groupScheme || {}
  const { email, firstName, surname } = primaryContactUser || {}

  const verifiedNationalInsuranceNo = client?.amlStatus === AmlStatus.PASS ? client?.nationalInsuranceNo : undefined

  const close = () => {
    goToMainAppStack()
  }

  const contactSupport = async () => {
    await composeIntercomMessage()
  }

  return (
    <ProcessScreen
      isLoading={false}
      error={undefined}
      buttonTitle={'Finish'}
      buttonAction={close}
      enableButton={true}
      headline={`Sorry, your details don't match`}
      subHeading={`Your NI Number does not match the one given to us by your employer`}
    >
      <Paragraph>{`We have your National Insurance Number as:`}</Paragraph>
      <Headline>{formatNationalInsuranceNumber(verifiedNationalInsuranceNo)}</Headline>
      <BulletItem style={{ textAlign: 'left'}}>{`If this is correct, you may need to speak to your employer to check their records.`}</BulletItem>
      <EmailLinkButton
          color={themeColors.accent}
          email={email}
        >{`Email Primary Contact`}
      </EmailLinkButton>
      <BulletItem style={{ textAlign: 'left'}}>{`If you think yours is wrong, or you believe this enrolment is for someone else, please contact Jarvis support.`}</BulletItem>
      <Button
        mode={'text'}
        color={themeColors.accent}
        onPress={contactSupport}
      >
        {'Contact Support'}
      </Button>
      {/* <View style={{ paddingVertical: Sizing.x10 }}>
        <UnborderedTable
          itemContainerStyle={{
            paddingVertical: Sizing.x5,
          }}
          data={schemeInfo?.map(item => {
            return {
              ...item,
              value: <Text style={{ fontWeight: '900' }}>{item.value}</Text>,
            }
          })}
        />
      </View> */}
    </ProcessScreen>
  )
}
