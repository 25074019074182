import { FunctionalAreaStackBuilder, FunctionalAreaStackBuilderScreen } from 'navigation/stacks/FunctionalAreaStackBuilder'
import React from 'react'
import { Enrolments_01_Main } from './Screens'
import { Enrolments_02_Contributions } from './Screens/Enrolments_02_Contributions'
import { Enrolments_03_Requests } from './Screens/Enrolments_03_Requests'
import { GroupSchemeEnrolmentDto } from 'store/dto/account.dto'

export const EnrolmentsFunctionalAreaStack = ({ route, navigation }) => {
  const { screen, enrolmentId, organizationDisplayName }: { screen: string, enrolmentId: string, organizationDisplayName: string } = route?.params || {}

  const screens: FunctionalAreaStackBuilderScreen[] = [
    { 
      navigationId: 'Main',
      component: Enrolments_01_Main,
    },
    { 
      navigationId: 'Contributions',
      component: Enrolments_02_Contributions,
    },
    { 
      navigationId: 'Requests',
      component: Enrolments_03_Requests,
    },
  ]

  return (
    <FunctionalAreaStackBuilder
      navigatorId={'Enrolments'}
      title={'Employer Details'}
      subTitle={organizationDisplayName}
      exitRoute='MainAppStack'
      initialParams={{
        enrolmentId: enrolmentId,
      }}
      initialRouteName={screen ? screen : 'Main'}
      screens={screens}
    />
  )
}
