import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { linkParams } from 'store/authSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingCalculatorRequest, workingCalculatorRequest } from 'store/tempDataSlice'

export const Calculator_20_SpouseChoice = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId }  = route?.params || {}

  const linkConfig = useAppSelector(linkParams)

  const dispatch = useAppDispatch()
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)

  //Setup form
  const formObj = useForm<{ withSpouse: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      withSpouse: workingCalculatorRequestData?.withSpouse
    },
  })
  const { handleSubmit, formState: { isValid } } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingCalculatorRequest({
      ...attributes,
    }))

    //PA-2201 - Removed
    // ampli.calculatorUsage({
    //   processStep: 'spouse_choice',
    //   outcome: attributes.withSpouse ? 'yes' : 'no',
    //   loggedInUserId,
    //   affiliateCode: linkConfig?.affiliateCode,
    //   affiliateRef: linkConfig?.affiliateRef,
    // })
    navigation.navigate(attributes.withSpouse ? nextScreen : 'Budget')
  }

  const spouseOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'No, on my own'
    },
    {
      value: true,
      label: 'Yes, with a partner',
    },
  ]

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Are you planning to retire with a partner?`}
      subHeading={`Retiring as a couple affects your timeline, and the cost of living in retirement`}
    >
      <ManagedSimpleChoiceInput
        name={'withSpouse'}
        formObj={formObj}
        options={spouseOptions}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
    </ProcessScreen>
  )
}
