import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { formatNationalInsuranceNumber, unformatNationalInsuranceNumber } from 'lib/clientHelpers'
import { NATIONAL_INSURANCE_NO_MASK, NINO_REGEX } from 'lib/constants'
import { formatUkDate, getActionDate } from 'lib/dateHelpers'
import { mapMemberToEnrolmentJobMember } from 'lib/enrolmentHelpers'
import { userCanManageJobsForScheme } from 'lib/groupSchemeHelpers'
import { Logger } from 'lib/logger'
import { EmployerAppNavScreen } from 'lib/navigationHelpers'
import { employerAppNavigate } from 'lib/RootNavigation'
import { default as React, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAddGroupSchemeJobWithDataMutation, useGetGroupSchemeMemberQuery, useGetGroupSchemeMembersQuery } from 'store/apiSlice'
import { CreateGroupSchemeJobMemberDto, GroupSchemeJobDataSetFormat, GroupSchemeJobType, GroupSchemeMemberDto } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentEmployerMemberDataId, setShowEmployerAddNinoToEnrolmentJobDataId, setShowEmployerUpdateEnrolmentJobDataId, setSnackbarData, showEmployerAddNinoToEnrolmentJobDataId, showEmployerUpdateEnrolmentJobDataId } from 'store/uxSlice'
import { Paper } from 'styles'

export const EmployerAddNinoToEnrolmentJobModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
    const dispatch = useAppDispatch()
 
    const [showDialog, setShowDialog] = useState(false)

    const currentScheme = useAppSelector(currentGroupScheme)
  
    const userCanManageJobs = userCanManageJobsForScheme(currentScheme)
  
    const currentMemberId = useAppSelector(showEmployerAddNinoToEnrolmentJobDataId)
  
    const { isLoading: memberIsLoading, error: memberError, data: member, refetch: refetchMember } = useGetGroupSchemeMemberQuery({ memberId: currentMemberId, id: currentScheme.id }, { skip: !currentMemberId } )
  
    const { client, employeeEmail, employeeId } = member || {}
    const { firstName, surname, gender, birthDate } = client || {}
  
    const [addMemberJob, { data: addedJob, isLoading: addJobIsLoading, error: addJobError, reset: addJobReset }] = useAddGroupSchemeJobWithDataMutation()
    
    const [niNumberToCheck, setNiNumberToCheck] = useState(undefined)
    const [matchingMembers, setMatchingMembers] = useState(undefined)

    const { data: members, isLoading: membersIsLoading, error: membersError, isFetching: membersIsFetching, refetch: refetchMembers } = useGetGroupSchemeMembersQuery({
      groupSchemeId: currentScheme?.id,
      nationalInsuranceNo: niNumberToCheck
    }, { skip: !niNumberToCheck})
    
    const formObj = useForm<{
      nationalInsuranceNo: string
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    })
  
    const { handleSubmit, setValue, setError, reset, trigger, watch, formState: { isDirty, isValid } } = formObj
  
    //Form refs for focussing
    const nationalInsuranceNoRef = useRef(null)
  
    //Show required fields on load
    useEffect(() => {
      trigger()
    }, [])
  
    const onSubmit = async attributes => {
      const memberUpdate: GroupSchemeMemberDto = {
        ...member,
        client: {
          ...member?.client,
          nationalInsuranceNo: unformatNationalInsuranceNumber(attributes.nationalInsuranceNo),
        },
      }
      const data: CreateGroupSchemeJobMemberDto = {
        jobType: GroupSchemeJobType.MEMBER,
        groupSchemeId: currentScheme.id,
        memberRecords: [mapMemberToEnrolmentJobMember(memberUpdate)]
      }
      addMemberJob(data)
    }
    
    const nationalInsuranceNo = watch('nationalInsuranceNo')

    //Update niNumberToCheck when value changes
    useEffect(() => {  
      setMatchingMembers(null)
      setNiNumberToCheck(nationalInsuranceNo && nationalInsuranceNo.length === NATIONAL_INSURANCE_NO_MASK.length ? unformatNationalInsuranceNumber(nationalInsuranceNo) : undefined)
    }, [nationalInsuranceNo])
  
    //Force refetch when NI number to check changes
    //NOTE: Without doing this, there seems to be a race condition of some kind with form error
    //states becoming out of sync, resulting in the error messages not being displayed consistently
    useEffect(() => {  
      if (niNumberToCheck) {
        refetchMembers()
      }    
    }, [niNumberToCheck])
  
    //Update matchingMembers based on check result
    useEffect(() => {  
      if (membersError || membersIsLoading || membersIsFetching) {
        setMatchingMembers(null)
      } else if (members) {
        setMatchingMembers(members)
      }
    }, [members, membersError, membersIsLoading, membersIsFetching])
  
    //Trigger validation when available changes
    useEffect(() => {
      if (matchingMembers !== undefined) {
        trigger('nationalInsuranceNo')
      }
    }, [matchingMembers])
  
    useEffect(() => {
      if (addedJob) {
        dispatch(setSnackbarData({
          message: `Member Update Enrolment Job added!`,
          subMessage: `We'll send you a message when it has been processed${addedJob?.expectedCompleteAt ? ` (estimated completion at ${getActionDate(addedJob?.expectedCompleteAt )})` : ``}`,
          iconName: 'check-circle-outline',
          duration: 5000,
        }))
        closeAndGoToJobs()
      }
    }, [addedJob])
  
    const close = () => {
      dispatch(setShowEmployerAddNinoToEnrolmentJobDataId(undefined))
    }
    
    const goToMemberView = () => {
      dispatch(setShowEmployerAddNinoToEnrolmentJobDataId(undefined))
      dispatch(setCurrentEmployerMemberDataId(currentMemberId))
    }
  
    const closeAndGoToJobs = () => {
      employerAppNavigate(EmployerAppNavScreen.ENROLMENTS)
      close()
    }
  
    const isLoading = memberIsLoading || addJobIsLoading
    const error: any = memberError || addJobError
  
    const { colors: themeColors } = Paper.useAppTheme()

    const isValidNiNumber = (value: string) => {
      if (value === undefined) {
        return true
      }
      const result = value.match(NINO_REGEX)
      return result ? true : 'Invalid NI Number - please double check'
    }
  
    const isNotEnrolledNiNumber = () => {
      if (matchingMembers === null) {
        return 'Validating...'
      }
      return matchingMembers && matchingMembers?.length === 0 ? true : `Existing scheme member exists for this National Insurance Number.`
    }
  
    return (
      <ModalEditScreen
        formTitle={'Add National Insurance Number'}
        onDismiss={() => dispatch(setShowEmployerAddNinoToEnrolmentJobDataId(undefined))}
        isDirty={isDirty}
        dismissDialogText={'Discard adding National Insurance Number for this member?'}
        error={error}
        errorTryAgain={addJobError ? handleSubmit(onSubmit) : refetchMember}
        errorCancel={addJobError ? addJobReset : close}
        isLoading={isLoading}
        loadingMessage={['Creating enrolment job...']}
        buttonTitle={'Submit National Insurance Number'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid && userCanManageJobs}
        allowTextButton={true}
        textButtonTitle={'Return to Member Details'}
        textButtonAction={isDirty ? () => setShowDialog(true) : goToMemberView}
        footerInfo={userCanManageJobs ? undefined : <Text>{`You do not have permissions to update enrolled members.`}</Text>}
      >
        <ContentDivider />
        <Subheading style={{ color: themeColors.primary }}>{`Member Identification`}</Subheading>
        <UnborderedTable
          data={[
            {
              label: `Jarvis Identifier`,
              value: member?.id,
              copyableValue: member?.id,
            },
            {
              label: `Employee Identifier`,
              value: employeeId || 'Not Provided',
              copyableValue: true,
            },
            {
              label: `Employee Email`,
              value: employeeEmail,
              copyableValue: true,
            },
          ]}
          noContentDivider={true}
        />
        
        <ContentDivider />
        <Subheading style={{ color: themeColors.primary }}>{`Client Details`}</Subheading>
        <UnborderedTable
          data={[
            {
              label: `Name`,
              value: `${firstName} ${surname}`,
            },
            {
              label: `Gender`,
              value: gender,
            },
            {
              label: `Date of Birth`,
              value: birthDate ? formatUkDate(new Date(birthDate)) : '',
            },
          ]}
          noContentDivider={true}
        />
      <Paragraph>{`Please note that client details are managed by the member within the Jarvis client app, and may not exactly match your payroll system.`}</Paragraph>

        <ContentDivider />
        <Subheading style={{ color: themeColors.primary }}>{`Add National Insurance Number`}</Subheading>
        <ManagedTextInput
          ref={nationalInsuranceNoRef}
          name={'nationalInsuranceNo'}
          formObj={formObj}
          label={'National Insurance Number'}
          mask={{
            type: 'custom',
            options: {
              mask: NATIONAL_INSURANCE_NO_MASK,
            }
          }}
          // onSelectionChange={(event) => {
          //   if (event?.nativeEvent?.selection?.start) {
          //     setCursorPosition(event?.nativeEvent?.selection?.start)
          //   }
          // }}
          // keyboardType={cursorPosition < 2 || cursorPosition > 10 ? 'default' : 'numeric'}
          // autoCapitalize={cursorPosition < 2 || cursorPosition > 10 ? 'characters' : undefined}
          autoCapitalize={'characters'}
          autoFocus={true}
          forceCapitals={true}
          returnKeyType={'next'}
          blurOnSubmit={false}
          rules={{
            required: true,
            minLength: {
              value: NATIONAL_INSURANCE_NO_MASK.length,
              message: 'Must be exactly 9 characters'
            },
            maxLength: {
              value: NATIONAL_INSURANCE_NO_MASK.length,
              message: 'Must be exactly 9 characters'
            },
            validate: {
              isValidNiNumber,
              isNotEnrolledNiNumber,
            }
        }} />
        <ConfirmationDialog
          visible={showDialog}
          onCancel={() => setShowDialog(false)}
          title={'Are you sure?'}
          content={`Add National Insurance Number for this member?`}
          cancelLabel={'Cancel'}
          confirmLabel={'Discard Changes'}
          onConfirm={goToMemberView}
        />
      </ModalEditScreen>
    )
  }
    
