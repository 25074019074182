  import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Paragraph } from 'components/Typography'
import React, { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { StyleSheet, View } from 'react-native'
import { DataTable, Portal } from 'react-native-paper'
import { GroupSchemeRole } from 'store/dto/group-organization.dto'
import { Colors, Paper, Sizing } from 'styles'
import { GroupSchemeUserRoleAssertion } from '../AdminGroupSchemeSetUserRolesModal'
import { AdminGroupSchemeUserRolesEditDialog } from './AdminGroupSchemeUserRolesEditDialog'
import { applyOpacity } from 'styles/colors'

type AdminGroupSchemeUserRolesTableRowProps = {
  formObj: UseFormReturn<any>
  name: string
  index: number
}

export const AdminGroupSchemeUserRolesTableRow = (props: AdminGroupSchemeUserRolesTableRowProps) => {
  const { formObj, name, index } = props
  const { control, watch, setValue, trigger, getFieldState } = formObj
  const userRoleAssertion: GroupSchemeUserRoleAssertion = watch(`${name}.${index}`)
  const { userName, userEmail, userId, schemeRole, orgSchemeRole } = userRoleAssertion

  const [showEditModal, setShowEditModal] = useState(false)

  const isSchemeAdmin = schemeRole === GroupSchemeRole.SCHEME_MANAGER
  const hasNoAccess = schemeRole === GroupSchemeRole.DEFAULT && orgSchemeRole === GroupSchemeRole.NONE
  const isInherited = schemeRole === GroupSchemeRole.DEFAULT && orgSchemeRole !== GroupSchemeRole.NONE
  const isOverridden = schemeRole !== GroupSchemeRole.DEFAULT && orgSchemeRole !== GroupSchemeRole.NONE
  const fieldState = getFieldState(`${name}.${index}`)
  const hasChanges = fieldState.isDirty

  const { colors: themeColors } = Paper.useAppTheme()
  const opacity = hasChanges ? themeColors.themeType === 'light' ? 0.2 : 0.1 : undefined
  const backgroundColor = hasChanges ? applyOpacity(themeColors.divider, opacity) : undefined

  const color =
    isInherited ? Colors.neutral.s200
    : undefined
  
  const iconName =
    hasNoAccess ? 'account-cancel-outline'
    : isSchemeAdmin ? 'account-tie-outline'
    : 'account-filter-outline'

  const handleEdit = (
    schemeRole?: GroupSchemeRole,
  ) => {
    if (schemeRole) {
      setValue(`${name}.${index}.schemeRole`, schemeRole, { shouldDirty: true, shouldValidate: true })
    }
    setShowEditModal(undefined)
  }

  return (
    <>
      <DataTable.Row style={{ backgroundColor }} onPress={() => setShowEditModal(true)}>
        <DataTable.Cell style={{ flex: 1 }}>
          <View style={localStyles.cellWrap}>
            <View style={{ width: Sizing.x40 }}>
              <MaterialCommunityIcons
                name={iconName as any}
                size={Sizing.x25}
                color={isInherited ? Colors.neutral.s200 : undefined} />
            </View>
            <Paragraph style={{
              textAlign: 'left',
              color: isInherited ? Colors.neutral.s200 : undefined,
            }}>{`${userName}`}</Paragraph>
          </View>
        </DataTable.Cell>
        <DataTable.Cell>
          <View style={localStyles.cellWrap}>
            <Paragraph style={{
              textAlign: 'left',
              color: isInherited ? Colors.neutral.s200 : undefined,
            }}>{userEmail}</Paragraph>
          </View>
        </DataTable.Cell>
        <DataTable.Cell> 
        <View style={{
          ...localStyles.cellWrap,
          justifyContent: 'flex-end',
        }}>
            <Paragraph style={{
              textAlign: 'right',
              color,
              fontWeight: hasChanges ? 'bold' : undefined,
            }}>
              {
                schemeRole !== GroupSchemeRole.DEFAULT ? schemeRole
                : orgSchemeRole !== GroupSchemeRole.NONE ? orgSchemeRole
                : `(${GroupSchemeRole.NONE})`
              }
            </Paragraph>
          </View>
        </DataTable.Cell>
        <DataTable.Cell>
          <View style={{
            ...localStyles.cellWrap,
            justifyContent: 'space-between',
          }}>
            <Paragraph style={{
              textAlign: 'left',
              color,
            }}>
              {
                hasNoAccess ? 'No role set at organisation level'
                : isInherited ? 'Inherited from organisation level'
                : isOverridden ? 'Overrides organisation level role'
                : ``
              }
            </Paragraph>
            <MaterialCommunityIcons name={'square-edit-outline'} size={Sizing.x20} color={Colors.brand.purple1}/>
          </View>
        </DataTable.Cell>
      </DataTable.Row>
      {
        showEditModal ?
          <Portal>
            <AdminGroupSchemeUserRolesEditDialog
              visible={showEditModal}
              onClose={() => setShowEditModal(false)}
              onSave={handleEdit}
              userRoleAssertion={userRoleAssertion}
            />
          </Portal>
        : <></>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  cellWrap: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: Sizing.x5,
    flex: 1,
  },
})


