import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem, CURRENT_ENTITY_OPTION_VALUE, CURRENT_SEARCH_TERM_OPTION_VALUE, isSpecialValue, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph } from 'components/Typography'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL, MAX_ASSET_CURRENT_VALUE, MAX_ASSET_REGULAR_CONTRIBUTIONS, PERSONAL_TAX_RELIEF_FACTOR } from 'lib/constants'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { Logger } from 'lib/logger'
import { getAssetCategory, isActiveOrRejectedTransfer, isLockedForTransfer, isOfTransferableType, RetirementAssetCategory } from 'lib/retirementAssetHelpers'
import { concat, orderBy, pick } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { ampli } from 'src/ampli'
import { useDeleteRetirementAssetMutation, useGetPensionBrandsQuery, useGetRetirementAssetQuery, useGetSpouseQuery, useLazyGetEmployersQuery, useUpdateRetirementAssetMutation } from 'store/apiSlice'
import { ContributionSource } from 'store/dto/base.dto'
import { RetirementAssetDto, RetirementAssetTransferStatus, RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { useAppDispatch } from 'store/hooks'
import { setEditRetirementAsset } from 'store/uxSlice'
import { Colors, Flex, Sizing } from 'styles'

export type RetirementAssetsEditAssetModalProps = {
  asset: RetirementAssetDto
  onDismiss?: any
}

export const RetirementAssetsEditAssetModal = (props: RetirementAssetsEditAssetModalProps) => {
  return (
    <ModalEditWrap
      screen={<ScreenContent
        {...props}
      />}
    />
  )
}

const ScreenContent = (props: RetirementAssetsEditAssetModalProps) => {
  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setEditRetirementAsset(undefined))
  const onDismiss = props.onDismiss ? props.onDismiss : globalDismiss

  //Get full asset including transfer
  const { data: asset, isLoading: assetIsLoading, error: assetError, isFetching: assetIsFetching, refetch: refetchAssets } = useGetRetirementAssetQuery(props?.asset?.id)
  const { data: spouse, isLoading: spouseIsLoading, isFetching: spouseIsFetching } = useGetSpouseQuery()

  //Disabled if spouse with a real user
  const isSpouse = asset?.clientId === spouse?.id
  const spouseControlled = isSpouse && !!spouse?.userId

  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const [ updateAsset, { data: updatedAsset, error: updateAssetError, isLoading: updateAssetIsLoading, reset: updateAssetReset } ] = useUpdateRetirementAssetMutation()
  const [ deleteAsset ] = useDeleteRetirementAssetMutation()

  const {
    pensionProviderBrandKey: existingPensionProviderBrandKey,
    pensionProviderBrandName: existingPensionProviderBrandName,
    employerId: existingEmployerId,
    employerName: existingEmployerName,
    employerIsPublicSector: existingEmployerIsPublicSector,
  } = props.asset || {} //Use props.asset so we have it immediately

  const existingEmployerOption: AutoCompleteItem = existingEmployerName ? {
    value: existingEmployerId ? existingEmployerId : CURRENT_ENTITY_OPTION_VALUE,
    label: existingEmployerName,
    description: existingEmployerId ? 'Existing choice' : 'Existing employer name set by you',
    extraData: {
      isPublicSector: existingEmployerIsPublicSector,
    }
  } : undefined

  const existingBrandOption: AutoCompleteItem = existingPensionProviderBrandName && !existingPensionProviderBrandKey ? {
    value: CURRENT_ENTITY_OPTION_VALUE,
    label: existingPensionProviderBrandName,
    description: 'Existing pension brand name set by you',
  } : undefined

  const [ getEmployers, { data: employers, error: employersError, isLoading: employersIsLoading, isFetching: employersIsFetching }] = useLazyGetEmployersQuery()
  const [searchTerm, setSearchTerm] = useState<string>(undefined)
  const [publicSectorLocked, setPublicSectorLocked] = useState(!!existingEmployerId)
  const [realEmployerOptions, setRealEmployerOptions] = useState<AutoCompleteItem[]>([])
  const [dummyEmployerOption, setDummyEmployerOption] = useState<AutoCompleteItem>(existingEmployerOption)
  const [dummyBrandOption, setDummyBrandOption] = useState<AutoCompleteItem>(existingBrandOption)
  const [employerOptions, setEmployerOptions] = useState<AutoCompleteItem[]>(existingEmployerOption ? [existingEmployerOption] : [])

  const handleDelete = () => {
    Logger.info(`Deleting asset...`)
    ampli.retirementAssetDelete({
      ...pick(asset, [
        'assetType',
        'isTransferable',
        'pensionProviderId',
        'currentValue',
        'monthlyContributionAmount',
        'contributionSource',
        'employerId',
        'employerName',
        'employerIsPublicSector',
        'pensionProviderBrandKey',
        'pensionProviderBrandName',
      ]),
      isSpouse,
      pensionProviderBrandName: asset?.pensionProviderBrandName,
    })
    deleteAsset(asset.id)
    onDismiss()
  }

  //Trigger getEmployers...
  const getSuggestions = useCallback(async q => {
    setSearchTerm(q)
    const filterToken = q.toLowerCase()
    if (typeof filterToken !== 'string' || filterToken.length < 3) {
      setRealEmployerOptions([])
      return
    }
    getEmployers(filterToken, true) //Get employers, preferring cache
  }, [])

  //Update realEmployerOptions when employers chanegs
  useEffect(() => {
    let options: AutoCompleteItem[] = []
    if (employers) {
      options = employers.map(employer => ({
        value: employer.identifier,
        label: employer.legalName,
        extraData: {
          isPublicSector: employer?.isLocalAuthority,
        }
      }))
    }
    if (searchTerm) {
      options.push({
        value: CURRENT_SEARCH_TERM_OPTION_VALUE,
        label: searchTerm,
        description: 'Use this employer name',
      })
    }
    setRealEmployerOptions(options)
  }, [employers])

  //Update employerOptions when real/dummy chaneg
  useEffect(() => {
    const combinedOptions: AutoCompleteItem[] = dummyEmployerOption
      ? concat(dummyEmployerOption, realEmployerOptions)
      : realEmployerOptions
    setEmployerOptions(combinedOptions)
  }, [realEmployerOptions, dummyEmployerOption])
  
  const isLoading = spouseIsLoading || assetIsLoading || brandsIsLoading || updateAssetIsLoading
  const error: any = assetError || brandsError || updateAssetError

  const belongsToSpouse = spouse && asset?.clientId === spouse?.id
  const isTransferableType = asset ? isOfTransferableType(asset) : false

  //Form refs for focussing
  const nameRef = useRef()
  const currentValueRef = useRef()
  const monthlyContributionAmountRef = useRef()

  //Setup form
  const formObj = useForm<{
    name: string
    assetType: RetirementAssetType
    employerId: string
    employerName: string
    employerIsPublicSector: boolean
    pensionProviderBrandKey: string
    pensionProviderBrandName: string
    externalProviderReference: string
    currentValue: number
    monthlyContributionAmount: number
    contributionSource: ContributionSource
    isTransferable: boolean
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, watch, setValue, reset, formState: { errors, isDirty, isValid, defaultValues} } = formObj

  //Re-initialize form when have asset
  useEffect(() => {
    if (asset) {
      reset({
        name: asset?.name, 
        assetType: asset?.assetType,
        employerId: existingEmployerId ? existingEmployerId : existingEmployerOption ? CURRENT_ENTITY_OPTION_VALUE : undefined,
        employerName: asset?.employerName,
        employerIsPublicSector: asset?.employerIsPublicSector !== undefined ? asset?.employerIsPublicSector : undefined,
        pensionProviderBrandKey: existingPensionProviderBrandKey ? existingPensionProviderBrandKey : existingBrandOption ? CURRENT_ENTITY_OPTION_VALUE : undefined,
        pensionProviderBrandName: asset?.pensionProviderBrandName,
        externalProviderReference: asset?.externalProviderReference,
        currentValue: asset?.currentValue,
        monthlyContributionAmount: asset?.monthlyContributionAmount,
        contributionSource: asset?.contributionSource,
        isTransferable: asset?.isTransferable
      })
    }
  }, [asset])

  const personalContribution = watch('contributionSource') === ContributionSource.PERSONAL
  const factor = personalContribution ? 1 + PERSONAL_TAX_RELIEF_FACTOR : 1

  const onSubmit = attributes => {
    const {
      employerId,
      pensionProviderBrandKey,
      name,
      currentValue,
      monthlyContributionAmount,
      contributionSource
    } = attributes || {}

    //Limit payload if locked for transfer
    const payload = isTransferLocked ? {
      id: asset.id,
      name,
      currentValue,
      monthlyContributionAmount,
      contributionSource,
    } : {
      id: asset.id,
      ...attributes,
      employerId: isSpecialValue(employerId) ? null : employerId,
      pensionProviderBrandKey: isSpecialValue(pensionProviderBrandKey) ? null : pensionProviderBrandKey,
      contributionSource: attributes.contributionSource || null,
    }
    Logger.info(`Updating asset`, asset.id)
    updateAsset(payload)
  }

  //Close on save result
  useEffect(() => {
    if (updatedAsset) {
      ampli.retirementAssetUpdate({
        ...pick(updatedAsset, [
          'assetType',
          'isTransferable',
          'currentValue',
          'monthlyContributionAmount',
          'contributionSource',
          'employerId',
          'employerName',
          'employerIsPublicSector',
          'pensionProviderBrandKey',
          'pensionProviderBrandName',
        ]),
        isSpouse: belongsToSpouse,
        pensionProviderBrandName: asset?.pensionProviderBrandName,
      })
      onDismiss()
    }
  }, [updatedAsset])

  //Build brand autocomplete options
  const brandOptions: AutoCompleteItem[] = brands ? orderBy(brands, ['name'], ['asc']).map(brand => {
    return {
      value: brand.key,
      label: brand.name,
      description: brand.description,
      icon: brand?.logo
      ? () => <Image source={{ uri: brand?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      : () => <View style={{
          ...Flex.column.center,
          alignItems: 'center',
        }}>
          <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
      </View>
    }
  }) : []

  if (dummyBrandOption) {
    brandOptions.unshift(dummyBrandOption)
  }
  const monthlyContributionAmount = watch('monthlyContributionAmount')  
  const percentage = `${PERSONAL_TAX_RELIEF_FACTOR * 100}%`

  const assetCategory = getAssetCategory(asset?.assetType)

  const isPension = assetCategory === RetirementAssetCategory.PENSIONS
  const isWorkplace = asset?.assetType === RetirementAssetType.WORKPLACE
  const isSaving = assetCategory === RetirementAssetCategory.SAVINGS
  const isTransferred = asset?.transferStatus === RetirementAssetTransferStatus.COMPLETED
  const isTransferLocked = isLockedForTransfer(asset)
  
  const publicSectorOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: `Yes`
    },
    {
      value: false,
      label: 'No'
    }
  ]

  const transferOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: `Yes, maybe`
    },
    {
      value: false,
      label: 'No, never'
    }
  ]

  const employerName = watch('employerName')

  const contributionSourceOptions: AutoCompleteItem[] = [
    { value: ContributionSource.PERSONAL, label: 'Personal Funds'},
    { value: ContributionSource.EMPLOYER, label: 'Limited Company'},
  ]

  const handleEmployerSelect = (item: AutoCompleteItem) => {
    const isPublicSector = item?.extraData?.isPublicSector === undefined ? null : item?.extraData?.isPublicSector
    if (watch('employerIsPublicSector') !== isPublicSector) {
      setValue('employerIsPublicSector', isPublicSector)
    }
    const publicIsLocked = item?.value && !isSpecialValue(item?.value)
    setPublicSectorLocked(publicIsLocked)
  }

  return (
    <ModalEditScreen
      formTitle={'Update Asset'}
      onDismiss={onDismiss}
      isDirty={isDirty}
      dismissDialogText={'Discard changes for this asset?'}
      onDelete={spouseControlled || isTransferLocked ? undefined : handleDelete}
      deleteDialogText='Really delete this asset?'
      isLoading={isLoading}
      loadingMessage={updateAssetIsLoading ? ['Saving asset...'] : undefined}
      error={error}
      errorTryAgain={updateAssetError ? handleSubmit(onSubmit) : assetError ? refetchAssets : brandsError ? refetchBrands : undefined}
      errorCancel={updateAssetError ? updateAssetReset : onDismiss}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid && (!isWorkplace || employerName) && !updateAssetIsLoading}
    >
      {
        spouseControlled
        ? <Paragraph>{`This information is read only because you have linked your account with ${spouse?.firstName}.`}</Paragraph>
        : isTransferred
        ? <Paragraph>{`Editing this pension is restricted as it has been transferred to your ${JAR_NAME_PERSONAL}.`}</Paragraph>
        : isTransferLocked ?
        <Paragraph>{`Editing this pension is restricted as it is currently being found or transferred to your ${JAR_NAME_PERSONAL}.`}</Paragraph>
        : <></>
      }
      <ManagedAutoCompleteInput
        name={'assetType'}
        formObj={formObj}
        label={'Asset Type'}
        selectOnlyMode={true}
        required={true}
        disabled={true}
        dataSet={enumToAutocompleteOptions(RetirementAssetType)}
      />
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Memorable Name'}
        placeholder={'Enter a memorable name for this asset'}
        blurOnSubmit={true}
        disabled={spouseControlled}
        returnKeyType={'next'}
        //@ts-ignore
        submitHandler={isPension ? undefined : () => currentValueRef?.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
      { isWorkplace ?
        <>
          <ManagedAutoCompleteInput
            name={'employerId'}
            allowFreeText={true}
            textCaptureFieldName={'employerName'}
            unknownItemUseDescription={'Use this employer name'}
            unknownItemSelectedDescription={'Employer name specified by you'}
            formObj={formObj}
            placeholder={'Tap to search...'}
            searchPlaceholder={'Enter 3 or more characters...'}
            label={'Employer'}
            modalTitle={'Find/Enter Employer'}
            dataSet={employerOptions}
            dataTriggerFunction={getSuggestions}
            selectTriggerFunction={handleEmployerSelect}
            required={isWorkplace}
            disabled={spouseControlled || isTransferred || isTransferLocked}
            isLoading={employersIsLoading || employersIsFetching}
          />
          <Paragraph>{`Public sector employer?`}</Paragraph>
          <ManagedSimpleChoiceInput
            name={'employerIsPublicSector'}
            formObj={formObj}
            options={publicSectorOptions}
            required={isWorkplace}
            disabled={spouseControlled || isTransferred || isTransferLocked || publicSectorLocked}
            informationMessage={publicSectorLocked ? 'We already know this from our records' : undefined}
          />
        </>
        : undefined
      }
      { isPension ?
        <ManagedAutoCompleteInput
          name={'pensionProviderBrandKey'}
          allowFreeText={true}
          textCaptureFieldName={'pensionProviderBrandName'}
          unknownItemUseDescription={'Use this name'}
          unknownItemSelectedDescription={'Name specified by you'}
          formObj={formObj}
          placeholder={'Tap to search or enter...'}
          label={'Pension Brand'}
          modalTitle={'Find/Enter Pension Brand'}
          dataSet={brandOptions}
          required={!isWorkplace}
          disabled={spouseControlled || isTransferred || isTransferLocked}
        />
        : undefined
      }
      <ManagedCurrencyInput
        ref={currentValueRef}
        name={'currentValue'}
        formObj={formObj}
        label={isTransferred ? 'Previous Value': isPension || isSaving ? 'Current Value' : 'Value at retirement'}
        placeholder={isPension || isSaving ? 'Enter current value' : 'Enter value at retirement'}
        returnKeyType={'next'}
        required={true}
        minValue={0}
        maxValue={MAX_ASSET_CURRENT_VALUE}
        blurOnSubmit={false}
        disabled={spouseControlled || isTransferred}
        // submitHandler={isPension ? () => monthlyContributionAmountRef?.current?.focus() : undefined }
      />
      { isPension && !isTransferred ?
        <>
          <ManagedCurrencyInput
            ref={monthlyContributionAmountRef}
            name={'monthlyContributionAmount'}
            formObj={formObj}
            label={isWorkplace ? 'Overall Monthly Contributions' : 'Monthly Contributions'}
            placeholder={'Enter monthly contribution amount'}
            returnKeyType={'next'}
            minValue={0}
            maxValue={MAX_ASSET_REGULAR_CONTRIBUTIONS}
            blurOnSubmit={false}
            disabled={spouseControlled}
            informationMessage={
              isWorkplace ? `Total amount from employer and ${belongsToSpouse ? spouse?.firstName : 'yourself'}`
              : personalContribution && monthlyContributionAmount ? `Including ${percentage} government topup: ${formatCurrencyAmount(monthlyContributionAmount * factor, 2)}` : undefined}
          />
        </>
        : undefined
      }
      { watch('monthlyContributionAmount') && isPension && !isWorkplace && !isTransferred  ?
        <ManagedAutoCompleteInput
          name={'contributionSource'}
          formObj={formObj}
          label={'Contribution Source'}
          selectOnlyMode={true}
          required={true}
          dataSet={contributionSourceOptions}
          disabled={spouseControlled}
        />
        : undefined
      }
      { isTransferableType && !belongsToSpouse && !isTransferred  ? 
        <>
          <Paragraph>{`Are you potentialy interested in transferring this pension to your ${JAR_NAME_ALL}?`}</Paragraph>
          <ManagedSimpleChoiceInput
            name={'isTransferable'}
            formObj={formObj}
            options={transferOptions}
            required={false}
            disabled={spouseControlled || isTransferLocked}
          />
        </>
        : <></>
      }
    </ModalEditScreen>
  )
}
