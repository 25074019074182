import { ProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { EmailLinkButton } from 'components/Utility/EmailLinkButton'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import React from 'react'
import { View } from 'react-native'
import { useCheckPendingEnrolmentNiNumberQuery } from 'store/apiSlice'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { AmlStatus, ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingSetupSchemeEnrolment } from 'store/tempDataSlice'
import { Sizing } from 'styles'

export const SchemeEnrolmentSetup_01_About = ({ route, navigation }) => {
  const { nextScreen, enrolment, client }: { nextScreen: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}

  const dispatch = useAppDispatch()

  const { groupScheme, isLinked } = enrolment || {}
  const { organizationName, schemeName, companyNo, primaryContactUser } = groupScheme || {}
  const { email, firstName, surname } = primaryContactUser || {}

  const verifiedNationalInsuranceNo = isLinked && client?.amlStatus === AmlStatus.PASS ? client?.nationalInsuranceNo : undefined

  const { data: niCheckResult, error, isLoading, refetch } = useCheckPendingEnrolmentNiNumberQuery({
    id: enrolment.id,
    nationalInsuranceNo: verifiedNationalInsuranceNo,
  }, { skip: !verifiedNationalInsuranceNo})

  const schemeInfo: UnborderedTableRow[] = [
    {
      label: `Employer`,
      value: organizationName,
    },
    {
      label: `Company No.`,
      value: companyNo,
    },
    {
      label: `Scheme Name`,
      value: schemeName
    },
    {
      label: `Primary Contact`,
      value: `${firstName} ${surname}`
    },
  ]

  const next = () => {
    if (!isLinked) {
      navigation.navigate('NoNino')
    } else if (verifiedNationalInsuranceNo) {
      if (niCheckResult.matches) {
        dispatch(updateWorkingSetupSchemeEnrolment({
          nationalInsuranceNo: verifiedNationalInsuranceNo
        }))
        navigation.navigate('Adopt')
      } else {
        navigation.navigate('Mismatch')
      }
    } else {
      navigation.navigate(nextScreen)
    }
  }

  return (
    <ProcessScreen
      isLoading={isLoading}
      errorTryAgain={refetch}
      error={error}
      buttonTitle={'Next'}
      buttonAction={next}
      enableButton={true}
      headline={`About the Scheme`}
      subHeading={`These are the details of your employer's workplace pension scheme`}
    >
      <View style={{ paddingVertical: Sizing.x10 }}>
        <UnborderedTable
          itemContainerStyle={{
            paddingVertical: Sizing.x5,
          }}
          data={schemeInfo?.map(item => {
            return {
              ...item,
              value: <Text style={{ fontWeight: '900' }}>{item.value}</Text>,
            }
          })}
        />
      </View>
      <Paragraph>{`Please review the details, and if you have any queries, get in touch with the primary contact for your employer.`}</Paragraph>
      <EmailLinkButton
          email={email}
        >{`Email Primary Contact`}</EmailLinkButton>
    </ProcessScreen>
  )
}
