import { ModalProcessResultScreen } from 'components/Layout/ModalProcessResultScreen'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React, { useEffect } from 'react'
import { useAddGroupSchemeEnrolmentRequestMutation } from 'store/apiSlice'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSchemeEnrolmentRequest, workingSchemeEnrolmentRequest } from 'store/tempDataSlice'

export const SchemeEnrolmentSetContributionRequestAdd_99_Result = ({ navigation, route }) => {
  const { onDismiss, resultHandlerFunction, enrolment }: { onDismiss: any, resultHandlerFunction: Function, enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto }  = route?.params || {}
  const dispatch = useAppDispatch()

  const workingSchemeEnrolmentRequestData = useAppSelector(workingSchemeEnrolmentRequest)
  const { data, requestType } = workingSchemeEnrolmentRequestData || {}

  const [ addRequest, { data: addedRequest, isLoading: addRequestIsLoading, error: addRequestError} ] = useAddGroupSchemeEnrolmentRequestMutation()

  const close = () => {
    dispatch(updateWorkingSchemeEnrolmentRequest(undefined))
    onDismiss()
  }

  //On enter, call resultHandlerFunction if provided, else save...
  useEffect(() => {
    if (resultHandlerFunction) {
      resultHandlerFunction(workingSchemeEnrolmentRequestData)
      close()
    } else {
      save()
    }
  }, [])

  const save = () => {
    addRequest({
      id: enrolment.id,
      requestType,
      data,
    })
  }

  //Close on success 
  useEffect(() => {
    close()
  }, [addedRequest])

  return (
    <ModalProcessResultScreen
      headline={``}
      isLoading={addRequestIsLoading}
      loadingMessage={!enrolment?.isVerified ? undefined : ['Submitting request...']}
      error={addRequestError}
      errorTryAgain={save}
      buttonTitle={'Continue'}
      errorCancel={() => navigation.goBack()}
      buttonAction={close}
      showButton={false}
    >
    </ModalProcessResultScreen>
  )
}
