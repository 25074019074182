import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { ActionCard } from 'components/Utility/ActionCard'
import { Button } from 'components/Utility/Button'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { SchemeEnrolmentPreviousPensionAddProcessStack } from 'features/ModalProcesses/SchemeEnrolmentPreviousPensionAdd/SchemeEnrolmentPreviousPensionAddProcessStack'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React, { useState } from 'react'
import { View } from 'react-native'
import { useGetPensionBrandsQuery, useGetPensionProvidersQuery } from 'store/apiSlice'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto, VerifySchemeEnrolmentPreviousPensionDto } from 'store/dto/account.dto'
import { ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSetupSchemeEnrolment, workingSetupSchemeEnrolment } from 'store/tempDataSlice'
import { Colors, Sizing } from 'styles'

export const SchemeEnrolmentSetup_31_AddPreviousPension = ({ route, navigation }) => {
  const { nextScreen, enrolment, client }: { nextScreen: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}

  const [addPreviousPension, setAddPreviousPension] = useState(false)
  const [showDialog, setShowDialog] = useState(false)

  const {
    groupScheme, 
    hasPreviousPensionProviderReference
  } = enrolment || {}
  const {
    previousPensionProviderId,
  } = groupScheme || {}

  const dispatch = useAppDispatch()

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)

  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const { data: providers, error: providersError, isLoading: providersIsLoading, refetch: refetchProviders } = useGetPensionProvidersQuery(undefined, { skip: !previousPensionProviderId })

  const previousProvider = providers && previousPensionProviderId ? providers.find(provider => {
    return provider.id === previousPensionProviderId
  }) : undefined

  const { previousPension } = workingSetupSchemeEnrolmentData || {}

  const { pensionProviderBrandKey } = previousPension || {}

  const selectedBrand = pensionProviderBrandKey && brands ? brands.find(brand => {
    return brand.key === pensionProviderBrandKey
  }) : undefined

  const next = () => {
    navigation.navigate('Results')
  }

  const handleSetPreviousPension = (previousPension: VerifySchemeEnrolmentPreviousPensionDto) => {
    dispatch(updateWorkingSetupSchemeEnrolment({
      previousPension,
    }))
    setAddPreviousPension(false)
  }

  const handleRemovePreviousPension = () => {
    dispatch(updateWorkingSetupSchemeEnrolment({
      previousPension: undefined,
    }))
  }

  const handleConfirm = () => {
    setShowDialog(false)
    next()
  }

  return (
    <>
      {
        addPreviousPension ?
          <SchemeEnrolmentPreviousPensionAddProcessStack
            visible={addPreviousPension}
            onDismiss={() => setAddPreviousPension(false)}
            onComplete={handleSetPreviousPension}
            groupScheme={groupScheme}
            previousProvider={previousProvider}
            hasPreviousPensionProviderReference={hasPreviousPensionProviderReference}
          />
        :
          <ProcessScreen
            buttonTitle={previousPension
              ? 'Finish'
              : `Add Previous Pension`
            }
            buttonAction={previousPension
              ? next
              : () => setAddPreviousPension(true)
            }
            allowTextButton={true}
            textButtonTitle={previousPension
              ? 'Remove previous pension'
              : `Skip / I didn't have one`
            }
            textButtonAction={previousPension
              ? handleRemovePreviousPension
              : () => setShowDialog(true)
            }
            enableButton={true}
            headline={previousPension
              ? `Great, we have that recorded`
              : `Ok, let's capture the details`
            }
            subHeading={previousPension
              ? `Your pension will appear as part of your retirement assets`
              : `This ensures accurate forecasting, and helps consolidate if you wish`
            }
          >
            <View>
              {
                previousPension
                  ? <>
                      <ActionCard
                        imageSource={{ uri: selectedBrand?.logo }}
                        title={previousPension?.name}
                        description={`Estimated Value: ${formatCurrencyAmount(previousPension?.currentValue)}`}
                        size={'small'}
                        onPress={() => {}}
                        buttonLabel={''}
                      />
                      <Paragraph>{`Your pension will contribute to Jarvis forecasting, and be available for transfer when you are ready`}</Paragraph>
                    </>
                  : <>
                      <MaterialCommunityIcons style={{ alignSelf: 'center' }} onPress={() => setAddPreviousPension(true)} name={'plus-circle'} size={Sizing.x60} color={Colors.brand.red3}/>
                      <Button mode='text' onPress={() => setAddPreviousPension(true)} color={Colors.brand.red3}>
                        {`Add Previous Pension`}
                      </Button>
                      <View style={{ paddingTop: Sizing.x20 }}>
                        <View style={{ alignSelf: 'center' }}>
                          <BulletItem style={{ textAlign: 'left' }}>{`Adding previous pensions helps Jarvis understand your wider planning, and accurately predict when you can retire.`}</BulletItem>
                          <BulletItem style={{ textAlign: 'left' }}>{`It can also help you consolidate those pensions when you are ready to do so.`}</BulletItem>
                          <BulletItem style={{ textAlign: 'left' }}>{`If you have already recorded this previous pension from this employer in your Jarvis account, there's no need to do so again.`}</BulletItem>
                        </View>
                      </View>
                    </>
              }
            </View>
            <ConfirmationDialog
              visible={showDialog}
              onCancel={() => setShowDialog(false)}
              title={'Are you sure?'}
              content={`You can add other pensions at any time in the Jarvis app to improve predictions and help you consolidate when you are ready to do so.`}
              confirmLabel={`I'm sure, Finish`}
              onConfirm={handleConfirm}
            />
          </ProcessScreen>
        }
    </>
  )
}
