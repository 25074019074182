import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { formatUkDate } from 'lib/dateHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { userCanManageRequestsForScheme } from 'lib/groupSchemeHelpers'
import { getBestName } from 'lib/userHelpers'
import { concat } from 'lodash'
import { default as React, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { useGetGroupSchemeRequestQuery, useUpdateGroupSchemeRequestMutation } from 'store/apiSlice'
import { GroupSchemeRequestStatus, GroupSchemeRequestType } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentEmployerRequestDataId, currentGroupScheme, setCurrentEmployerRequestDataId, setSnackbarData } from 'store/uxSlice'
import { Paper } from 'styles'

export const EmployerRequestEditModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
  const dispatch = useAppDispatch()
 
  const currentScheme = useAppSelector(currentGroupScheme)
  
  const userCanManageRequests = userCanManageRequestsForScheme(currentScheme)

  const currentRequestId = useAppSelector(currentEmployerRequestDataId)

  const [dialogVisible, setDialogVisible] = useState(false)
  const { isLoading: requestIsLoading, error: requestError, data: request, refetch: refetchRequest } = useGetGroupSchemeRequestQuery({ requestId: currentRequestId, id: currentScheme.id }, { skip: !currentRequestId } )
  const [updateRequest, { data: updateRequestResult, isLoading: updateRequestIsLoading, error: updateRequestError, reset: updateRequestReset }] = useUpdateGroupSchemeRequestMutation()

  const { status, requestType, enrolment, actionDate, actionMessage, client, createdAt, actioner, data } = request || {}
  const { firstName, surname, nationalInsuranceNo, gender, birthDate } = client || {}
  const { employeeEmail, employeeId, enrolmentId } = enrolment || {}
  const { nationalInsuranceNo: dataNationalInsuranceNo, employerContributionAmount, contributionFrequency } = data || {}

  const close = () => {
    dispatch(setCurrentEmployerRequestDataId(undefined))
  }

  const isLoading = requestIsLoading || updateRequestIsLoading
  const error: any = requestError || updateRequestError

  const { colors: themeColors } = Paper.useAppTheme()

  const formObj = useForm<{
    actionMessage: string,
    completed: boolean,
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      actionMessage: '',
    }
  })
  const { handleSubmit, watch, trigger, formState: { isDirty, isValid, errors } } = formObj

  //Form refs for focussing
  const actionMessageRef = useRef(null)

  const onSubmit = attributes => {
    const { completed } = attributes
    updateRequest({
      groupSchemeId: currentScheme?.id,
      requestId: request?.id,
      status: completed ? GroupSchemeRequestStatus.COMPLETED : GroupSchemeRequestStatus.REJECTED,
      actionMessage: completed ? undefined : attributes.actionMessage,
    })
    setDialogVisible(false)
  }

  //Close on action
  useEffect(()=>{
    if (updateRequestResult) {
      dispatch(setSnackbarData({
        message: completed ? `Request marked as actioned` : `Request rejected`,
        subMessage: completed ? `We'll let the recipient know that you have completed their request.` : `We'll let the recipient know that their request was rejected.`,
        iconName: completed ? 'check-circle-outline' : 'close-circle-outline',
        duration: 5000,
      }))
      close()
    }
  },[updateRequestResult])

  const isPending = status === GroupSchemeRequestStatus.PENDING

  const options: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: 'Yes, mark as complete'
    },
    {
      value: false,
      label: 'No, I want to reject the request',
    },
  ]

  const completed = watch('completed')

  //Validate rejection reason on completed change
  useEffect(() => {
    trigger('actionMessage')
    if (!completed) {
      actionMessageRef.current?.focus()
    }
  }, [completed])

  return (
    <ModalEditScreen
      formTitle={'Request Details'}
      onDismiss={() => dispatch(setCurrentEmployerRequestDataId(undefined))}
      isDirty={isDirty}
      dismissDialogText={'Discard changes for this request?'}
      error={error}
      errorCancel={updateRequestError ? updateRequestReset : close}
      errorTryAgain={updateRequestError ? handleSubmit(onSubmit) : undefined}
      isLoading={isLoading}
      loadingMessage={requestIsLoading ? undefined : ['Saving...']}
      buttonTitle={isPending && userCanManageRequests ? 'Save Decision' : 'Close'}
      buttonAction={isPending && userCanManageRequests ? () => setDialogVisible(true) : close}
      showButton={true}
      enableButton={!isPending || !userCanManageRequests || isValid}
      footerInfo={userCanManageRequests ? undefined : <Text>{`You do not have permissions to action requests.`}</Text>}
    >
      <Subheading style={{ color: themeColors.primary }}>{enrolment ? `Member Identification` : `Employee Identification`}</Subheading>
      <UnborderedTable
        data={concat(
          enrolmentId ? [
              {
              label: `Jarvis Identifier`,
              value: enrolmentId,
              copyableValue: true,
            },
          ] : [],
          employeeId ? [
              {
              label: `Employee Identifier`,
              value: employeeId,
              copyableValue: true,
            },
          ] : [],
          employeeEmail ? [
            {
              label: `Employee Email`,
              value: employeeEmail,
              copyableValue: true,
            },
          ] : [],
        )}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Client Details`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `National Insurance Number`,
            value: nationalInsuranceNo ? formatNationalInsuranceNumber(nationalInsuranceNo) : 'Not Yet Provided',
            copyableValue: nationalInsuranceNo,
          },
          {
            label: `Name`,
            value: `${firstName} ${surname}`,
          },
          {
            label: `Gender`,
            value: gender,
          },
          {
            label: `Date of Birth`,
            value: birthDate ? formatUkDate(new Date(birthDate)) : '',
          },
        ]}
        noContentDivider={true}
      />
      <Paragraph>{`Please note that client details are managed by the member within the Jarvis client app, and may not exactly match your payroll system.`}</Paragraph>

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Request Details`}</Subheading>
      <UnborderedTable
        data={concat(
          [
            {
              label: `Identifier`,
              value: request?.id,
              copyableValue: true,
            },
            {
              label: `Request Type`,
              value: requestType,
            },
            {
              label: `Requested On`,
              value: createdAt ? formatUkDate(new Date(createdAt)) : 'n/a',
            },
          ],
          requestType === GroupSchemeRequestType.SET_GROSS_CONTRIBUTION ? [
            {
              label: `Gross Amount Requested`,
              value: formatCurrencyAmount(employerContributionAmount, 2),
            },
            {
              label: `Requested Frequency`,
              value: contributionFrequency,
            },
            {
              label: `Notes`,
              value: `When actionining this request, please verify that the requested frequency matches your payroll pension contribution cycle.`,
            },
          ] : [],
          requestType === GroupSchemeRequestType.OPT_IN ? [
            {
              label: `Employee Email`,
              value: data?.email,
              copyableValue: true,
            },
            {
              label: `National Insurance No`,
              value: formatNationalInsuranceNumber(dataNationalInsuranceNo),
              copyableValue: dataNationalInsuranceNo,
            },
          ] : [],
          requestType === GroupSchemeRequestType.ADD_NINO ? [
            {
              label: `National Insurance No`,
              value: formatNationalInsuranceNumber(dataNationalInsuranceNo),
              copyableValue: dataNationalInsuranceNo,
            },
          ] : [],
        )}
        noContentDivider={true}
      />
      
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Status/History`}</Subheading>
      <UnborderedTable
        data={concat([
          {
            label: `Status`,
            value: status,
          },
          status === GroupSchemeRequestStatus.COMPLETED ? [
            {
              label: `Completed`,
              value: actionDate ? formatUkDate(new Date(actionDate)) : '',
            },
          ] : status === GroupSchemeRequestStatus.REJECTED ? [
            {
              label: `Rejected`,
              value: actionDate ? formatUkDate(new Date(actionDate)) : '',
            },
          ] : [],
          actionMessage ? [{
            label: `Details`,
            value: actionMessage,
          }] : [],
          actioner ? [{
            label: `Actioned By`,
            value: getBestName(actioner),
          }] : [],
        ])}
        noContentDivider={true}
      />

      {
        isPending && userCanManageRequests
        ? <>
            <ContentDivider />
            <Subheading style={{ color: themeColors.primary }}>{`Record Request Decision`}</Subheading>
            <Paragraph>{`Have you actioned this request in your payroll system?`}</Paragraph>
            <ManagedSimpleChoiceInput
              name={'completed'}
              formObj={formObj}
              options={options}
              required={true}
              noSeparator={true}
            />
            {
              <View style={{ display: completed ? 'none' : undefined }}>
                <ManagedTextInput
                  ref={actionMessageRef}
                  name={'actionMessage'}
                  formObj={formObj}
                  label={'Rejection Reason'}
                  placeholder={'Please enter a rejection reason to share with the member'}
                  submitHandler={isValid ? () => setDialogVisible(true) : undefined}
                  rules={{
                    required: completed === false,
                    minLength: 4,
                    maxLength:500,
                }} />
              </View>
            }
          </>
        : <></>
      }

      <ConfirmationDialog
        visible={dialogVisible}
        title={'Are you sure?'}
        content={completed
          ? 'This will mark the request as completed, confirming that you have actioned the change in your payroll system.'
          : 'This will mark the request as rejected, sending your rejection reason to the member.'
        }
        onCancel={() => setDialogVisible(false)}
        onConfirm={handleSubmit(onSubmit)}
        confirmLabel={completed ? 'Confirm as Completed' : 'Confirm Rejection'}
        cancelLabel={'Go Back'}
      />
    </ModalEditScreen>
  )
}
