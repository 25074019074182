import { PaymentMethod, ContributionDto, AccountTransactionStatus, TransactionSubType, AccountType } from "store/dto/account.dto"
import { startCase } from 'lodash'
import { JAR_NAME_GROUP } from "./constants"

export const getTransactionIconName = (transaction: ContributionDto) => {
  if (transaction?.status === AccountTransactionStatus.RESERVED) {
    return 'briefcase-outline'
  } else if (transaction?.status === AccountTransactionStatus.FUTURE) {
    return 'calendar-month'
  } else if (transaction?.transactionSubType === TransactionSubType.TAX_RECLAIM) {
    return 'town-hall'
  } else if (transaction?.accountType === AccountType.GROUP_PENSION) {
    if (transaction?.transactionSubType === TransactionSubType.EMPLOYER) {
      return 'briefcase-outline'
    } else {
      return 'briefcase-account-outline'
    }
  } else if (transaction?.isRecurring) {
    return 'repeat'
  } else if (transaction?.method === PaymentMethod.TRANSFER) {
    return 'consolidate'
  } else {
    return 'bank-transfer-in'
  }
}

export const getTransactionTitle = (transaction: ContributionDto) => {
  if (transaction?.status === AccountTransactionStatus.RESERVED) {
    return 'Workplace Contributions'
  } else if (transaction?.transactionSubType === TransactionSubType.TAX_RECLAIM) {
    return 'Tax Relief'
  } else if (transaction?.transactionSubType === TransactionSubType.TRANSFER) {
    return 'Pension Transfer'
  } else if (transaction?.accountType === AccountType.PERSONAL_PENSION) {
    if (transaction?.isRecurring) {
      return 'Monthly Contribution'
    } else {
      return 'One-off Contribution'
    }
  } else if (transaction?.transactionSubType === TransactionSubType.EMPLOYEE) {
      return 'Employee Contribution'
  } else {
    return 'Employer Contribution'
  }
}

export const getTransactionDescription = (transaction: ContributionDto, employerName?: string) => {
  if (transaction?.status === AccountTransactionStatus.RESERVED) {
    const employer = employerName ? startCase(employerName) : 'Your Employer'
    return `From ${employer}`
  } else if (transaction?.transactionSubType === TransactionSubType.TAX_RECLAIM) {
    return 'From HMRC'
  } else if (transaction?.transactionSubType === TransactionSubType.TRANSFER) {
    return 'From Previous Provider'
  } else if (transaction?.accountType === AccountType.PERSONAL_PENSION) {
    if (transaction?.transactionSubType === TransactionSubType.EMPLOYER) {
      return `From Limited Company`
    } else {
      return `From Personal Funds`
    }
  } else {
    const employer = employerName ? startCase(employerName) : 'Your Employer'
    return `From ${employer}`
  }
}

export const getContributionStatus = (contribution: ContributionDto) => {
  switch (contribution?.status) {
    case AccountTransactionStatus.FUTURE:
      return 'Upcoming'
    case AccountTransactionStatus.PENDING:
      return 'Pending'
    case AccountTransactionStatus.COMPLETED:
    case AccountTransactionStatus.RESERVED:
      return 'Completed'
    case AccountTransactionStatus.DELETED:
      return 'Cancelled'
    default:
      return ''
  }
}

export const getContributionStatusDescription = (contribution: ContributionDto) => {
  const { accountName } = contribution || {}
  switch (contribution?.status) {
    case AccountTransactionStatus.FUTURE:
      return 'Based on your regular contribution schedule'
    case AccountTransactionStatus.PENDING:
      return `Pending receipt into your ${accountName}`
    case AccountTransactionStatus.COMPLETED:
    case AccountTransactionStatus.RESERVED:
      return `Received into your ${accountName}`
    case AccountTransactionStatus.DELETED:
      return 'Cancelled by you'
    default:
      return ''
  }
}
