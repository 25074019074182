import { RetirementBudgetTab } from 'components/Layout/RetirementBudgetTab'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { InformationButton, InformationModalLink, NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { GROSS_TOTAL_BUDGET_ID, NET_TOTAL_BUDGET_ID } from 'lib/constants'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { ampli } from 'src/ampli'
import { useGetCalculatorBudgetsQuery } from 'store/apiSlice'
import { linkParams } from 'store/authSlice'
import { BudgetDto, BudgetLevelIdentifier } from 'store/dto/reference-data.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingCalculatorRequest, workingCalculatorRequest } from 'store/tempDataSlice'
import { layoutStyles } from 'styles/common'
const DEFAULT_BUDGET_LEVEL = BudgetLevelIdentifier.MODERATE

export const Calculator_30_Budget = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId }  = route?.params || {}
  
  const linkConfig = useAppSelector(linkParams)

  let selectedLevel = DEFAULT_BUDGET_LEVEL

  const dispatch = useAppDispatch()

  const [currentLevel, setCurrentLevel] = useState<BudgetLevelIdentifier>(selectedLevel)

  //Temp state
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)
  // let netTotalBudget: BudgetDto
  let grossTotalBudget: BudgetDto
  let categoryBudgets: BudgetDto[] = []

  const { data: budgets, error, isLoading, refetch } = useGetCalculatorBudgetsQuery({
    asCouple: workingCalculatorRequestData?.withSpouse,
    insideLondon: false,
  }, { skip: !workingCalculatorRequestData })

  const updateLevelFromState = (e) => {
    const index = e?.state?.index
    const routeNames = e?.state?.routeNames
    const currentName = index !== undefined && routeNames && routeNames.length > index ? routeNames[index] : undefined
    selectedLevel = currentName
    setCurrentLevel(selectedLevel)
  }

  const onSubmit = () => {
    const netTotalBudgets = budgets.find(budget => {
      return budget.id === NET_TOTAL_BUDGET_ID
    })
    const netBudgetLevel = netTotalBudgets.levels.find(l => {
      return l.id === currentLevel
    })
    const netBudgetTotalAmount = netBudgetLevel?.value || 0

    const grossTotalBudgets = budgets.find(budget => {
      return budget.id === GROSS_TOTAL_BUDGET_ID
    })
    const grossBudgetLevel = grossTotalBudgets.levels.find(l => {
      return l.id === currentLevel
    })
    const grossBudgetTotalAmount = grossBudgetLevel?.value || 0

    const expenses = budgets.filter(budget => {
      return budget.id !== NET_TOTAL_BUDGET_ID && budget.id !== GROSS_TOTAL_BUDGET_ID
    }).map(budget => {
      //Extract budget level
      const budgetLevel = budget.levels.find(l => {
        return l.id === currentLevel
      })
      //Construct expense item
      return {
        id: budget.id,
        selectedLevel: budgetLevel?.id || BudgetLevelIdentifier.CUSTOM, 
        selectedCost: budgetLevel?.value || 0
      }
    })

    //Update the working retirement profile
    dispatch(updateWorkingCalculatorRequest({
      expenses,
      baseBudgetLevel: currentLevel,
      netBudgetTotalAmount,
      grossBudgetTotalAmount,
    }))

    //PA-2201 - Removed
    // ampli.calculatorUsage({
    //   processStep: 'budget_choice',
    //   outcome: currentLevel,
    //   loggedInUserId,
    //   affiliateCode: linkConfig?.affiliateCode,
    //   affiliateRef: linkConfig?.affiliateRef,
    // })

    //Navigate
    navigation.navigate(nextScreen)
  }

  //Extract budgets when available
  if (budgets) {
    const temp = []
    budgets.forEach(budget => {
      if (budget.id !== NET_TOTAL_BUDGET_ID && budget.id !== GROSS_TOTAL_BUDGET_ID) {
        temp.push(budget)
      }
    })
    categoryBudgets = temp
  }

  const netTotalBudget = budgets ? budgets.find(budget => {
    return budget.id === NET_TOTAL_BUDGET_ID
  }) : undefined

  const informationTexts: string[] = [
    `Retirement is different for everyone. That's the point. You get to decide how to live and how to spend your time and money. Creating a realistic budget for your retirement lifestyle is key to effective planning.`,
    `Using the 'Retirement Living Standards' data from the Pension And Lifetime Savings Association (PLSA), Jarvis can help you create a budget that suits your situation and aspirations.`,
    `For simplicity, this calculator let's you choose from the PLSA budget levels, which appropriate retirement budgets for either an individual or a couple. The full Jarvis app let's you further customize your budget to suit your own circumstances and lifestyle.`,    
    `The budgets shown are in today's terms and are an estimate of the expenses you will would incur for a given lifestyle. i.e. these are the amounts you would spend today for these lifestyles - the Jarvis forecasting engine is smart enough to know how much this would equal in the future when you are retired.`,
]

  const informationLinks: InformationModalLink[] = [
    {
      title: `Retirement Living Standards`,
      url: `https://www.retirementlivingstandards.org.uk/`,
    },
    {
      title: `Pension And Lifetime Savings Association`,
      url: 'https://www.plsa.co.uk/'
    }
  ]

  return (
    <ProcessScreen
      isLoading={isLoading}
      loadingMessage={['Getting budget options...']}
      error={error}
      errorTryAgain={refetch}
      buttonTitle={`Choose ${currentLevel}`}
      buttonAction={onSubmit}
      showButton={true}
      headline={`Next up, time to pick a target lifestyle`}
      subHeading={`Understanding your retirement budget is key to knowing how much to save`}
      subHeadingInfo={<InformationButton title={`Budget Levels Explained`} texts={informationTexts} links={informationLinks} illustrationFilename={'combined_retirement_timeline.png'} buttonTitle={`About Budget Levels`} />}
    >
      <RetirementBudgetTab
        netTotalBudget={netTotalBudget}
        categoryBudgets={categoryBudgets}
        initialLevel={DEFAULT_BUDGET_LEVEL}
        stateListenerFunction={updateLevelFromState}
      />
    </ProcessScreen>
  )
}
