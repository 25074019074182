import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ModalProcessScreen } from 'components/Layout'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { JAR_NAME_ALL } from 'lib/constants'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { ampli } from 'src/ampli'
import { useUpdateAccountMutation } from 'store/apiSlice'
import { MakeContributionsData } from 'store/uxSlice'
import { Colors, Flex, Sizing } from 'styles'

export const MakeContributions_49_CancelRegularContributionSave = ({ route, navigation }) => {
  const { nextScreen, contributionsData, onDismiss }: { nextScreen: string, contributionsData: MakeContributionsData, onDismiss: any } = route?.params || {}

  const [updateAccount, { data: updatedAccount, error: updatedAccountError, isLoading: updatedAccountIsLoading, reset: updatedAccountReset }] = useUpdateAccountMutation()
  const isLoading = updatedAccountIsLoading
  const error: any = updatedAccountError

  const [showDialog, setShowDialog] = useState(false)

  //Save on enter
  useEffect(() => {
    saveAccount()
  }, [])

  const saveAccount = () => {
    updateAccount({
      id: contributionsData?.account?.id,
      regularContribution: null
    })
    ampli.contributionChangeRegular({
      amount: 0,
      dayOfMonth: 0,
    })

  }

  //Clear data and either close or next step
  const next = () => {
    setShowDialog(false)
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      loadingMessage={['Cancelling regular contributions...']}
      error={error}
      errorTryAgain={saveAccount}
      errorCancel={updatedAccountReset}
      buttonTitle={'Confirm'}
      buttonAction={() => setShowDialog(true)}
      showButton={true}
      headline={`Ok, we'll no longer expect regular contributions`}
      subHeading={`You must cancel the existing standing order with your bank`}
    >
      <View style={{
        ...Flex.column.start,
        paddingTop: Sizing.x30,
      }}>
        <MaterialCommunityIcons style={{ alignSelf: 'center' }} name={'alert-circle'} size={Sizing.x100} color={Colors.warning.s400} />
      </View>
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'Please confirm'}
        content={`I have cancelled the existing standing order to my ${JAR_NAME_ALL} in my banking app/website.`}
        onConfirm={next}
        cancelLabel={`Go back`}
        confirmLabel={`Yes, I've done it`}
      />
    </ModalProcessScreen>
  )
}
