import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { RefreshableScrollView } from 'components/ScreenTemplates/RefreshableScrollView'
import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { LegalDocumentsModal } from 'components/Utility/LegalDocumentsModal'
import { Loading } from 'components/Utility/Loading'
import { PensionInvestmentInformationCard } from 'components/Utility/PensionInvestmentInformationCard'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { addMonths, format, getDate, setDate } from 'date-fns'
import { OnboardingGuard } from 'features/Onboarding/components/OnboardingGuard'
import { mainAppNavigate, rootNavigate } from 'lib/RootNavigation'
import { getGroupPension, getInvestmentChoiceDataForAccount, getPersonalPension } from 'lib/accountHelpers'
import { JAR_NAME_PERSONAL } from 'lib/constants'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { OnboardingStateStepIdentifier } from 'providers'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Portal } from 'react-native-paper'
import { useGetGroupSchemeEnrolmentsQuery, useGetMeQuery, useGetSpouseQuery, useGetUserAccountsQuery } from 'store/apiSlice'
import { useAppDispatch } from 'store/hooks'
import { setStatementRequestVisible } from 'store/uxSlice'
import { Paper, Sizing, Typography } from 'styles'
import { layoutStyles } from 'styles/common'

const isWeb = platformIsWeb()

export const PersonalPensionScreen = () => {

  const dispatch = useAppDispatch()
  
  const [showDocsModal, setShowDocsModal] = useState(false)

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery()
  const { data: enrolments, error: enrolmentsError, isLoading: enrolmentsIsLoading, refetch: refetchEnrolments } = useGetGroupSchemeEnrolmentsQuery()
  const { data: client, error: clientError, isLoading: clientIsLoading, refetch: refetchClient } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const refetchAll = () => {
    refetchClient()
    refetchSpouse()
    refetchAccounts()
  }
  
  const personalPension = getPersonalPension(accounts)
  const groupPension = getGroupPension(accounts)
  const workplaceIsActive = !!groupPension || enrolments?.length > 0
  
  const isLoading = clientIsLoading || accountsIsLoading || enrolmentsIsLoading || spouseIsLoading
  const error: any = clientError || accountsError || enrolmentsError
  
  const { colors: themeColors } = Paper.useAppTheme()

  const { valueReport } = personalPension || {}

  const totalGrowth = valueReport ? valueReport?.realisedGrowthTotal + valueReport?.retainedGrowthTotal : 0

  const totalPendingContributionValue = personalPension?.pendingContributionValue || 0
  const totalPendingTaxReclaimValue = personalPension?.pendingTaxReclaimValue || 0
  const totalPendingValue = totalPendingContributionValue + totalPendingTaxReclaimValue

  const monthlyAmount = personalPension?.regularContribution?.amount
  const dayOfMonth = personalPension?.regularContribution?.dayOfMonth

  const spouseMonthlyAmount = spouse?.totalMonthlyContribution || 0

  const today = new Date()
  const thisMonthDate = dayOfMonth ? setDate(today, dayOfMonth) : today
  const nextPaymentDate = dayOfMonth < getDate(today) ? addMonths(thisMonthDate, 1) : thisMonthDate

  let balanceTableData: UnborderedTableRow[] = []

  if (personalPension) {
    //Charges
    balanceTableData.push({
      label: `Opening Balance`,
      subLabel: format(new Date(personalPension?.createdAt), 'do MMMM yyyy'),
      value: formatCurrencyAmount(0, 2),
      isTotal: true,
    }) 

    //Contributions
    balanceTableData.push({
      label: `Contributions`,
      iconSource: <MaterialCommunityIcons name={'bank-transfer-in'} size={Sizing.x20} color={themeColors.primary} />,
      value: formatCurrencyAmount(valueReport?.contributionsTotal || 0, 2),
      linkFunction: () => mainAppNavigate(MainAppNavScreen.CONTRIBUTIONS, { accountId: personalPension.id }),
    })

    //Tax Relief
    balanceTableData.push({
      label: `HMRC Tax Relief`,
      iconSource: <MaterialCommunityIcons name={'town-hall'} size={Sizing.x20} color={themeColors.primary} />,
      value: formatCurrencyAmount(valueReport?.taxReclaimTotal || 0, 2),
      linkFunction: () => mainAppNavigate(MainAppNavScreen.CONTRIBUTIONS, { accountId: personalPension.id }),
    })

    //Transfers
    balanceTableData.push({
      label: `Transfers In`,
      iconSource: <MaterialCommunityIcons name={'consolidate'} size={Sizing.x20} color={themeColors.primary} />,
      value: formatCurrencyAmount(valueReport?.transfersInTotal || 0, 2),
      linkFunction: () => mainAppNavigate(MainAppNavScreen.TRANSFERS),
    }) 

    //Investment Growth
    balanceTableData.push({
      label: <Text style={{textAlign: 'left', color: totalGrowth < 0 ? themeColors.accent : themeColors.primary }}>{`Investment Growth`}</Text>,
      iconSource: <MaterialCommunityIcons name={'chart-areaspline-variant'} size={Sizing.x20} color={totalGrowth < 0 ? themeColors.accent : themeColors.primary} />,
      value: <Text style={{color: totalGrowth < 0 ? themeColors.accent : themeColors.primary }}>{`${totalGrowth < 0 ? '-' : ''}${formatCurrencyAmount(totalGrowth, 2)}`}</Text>,
      linkFunction: () => mainAppNavigate(MainAppNavScreen.STATEMENTS),
    })

    //Other Income
    if (valueReport?.incomeTotal > 0) {
      balanceTableData.push({
        label: `Other Income`,
        iconSource: <MaterialCommunityIcons name={'cash-plus'} size={Sizing.x20} color={themeColors.primary} />,
        value: formatCurrencyAmount(valueReport?.incomeTotal || 0, 2),
        linkFunction: () => mainAppNavigate(MainAppNavScreen.STATEMENTS),
      })
    }

    //Charges
    balanceTableData.push({
      label: <Text style={{ textAlign: 'left', color: valueReport?.chargesTotal < 0 ? themeColors.accent : themeColors.primary }}>{`Total Charges`}</Text>,
      iconSource: <MaterialCommunityIcons name={'cash-register'} size={Sizing.x20} color={valueReport?.chargesTotal < 0 ? themeColors.accent : themeColors.primary} />,
      value: <Text style={{color: valueReport?.chargesTotal < 0 ? themeColors.accent : themeColors.primary }}>{`${valueReport?.chargesTotal < 0 ? `-` : ``}${formatCurrencyAmount(valueReport?.chargesTotal || 0, 2)}`}</Text>,
      linkFunction: () => mainAppNavigate(MainAppNavScreen.STATEMENTS),
    }) 

    //Overall Balance
    balanceTableData.push({
      label: `Current Balance`,
      subLabel: format(new Date(personalPension?.updatedAt), 'do MMMM yyyy'),
      value: formatCurrencyAmount(valueReport?.closingValue || 0, 2),
      isTotal: true,
    })
  }

  const investmentChoiceDataForAccount = personalPension && accounts ? getInvestmentChoiceDataForAccount(personalPension, accounts) : undefined
  
  return (
    <MainAppScreen>
      {
        isLoading
        ? <Loading message={getLookingLoadingMessages('Loading your pension...')} />
        : error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> :
        <OnboardingGuard
          explanation={workplaceIsActive
            ? `A ${JAR_NAME_PERSONAL} enables you to make your own contributions, and consolidate old workplace and personal pensions.`
            : `Your ${JAR_NAME_PERSONAL} screen will show you the performance and growth of your SIPP account with us`
          }
          subExplanation={workplaceIsActive
            ? `Get started by verifying your identity and opening your ${JAR_NAME_PERSONAL}.`
            : `You will see information here once you have verified your identity and opened your ${JAR_NAME_PERSONAL}.`
          }
          onboardingSteps={[
            OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
            OnboardingStateStepIdentifier.PLAN_CHOICE,
          ]}
        >
          <View style={[
            layoutStyles.mainContentContainer,
            localStyles.pageContainer,
            ]} >
            <RefreshableScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={true}
              refreshFunctions={[
                refetchAccounts,
              ]}
            >
              {
                personalPension
                ? <>
                    <Subheading style={Typography.defined.sectionListHeader}>{'Current Balance'} </Subheading>
                    <View style={{ paddingTop: Sizing.x10 }}>
                      <Paragraph
                        adjustsFontSizeToFit={true}
                        numberOfLines={1}
                        style={{
                          fontFamily: 'LabGrotesque-Medium',
                          fontWeight: '400',
                          lineHeight: scaleNormalizer(40),
                          fontSize: scaleNormalizer(40),
                          textAlign: 'center',
                        }}>
                          {formatCurrencyAmount(personalPension?.currentValue, 2)}
                      </Paragraph>
                      {
                        totalPendingValue > 0
                          ? <Paragraph>{`Pending Contributions: ${formatCurrencyAmount(totalPendingValue, 2)}`}</Paragraph>
                          : <></>
                      }
                      <Button mode='text' color={themeColors.accent} onPress={() => mainAppNavigate(MainAppNavScreen.CONTRIBUTIONS, { accountId: personalPension.id })}>{`View all Contributions`}</Button>
                    </View>
                    <ContentDivider />

                    <Subheading style={Typography.defined.sectionListHeader}>{'Contribution Plan'} </Subheading>
                    <View>
                      {
                        monthlyAmount > 0
                        ?
                          <>
                            <Paragraph>{'You are making monthly contributions of:'}</Paragraph>
                            <Paragraph
                              adjustsFontSizeToFit={true}
                              numberOfLines={1}
                              style={{
                                fontFamily: 'LabGrotesque-Medium',
                                fontWeight: '400',
                                lineHeight: scaleNormalizer(30),
                                fontSize: scaleNormalizer(30),
                                textAlign: 'center',
                              }}>
                                {formatCurrencyAmount(monthlyAmount, 2)}
                            </Paragraph>
                            <Paragraph>{`Next contribution: ${format(new Date(nextPaymentDate), 'do MMMM')}`}</Paragraph>
                            {
                              spouse && spouseMonthlyAmount
                                ? <Paragraph>{`${spouse.firstName} is also contributing ${formatCurrencyAmount(monthlyAmount, 2)} each month to your joint retirement.`}</Paragraph>
                                : <></>
                            }
                            <Button mode='text' color={themeColors.accent} onPress={() => rootNavigate('ContributionsIntroScreen')}>{`Make or Change Contributions`}</Button>
                          </>
                        :
                        <>
                          <Paragraph>{'You do not currently have monthly contributions setup.'}</Paragraph>
                          {
                            spouse && spouseMonthlyAmount
                              ? <Paragraph>{`However, ${spouse.firstName} is currently contributing ${formatCurrencyAmount(monthlyAmount, 2)} each month to your joint retirement.`}</Paragraph>
                              : <></>
                          }
                          <View style={{ paddingVertical: Sizing.x10 }}>
                            <Button mode='text' color={themeColors.accent}onPress={() => rootNavigate('ContributionsIntroScreen')}>{`Start Regular Contributions`}</Button>
                          </View>
                        </>
                      }
                    </View>
                    <ContentDivider />

                    <Subheading style={Typography.defined.sectionListHeader}>{'Investment Plan'} </Subheading>
                    <PensionInvestmentInformationCard
                      account={investmentChoiceDataForAccount?.account}
                      investmentReferenceAccount={investmentChoiceDataForAccount?.investmentReferenceAccount}
                      showAsGlobalDecision={investmentChoiceDataForAccount?.showAsGlobalDecision}
                      client={client}
                      includePreamble={true}
                    />
                    <View style={{ paddingVertical: Sizing.x10 }}>
                      <Button mode='text' color={themeColors.accent} onPress={() => rootNavigate('InvestmentChoiceIntroScreen', { contextAccount: personalPension, investmentReferenceAccount: investmentChoiceDataForAccount.investmentReferenceAccount })}>{`Change Investment Strategy`}</Button>
                    </View>
                    <ContentDivider />
                    <Subheading style={Typography.defined.sectionListHeader}>{'Balance Summary'} </Subheading>
                    <Paragraph>{`The below table summarises how your current balance is calculated.`}</Paragraph>
                    <UnborderedTable
                      isLoading={false}
                      isFetching={false}
                      error={false}
                      data={balanceTableData}
                    />
                    <Button mode='text' color={themeColors.accent} onPress={() => dispatch(setStatementRequestVisible(true))}>{`Request a Statement`}</Button>
                    <ContentDivider />

                    <Subheading style={Typography.defined.sectionListHeader}>{'Legal Documents'} </Subheading>
                    <Paragraph>{`Access all the messages, legal docuemnts and information related to your ${JAR_NAME_PERSONAL}.`}</Paragraph>
                    <Button
                      mode='text'
                      color={themeColors.accent} 
                      onPress={() => mainAppNavigate(MainAppNavScreen.DOCUMENTS, { accountId: personalPension.id })}>
                      {'View Messages & Documents'}
                    </Button>
                    <Button
                      mode='text'
                      color={themeColors.accent} 
                      onPress={() => setShowDocsModal(true)}>
                      {'View Legal Documents'}
                    </Button>
                    <Portal>
                      <LegalDocumentsModal
                        client={client}
                        visible={showDocsModal}
                        onDismiss={() => setShowDocsModal(false)}
                      />
                    </Portal>
                    <View style={{ paddingTop: Sizing.x30 }}></View>
                  </>
                : <></>
              }
            </RefreshableScrollView>
          </View>
        </OnboardingGuard>
      }
    </MainAppScreen>
  )
}

const localStyles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: Sizing.x30,
  },
  pageContainer: {
    paddingHorizontal: Sizing.x30,
  },
})