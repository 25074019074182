import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { MAX_CLIENT_AGE, MIN_CLIENT_AGE } from 'lib/constants'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSpouse, workingSpouse } from 'store/tempDataSlice'

export const SpouseAdd_07_DateOfBirth = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingSpouseData = useAppSelector(workingSpouse)

  //Setup form
  const formObj = useForm<{ birthDate: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      birthDate: workingSpouseData?.birthDate,
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingSpouse(attributes))
    navigation.navigate('Results')
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Save Partner'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Finally - what's their date of birth?`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.DATE_OF_BIRTH} />}
    >
      <ManagedDateInput
        name={'birthDate'}
        autoFocus={true}
        formObj={formObj}
        // label={'Date of Birth'}
        required={true}
        mustBeInPast={true}
        notOlderThanYears={MAX_CLIENT_AGE}
        notYoungerThanYears={MIN_CLIENT_AGE}
        tryParseTwoDigitYear={true}
        showCurrentAgeMessage={true}
      />
    </ModalProcessScreen>
  )
}
