import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { AutoCompleteItem } from 'components/Inputs/ManagedAutoCompleteMultipleInput'
import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { addYears, formatISO } from 'date-fns'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { formatUkDate, getActionDate } from 'lib/dateHelpers'
import { mapMemberToEnrolmentJobMember } from 'lib/enrolmentHelpers'
import { userCanManageJobsForScheme } from 'lib/groupSchemeHelpers'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { EmployerAppNavScreen } from 'lib/navigationHelpers'
import { employerAppNavigate } from 'lib/RootNavigation'
import { default as React, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { useAddGroupSchemeJobWithDataMutation, useGetGroupSchemeMemberQuery } from 'store/apiSlice'
import { GroupSchemeEnrolmentEnrolmentEndReason, GroupSchemeEnrolmentStatus } from 'store/dto/account.dto'
import { CreateGroupSchemeJobMemberDto, GroupSchemeJobDataSetFormat, GroupSchemeJobType } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentEmployerMemberDataId, setShowEmployerChangeEnrolmentStatusJobDataId, setSnackbarData, showEmployerChangeEnrolmentStatusJobDataId } from 'store/uxSlice'
import { Paper } from 'styles'

export const EmployerChangeEnrolmentStatusJobModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {

    const dispatch = useAppDispatch()

    const [showDialog, setShowDialog] = useState(false)

    const currentScheme = useAppSelector(currentGroupScheme)

    const userCanManageJobs = userCanManageJobsForScheme(currentScheme)
    
    const currentMemberId = useAppSelector(showEmployerChangeEnrolmentStatusJobDataId)
  
    const { isLoading: memberIsLoading, error: memberError, data: member, refetch: refetchMember } = useGetGroupSchemeMemberQuery({ memberId: currentMemberId, id: currentScheme.id }, { skip: !currentMemberId } )
  
    const { client, employeeEmail, employeeId } = member || {}
    const { firstName, surname, title, gender, birthDate, nationalInsuranceNo, addressDetail } = client || {}
  
    const [addMemberJob, { data: addedJob, isLoading: addJobIsLoading, error: addJobError, reset: addJobReset }] = useAddGroupSchemeJobWithDataMutation()
    
    const formObj = useForm<{
      enrolmentStatus: GroupSchemeEnrolmentStatus
      enrolmentEndDate?: string
      enrolmentEndReason?: GroupSchemeEnrolmentEnrolmentEndReason
      autoEnrolmentOptOutDate?: string
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    })
    const { handleSubmit, setValue, setError, reset, trigger, watch, formState: { isDirty, isValid } } = formObj
  
    useEffect(() => {
      if (member) {
        reset({
          enrolmentStatus: member?.enrolmentStatus,
          enrolmentEndDate: member?.enrolmentEndDate || formatISO(new Date()),
          enrolmentEndReason: member?.enrolmentEndReason,
          autoEnrolmentOptOutDate: member?.autoEnrolmentOptOutDate || formatISO(new Date()),
        })
      }
    }, [member])
  
    //Form refs for focussing
    const enrolmentEndDateRef = useRef(null)
    const autoEnrolmentOptOutDateRef = useRef(null)
  
    const onSubmit = async attributes => {
      const { enrolmentStatus, enrolmentEndDate, enrolmentEndReason, autoEnrolmentOptOutDate } = attributes
      const patch =
        enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE ? {
          enrolmentEndDate,
          enrolmentEndReason
        } : enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT ? {
          autoEnrolmentOptOutDate,
        } : enrolmentStatus === GroupSchemeEnrolmentStatus.ACTIVE ? {
          enrolmentEndDate: null,
          enrolmentEndReason: null,
          autoEnrolmentOptOutDate: null,
        } : {}
      const memberUpdate = {
        ...member,
        ...patch,
      }
      const data: CreateGroupSchemeJobMemberDto = {
        jobType: GroupSchemeJobType.MEMBER,
        groupSchemeId: currentScheme.id,
        memberRecords: [mapMemberToEnrolmentJobMember(memberUpdate)]
      }
      addMemberJob(data)
    }
    
    useEffect(() => {
      if (addedJob) {
        dispatch(setSnackbarData({
          message: `Member Status Change Enrolment Job added!`,
          subMessage: `We'll send you a message when it has been processed${addedJob?.expectedCompleteAt ? ` (estimated completion at ${getActionDate(addedJob?.expectedCompleteAt )})` : ``}`,
          iconName: 'check-circle-outline',
          duration: 5000,
        }))
        closeAndGoToJobs()
      }
    }, [addedJob])
  
    const close = () => {
      dispatch(setShowEmployerChangeEnrolmentStatusJobDataId(undefined))
    }

    const goToMemberView = () => {
      dispatch(setShowEmployerChangeEnrolmentStatusJobDataId(undefined))
      dispatch(setCurrentEmployerMemberDataId(currentMemberId))
    }
  
    const closeAndGoToJobs = () => {
      employerAppNavigate(EmployerAppNavScreen.ENROLMENTS)
      close()
    }
  
    const isLoading = memberIsLoading || addJobIsLoading
    const error: any = memberError || addJobError
  
    const { colors: themeColors } = Paper.useAppTheme()
    const enrolmentStatus = watch('enrolmentStatus')
  
    const statusOptions: AutoCompleteItem[] = [
      {
        value: GroupSchemeEnrolmentStatus.ACTIVE,
        label: 'Active',
        description: 'Enrolled / Re-enrolled',
      },
      {
        value: GroupSchemeEnrolmentStatus.INACTIVE,
        label: 'Inactive',
        description: 'Ceased Enrolment',
      },
      {
        value: GroupSchemeEnrolmentStatus.OPTED_OUT,
        label: 'Opted Out',
        description: 'Opted Out Within Opt Out Window'
      },
    ]
  
    return (
      <ModalEditScreen
        formTitle={'Change Member Status'}
        onDismiss={() => dispatch(setShowEmployerChangeEnrolmentStatusJobDataId(undefined))}
        isDirty={isDirty}
        dismissDialogText={'Discard status changes for this member?'}
        error={error}
        errorTryAgain={addJobError ? handleSubmit(onSubmit) : refetchMember}
        errorCancel={addJobError ? addJobReset : close}
        isLoading={isLoading}
        loadingMessage={['Creating enrolment job...']}
        buttonTitle={'Submit Change'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid && userCanManageJobs}
        allowTextButton={true}
        textButtonTitle={'Return to Member Details'}
        textButtonAction={isDirty ? () => setShowDialog(true) : goToMemberView}
        footerInfo={userCanManageJobs ? undefined : <Text>{`You do not have permissions to update enrolled member status.`}</Text>}
      >
        <ContentDivider />
        <Subheading style={{ color: themeColors.primary }}>{`Member Identification`}</Subheading>
        <UnborderedTable
          data={[
            {
              label: `Jarvis Identifier`,
              value: member?.id,
              copyableValue: member?.id,
            },
            {
              label: `Employee Identifier`,
              value: employeeId || 'Not Provided',
              copyableValue: true,
            },
            {
              label: `Employee Email`,
              value: employeeEmail,
              copyableValue: true,
            },
          ]}
          noContentDivider={true}
        />
  
        <ContentDivider />
        <Subheading style={{ color: themeColors.primary }}>{`Client Details`}</Subheading>
        <UnborderedTable
          data={[
            {
              label: `National Insurance Number`,
              value: nationalInsuranceNo ? formatNationalInsuranceNumber(nationalInsuranceNo) : 'Not Yet Provided',
              copyableValue: nationalInsuranceNo,
            },
            {
              label: `Name`,
              value: `${firstName} ${surname}`,
            },
            {
              label: `Gender`,
              value: gender,
            },
            {
              label: `Date of Birth`,
              value: birthDate ? formatUkDate(new Date(birthDate)) : '',
            },
          ]}
          noContentDivider={true}
        />
        <Paragraph>{`Please note that client details are managed by the member within the Jarvis client app, and may not exactly match your payroll system.`}</Paragraph>

        <ContentDivider />
        <Subheading style={{ color: themeColors.primary }}>{'Change Enrolment Status'}</Subheading>
        <ManagedAutoCompleteInput
          name={'enrolmentStatus'}
          formObj={formObj}
          label={'Status'}
          selectOnlyMode={true}
          dataSet={statusOptions}
          required={true}
        />
        <View style={{
          display: enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE ? undefined : 'none',
        }}>
          <ManagedDateInput
            ref={enrolmentEndDateRef}
            name={'enrolmentEndDate'}
            formObj={formObj}
            label={'Enrolment End Date'}
            blurOnSubmit={false}
            required={enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE}
            notBeforeDate={new Date('1900-01-01')}
            notAfterDate={addYears(new Date(), 1)}
          />
          <ManagedAutoCompleteInput
            name={'enrolmentEndReason'}
            formObj={formObj}
            label={'End Reason'}
            selectOnlyMode={true}
            dataSet={enumToAutocompleteOptions(GroupSchemeEnrolmentEnrolmentEndReason)}
            required={enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE}
          />
        </View>
        <View style={{
          display: enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT ? undefined : 'none',
        }}>
          <ManagedDateInput
            ref={autoEnrolmentOptOutDateRef}
            name={'autoEnrolmentOptOutDate'}
            formObj={formObj}
            label={'Opt Out Date'}
            blurOnSubmit={false}
            required={enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT}
            notBeforeDate={new Date('1900-01-01')}
            notAfterDate={addYears(new Date(), 1)}
          />
        </View>
        <ConfirmationDialog
          visible={showDialog}
          onCancel={() => setShowDialog(false)}
          title={'Are you sure?'}
          content={`Discard changes and return to member view?`}
          cancelLabel={'Cancel'}
          confirmLabel={'Discard Changes'}
          onConfirm={goToMemberView}
        />
      </ModalEditScreen>
    )
  
}

