import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { EmailLinkButton } from 'components/Utility/EmailLinkButton'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import React from 'react'
import { View } from 'react-native'
import { useGetPendingEnrolmentByIdQuery } from 'store/apiSlice'
import { Sizing } from 'styles'

export const EnrolmentOptOut_01_Information = ({ route, navigation }) => {
  const { nextScreen, enrolmentId }: { nextScreen: string, enrolmentId: string }  = route?.params || {}
  
  const { data: pendingEnrolment, error: pendingEnrolmentError, isLoading: pendingEnrolmentIsLoading, isFetching: pendingEnrolmentIsFetching, refetch: refetchPendingEnrolment } = useGetPendingEnrolmentByIdQuery(enrolmentId, { skip: !enrolmentId })

  const { groupScheme, isLinked } = pendingEnrolment || {}
  const { schemeName, organizationDisplayName, companyNo, primaryContactUser } = groupScheme || {}
  const { email, firstName, surname } = primaryContactUser || {}

  const schemeInfo: UnborderedTableRow[] = [
    {
      label: `Employer`,
      value: organizationDisplayName,
    },
    {
      label: `Company No.`,
      value: companyNo,
    },
    {
      label: `Scheme Name`,
      value: schemeName
    },
    {
      label: `Primary Contact`,
      value: `${firstName} ${surname}`
    },
  ]

  const next = () => {
    if (!isLinked) {
      navigation.navigate('VerifyEmployeeId')
    } else {
      navigation.navigate(nextScreen)
    }
  }

  return (
    <ProcessScreen
      isLoading={pendingEnrolmentIsLoading}
      error={pendingEnrolmentError}
      errorTryAgain={refetchPendingEnrolment}
      buttonTitle={'Continue'}
      buttonAction={next}
      enableButton={true}
      headline={`About the Scheme`}
      subHeading={`These are the details of your employer's workplace pension scheme`}
    >
      <View style={{ paddingVertical: Sizing.x10 }}>
        <UnborderedTable
          itemContainerStyle={{
            paddingVertical: Sizing.x5,
          }}
          data={schemeInfo?.map(item => {
            return {
              ...item,
              value: <Text style={{ fontWeight: '900' }}>{item.value}</Text>,
            }
          })}
        />
      </View>
      <Paragraph>{`Please review the details, and if you have any queries before opting out, please get in touch with the primary contact for your employer.`}</Paragraph>
      <EmailLinkButton
          email={email}
        >{`Email Primary Contact`}</EmailLinkButton>
    </ProcessScreen>
  )
}
