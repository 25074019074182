import { ProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { JAR_NAME_GROUP } from 'lib/constants'
import React from 'react'
import { View } from 'react-native'
import { GroupSchemeEnrolmentDto, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { ClientMeDto } from 'store/dto/client.dto'

export const SchemeEnrolmentSetup_03_NoNino = ({ route, navigation }) => {
  const { nextScreen, enrolment, client }: { nextScreen: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}

  const { groupScheme } = enrolment || {}
  const { enabledRequestTypes } = groupScheme || {}

  const addNinoRequestEnabled = enabledRequestTypes && enabledRequestTypes.includes(GroupSchemeRequestType.ADD_NINO)

  const next = () => {
    navigation.navigate(nextScreen)
  }

  return (
    <ProcessScreen
      isLoading={false}
      error={undefined}
      buttonTitle={'I understand'}
      buttonAction={next}
      enableButton={true}
      headline={`About your enrolment`}
      subHeading={`Your have been enrolled without a National Insurance Number`}
    >
      <Paragraph>{`In order to unlock all Jarvis features for this employer, you will to obtain a National Insurance Number (if you do not have one), and give it to your employer.`}</Paragraph>
      {
        addNinoRequestEnabled
          ? <Paragraph>{`If you already have it to hand, we'll give you the opportunity to send this to your employer.`}</Paragraph>
          : <></>
      }
      <Paragraph>{`When your employer adds your National Insurance Number:`}</Paragraph>
      <View style={{ alignSelf: 'center' }}>
        <BulletItem style={{ textAlign: 'left' }}>{`All Jarvis features will be enabled for your ${JAR_NAME_GROUP}.`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`We will start the process of combining the value of any contributions made until this point into your ${JAR_NAME_GROUP}. This can take a few weeks to process.`}</BulletItem>
      </View>
    </ProcessScreen>
)
}
