import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { unformatNationalInsuranceNumber } from 'lib/clientHelpers'
import { MAX_CLIENT_AGE, MIN_CLIENT_AGE, NATIONAL_INSURANCE_NO_MASK, NINO_REGEX } from 'lib/constants'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { default as React, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAddGroupSchemeInviteMutation, useGetGroupSchemeInvitesQuery, useGetGroupSchemeMembersQuery } from 'store/apiSlice'
import { Gender, Title } from 'store/dto/base.dto'
import { CreateGroupSchemeInviteDto } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setShowEmployerAddInviteVisible, setSnackbarData, showEmployerAddInviteVisible } from 'store/uxSlice'

export const EmployerAddInviteModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
  //Show required fields on load
  useEffect(() => {
    trigger()
  }, [])

    const dispatch = useAppDispatch()
    const visible = useAppSelector(showEmployerAddInviteVisible)

    const currentScheme = useAppSelector(currentGroupScheme)
    // const [cursorPosition, setCursorPosition] = useState(0)
  
    const [addInvite, { data: addedInvite, isLoading: addInviteIsLoading, error: addInviteError, reset: addInviteReset }] = useAddGroupSchemeInviteMutation()
  
    const [niNumberToCheck, setNiNumberToCheck] = useState(undefined)
    const [matchingInvites, setMatchingInvites] = useState(undefined)
    const [matchingMembers, setMatchingMembers] = useState(undefined)
  
    const { data: invites, isLoading: invitesIsLoading, error: invitesError, isFetching: invitesIsFetching, refetch: refetchInvites } = useGetGroupSchemeInvitesQuery({
      groupSchemeId: currentScheme?.id,
      nationalInsuranceNo: niNumberToCheck,
      accepted: false,
    }, { skip: !niNumberToCheck})
  
    const { data: members, isLoading: membersIsLoading, error: membersError, isFetching: membersIsFetching, refetch: refetchMembers } = useGetGroupSchemeMembersQuery({
      groupSchemeId: currentScheme?.id,
      nationalInsuranceNo: niNumberToCheck
    }, { skip: !niNumberToCheck})
    
    const formObj = useForm<{
      email: string
      nationalInsuranceNo: string
      title: Title
      firstName: string
      surname: string
      gender: Gender
      birthDate: string
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        nationalInsuranceNo: '',
        email: '',
        firstName: '',
        surname: '',
        birthDate: '',
      },
    })
  
    //Form refs for focussing
    const emailRef = useRef(null)
    const nationalInsuranceNoRef = useRef(null)
    const firstNameRef = useRef(null)
    const surnameRef = useRef(null)
    const birthDateRef = useRef(null)
  
    const { handleSubmit, setValue, setError, trigger, watch, formState: { isDirty, isValid } } = formObj
  
    const onSubmit = async attributes => {
      const data: CreateGroupSchemeInviteDto = {
        ...attributes,
        groupSchemeId: currentScheme.id,
        nationalInsuranceNo: unformatNationalInsuranceNumber(attributes.nationalInsuranceNo),
      }
      addInvite(data)
    }
  
    const nationalInsuranceNo = watch('nationalInsuranceNo')
    
    //Update niNumberToCheck when value changes
    useEffect(() => {  
      setMatchingInvites(undefined)
      setMatchingMembers(undefined)
      setNiNumberToCheck(nationalInsuranceNo && nationalInsuranceNo.length === NATIONAL_INSURANCE_NO_MASK.length ? unformatNationalInsuranceNumber(nationalInsuranceNo) : undefined)
    }, [nationalInsuranceNo])
  
    //Force refetch when NI number to check changes
    //NOTE: Without doing this, there seems to be a race condition of some kind with form error
    //states becoming out of sync, resulting in the error messages not being displayed consistently
    useEffect(() => {  
      if (niNumberToCheck) {
        refetchInvites()
        refetchMembers()
      }    
    }, [niNumberToCheck])
  
    //Update matchingInvites based on check result
    useEffect(() => {  
      if (invitesError || invitesIsLoading || invitesIsFetching) {
        setMatchingInvites(undefined)
      } else if (invites) {
        setMatchingInvites(invites)
      }
    }, [invites, invitesError, invitesIsLoading, invitesIsFetching])
  
    //Update matchingMembers based on check result
    useEffect(() => {  
      if (membersError || membersIsLoading || membersIsFetching) {
        setMatchingMembers(undefined)
      } else if (members) {
        setMatchingMembers(members)
      }
    }, [members, membersError, membersIsLoading, membersIsFetching])
  
    //Trigger validation when available changes
    useEffect(() => {
      trigger('nationalInsuranceNo')
    }, [matchingInvites, matchingMembers])
  
    useEffect(() => {
      if (addedInvite) {
        dispatch(setSnackbarData({
          message: `Scheme Invite sent!`,
          subMessage: `If the recipient accepts, you'll receive an 'Opt In' request`,
          iconName: 'email-send-outline',
          duration: 5000,
        }))
        close()
      }
    }, [addedInvite])
  
    const close = () => {
      dispatch(setShowEmployerAddInviteVisible(false))
    }
  
    const isLoading = addInviteIsLoading
    const error: any = addInviteError
  
    const isValidNiNumber = (value: string) => {
      if (value === undefined) {
        return true
      }
      const result = value.match(NINO_REGEX)
      return result ? true : 'Invalid NI Number - please double check'
    }
  
    const isNotInvitedNiNumber = () => {
      if (matchingInvites === undefined) {
        return 'Validating...'
      }
      return matchingInvites && matchingInvites?.length === 0 ? true : `Existing scheme invite exists for this national insurance number.`
    }
  
    const isNotEnrolledNiNumber = () => {
      if (matchingMembers === undefined) {
        return 'Validating...'
      }
      return matchingMembers && matchingMembers?.length === 0 ? true : `Existing scheme member exists for this National Insurance Number.`
    }
  
    return (
      <ModalEditScreen
        formTitle={'Send New Scheme Invite'}
        onDismiss={() => dispatch(setShowEmployerAddInviteVisible(false))}
        isDirty={isDirty}
        dismissDialogText={'Discard changes for this request?'}
        error={error}
        errorTryAgain={addInviteError ? handleSubmit(onSubmit) : undefined}
        errorCancel={addInviteError ? addInviteReset : close}
        isLoading={isLoading}
        loadingMessage={['Sending invite...']}
        buttonTitle={'Send Invite'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        <ManagedTextInput
          ref={emailRef}
          name={'email'}
          keyboardType='email-address'
          formObj={formObj}
          label={'Email Address'}
          placeholder={'Their email address'}
          autoCapitalize={'none'}
          autoCorrect={false}
          autoComplete={'email'}
          returnKeyType={'next'}
          submitHandler={() => nationalInsuranceNoRef.current?.focus()}
          blurOnSubmit={false}
          rules={{
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Invalid email address"
            },
            required: true,
            minLength: 2,
            maxLength: 40,
        }} />
        <ManagedTextInput
          name={'nationalInsuranceNo'}
          autoFocus={false}
          formObj={formObj}
          label={'National Insurance Number'}
          mask={{
            type: 'custom',
            options: {
              mask: NATIONAL_INSURANCE_NO_MASK,
            }
          }}
          // onSelectionChange={(event) => {
          //   if (event?.nativeEvent?.selection?.start) {
          //     setCursorPosition(event?.nativeEvent?.selection?.start)
          //   }
          // }}
          // keyboardType={cursorPosition < 2 || cursorPosition > 10 ? 'default' : 'numeric'}
          // autoCapitalize={cursorPosition < 2 || cursorPosition > 10 ? 'characters' : undefined}
          autoCapitalize={'characters'}
          forceCapitals={true}
          returnKeyType={'next'}
          submitHandler={() => firstNameRef.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: true,
            minLength: {
              value: NATIONAL_INSURANCE_NO_MASK.length,
              message: 'Must be exactly 9 characters'
            },
            maxLength: {
              value: NATIONAL_INSURANCE_NO_MASK.length,
              message: 'Must be exactly 9 characters'
            },
            validate: {
              isValidNiNumber,
              isNotInvitedNiNumber,
              isNotEnrolledNiNumber
            }
        }} />
        <ManagedAutoCompleteInput
          name={'title'}
          formObj={formObj}
          label={'Title'}
          selectOnlyMode={true}
          dataSet={enumToAutocompleteOptions(Title)}
          required={true}
        />
        <ManagedTextInput
          ref={firstNameRef}
          name={'firstName'}
          formObj={formObj}
          label={'First Name'}
          placeholder={'Their first name'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => surnameRef.current?.focus()}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 40,
          }}/>
        <ManagedTextInput
          ref={surnameRef}
          name={'surname'}
          formObj={formObj}
          label={'Surname'}
          placeholder={'Their surname'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 40,
          }}/>
          <ManagedAutoCompleteInput
            required={true}
            formObj={formObj}
            name={'gender'}
            label={'Gender'}
            selectOnlyMode={true}
            dataSet={enumToAutocompleteOptions(Gender)}
          />
          <ManagedDateInput
            ref={birthDateRef}
            name={'birthDate'}
            formObj={formObj}
            label={'Date of Birth'}
            blurOnSubmit={false}
            required={true}
            mustBeInPast={true}
            notOlderThanYears={MAX_CLIENT_AGE}
            notYoungerThanYears={MIN_CLIENT_AGE}
            tryParseTwoDigitYear={true}
            showCurrentAgeMessage={true}
          />
      </ModalEditScreen>
    )
  
}

