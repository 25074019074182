import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Subheading } from 'components/Typography'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { getAssetCategory, getAssetIconName, sortAssets } from 'lib/retirementAssetHelpers'
import React, { useRef } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { ClientBaseDto } from 'store/dto/client.dto'
import { RetirementAssetDto } from 'store/dto/retirement-asset.dto'
import { Flex, Paper, Sizing } from 'styles'

type AssetListProps = {
  client: ClientBaseDto
  assets: RetirementAssetDto[]
  addAssetStateFunction?: Function
  editAssetStateFunction: Function
}

export  const AssetList = (props: AssetListProps) => {
    const { client, assets, addAssetStateFunction, editAssetStateFunction } = props

  const clientAssets = assets.filter(asset => {
    return asset.clientId === client?.id
  })

  const scrollRef = useRef()

  
  type AddRowProps = {
    title: string
    onPress?: any
  }
  const AddRow = (props: AddRowProps) => {
    const { title, onPress } = props
    const { colors: themeColors } = Paper.useAppTheme()
    return (
      <View style={localStyles.rowContainer} >
        <Pressable style={localStyles.itemContainer} onPress={onPress}>
          <View style={localStyles.iconContainer} >
            <MaterialCommunityIcons name={'plus-circle'} size={Sizing.x20} color={themeColors.primary}/>
          </View>
          <View>
            <Subheading>{title}</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading> </Subheading>
          </View>
        </Pressable>
        <ContentDivider />
      </View>
    )
  }

  type RetirementAssetRowProps = {
    asset: RetirementAssetDto
    onPress?: any
  }
  const RetirementAssetRow = (props: RetirementAssetRowProps) => {
    const { asset, onPress } = props
    const { colors: themeColors } = Paper.useAppTheme()
    const assetCategory = getAssetCategory(asset.assetType)
    const iconName: any = getAssetIconName(asset.assetType, assetCategory)
    return (
      <View style={localStyles.rowContainer} >
        <Pressable style={localStyles.itemContainer} onPress={asset?.id ? onPress : undefined}>
          <View style={localStyles.iconContainer} >
            <MaterialCommunityIcons name={iconName} size={Sizing.x20} color={themeColors.primary}/>
          </View>
          <View style={{flex:1}}>
            <Subheading numberOfLines={1} style={{textAlign:'left' }} >{asset.name}</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading>{formatCurrencyAmount(asset.currentValue)}</Subheading>
          </View>
        </Pressable>
        <ContentDivider />
      </View>
    )
  }

  return (
    <View style={localStyles.listContainer}>
      {
          clientAssets ? sortAssets(clientAssets).map((asset, idx) => {
            return (
              <RetirementAssetRow
                key={idx}
                asset={asset}
                onPress={() => editAssetStateFunction(asset)}
              />
            )
          }) : <></>
        }
        { addAssetStateFunction ?
          <AddRow
            title={'Add a pension/asset'}
            onPress={() => addAssetStateFunction(client)}
          /> : <></>
        }
    </View>
  )
}

const localStyles = StyleSheet.create({
  listContainer: {
    paddingTop: Sizing.x20,
    ...Flex.override.fill,
  },
  iconContainer: {
    paddingRight: Sizing.x5,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingHorizontal: Sizing.x10,
  },
  rowContainer: {
    height: Sizing.x50,
  },
  itemContainer: {
    paddingTop: Sizing.x10,
    ...Flex.row.start,
  },
  amountContainer: {
    ...Flex.override.right,
  },
})