import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/Layout'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingInvite, workingInvite } from 'store/tempDataSlice'

export const SpouseInvite_02_FirstName = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingInviteData = useAppSelector(workingInvite)

  //Setup form
  const formObj = useForm<{ firstName: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: workingInviteData?.firstName || '',
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingInvite(attributes))
    navigation.navigate(nextScreen)
  }
  
  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Ok, please correct their first name`}
    >
      <ManagedTextInput
        name={'firstName'}
        autoFocus={true}
        formObj={formObj}
        returnKeyType={"next"}
        placeholder={'Their legal first name'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
    </ProcessScreen>
  )
}
