import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'

import { ProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useCheckPendingEnrolmentEmployeeIdQuery, useGetPendingEnrolmentByIdQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingEnrolmentOptOut, workingEnrolmentOptOut } from 'store/tempDataSlice'
import { useDebounce } from 'use-debounce'

export const EnrolmentOptOut_03_VerifyByEmployeeId = ({ route, navigation }) => {
  const { nextScreen, enrolmentId }: { nextScreen: string, enrolmentId: string }  = route?.params || {}
  
  const { data: pendingEnrolment, error: pendingEnrolmentError, isLoading: pendingEnrolmentIsLoading, isFetching: pendingEnrolmentIsFetching, refetch: refetchPendingEnrolment } = useGetPendingEnrolmentByIdQuery(enrolmentId, { skip: !enrolmentId })

  const { groupScheme, id } = pendingEnrolment || {}

  const { organizationDisplayName } = groupScheme || {}

  const workingEnrolmentOptOutData = useAppSelector(workingEnrolmentOptOut)
  const [employeeIdToCheck, setEmployeeIdToCheck] = useState(undefined)
  const [debouncedEmployeeIdToCheck] = useDebounce(employeeIdToCheck, 1000)
  const [matches, setMatches] = useState(undefined)

  const dispatch = useAppDispatch()

  //Setup form
  const formObj = useForm<{ employeeId: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      employeeId: workingEnrolmentOptOutData?.employeeId,
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingEnrolmentOptOut({
      employeeId: attributes.employeeId
    }))
    navigation.navigate(nextScreen)
  }

  
  //Check the Employee ID matches
  const { data: employeeIdCheckResult, error, isLoading, isFetching, refetch } = useCheckPendingEnrolmentEmployeeIdQuery({
    id: enrolmentId,
    employeeId: debouncedEmployeeIdToCheck,
  }, { skip: !debouncedEmployeeIdToCheck})
  
  const employeeId = watch('employeeId')
  
  //Update employeeIdToCheck when value changes
  useEffect(() => {
    setMatches(undefined)
    setEmployeeIdToCheck(employeeId)
  }, [employeeId])

  //Force refetch when debouncedEmployeeIdToCheck to check changes
  //NOTE: Without doing this, there seems to be a race condition of some kind with form error
  //states becoming out of sync, resulting in the error messages not being displayed consistently
  useEffect(() => {
    if (debouncedEmployeeIdToCheck) {
      refetch()
    }
  }, [debouncedEmployeeIdToCheck])

  //Update available status based on check result
  useEffect(() => {  
    if (error || isLoading || isFetching) {
      setMatches(undefined)
    } else if (employeeIdCheckResult) {
      setMatches(employeeIdCheckResult.matches)
    }
  }, [employeeIdCheckResult, error, isLoading, isFetching])

  //Trigger validation when available changes
  useEffect(() => {
    trigger('employeeId')
  }, [matches])

  const isMatchingEmployeeId = () => {
    if (matches === undefined) {
      return 'Validating...'
    }
    return matches ? true : `No match with your employer's records`
  }

  return (
    <ProcessScreen
      isLoading={pendingEnrolmentIsLoading}
      error={pendingEnrolmentError}
      errorTryAgain={refetchPendingEnrolment}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Please verify your identity`}
      subHeading={`To opt out, please confirm your Employee Identifier`}
    >
      <ManagedTextInput
        name={'employeeId'}
        autoFocus={true}
        formObj={formObj}
        placeholder={'Enter Employee Identifier'}
        blurOnSubmit={true}
        submitHandler={handleSubmit(onSubmit)}
        rules={{
          required: true,
          validate: {
            isMatchingEmployeeId,
          }
      }}/>
      {
        employeeId && matches === false
        ? <Paragraph>{`Please double-check the enrolment email we sent you. If you are sure that you have entered it correctly, please contact your employer to check their records.`}</Paragraph>
        : matches === true
        ? <></>
        : <Paragraph>{'You can find your Employee Identifier on the enrolment email we sent you.'}</Paragraph>
      }
    </ProcessScreen>
  )
}
