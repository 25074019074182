import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingPreviousPension, workingPreviousPension } from 'store/tempDataSlice'

export const SchemeEnrolmentPreviousPensionAdd_03_Reference = ({ route, navigation }) => {
  const { nextScreen, hasPreviousPensionProviderReference }: { nextScreen: string, hasPreviousPensionProviderReference: boolean }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingPreviousPensionData = useAppSelector(workingPreviousPension)

  //Setup form
  const formObj = useForm<{ externalProviderReference: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      externalProviderReference: hasPreviousPensionProviderReference
        ? '********'
        : workingPreviousPensionData?.externalProviderReference
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    if (!hasPreviousPensionProviderReference) {
      dispatch(updateWorkingPreviousPension({
        ...attributes,
      }))  
    }
    next()
  }

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const externalProviderReference = watch('externalProviderReference')

  return (
    <ModalProcessScreen
      buttonTitle={`Confirm`}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={hasPreviousPensionProviderReference
        ? `Your employer gave us your previous pension reference`
        : `Do you know your pension reference?`
      }
      subHeading={hasPreviousPensionProviderReference
        ? `It's hidden below, but well show it to you once you have connected this employer`
        : `If you don't have it to hand, you can find it later when consolidating`
      }
      allowTextButton={!externalProviderReference && !hasPreviousPensionProviderReference}
      textButtonTitle={`Skip for now`}
      textButtonAction={next}
    >
      <ManagedTextInput
        name={'externalProviderReference'}
        autoFocus={false}
        formObj={formObj}
        returnKeyType={'next'}
        placeholder={'Enter reference'}
        autoCapitalize={'characters'}
        blurOnSubmit={true}
        disabled={hasPreviousPensionProviderReference}
        informationMessage={hasPreviousPensionProviderReference
          ? undefined
          : `Typically 6-16 characters long, and could contain numbers and/or letters, sometimes with separating dashes or slashes`
        }
        rules={hasPreviousPensionProviderReference ? {} : {
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
    </ModalProcessScreen>
  )
}
