import { ManagedTextInput } from 'components/Inputs/ManagedTextInput';
import { ModalProcessScreen } from 'components/Layout';
import { ACCOUNT_NO_MASK } from 'lib/constants';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ContributionSource } from 'store/dto/base.dto';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateWorkingContributionSetup, workingContributionSetup } from 'store/tempDataSlice';

export const MakeContributions_07_AccountNumber = ({ route, navigation }) => {
  const dispatch = useAppDispatch();

  const { nextScreen, contributionsData } = route?.params || {}
  const { existingContributionConfiguration } = contributionsData || {}
  
  const workingContributionData = useAppSelector(workingContributionSetup)

  const { bankDetails } = workingContributionData || {}

  const formObj = useForm<{ accountNumber: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      accountNumber: workingContributionData?.bankDetails?.accountNumber
        ? workingContributionData?.bankDetails?.accountNumber
        : undefined
    }
  })
  const { handleSubmit, formState: { isValid }} = formObj

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const onSubmit = attributes => {
    dispatch(updateWorkingContributionSetup({ bankDetails: { ...bankDetails, accountNumber: attributes.accountNumber } }))
    next()
  }
  
  return (
    <ModalProcessScreen
      buttonTitle={'Save Contribution Source'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isValid}
      headline={`And your account number?`}
      subHeading={workingContributionData?.source === ContributionSource.PERSONAL
        ? `Again, for your personal account`
        : `Again, for your business bank account`
      }

    >
      <ManagedTextInput
        name={'accountNumber'}
        keyboardType={'numeric'}
        autoFocus={true}
        formObj={formObj}
        mask={{
          type: 'custom',
          options: {
            mask: ACCOUNT_NO_MASK,
          }
        }}
        blurOnSubmit={true}
        rules={{

          required: true,
          minLength: {
            value: 8,
            message: 'Must be 8 characters'
          },
          maxLength: {
            value: 8,
            message: 'Must be 8 characters'
          },

        }} />
    </ModalProcessScreen>
  )
}

