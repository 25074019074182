import { ProcessScreen } from 'components/Layout'
import { Paragraph, Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { SchemeEnrolmentChangeStatusRequestAddProcessStack } from 'features/ModalProcesses/SchemeEnrolmentChangeStatusRequestAdd/SchemeEnrolmentChangeStatusRequestAddProcessStack'
import { deriveAvailableStatusChangeRequestType, isWithinOptOutWindow } from 'lib/enrolmentHelpers'
import { concat } from 'lodash'
import React, { useState } from 'react'
import { View } from 'react-native'
import { GroupSchemeEnrolmentDto, GroupSchemeEnrolmentStatus, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { WorkingSchemeEnrolmentRequest, updateWorkingSetupSchemeEnrolment, workingSetupSchemeEnrolment } from 'store/tempDataSlice'
import { Sizing } from 'styles'

const handledRequestTypes: GroupSchemeRequestType[] = [
  GroupSchemeRequestType.OPT_IN,
  GroupSchemeRequestType.OPT_OUT,
  GroupSchemeRequestType.CEASE_MEMBERSHIP,
]

export const SchemeEnrolmentSetup_10_Adopt = ({ route, navigation }) => {
  const { nextScreen, enrolment, client }: { nextScreen: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}
  const { enrolmentStatus, groupScheme, isLinked } = enrolment || {}
  
  const { enabledRequestTypes } = groupScheme || {}

  const addNinoRequestEnabled = enabledRequestTypes && enabledRequestTypes.includes(GroupSchemeRequestType.ADD_NINO)

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)
  const currentRequests = workingSetupSchemeEnrolmentData?.requests || []
  const currentChangeStatusRequest = currentRequests.find(request => {
    return handledRequestTypes.includes(request.requestType)
  })

  const [addRequest, setAddRequest] = useState(false)

  const pendingRequestTypes: GroupSchemeRequestType[] = workingSetupSchemeEnrolmentData?.requests ? workingSetupSchemeEnrolmentData?.requests.map(request => {
    return request.requestType
  }) : []

  const requestType = deriveAvailableStatusChangeRequestType(enrolment, pendingRequestTypes)
  const withinOptOutWindow = isWithinOptOutWindow(enrolment)

  const dispatch = useAppDispatch()

  const handleAddRequest = (request: Partial<WorkingSchemeEnrolmentRequest>) => {
    const otherRequests = workingSetupSchemeEnrolmentData?.requests ? workingSetupSchemeEnrolmentData?.requests.filter(request => {
      return !handledRequestTypes.includes(request.requestType)
    }) : []
    const newRequest: WorkingSchemeEnrolmentRequest = {
      requestType: request.requestType,
      data: request.data,
    }
    const requests = concat(otherRequests, newRequest)
    dispatch(updateWorkingSetupSchemeEnrolment({
      requests,
    }))
    setAddRequest(false)
  }

  const handleRemoveRequest = () => {
    const otherRequests = workingSetupSchemeEnrolmentData?.requests ? workingSetupSchemeEnrolmentData?.requests.filter(request => {
      return !handledRequestTypes.includes(request.requestType)
    }) : []
    dispatch(updateWorkingSetupSchemeEnrolment({
      requests: otherRequests,
    }))
  }

  const next = () => {
    navigation.navigate(!isLinked && addNinoRequestEnabled ? nextScreen : 'ContributionInfo')
  }

  const end = () => {
    navigation.navigate('Results')
  }

  return (
    <>
    {
      addRequest ?
        <SchemeEnrolmentChangeStatusRequestAddProcessStack
          onDismiss={() => setAddRequest(false)}
          resultHandlerFunction={handleAddRequest}
          enrolment={enrolment}
          requestType={requestType}
        />
      :
        <ProcessScreen
          isLoading={false}
          error={undefined}
          headline={enrolmentStatus === GroupSchemeEnrolmentStatus.ACTIVE
            ? `About your scheme membership`
            : enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT || enrolmentStatus === GroupSchemeEnrolmentStatus.OPT_OUT_DECLARED
            ? `You have opted out of this scheme`
            : `You have previously left this scheme`
          }
          subHeading={enrolmentStatus === GroupSchemeEnrolmentStatus.ACTIVE
            ? `Your employer has enrolled you in their pension scheme`
            : enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT || enrolmentStatus === GroupSchemeEnrolmentStatus.OPT_OUT_DECLARED
            ? `You previously instructed us that you wished to opt out`
            : `You are not currently an active member of this pension scheme`
          }
          buttonTitle={
            currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_IN ? 'Request Opt In & Continue'
            : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_OUT ? 'Confirm Opt Out'
            : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.CEASE_MEMBERSHIP ? 'Confirm Leave'
            : requestType === GroupSchemeRequestType.OPT_IN ? 'Confirm & Finish'
            : `Confirm Membership`
          }
          buttonAction={
            currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_IN ? next
            : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_OUT ? end
            : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.CEASE_MEMBERSHIP ? end
            : requestType === GroupSchemeRequestType.OPT_IN ? end
            : next
          }
          enableButton={true}
          allowTextButton={true}
          textButtonTitle={
            currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_IN ? 'Cancel Request to Opt In'
            : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_OUT ? 'Cancel Opt Out'
            : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.CEASE_MEMBERSHIP ? 'Cancel Request to Leave'
            : requestType === GroupSchemeRequestType.OPT_IN ? 'Request to Opt In'
            : withinOptOutWindow
              ? 'Opt Out of Scheme'
              : 'Request to Leave'
          }
          textButtonAction={currentChangeStatusRequest
            ? handleRemoveRequest
            : () => setAddRequest(true)}
          footerInfo={
            <Subheading>{
              currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_OUT ? `Please confirm below to notify your employer of your decision to opt out`
              : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_IN ? 'We will send a request to your employer to opt into their scheme'
              : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.CEASE_MEMBERSHIP ? 'We will send a request to your employer to leave their scheme'
              : `If you${requestType === GroupSchemeRequestType.OPT_IN ? ' now' : ' do not'} wish to be part of this employer pension scheme, you may instead ${
                requestType === GroupSchemeRequestType.OPT_IN ? 'request to Opt In'
                  : withinOptOutWindow
                    ? 'Opt Out'
                    : 'request to Leave'
                }.`
            }</Subheading>
          }
        >
          <AppIllustration
            filename={enrolmentStatus === GroupSchemeEnrolmentStatus.ACTIVE
              ? 'great_partnership.png'
              : 'info.png'
            } style={[
            {
              width: Sizing.x100,
              height: Sizing.x100,
              resizeMode: 'contain',
              alignSelf: 'center'
            },
          ]} />
          {
            enrolmentStatus === GroupSchemeEnrolmentStatus.ACTIVE ?
              <>
                <Paragraph>{`Depending on your individual circumstances, you may have been enrolled due to:`}</Paragraph>
                <View style={{ alignSelf: 'center' }}>
                  <BulletItem style={{ textAlign: 'left' }}>{`Automatical enrolment based on the UK Govermnent's pension legislation`}</BulletItem>
                  <BulletItem style={{ textAlign: 'left' }}>{`Elective 'Opt In' to the scheme based on an agreement with your employer.`}</BulletItem>
                </View>
              </>
            : <></>
          }
        </ProcessScreen>
      }
    </>
  )
}
