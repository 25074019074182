import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { goToMainAppStack } from 'lib/RootNavigation'
import { formatNationalInsuranceNumber, unformatNationalInsuranceNumber } from 'lib/clientHelpers'
import { NATIONAL_INSURANCE_NO_MASK, NINO_REGEX } from 'lib/constants'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useCheckPendingEnrolmentNiNumberQuery } from 'store/apiSlice'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSetupSchemeEnrolment, workingSetupSchemeEnrolment } from 'store/tempDataSlice'

export const SchemeEnrolmentSetup_02_VerifyByNino = ({ route, navigation }) => {
  const { nextScreen, enrolment, client }: { nextScreen: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}
  
  const { groupScheme } = enrolment || {}
  const { organizationName } = groupScheme || {}

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)
  const [niNumberToCheck, setNiNumberToCheck] = useState(undefined)
  const [matches, setMatches] = useState(undefined)

  const dispatch = useAppDispatch()
  // const [cursorPosition, setCursorPosition] = useState(0)

  //Setup form
  const formObj = useForm<{ nationalInsuranceNo: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      nationalInsuranceNo: workingSetupSchemeEnrolmentData?.nationalInsuranceNo
        ? formatNationalInsuranceNumber(workingSetupSchemeEnrolmentData?.nationalInsuranceNo)
        : formatNationalInsuranceNumber(client?.nationalInsuranceNo)
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingSetupSchemeEnrolment({
      nationalInsuranceNo: unformatNationalInsuranceNumber(attributes.nationalInsuranceNo)
    }))
    navigation.navigate('Adopt')
  }

  //Check the NI number matches only if 9 characters
  const { data: niCheckResult, error, isLoading, isFetching, refetch } = useCheckPendingEnrolmentNiNumberQuery({
    id: enrolment.id,
    nationalInsuranceNo: niNumberToCheck,
  }, { skip: !niNumberToCheck})
  
  const nationalInsuranceNo = watch('nationalInsuranceNo')
  
  //Update niNumberToCheck when value changes
  useEffect(() => {  
    setMatches(undefined)
    setNiNumberToCheck(nationalInsuranceNo && nationalInsuranceNo.length === NATIONAL_INSURANCE_NO_MASK.length ? unformatNationalInsuranceNumber(nationalInsuranceNo) : undefined)
  }, [nationalInsuranceNo])

  //Force refetch when NI number to check changes
  //NOTE: Without doing this, there seems to be a race condition of some kind with form error
  //states becoming out of sync, resulting in the error messages not being displayed consistently
  useEffect(() => {  
    if (niNumberToCheck) {
      refetch()
    }    
  }, [niNumberToCheck])

  //Update available status based on check result
  useEffect(() => {  
    if (error || isLoading || isFetching) {
      setMatches(undefined)
    } else if (niCheckResult) {
      setMatches(niCheckResult.matches)
    }
  }, [niCheckResult, error, isLoading, isFetching])

  //Trigger validation when available changes
  useEffect(() => {
    trigger('nationalInsuranceNo')
  }, [matches])

  const isValidNiNumber = (value: string) => {
    const result = value.match(NINO_REGEX)
    return result ? true : 'Invalid NI Number - please double check'
  }

  const isMatchingNiNumber = () => {
    if (matches === undefined) {
      return 'Validating...'
    }
    return matches ? true : `No match with your employer's records`
  }

  return (
    <ProcessScreen
      isLoading={false}
      error={undefined}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Please verify your identity`}
      subHeading={`To link this employer, please confirm your National Insurance Number`}
      allowTextButton={matches === false}
      textButtonTitle={`Back to Dashboard`}
      textButtonAction={goToMainAppStack}
    >
      <ManagedTextInput
        name={'nationalInsuranceNo'}
        autoFocus={true}
        formObj={formObj}
        // label={'National Insurance Number'}
        mask={{
          type: 'custom',
          options: {
            mask: NATIONAL_INSURANCE_NO_MASK,
          }
        }}
        autoCapitalize={'characters'}
        forceCapitals={true}
        blurOnSubmit={true}
        submitHandler={handleSubmit(onSubmit)}
        informationMessage={matches
          ? undefined
          : 'You can find your National Insurance Number on the enrolment email we sent you.'
        }
        rules={{
          required: true,
          minLength: {
            value: NATIONAL_INSURANCE_NO_MASK.length,
            message: 'Must be exactly 9 characters'
          },
          maxLength: {
            value: NATIONAL_INSURANCE_NO_MASK.length,
            message: 'Must be exactly 9 characters'
          },
          validate: {
            isValidNiNumber,
            isMatchingNiNumber,
          }
      }} />
      {
        nationalInsuranceNo && matches === false
        ? <Paragraph>{`Please double-check the enrolment email we sent you. If you are sure that you have entered it correctly, please contact your employer to check their records.`}</Paragraph>
        : matches === true
        ? <></>
        : <Paragraph>{'You can find your National Insurance Number on the enrolment email we sent you.'}</Paragraph>
      }
    </ProcessScreen>
  )
}
