import { ManagedCompanyNumberInput } from 'components/Inputs/ManagedCompanyNumberInput'
import { ModalProcessScreen } from 'components/Layout'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { isNotFoundError } from 'lib/errorHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { padStart, truncate } from 'lodash'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLazyGetCompanyQuery } from 'store/apiSlice'
import { ClientEmployer } from 'store/dto/client.dto'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingContributionSetup } from 'store/tempDataSlice'

export const MakeContributions_04_CompanyNumber = ({ route, navigation }) => {
  const [companySearch, setCompanySearch] = React.useState(false)

  const dispatch = useAppDispatch()

  const { nextScreen, contributionsData } = route?.params || {}
  const { existingContributionConfiguration } = contributionsData || {}

  const formObj = useForm<{ companyReg: boolean }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      companyReg: existingContributionConfiguration?.employer?.companyRegNo,
    }
  })
  const [getCompany, { data: companyData, isLoading, error, isFetching }] = useLazyGetCompanyQuery({})
  const { handleSubmit, setValue,  formState: { isValid } } = formObj

  useEffect(() => {
    if (companyData !== undefined && !isLoading && !isFetching) {
      setCompanySearch(true)
    }
  }, [companyData, isLoading, isFetching])

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const clearValue = () => {
    setCompanySearch(false)
    setValue('companyReg', undefined, { shouldDirty: true })
  }

  const onSubmit = attributes => {
    if (companySearch) {
      const employer: ClientEmployer = {
        companyName: attributes.name,
        companyRegNo: attributes.companyReg,
        addressDetail: {
          building: attributes.building,
          street1: attributes.street1,
          town: attributes.town,
          postCode: attributes.postCode,
          country: attributes.country
        },
        accountingReferenceMonth: companyData?.accountingReferenceMonth,
        accountingReferenceDay: companyData?.accountingReferenceDay,
      }
      dispatch(updateWorkingContributionSetup({ employer }))
      next()
    } else {
      getCompany({ id: attributes.companyReg }, false)
    }
  }

  const companyNotFound = error && isNotFoundError(error)

  return (
    <ModalProcessScreen
      isLoading={isLoading || isFetching}
      loadingMessage={getLookingLoadingMessages('Finding your company...')}
      error={error && !companyNotFound}
      enableButton={isValid}
      buttonTitle={companySearch ? 'Confirm' : 'Find'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      headline={companySearch ? 'Please check the details' : `What's your Company Number?`}
      subHeadingInfo={!companySearch ? <NamedInformationButton name={NamedInformation.CONTRIBUTION_COMPANY_NO} /> : undefined}      
      allowTextButton={companySearch}
      textButtonTitle={'Start Search again'}
      textButtonAction={clearValue}
    >
      <ManagedCompanyNumberInput
        companySearchComplete={companySearch}
        companyNotFound={companyNotFound}
        formObj={formObj}
        onSubmitEditing={handleSubmit(onSubmit)}
        name={'companyDetails'}
        updatedCompanyData={companyData}
        informationMessage={'8 digits, allocated by Companies House'}
      />
    </ModalProcessScreen>
  )
}

