import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { ActionCardProps } from 'components/Utility/ActionCard'
import { NamedInformation } from 'components/Utility/InformationButton'
import { formatISO } from 'date-fns'
import { mainAppNavigate, rootNavigate } from 'lib/RootNavigation'
import { getPersonalPension } from 'lib/accountHelpers'
import { DEFAULT_LUMP_SUM_CONTRIBUTIONS_AMOUNT, DEFAULT_REGULAR_CONTRIBUTIONS_AMOUNT, JAR_NAME_PERSONAL, LUMP_SUM_REQUIRED_RATIO, MAX_SUGGESTED_LUMP_SUM_CONTRIBUTIONS_AMOUNT, MIN_SUGGESTED_LUMP_SUM_CONTRIBUTIONS_AMOUNT, PERSONAL_TAX_RELIEF_FACTOR } from "lib/constants"
import { calculateAgeAtDate } from 'lib/dateHelpers'
import { Logger } from 'lib/logger'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { concat, max, min, orderBy, round, sumBy, uniqBy, without } from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useGetInvitesQuery, useGetMeQuery, useGetRetirementAssetsQuery, useGetRetirementIncomesQuery, useGetRetirementProfileQuery, useGetSpouseQuery, useGetUserAccountsQuery, useGetViableAgeQuery, useLazyGetModelQuery } from 'store/apiSlice'
import { ContributionSource } from 'store/dto/base.dto'
import { AmlStatus } from 'store/dto/client.dto'
import { InviteStatus } from 'store/dto/invite.dto'
import { ModelRepresentationDto, ModelScenarioRequest, ModelScenarioRequestParams, ViableAgeRepresentationDto } from 'store/dto/model.dto'
import { useAppDispatch } from 'store/hooks'

export interface GoalScenariosInputs {
  goal: ModelGoalIdentifier,
  proposedContributionSource: ContributionSource,
  proposedRetirementAge?: number,
  surplusStrategy?: SurplusStrategy
}

export type GuidanceContextType = {
  suggestedOnboardingPhase: OnboardingPhase
  onboardingStates: OnboardingState[]
  getOutstandingOnboardingStepActionCardProps: Function
  getOnboardingStepActionCardProps: Function
  getAvailableGoalActionCardProps: Function
  getGoalSuggestionActionCardProps: Function
  getGoalScenarios: Function
  getOnboardingStateByPhase: Function
  incompleteOnboardingPhases: OnboardingPhase[]
  modelAvailable: boolean
  currentViableAge: ViableAgeRepresentationDto
  currentViableAgeError: FetchBaseQueryError | SerializedError
  currentViableAgeIsLoading: boolean
  currentViableAgeIsFetching: boolean
  currentModel: ModelRepresentationDto
  currentModelError: FetchBaseQueryError | SerializedError
  currentModelIsLoading: boolean
  currentModelIsFetching: boolean
  refetchCurrentViableAge: Function
  refetchCurrentModel: Function
  currentGoals: ModelGoal[]
}

export type ModelGoal = {
  id: ModelGoalIdentifier
  title: string
}

export enum ModelGoalIdentifier {
  ACHIEVE_TARGET_RETIREMENT_AGE = 'achieve_tra',
  REDUCE_VIABLE_RETIREMENT_AGE = 'reduce_vra',
  INCREASE_LEGACY_SURPLUS = 'increase_surplus',
  REDUCE_TARGET_RETIREMENT_AGE = 'reduce_tra',
}

//Keep these as string-defined
//(otherwise tests may fail for 0th element!)
export enum OnboardingPhase {
  DUMMY = 'dummy', //Used to represent multiple complete phases
  PROFILE = 'profile', //User to represent initial client setup
  CONNECT = 'connect', //User to represent spouse invite
  SCHEME_CONNECT = 'scheme_connect', //User to represent scheme invite
  SCHEME_ENROL = 'scheme_enrol', //User to represent scheme invite
  PLAN = 'plan',
  CONTRIBUTE = 'contribute',
  CONSOLIDATE = 'consolidate',
  //PA-2033 - Remove Family Onboarding Card
  // FAMILY = 'family',
  COMPLETE = 'complete',
}

const BASE_CONTEXT: GuidanceContextType = {
  suggestedOnboardingPhase: undefined,
  onboardingStates: [],
  getOutstandingOnboardingStepActionCardProps: () => {},
  getOnboardingStepActionCardProps: () => {},
  getAvailableGoalActionCardProps: () => {},
  getGoalSuggestionActionCardProps: () => {},
  getGoalScenarios: () => {},
  getOnboardingStateByPhase: () => {},
  incompleteOnboardingPhases: [],
  modelAvailable: false,
  currentViableAge: undefined,
  currentViableAgeError: undefined,
  currentViableAgeIsLoading: false,
  currentViableAgeIsFetching: false,
  currentModel: undefined,
  currentModelError: undefined,
  currentModelIsLoading: false,
  currentModelIsFetching: false,
  refetchCurrentViableAge: () => {},
  refetchCurrentModel: () => {},
  currentGoals: [],
}

export const GuidanceContext = createContext<GuidanceContextType>(BASE_CONTEXT)

export enum SurplusStrategy {
  RETIRE_LATER = 'retire_later',
  INCREASE_REGULAR = 'increase_regular',
  LUMP_SUM = 'lump_sum',
}

export interface OnboardingState {
  onboardingPhase: OnboardingPhase
  title: string
  subTitle: string
  titleInfoName?: NamedInformation
  shortMessage: string
  illustrationFilename?: string
  imageSource?: any
  isLoading: boolean
  error: any
  complete: boolean
  totalSteps: number
  pendingSteps: number
  notRequiredSteps: number
  completedSteps: number
  remainingSteps: number
  steps: OnboardingStateStep[]
  suggestedStepId?: OnboardingStateStepIdentifier
  completeButtonTitle: string
  completeAction: any
}

export interface OnboardingStateStep {
  id: OnboardingStateStepIdentifier
  title: string
  info: string
  status: OnboardingStateStepStatus
  startFunction: any
}

export enum OnboardingStateStepIdentifier {
  IDENTITY_VERIFICATION,
  PLAN_CHOICE,
  ASSETS,
  RETIREMENT_PROFILE,
  INCOMES,
  CONSOLIDATE,
  CONTRIBUTE,
  BENEFICIARIES,
}

export enum OnboardingStateStepStatus {
  NOT_STARTED,
  COMPLETE,
  DECLINED,
  FAILED,
  UNAVAILABLE,
  PENDING,
  NOT_REQUIRED,
}


export const GuidanceProvider = ({ ready, children }) => {

  const dispatch = useAppDispatch()

  const { data: client, isLoading: clientIsLoading, error: clientError } = useGetMeQuery(undefined, { skip: !ready })
  const { data: spouse, isLoading: spouseIsLoading, error: spouseError } = useGetSpouseQuery(undefined, { skip: !ready || !client })
  const { data: accounts, error: accountsError, isLoading: accountsIsLoading } = useGetUserAccountsQuery(undefined, { skip: !ready || !client })
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading } = useGetRetirementProfileQuery(undefined, { skip: !ready || !client })
  const { data: assets, isLoading: assetsIsLoading, error: assetsError} = useGetRetirementAssetsQuery(undefined, { skip: !ready || !client })
  const { data: incomes, isLoading: incomesIsLoading, error: incomesError } = useGetRetirementIncomesQuery(undefined, { skip: !ready || !client })
  const { data: invitesAsInvitor, isLoading: invitesAsInvitorIsLoading, error: invitesAsInvitorError } = useGetInvitesQuery(undefined, { skip: !ready || !client })
      
  const personalPension = getPersonalPension(accounts)

  const isLoading = clientIsLoading || accountsIsLoading || rpIsLoading || spouseIsLoading || invitesAsInvitorIsLoading || assetsIsLoading || incomesIsLoading
  const error: any = clientError || invitesAsInvitorError || assetsError || incomesError

  const [incompleteOnboardingPhases, setIncompleteOnboardingPhases] = useState<OnboardingPhase[]>(undefined)

  const removeFromIncompletedOnboardingPhase = (onboardingPhase: OnboardingPhase) => {
    const currentIncomplete = incompleteOnboardingPhases === undefined ? [] : incompleteOnboardingPhases
    const remainingPhases = without(currentIncomplete, onboardingPhase)
    // Logger.debug({ remainingPhases }, 'Updating remaining onboarding phases')
    setIncompleteOnboardingPhases(remainingPhases)
  }

  const [suggestedOnboardingPhase, setSuggestedOnboardingPhase] = useState<OnboardingPhase>(undefined)
  const [onboardingStates, setOnboardingStates] = useState<OnboardingState[]>([])

  const [modelAvailable, setModelAvailable] = useState<boolean>(false)
  const [currentGoals, setCurrentGoals] = useState<ModelGoal[]>([])

  const { data: currentViableAge, error: currentViableAgeError, isLoading: currentViableAgeIsLoading, isFetching: currentViableAgeIsFetching, refetch: refetchCurrentViableAge } = useGetViableAgeQuery(undefined, { skip: !modelAvailable })

  const [getCurrentModel, { data: currentModel, error: currentModelError, isLoading: currentModelIsLoading, isFetching: currentModelIsFetching}] = useLazyGetModelQuery()

  //Function to refetch current model
  const refetchCurrentModel = () => {
    if (currentViableAge) {
      getCurrentModel(currentViableAge?.output?.viableRetirementAge)
    }
  }

  //Calcs for spouse onboarding flow
  const hasLinkedSpouse = spouse && spouse?.userId
  const hasOwnAssets = client && assets ? assets.filter(asset => { return asset?.clientId === client?.id}).length : false
  const hasOwnIncomes = client && incomes ? incomes.filter(income => { return income?.clientId === client?.id}).length : false

  //Set modelAvailable when dependencies met
  useEffect(() => {
    if (retirementProfile && client?.onboardingFlags?.assets && client?.onboardingFlags?.incomes) {
      Logger.debug(`Model is now available`)
      setModelAvailable(true)
    }
  }, [client, retirementProfile])

  //Update suggestedOnboardingPhase when incompleteOnboardingPhases changes
  //Go to complete if now empty
  useEffect(() => {
    if (incompleteOnboardingPhases !== undefined) {
      const newSuggestedPhase = incompleteOnboardingPhases?.length === 0
      ? OnboardingPhase.COMPLETE
      : incompleteOnboardingPhases[0]
      // Logger.debug({ newSuggestedPhase }, `Updating suggestedOnboardingPhase`)
      setSuggestedOnboardingPhase(newSuggestedPhase)
    }
  }, [incompleteOnboardingPhases])

  //Get new model when currentViableAgeChanges
  useEffect(() => {
    if (currentViableAge) {
      Logger.info({
        viableRetirementAge: currentViableAge?.output?.viableRetirementAge
      }, `Refreshing model due to currentViableAge change...`)
      getCurrentModel(currentViableAge?.output?.viableRetirementAge)
    }
  }, [currentViableAge])

  //Derive goals when model changes
  useEffect(() => {
    if (currentModel) {
      deriveGoals()
    }
  }, [currentModel])

  //Reset if ready changes (for account reset)
  useEffect(() => {
    if (!ready) {
      Logger.info(`Resetting onboardingPhase...`)
      setSuggestedOnboardingPhase(undefined)
    }
  }, [ready])

  //Function to derive the current goals based on the state of the current model
  const deriveGoals = () => {
    let goals: ModelGoal[] = []
    if (currentModel) {
      if (currentModel.output.targetRetirementAgeIsMet) {
        goals.push({
          id: ModelGoalIdentifier.REDUCE_TARGET_RETIREMENT_AGE,
          title: 'Retire Even Earlier',
        })
        goals.push({
          id: ModelGoalIdentifier.INCREASE_LEGACY_SURPLUS,
          title: 'Increase Legacy Surplus',
        })
      } else {
        goals.push({
          id: ModelGoalIdentifier.ACHIEVE_TARGET_RETIREMENT_AGE,
          title: `Retire at Target of ${retirementProfile?.targetRetirementAge}`,
        })
        goals.push({
          id: ModelGoalIdentifier.REDUCE_VIABLE_RETIREMENT_AGE,
          title: `Reduce Achievable Age`,
        })
      }
    }
    setCurrentGoals(goals)
  }

  //Function to derive the scenarios parameters 
  const getGoalScenarios = (props: GoalScenariosInputs): ModelScenarioRequest => {
    const { goal, proposedContributionSource, proposedRetirementAge, surplusStrategy } = props
    const scenarios: ModelScenarioRequestParams[] = []

    const clientAge = client ? calculateAgeAtDate(formatISO(new Date()), client?.birthDate) : 0
    const modelTargetAge = retirementProfile?.targetRetirementAge || 0
    const yearsToModelTarget = modelTargetAge - clientAge
    // const yearsToScenarioTarget = (proposedRetirementAge || modelTargetAge) - clientAge

    const viableRetirementAge = currentModel?.output?.viableRetirementAge || 0
    const yearsModelTargetToViable = viableRetirementAge - modelTargetAge
    const yearsScenarioTargetToViable = viableRetirementAge - (proposedRetirementAge || modelTargetAge)
    const yearsToViableRatio = yearsScenarioTargetToViable && yearsModelTargetToViable ? yearsScenarioTargetToViable / yearsModelTargetToViable : 1


    const currentRequired = proposedContributionSource === ContributionSource.EMPLOYER
      ? currentModel?.output?.requiredGrossMonthlyContributions || 0
      : currentModel?.output?.requiredPersonalMonthlyContributions || 0
    const totalRequiredForModelTarget = currentRequired * yearsToModelTarget * 12
    const totalRequiredForScenarioTarget = totalRequiredForModelTarget * yearsToViableRatio
    const rawLargeLumpSum = totalRequiredForScenarioTarget / LUMP_SUM_REQUIRED_RATIO
    //Round to -2 is 2 significant figures?
    const largeLumpSum = round(min([MAX_SUGGESTED_LUMP_SUM_CONTRIBUTIONS_AMOUNT, rawLargeLumpSum]), -2)
    const possibleLumpSums = [
      largeLumpSum / 4,
      largeLumpSum / 2,
      largeLumpSum,
    ]
    const okLumpSums = possibleLumpSums.filter(ls => {
      return ls > MIN_SUGGESTED_LUMP_SUM_CONTRIBUTIONS_AMOUNT
    })
    
    switch (goal) {
      //Achieve Target Retirement Age      
      case ModelGoalIdentifier.ACHIEVE_TARGET_RETIREMENT_AGE:
        okLumpSums.forEach((lumpSum, i) => {
          scenarios.push({
            identifier: `achieve_tra_with_lump_sum_${i + 1}`,
            proposedOneOffContribution: lumpSum,
            proposedContributionSource,
          })
        })
        break

      //Reduce Viable Retirement Age
      case ModelGoalIdentifier.REDUCE_VIABLE_RETIREMENT_AGE:
        scenarios.push({
          identifier: 'reduce_vra_to_x_no_lump_sum',
          proposedRetirementAge,
        })
        okLumpSums.forEach((lumpSum, i) => {
          scenarios.push({
            identifier: `reduce_vra_with_lump_sum_${i + 1}`,
            proposedRetirementAge,
            proposedOneOffContribution: lumpSum,
            proposedContributionSource,
          })
        })
        break

      //Increase Surplus Amount
      case ModelGoalIdentifier.INCREASE_LEGACY_SURPLUS:

        //Switch on surplus strategy..
        switch (surplusStrategy) {
          case SurplusStrategy.RETIRE_LATER:
            const ageIncreases = [1,2,3]
            ageIncreases.forEach(increase => {
              scenarios.push({
                identifier: `increase_surplus_retire_later_${increase}`,
                proposedRetirementAge: retirementProfile.targetRetirementAge + increase,
              })  
            })
            break

          case SurplusStrategy.INCREASE_REGULAR:
            if (personalPension.regularContribution?.amount) {
              const ratioIncreases = [1.1,1.2,1.3]
              ratioIncreases.forEach(increase => {
                scenarios.push({
                  identifier: `increase_surplus_increase_regular_${increase}`,
                  proposedMonthlyContributions: personalPension.regularContribution?.amount * increase,
                  proposedContributionSource,
                })
              })
            } else {
              const factorIncreases = [1,2,3]
              factorIncreases.forEach(increase => {
                scenarios.push({
                  identifier: `increase_surplus_start_regular_x${increase}`,
                  proposedMonthlyContributions: DEFAULT_REGULAR_CONTRIBUTIONS_AMOUNT * increase,
                  proposedContributionSource,
                })
              })
            }
            break
  
          case SurplusStrategy.LUMP_SUM:
            const factorIncreases = [1,2,3]
            factorIncreases.forEach(increase => {
              scenarios.push({
                identifier: `increase_surplus_with_lump_sum_x${increase}`,
                proposedOneOffContribution: DEFAULT_LUMP_SUM_CONTRIBUTIONS_AMOUNT * increase,
                proposedContributionSource,
              })
            })
            break
        }
        break

      //Reduce Target Retirement Age
      case ModelGoalIdentifier.REDUCE_TARGET_RETIREMENT_AGE:
        const grossAnnualBudget = retirementProfile?.expensesGrossTotalAmount
        const totalAnnualRetirementIncome = client?.statePensionAmount + (spouse?.statePensionAmount || 0) + sumBy(incomes, 'annualIncomeAmount')
        const annualRequiredDrawdown = max([grossAnnualBudget - totalAnnualRetirementIncome, 0])
        const yearsScenarioToTarget = modelTargetAge - proposedRetirementAge
        const totalGrossCapitalRequired = annualRequiredDrawdown * yearsScenarioToTarget
        const totalActualCapitalRequired = proposedContributionSource === ContributionSource.EMPLOYER
          ? totalGrossCapitalRequired
          : totalGrossCapitalRequired / (1 + PERSONAL_TAX_RELIEF_FACTOR)
        const rawReduceTraLargeLumpSum = totalActualCapitalRequired / LUMP_SUM_REQUIRED_RATIO
        const largeReduceLumpSum = round(min([MAX_SUGGESTED_LUMP_SUM_CONTRIBUTIONS_AMOUNT, rawReduceTraLargeLumpSum]), -2)

        const possibleReduceLumpSums = [
          largeReduceLumpSum / 4,
          largeReduceLumpSum / 2,
          largeReduceLumpSum,
        ]
        const okReduceLumpSums = possibleReduceLumpSums.filter(ls => {
          return ls > MIN_SUGGESTED_LUMP_SUM_CONTRIBUTIONS_AMOUNT
        })
    
        scenarios.push({
          identifier: 'reduce_tra_to_x_no_lump_sum',
          proposedRetirementAge,
        })
        okReduceLumpSums.forEach((lumpSum, i) => {
          scenarios.push({
            identifier: `reduce_tra_with_lump_sum_${i + 1}`,
            proposedRetirementAge,
            proposedOneOffContribution: lumpSum,
            proposedContributionSource,
          })
        })
        break
    }

    const response: ModelScenarioRequest = {
      scenarios
    }

    return response
  }

  //Function to check if all entities have finished loading
  const allEntitiesLoaded = () => {
    const result = (!!client || !!clientError)
      && (spouse || spouse === null || !!spouseError) //Null to account for when server returns 204 for no spouse
      && (!!accounts || !!accountsError)
      && (!!retirementProfile || !!rpError)
      && (!!assets)
      && (!!incomes)
      && (!!invitesAsInvitor)
    return result
  }

  //Initialize onboarding phase when everything loaded - Initialization only!!
  const stateAndPhaseChangeTriggerArray = [ready, client, clientError, spouse, spouseError, accounts, accountsError, retirementProfile, rpError, assets, incomes, invitesAsInvitor]
  useEffect(() => {
    const ael = allEntitiesLoaded()
    if (suggestedOnboardingPhase === undefined && ready && ael) {
      Logger.debug(`Setting intial onboardingPhase...`)
      setInitialOnboardingPhase()
    } else {
    }
  }, stateAndPhaseChangeTriggerArray)

  //Recalculate onboarding state when anything changes
  useEffect(() => {
    if (ready && allEntitiesLoaded()) {
      Logger.debug('Re-deriving onboarding states (entity change)')
      deriveOnboardingStates()
    }
  }, stateAndPhaseChangeTriggerArray)

  //Recalculate onboarding state when onboardingPhase changes
  //Note - has been separated from previous useEffect as it seems to "miss" changes?
  useEffect(() => {
    Logger.debug('Re-deriving onboarding state (onboarding phase change)')
    deriveOnboardingStates()
    // }
  }, [suggestedOnboardingPhase])

  //Function to find the status of a step by id
  const getStepStatus = (steps: OnboardingStateStep[], id: OnboardingStateStepIdentifier) => {
    const step = steps.find(step => {
      return step.id === id
    })
    return step ? step.status : undefined
  }

  //Function to calculate the quantities of steps in different statuses adn overall completeness
  const getStatuses = (steps: OnboardingStateStep[]): {
    totalSteps: number,
    pendingSteps: number,
    notRequiredSteps: number,
    completedSteps: number,
    remainingSteps: number,
    complete: boolean
  } => {
    const totalSteps = steps.length
    const pendingSteps = sumBy(steps, function(step) {
      return step.status === OnboardingStateStepStatus.PENDING ? 1 : 0
    })
    const notRequiredSteps = sumBy(steps, function(step) {
      return step.status === OnboardingStateStepStatus.NOT_REQUIRED ? 1 : 0
    })
    const completedSteps = sumBy(steps, function(step) {
      return [OnboardingStateStepStatus.COMPLETE, OnboardingStateStepStatus.DECLINED].includes(step.status) ? 1 : 0
    })
    const remainingSteps = totalSteps - completedSteps - pendingSteps - notRequiredSteps
    const complete = completedSteps + notRequiredSteps === totalSteps

    return {
      totalSteps,
      pendingSteps,
      notRequiredSteps,
      completedSteps,
      remainingSteps,
      complete,
    }
  }

  //Function to derive onboarding state for 'Plan'
  const getOnboardingStatePlan = (): OnboardingState => {
    const steps: OnboardingStateStep[] = [
      {
        id: OnboardingStateStepIdentifier.RETIREMENT_PROFILE,
        title: 'Define Retirement Lifestyle',
        info: '2-3 mins',
        status:
          retirementProfile ? OnboardingStateStepStatus.COMPLETE
          : OnboardingStateStepStatus.NOT_STARTED,
          startFunction: () => rootNavigateForOnboarding('RetirementProfileSetupIntroScreen', OnboardingPhase.PLAN),
      },
      {
        id: OnboardingStateStepIdentifier.INCOMES,
        title: hasLinkedSpouse && hasOwnIncomes ? 'Review Retirement Incomes' : 'Capture Retirement Incomes',
        info: '1-5 mins',
        status:
          client?.onboardingFlags?.incomes ? OnboardingStateStepStatus.COMPLETE
          : retirementProfile ? OnboardingStateStepStatus.NOT_STARTED
          : OnboardingStateStepStatus.UNAVAILABLE,
          startFunction: () => rootNavigateForOnboarding('RetirementIncomeSetupIntroScreen', OnboardingPhase.PLAN),
      },
      {
        id: OnboardingStateStepIdentifier.ASSETS,
        title: hasLinkedSpouse && hasOwnAssets ? 'Review Old Pensions' : 'Capture Old Pensions',
        info: '1-10 mins',
        status:
          client?.onboardingFlags?.assets ? OnboardingStateStepStatus.COMPLETE
          : OnboardingStateStepStatus.NOT_STARTED,
          startFunction: () => rootNavigateForOnboarding('RetirementAssetSetupIntroScreen', OnboardingPhase.PLAN),
      },
    ]

    const stepStatuses = getStatuses(steps)

    const statuses = stepStatuses.complete ? {} : {
      assets: getStepStatus(steps, OnboardingStateStepIdentifier.ASSETS),
      profile: getStepStatus(steps, OnboardingStateStepIdentifier.RETIREMENT_PROFILE),
      incomes: getStepStatus(steps, OnboardingStateStepIdentifier.INCOMES),
    }

    const suggestedStepId =
      //No suggested step if complete
      stepStatuses.complete ? undefined
      : statuses.profile === OnboardingStateStepStatus.NOT_STARTED
        ? OnboardingStateStepIdentifier.RETIREMENT_PROFILE
      //Retirement incomes if not started
      : statuses.incomes === OnboardingStateStepStatus.NOT_STARTED
        ? OnboardingStateStepIdentifier.INCOMES
      //Retirement assets if not started
      : statuses.assets === OnboardingStateStepStatus.NOT_STARTED
        ? OnboardingStateStepIdentifier.ASSETS
      //Else no suggested step (shouldn't get here)
      : undefined

    const shortMessage = stepStatuses.complete
      ? `All done - tap to see your achievable retirement age`
      : stepStatuses.completedSteps
       ? `Complete ${stepStatuses.remainingSteps} more task${stepStatuses.remainingSteps === 1 ?'' : 's'} to find out when you can currently retire`
       : `Find out when you can retire based on the retirement lifestyle you want`

    return {
      onboardingPhase: OnboardingPhase.PLAN,
      title: 'Plan Your Retirement',
      subTitle: 'Work out what you need and define what you have, to find out your achievable retirement age',
      titleInfoName: NamedInformation.ONBOARDING_PHASE_PLAN,
      shortMessage,
      illustrationFilename: 'plan_retirement.png',
      isLoading: false,
      error: null,
      ...stepStatuses,
      steps,
      suggestedStepId,
      completeButtonTitle: 'See your Retirement Age',
      completeAction: () => {
        removeFromIncompletedOnboardingPhase(OnboardingPhase.PLAN)
        rootNavigate('OnboardingPhasePlanHeroScreen')
      }
    }
  }

  //Function to derive onboarding state for 'Contribute'
  const getOnboardingStateContribute = (): OnboardingState => {
    const steps: OnboardingStateStep[] = [
      {
        id: OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
        title: 'Verify Identity',
        info: '~2 mins',
        status:
          !client?.amlStatus ? OnboardingStateStepStatus.NOT_STARTED
          : client?.amlStatus === AmlStatus.PASS ? OnboardingStateStepStatus.COMPLETE
          : client?.amlStatus === AmlStatus.REFER ? OnboardingStateStepStatus.PENDING
          : OnboardingStateStepStatus.FAILED,
        startFunction: () => rootNavigateForOnboarding('ClientVerificationIntroScreen', OnboardingPhase.CONTRIBUTE),
      },
      {
        id: OnboardingStateStepIdentifier.PLAN_CHOICE,
        title: `Open ${JAR_NAME_PERSONAL}`,
        info: '~2 mins',
        status:
          personalPension ? OnboardingStateStepStatus.COMPLETE
          : client?.amlStatus !== AmlStatus.PASS ? OnboardingStateStepStatus.UNAVAILABLE
          : OnboardingStateStepStatus.NOT_STARTED,
          startFunction: () => rootNavigateForOnboarding('InvestmentChoiceIntroScreen', OnboardingPhase.CONTRIBUTE),
      },
      {
        id: OnboardingStateStepIdentifier.CONTRIBUTE,
        title: 'Set Up Contributions',
        info: '~5 mins',
        status:
          client?.onboardingFlags?.contribute ? OnboardingStateStepStatus.COMPLETE
          : client?.amlStatus !== AmlStatus.PASS ? OnboardingStateStepStatus.UNAVAILABLE
          : !personalPension ? OnboardingStateStepStatus.UNAVAILABLE
          : OnboardingStateStepStatus.NOT_STARTED,
          startFunction: () => rootNavigateForOnboarding('ContributionsIntroScreen', OnboardingPhase.CONTRIBUTE),
      },
    ]

    const stepStatuses = getStatuses(steps)

    const statuses = stepStatuses.complete ? {} : {
      identity: getStepStatus(steps, OnboardingStateStepIdentifier.IDENTITY_VERIFICATION),
      plan: getStepStatus(steps, OnboardingStateStepIdentifier.PLAN_CHOICE),
      contribute: getStepStatus(steps, OnboardingStateStepIdentifier.CONTRIBUTE),
    }

    const suggestedStepId =
      //No suggested step if complete
      stepStatuses.complete ? undefined
      //No suggested step if identity verification failed
      : statuses.identity === OnboardingStateStepStatus.FAILED ? undefined
      //Identity verification if not started
      : statuses.identity === OnboardingStateStepStatus.NOT_STARTED
        ? OnboardingStateStepIdentifier.IDENTITY_VERIFICATION
      //Plan choice if identity verfication complete and plan not started
      : statuses.identity === OnboardingStateStepStatus.COMPLETE && statuses.plan === OnboardingStateStepStatus.NOT_STARTED
        ? OnboardingStateStepIdentifier.PLAN_CHOICE
      //Contribute if not started
      : statuses.contribute === OnboardingStateStepStatus.NOT_STARTED
        ? OnboardingStateStepIdentifier.CONTRIBUTE
      //Else no suggested step (shouldn't get here)
      : undefined

    const shortMessage = stepStatuses.complete
      ? `All done - tap for results`
      : stepStatuses.completedSteps
        ? `Complete ${stepStatuses.remainingSteps} more task${stepStatuses.remainingSteps === 1 ?'' : 's'} to get started with contributions`
        : `Choose a pension plan and get started saving for your future`

    return {
      onboardingPhase: OnboardingPhase.CONTRIBUTE,
      title: 'Start Saving',
      subTitle: `Open your ${JAR_NAME_PERSONAL}, and start putting money away for your future`,
      titleInfoName: NamedInformation.ONBOARDING_PHASE_CONTRIBUTE,
      shortMessage,
      illustrationFilename: 'start_contributing.png',
      isLoading: false,
      error: null,
      ...stepStatuses,
      steps,
      suggestedStepId,
      completeButtonTitle: 'See the results',
      completeAction: () => {
        removeFromIncompletedOnboardingPhase(OnboardingPhase.CONTRIBUTE)
        rootNavigate('OnboardingPhaseContributeHeroScreen')
      }
    }
  }

  //Function to derive onboarding state for 'Consolidate'
  const getOnboardingStateConsolidate = (): OnboardingState => {
    const steps: OnboardingStateStep[] = [
      {
        id: OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
        title: 'Verify Identity',
        info: '~2 mins',
        status:
          !client?.amlStatus ? OnboardingStateStepStatus.NOT_STARTED
          : client?.amlStatus === AmlStatus.PASS ? OnboardingStateStepStatus.COMPLETE
          : client?.amlStatus === AmlStatus.REFER ? OnboardingStateStepStatus.PENDING
          : OnboardingStateStepStatus.FAILED,
        startFunction: () => rootNavigateForOnboarding('ClientVerificationIntroScreen', OnboardingPhase.CONSOLIDATE),
      },
      {
        id: OnboardingStateStepIdentifier.PLAN_CHOICE,
        title: `Open ${JAR_NAME_PERSONAL}`,
        info: '~2 mins',
        status:
          personalPension ? OnboardingStateStepStatus.COMPLETE
          : client?.amlStatus !== AmlStatus.PASS ? OnboardingStateStepStatus.UNAVAILABLE
          : OnboardingStateStepStatus.NOT_STARTED,
          startFunction: () => rootNavigateForOnboarding('InvestmentChoiceIntroScreen', OnboardingPhase.CONSOLIDATE),
      },
      {
        id: OnboardingStateStepIdentifier.CONSOLIDATE,
        title: 'Find & Combine Pensions',
        info: '~5 mins',
        status:
          client?.onboardingFlags?.consolidate ? OnboardingStateStepStatus.COMPLETE
          : client?.amlStatus !== AmlStatus.PASS ? OnboardingStateStepStatus.UNAVAILABLE
          : !personalPension ? OnboardingStateStepStatus.UNAVAILABLE
          : OnboardingStateStepStatus.NOT_STARTED,
        startFunction: () => rootNavigateForOnboarding('BulkTransferIntroScreen', OnboardingPhase.CONSOLIDATE),
      },
    ]

    const stepStatuses = getStatuses(steps)

    const statuses = stepStatuses.complete ? {} : {
      identity: getStepStatus(steps, OnboardingStateStepIdentifier.IDENTITY_VERIFICATION),
      plan: getStepStatus(steps, OnboardingStateStepIdentifier.PLAN_CHOICE),
      consolidate: getStepStatus(steps, OnboardingStateStepIdentifier.CONSOLIDATE),
    }

    const suggestedStepId =
      //No suggested step if complete
      stepStatuses.complete ? undefined
      //No suggested step if identity verification failed
      : statuses.identity === OnboardingStateStepStatus.FAILED ? undefined
      //Identity verification if not started
      : statuses.identity === OnboardingStateStepStatus.NOT_STARTED
        ? OnboardingStateStepIdentifier.IDENTITY_VERIFICATION
      //Plan choice if identity verfication complete and plan not started
      : statuses.identity === OnboardingStateStepStatus.COMPLETE && statuses.plan === OnboardingStateStepStatus.NOT_STARTED
        ? OnboardingStateStepIdentifier.PLAN_CHOICE
      //Contribute if not started
      : statuses.consolidate === OnboardingStateStepStatus.NOT_STARTED
        ? OnboardingStateStepIdentifier.CONSOLIDATE
      //Else no suggested step (shouldn't get here)
      : undefined

    const shortMessage = stepStatuses.complete
      ? `All done - tap for results`
      : stepStatuses.completedSteps
        ? `Complete ${stepStatuses.remainingSteps} more task${stepStatuses.remainingSteps === 1 ?'' : 's'} to bring together old pensions in one place`
        : `Find your old workplace and personal pensions and consolidate into Jarvis`

    return {
      onboardingPhase: OnboardingPhase.CONSOLIDATE,
      title: 'Combine Old Pensions',
      subTitle: `Open your ${JAR_NAME_PERSONAL}, find your old workplace and personal pensions and consolidate into one place`,
      titleInfoName: NamedInformation.ONBOARDING_PHASE_CONSOLIDATE,
      shortMessage,
      illustrationFilename: 'consolidate_old_pensions.png',
      isLoading: false,
      error: null,
      ...stepStatuses,
      steps,
      suggestedStepId,
      completeButtonTitle: 'See the results',
      completeAction: () => {
        removeFromIncompletedOnboardingPhase(OnboardingPhase.CONSOLIDATE)
        rootNavigate('OnboardingPhaseConsolidateHeroScreen')
      }
    }
  }
  
  //PA-2033 - Remove Family Onboarding Card
  // //Function to derive onboarding state for 'Family'
  // const getOnboardingStateFamily = (): OnboardingState => {
  //   const personalPensionStepsAreSkippable = !!groupPension
  //   const steps: OnboardingStateStep[] = [
  //     {
  //       id: OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
  //       title: 'Verify Identity',
  //       info: '~2 mins',
  //       status:
  //         client?.amlStatus === AmlStatus.PASS ? OnboardingStateStepStatus.COMPLETE
  //         : personalPensionStepsAreSkippable ? OnboardingStateStepStatus.NOT_REQUIRED
  //         : !client?.amlStatus ? OnboardingStateStepStatus.NOT_STARTED
  //         : client?.amlStatus === AmlStatus.REFER ? OnboardingStateStepStatus.PENDING
  //         : OnboardingStateStepStatus.FAILED,
  //       startFunction: () => rootNavigateForOnboarding('ClientVerificationIntroScreen', OnboardingPhase.FAMILY),
  //     },
  //     {
  //       id: OnboardingStateStepIdentifier.PLAN_CHOICE,
  //       title: `Open ${JAR_NAME_PERSONAL}`,
  //       info: '~2 mins',
  //       status:
  //         personalPension ? OnboardingStateStepStatus.COMPLETE
  //         : personalPensionStepsAreSkippable ? OnboardingStateStepStatus.NOT_REQUIRED
  //         : client?.amlStatus !== AmlStatus.PASS ? OnboardingStateStepStatus.UNAVAILABLE
  //         : OnboardingStateStepStatus.NOT_STARTED,
  //         startFunction: () => rootNavigateForOnboarding('InvestmentChoiceIntroScreen', OnboardingPhase.FAMILY),
  //     },
  //     {
  //       id: OnboardingStateStepIdentifier.BENEFICIARIES,
  //       title: 'Define Beneficiaries',
  //       info: '~2 mins',
  //       status:
  //         client?.onboardingFlags?.beneficiaries
  //           ? OnboardingStateStepStatus.COMPLETE
  //           : personalPensionStepsAreSkippable ? OnboardingStateStepStatus.NOT_STARTED
  //           : !personalPension ? OnboardingStateStepStatus.UNAVAILABLE
  //           : OnboardingStateStepStatus.NOT_STARTED,
  //         startFunction: () => rootNavigateForOnboarding('BeneficiariesSetupIntroScreen', OnboardingPhase.FAMILY),
  //     }
  //   ]

  //   const stepStatuses = getStatuses(steps)

  //   const statuses = stepStatuses.complete ? {} : {
  //     identity: getStepStatus(steps, OnboardingStateStepIdentifier.IDENTITY_VERIFICATION),
  //     plan: getStepStatus(steps, OnboardingStateStepIdentifier.PLAN_CHOICE),
  //     beneficaries: getStepStatus(steps, OnboardingStateStepIdentifier.BENEFICIARIES),
  //   }

  //   const suggestedStepId =
  //     stepStatuses.complete ? undefined
  //     //If personalPensionStepsAreSkippable, show beneficiaries
  //     : personalPensionStepsAreSkippable && statuses.beneficaries === OnboardingStateStepStatus.NOT_STARTED ? OnboardingStateStepIdentifier.BENEFICIARIES
  //     //No suggested step if identity verification failed
  //     : statuses.identity === OnboardingStateStepStatus.FAILED ? undefined
  //     //Identity verification if not started
  //     : statuses.identity === OnboardingStateStepStatus.NOT_STARTED
  //       ? OnboardingStateStepIdentifier.IDENTITY_VERIFICATION
  //     //Plan choice if identity verfication complete and plan not started
  //     : statuses.identity === OnboardingStateStepStatus.COMPLETE && statuses.plan === OnboardingStateStepStatus.NOT_STARTED
  //       ? OnboardingStateStepIdentifier.PLAN_CHOICE
  //     //Show beneficiaries if not started
  //     : statuses.beneficaries === OnboardingStateStepStatus.NOT_STARTED ? OnboardingStateStepIdentifier.BENEFICIARIES
  //     //Else nothing (shouldn't get here)
  //     : undefined

  //   const shortMessage = stepStatuses.complete
  //     ? `All done - tap for results`
  //     : stepStatuses.completedSteps
  //       ? `Complete ${stepStatuses.remainingSteps} more task${stepStatuses.remainingSteps === 1 ?'' : 's'} to ensure your loved ones are looked after`
  //       : `Ensure your loved ones are looked after when the time comes`

  //   return {
  //     onboardingPhase: OnboardingPhase.FAMILY,
  //     title: 'Organise Your Family',
  //     subTitle: 'Get everything in place to ensure your loved ones are looked after',
  //     titleInfoName: NamedInformation.ONBOARDING_PHASE_FAMILY,
  //     illustrationFilename: 'organise_family.png',
  //     shortMessage,
  //     isLoading: false,
  //     error: null,
  //     ...stepStatuses,
  //     steps,
  //     suggestedStepId,
  //     completeButtonTitle: 'See the Results',
  //     completeAction: () => {
  //       removeFromIncompletedOnboardingPhase(OnboardingPhase.FAMILY)
  //       rootNavigate('OnboardingPhaseFamilyHeroScreen')
  //     }
  //   }
  // }

  //Function check if there is an invite in a given status
  const hasInviteInStatus = (status: InviteStatus): boolean => {
    const filtered = invitesAsInvitor ? invitesAsInvitor.filter(invite => {
      return invite.status === status
    }) : []
    return !!filtered.length
  }

  //Function to navigate whilst setting the fromOnboarding param
  const rootNavigateForOnboarding = (screen: string, fromOnboarding: OnboardingPhase) => {
    rootNavigate(screen, { fromOnboarding })
  }

  //Function to calculate the current onboarding phase on initial load
  const setInitialOnboardingPhase = () => {
    Logger.info(`Initializing onboarding phase...`)
    
    let newSuggestedOnboardingPhase = undefined

    //Check if plan not complete
    if (!retirementProfile || !client?.onboardingFlags?.assets || !client?.onboardingFlags?.incomes) {
      newSuggestedOnboardingPhase = OnboardingPhase.PLAN
    } else if (!client?.onboardingFlags?.contribute) {
      newSuggestedOnboardingPhase = OnboardingPhase.CONTRIBUTE
    } else if (!client?.onboardingFlags?.consolidate) {
      newSuggestedOnboardingPhase = OnboardingPhase.CONSOLIDATE
    //PA-2033 - Remove Family Onboarding Card
    // } else if (!client?.onboardingFlags?.beneficiaries) {
    //   newSuggestedOnboardingPhase = OnboardingPhase.FAMILY
    } else {
      newSuggestedOnboardingPhase = OnboardingPhase.COMPLETE
    }

    //NOTE: Do NOT set when undefined without checking consequences!
    //May need a different state when everything is finished?
    if (newSuggestedOnboardingPhase !== undefined) {
      Logger.info({ newSuggestedOnboardingPhase }, `Setting initial onboarding phase`)
      setSuggestedOnboardingPhase(newSuggestedOnboardingPhase)
    }

    //Also set the initial array for incomplete onboarding phases
    const incompletePhases: OnboardingPhase[] = []
    const completePhases: OnboardingPhase[] = []
    if (!retirementProfile || !client?.onboardingFlags?.assets || !client?.onboardingFlags?.incomes) {
      incompletePhases.push(OnboardingPhase.PLAN)
    } else {
      completePhases.push(OnboardingPhase.PLAN)
    }
    if (!client?.onboardingFlags?.contribute) {
      incompletePhases.push(OnboardingPhase.CONTRIBUTE)
    } else {
      completePhases.push(OnboardingPhase.CONTRIBUTE)
    }
    if (!client?.onboardingFlags?.consolidate) {
      incompletePhases.push(OnboardingPhase.CONSOLIDATE)
    } else {
      completePhases.push(OnboardingPhase.CONSOLIDATE)
    }
    //PA-2033 - Remove Family Onboarding Card
    // if (!client?.onboardingFlags?.beneficiaries) {
    //   incompletePhases.push(OnboardingPhase.FAMILY)
    // } else {
    //   completePhases.push(OnboardingPhase.FAMILY)
    // }
    // Logger.debug({ incompletePhases }, 'Initial incomplete onboarding phases')

    //PA-2015 - Onboarding choice
    const sortedIncompletePhases = client?.preferredOnboardingPhase
      ? orderBy(incompletePhases, [function(p: OnboardingPhase) { return p === client?.preferredOnboardingPhase ? 0 : 1 }], ['asc'])
      : incompletePhases

    setIncompleteOnboardingPhases(sortedIncompletePhases)
  }

  //Function to derive the set of onboarding states
  const deriveOnboardingStates = () => {
    //Return empty array if loading, error, suggested phase not ready (undefined) or suggested phase is complete
    if (isLoading || error || suggestedOnboardingPhase === undefined || suggestedOnboardingPhase === OnboardingPhase.COMPLETE) {
      setOnboardingStates([])
      return
    }

    //Initialize empty array
    let newOnboardingStates: OnboardingState[] = []

    //Add all the other onboarding states
    newOnboardingStates.push(getOnboardingStatePlan())
    newOnboardingStates.push(getOnboardingStateConsolidate())
    newOnboardingStates.push(getOnboardingStateContribute())
    //PA-2033 - Remove Family Onboarding Card
    // newOnboardingStates.push(getOnboardingStateFamily())

    //Sort the array by complete first
    newOnboardingStates = orderBy(newOnboardingStates, ['complete'], ['desc'])

    //Add the fake Create Profile state to the start
    newOnboardingStates.unshift({
      onboardingPhase: OnboardingPhase.PROFILE,
      title: 'Create Initial Profile',
      subTitle: '',
      shortMessage: '',
      illustrationFilename: 'create_profile.png',
      isLoading: false,
      error: null,
      complete: true,
      totalSteps: 0,
      pendingSteps: 0,
      notRequiredSteps: 0,
      completedSteps: 0,
      remainingSteps: 0,
      steps: [],
      completeButtonTitle: '',
      completeAction: () => mainAppNavigate(MainAppNavScreen.DASHBOARD),
    })

    // Logger.debug(`New onboarding state`, newOnboardingStates)
    setOnboardingStates(newOnboardingStates)
  }

  //Function to create a set of action card props based on the steps that are currently not started
  //Optionally filtered by a selected set of steps that are wanted
  const getOutstandingOnboardingStepActionCardProps = (
    selectedSteps?: OnboardingStateStepIdentifier[],
    preferredSize?: 'small' | 'large',
  ): ActionCardProps[] => {
    if (suggestedOnboardingPhase === undefined) {
      return []
    }

    //Gather all possible onboarding state steps
    let allPossibleOnboardingSteps: OnboardingStateStep[] = []
    onboardingStates.forEach(state => {
      allPossibleOnboardingSteps = concat(allPossibleOnboardingSteps, state.steps)
    })
    //Remove duplicates by id
    allPossibleOnboardingSteps = uniqBy(allPossibleOnboardingSteps, 'id')

    //Filter if selected steps provided
    const selectedNotStartedSteps = selectedSteps ? allPossibleOnboardingSteps.filter(step => {
      return selectedSteps.includes(step.id) && step.status === OnboardingStateStepStatus.NOT_STARTED
    }) : allPossibleOnboardingSteps

    const actionCardProps: ActionCardProps[] = []
    selectedNotStartedSteps.forEach(step => {
      const props = getOnboardingStepActionCardProps(step.id, preferredSize)
      if (props) {
        actionCardProps.push(props)
      }
    })
    return actionCardProps
  }

  //Function to return a suggestion by onboarding state step identifier
  const getOnboardingStepActionCardProps = (
    identifier: OnboardingStateStepIdentifier,
    preferredSize?: 'small' | 'large',
  ): ActionCardProps => {
    const size = preferredSize || 'large'
    switch (identifier) {
      case OnboardingStateStepIdentifier.IDENTITY_VERIFICATION:
        return {
          size,
          buttonLabel: 'Verify Now!',
          title: `Verify your identity`,
          description: `Get instantly approved to open your ${JAR_NAME_PERSONAL}`,
          illustrationFilename: 'verify_your_identity.png',
          onPress: () => rootNavigate('ClientVerificationIntroScreen'),
        }
      case OnboardingStateStepIdentifier.PLAN_CHOICE:
        return {
          size,
          buttonLabel: 'Choose Plan Now',
          title: `Choose your Jarvis Plan`,
          description: `Pick an investment plan that's right for you`,
          illustrationFilename: 'choose_your_jarvis_plan.png',
          onPress: () => rootNavigate('InvestmentChoiceIntroScreen'),
        }
      case OnboardingStateStepIdentifier.ASSETS:
        const confirmAssets = spouse?.userId && assets?.length
        return {
          size,
          buttonLabel: 'Start Now',
          title: confirmAssets ? 'Review Old Pensions' : 'Capture Old Pensions',
          description: confirmAssets
            ? `Check the pensions/savings that ${spouse?.firstName} added`
            : `Record other personal pensions and savings`,
          illustrationFilename: 'capture_old_pensions.png',
          onPress: () => rootNavigate('RetirementAssetSetupIntroScreen'),
        }
      case OnboardingStateStepIdentifier.RETIREMENT_PROFILE:
        return {
          size,
          buttonLabel: 'Start Now',
          title: 'Create Your Budget',
          description: `See what you'll need for your retirement`,
          illustrationFilename: 'set_your_budget.png',
          onPress: () => rootNavigate('RetirementProfileSetupIntroScreen'),
        }
      case OnboardingStateStepIdentifier.INCOMES:
        const confirmIncomes = spouse?.userId && incomes?.length
        return {
          size,
          buttonLabel: 'Start Now',
          title: confirmIncomes ? 'Review Retirement Incomes' : 'Add Retirement Incomes',
          description: confirmIncomes
            ? `Check the incomes that ${spouse?.firstName} added`
            : `Record your state pension and retirement incomes`,
          illustrationFilename: 'capture_retirement_income.png',
          onPress: () => rootNavigate('RetirementIncomeSetupIntroScreen'),
        }
      case OnboardingStateStepIdentifier.CONTRIBUTE:
        return {
          size,
          buttonLabel: 'Start Now',
          title: `Set Up Contributions`,
          description: currentModel
            ? `Control your retirement with suggested one-off and regular contributions`
            : `Contribute to your retirement with via one-off and regular contributions`,
          illustrationFilename: 'regular_contributions.png',
          onPress: () => rootNavigate('ContributionsIntroScreen'),
        }  
      case OnboardingStateStepIdentifier.CONSOLIDATE:
        return {
          size,
          buttonLabel: 'Start Now',
          title: `Find & Combine Pensions`,
          description: `Easily locate old pensions and consolidate into your ${JAR_NAME_PERSONAL}`,
          illustrationFilename: 'consolidate.png',
          onPress: () => rootNavigate('BulkTransferIntroScreen'),
        }
      case OnboardingStateStepIdentifier.BENEFICIARIES:
        return {
          size,
          buttonLabel: 'Choose Now',
          title: `Choose Your Beneficiaries`,
          description: `Decide who should inherit any residual savings`,
          illustrationFilename: 'choose_beneficiaries.png',
          onPress: () => rootNavigate('BeneficiariesSetupIntroScreen'),
        }
      default:
        return undefined
    }
  }

  //Function to get set of action card props to achieve goals, based on the state of the model and current available goals
  const getAvailableGoalActionCardProps = (
    selectedGoals?: ModelGoalIdentifier[],
    preferredSize?: 'small' | 'large',
  ): ActionCardProps[] => {
    if (!currentModel) {
      return []
    }

    //Filter if selected steps provided
    const goals = selectedGoals ? currentGoals.filter(goal => {
      return selectedGoals.includes(goal.id)
    }) : currentGoals

    const availableGoals: ActionCardProps[] = []
    goals.forEach(goal => {
      const props = getGoalSuggestionActionCardProps(goal.id, preferredSize)
      if (props) {
        availableGoals.push(props)
      }
    })
    return availableGoals
  }
  
  //Function to return a suggestion by model goal identifier
  const getGoalSuggestionActionCardProps = (
    identifier: ModelGoalIdentifier,
    preferredSize?: 'small' | 'large',
  ): ActionCardProps => {
    const size = preferredSize || 'small'
    switch (identifier) {
      case ModelGoalIdentifier.ACHIEVE_TARGET_RETIREMENT_AGE:
        return {
          size,
          buttonLabel: 'Start Now',
          title: `Achieve Target Age`,
          description: `See how to achieve your target age`,
          illustrationFilename: 'path_to_freedom.png',
          onPress: () => rootNavigate('ContributionsIntroScreen', { goal: ModelGoalIdentifier.ACHIEVE_TARGET_RETIREMENT_AGE }),
        }
        case ModelGoalIdentifier.REDUCE_VIABLE_RETIREMENT_AGE:
          return {
            size,
            buttonLabel: 'Start Now',
            title: `Reduce Retirement Age`,
            description: `See how to reduce your achievable age`,
            illustrationFilename: 'clock.png',
            onPress: () => rootNavigate('ContributionsIntroScreen', { goal: ModelGoalIdentifier.REDUCE_VIABLE_RETIREMENT_AGE }),
        }
        case ModelGoalIdentifier.INCREASE_LEGACY_SURPLUS:
          return {
            size,
            buttonLabel: 'Start Now',
            title: `Increase Legacy Surplus`,
            description: `See how to leave more to beneficiaries`,
            illustrationFilename: 'choose_beneficiaries.png',
            onPress: () => rootNavigate('ContributionsIntroScreen', { goal: ModelGoalIdentifier.INCREASE_LEGACY_SURPLUS }),
        }
        case ModelGoalIdentifier.REDUCE_TARGET_RETIREMENT_AGE:
          return {
            size,
            buttonLabel: 'Start Now',
            title: `Retire Even Earlier`,
            description: `See how to retire before your target age`,
            illustrationFilename: 'good_to_go.png',
            onPress: () => rootNavigate('ContributionsIntroScreen', { goal: ModelGoalIdentifier.REDUCE_TARGET_RETIREMENT_AGE }),
        }
      default:
        return undefined
    }
  }

  const getOnboardingStateByPhase = (onboardingPhase: OnboardingPhase): OnboardingState => {
    return onboardingStates.find(state => {
      return state.onboardingPhase === onboardingPhase
    })
  }

  return (
    <>
    {
      ready ?
        <GuidanceContext.Provider value={{
          onboardingStates,
          suggestedOnboardingPhase,
          getOnboardingStepActionCardProps,
          getOutstandingOnboardingStepActionCardProps,
          getGoalSuggestionActionCardProps,
          getAvailableGoalActionCardProps,
          getGoalScenarios,
          getOnboardingStateByPhase,
          incompleteOnboardingPhases,
          modelAvailable,
          currentViableAge,
          currentViableAgeError,
          currentViableAgeIsLoading,
          currentViableAgeIsFetching,
          refetchCurrentViableAge,
          currentModel,
          currentModelError,
          currentModelIsLoading,
          currentModelIsFetching,
          refetchCurrentModel,
          currentGoals,
        }}>
          {children}
        </GuidanceContext.Provider>
        : <>{children}</>
    }
    </>
  )
}

export const useGuidanceContext = () => useContext(GuidanceContext)

export const getSuggestedStep = (steps: OnboardingStateStep[], id: OnboardingStateStepIdentifier): OnboardingStateStep | undefined => {
  return steps && id !== undefined ? steps.find(step => {
    return step.id === id
  }) : undefined
}