import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { NATIONAL_INSURANCE_NO_MASK, NINO_REGEX } from 'lib/constants'
import React from 'react'
import { useForm } from 'react-hook-form'
import { GroupSchemeEnrolmentDto, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { AmlStatus, ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSchemeEnrolmentRequest, workingSchemeEnrolmentRequest } from 'store/tempDataSlice'

export const SchemeEnrolmentAddNinoRequestAdd_01_Nino = ({ route, navigation }) => {
  const { nextScreen, enrolment, client }: { nextScreen: string, enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingSchemeEnrolmentRequestData = useAppSelector(workingSchemeEnrolmentRequest)
  const { data } = workingSchemeEnrolmentRequestData || {}

  const verifiedNationalInsuranceNo = client?.amlStatus === AmlStatus.PASS ? client?.nationalInsuranceNo : undefined

  //Setup form
  const formObj = useForm<{ nationalInsuranceNo: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      nationalInsuranceNo: client?.nationalInsuranceNo
        ? formatNationalInsuranceNumber(client?.nationalInsuranceNo)
        : formatNationalInsuranceNumber(data?.nationalInsuranceNo)
    },
  })
  const { handleSubmit, watch, trigger, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    const { nationalInsuranceNo } = attributes
    dispatch(updateWorkingSchemeEnrolmentRequest({
      requestType: GroupSchemeRequestType.ADD_NINO,
      data: {
        nationalInsuranceNo,
      }
    }))
    navigation.navigate(nextScreen)
  }

  const isValidNiNumber = (value: string) => {
    const result = value.match(NINO_REGEX)
    return result ? true : 'Invalid NI Number - please double check'
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={verifiedNationalInsuranceNo || client?.nationalInsuranceNo
        ? `Please double check your National Insurance Number`
        : `Ok, what's your National Insurance Number?`
      }
      subHeading={verifiedNationalInsuranceNo
        ? `You previously confirmed this when verifying your identity`
        : `Please ensure you enter it correctly to ensure you receive correct tax relief`
      }
    >
      <ManagedTextInput
        name={'nationalInsuranceNo'}
        autoFocus={true}
        formObj={formObj}
        // label={'National Insurance Number'}
        mask={{
          type: 'custom',
          options: {
            mask: NATIONAL_INSURANCE_NO_MASK,
          }
        }}
        autoCapitalize={'characters'}
        forceCapitals={true}
        blurOnSubmit={true}
        submitHandler={handleSubmit(onSubmit)}
        rules={{
          required: true,
          minLength: {
            value: NATIONAL_INSURANCE_NO_MASK.length,
            message: 'Must be exactly 9 characters'
          },
          maxLength: {
            value: NATIONAL_INSURANCE_NO_MASK.length,
            message: 'Must be exactly 9 characters'
          },
          validate: {
            isValidNiNumber,
          }
      }} />
    </ModalProcessScreen>
  )
}
