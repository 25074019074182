import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { BulkSelectToolbar } from 'components/Utility/BulkSelectToolbar'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { ExternalLinkButton } from 'components/Utility/ExternalLinkButton'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { getActionDate } from 'lib/dateHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { userCanCancelJobsForScheme, userCanManageJobsForScheme, userCanRectifyJobsForScheme } from 'lib/groupSchemeHelpers'
import { isAsyncInProgressGroupSchemeJob, isFinishedGroupSchemeJob, isInProgressGroupSchemeJob, isSuccessfulGroupSchemeJob, isUnfinishedGroupSchemeJob } from 'lib/groupSchemeJobHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { getBestName } from 'lib/userHelpers'
import { compact, difference, partition, pull, union } from 'lodash'
import { default as React, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Checkbox } from 'react-native-paper'
import { useAcknowledgeGroupSchemeJobMutation, useCancelGroupSchemeJobMutation, useCheckGroupSchemeJobStatusMutation, useGetGroupSchemeJobQuery, useSetActionedResultsGroupSchemeJobMutation } from 'store/apiSlice'
import { GroupSchemeJobDataSetFormat, GroupSchemeJobRecordDto, GroupSchemeJobStatus, GroupSchemeJobType } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentEmployerJobDataId, currentGroupScheme, setCurrentEmployerJobDataId } from 'store/uxSlice'
import { Colors, Paper, Sizing } from 'styles'

const isWeb = platformIsWeb()
const iconWidth = isWeb ? Sizing.x40 : Sizing.x30

export const EmployerJobEditModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
  const dispatch = useAppDispatch()
  const currentScheme = useAppSelector(currentGroupScheme)

  const userCanManageJobs = userCanManageJobsForScheme(currentScheme)
  const userCanRectifyJobs = userCanRectifyJobsForScheme(currentScheme)
  const userCanCancelJobs = userCanCancelJobsForScheme(currentScheme)

  const currentJobId = useAppSelector(currentEmployerJobDataId)

  const [dirtyDialogVisible, setDirtyDialogVisible] = useState(false)
  const [closeDialogVisible, setCloseDialogVisible] = useState(false)
  const [acknowledgeDialogVisible, setAcknowledgeDialogVisible] = useState(false)
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false)

  const [checkDisabled, setCheckDisabled] = useState(false)
  const [actionedRecords, setActionedRecords] = useState<GroupSchemeJobRecordDto[]>([])
  const [unactionedRecords, setUnactionedRecords] = useState<GroupSchemeJobRecordDto[]>([])
  const [actionedRecordIds, setActionedRecordIds] = useState<string[]>([])
  const [unactionedRecordIds, setUnactionedRecordIds] = useState<string[]>([])

  const { isLoading: jobIsLoading, error: jobError, data: job, refetch: refetchJob } = useGetGroupSchemeJobQuery({ jobId: currentJobId, groupSchemeId: currentScheme.id }, { skip: !currentJobId } )
  const [acknowledgeResult, { isLoading: acknowledgeResultIsLoading, error: acknowledgeResultError, reset: acknowledgeResultReset }] = useAcknowledgeGroupSchemeJobMutation()
  const [cancelJob, { isLoading: cancelJobIsLoading, error: cancelJobError, reset: cancelJobReset }] = useCancelGroupSchemeJobMutation()
  const [actionRecords, { isLoading: actionRecordsResultIsLoading, error: actionRecordsResultError, reset: actionRecordsResultReset }] = useSetActionedResultsGroupSchemeJobMutation()
  const [checkStatus, { isLoading: checkStatusIsLoading, error: checkStatusError }] = useCheckGroupSchemeJobStatusMutation()

  const { id, description, status, paymentId, paymentAmount, paymentReference, fileDownloadUrl, failedRecordFileDownloadUrl, recordCount, failedRecords, createdAt, updatedAt, expectedCompleteAt, jobType, dataSetFormat, errorMessage, originalFilename, resultAcknowledged, creator, acknowledger, canceller, supportMessage } = job || {}

  const isFinished = job && isFinishedGroupSchemeJob(job)

  useEffect(() => {
    if (job) {
      const failedRecords = isFinished ? job.failedRecords || [] : []
      const [actioned, rejected] = partition(failedRecords, 'actioned')
      setActionedRecords(actioned)
      setUnactionedRecords(rejected)
      setActionedRecordIds(actioned.map(r => r.id))
      setUnactionedRecordIds(rejected.map(r => r.id))
    }
  }, [job])

  const anyUnactionedRecords = unactionedRecordIds?.length

  const formObj = useForm<{
    selectedUnactionedRecordIds: string[]
    selectedActionedRecordIds: string[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      selectedUnactionedRecordIds: [],
      selectedActionedRecordIds: [],
    },
  })
  const { handleSubmit, watch, reset, setValue, formState: { isDirty, isValid } } = formObj

  const selectedUnactionedRecordIds = watch('selectedUnactionedRecordIds')
  const selectedActionedRecordIds = watch('selectedActionedRecordIds')
  const unselectedUnactionedRecordIds = difference(unactionedRecordIds, selectedUnactionedRecordIds)
  const unselectedActionedRecordIds = difference(actionedRecordIds, selectedActionedRecordIds)
  
  const anyUnactionedSelected = !!selectedUnactionedRecordIds.length
  const anyActionedSelected = !!selectedActionedRecordIds.length

  const unfinished = isUnfinishedGroupSchemeJob(job)
  const successful = isSuccessfulGroupSchemeJob(job)
  const finalized = !unfinished && resultAcknowledged
  const acknowledgeable = !unfinished && !resultAcknowledged && !anyUnactionedRecords
  const cancellable = status === GroupSchemeJobStatus.INVESTIGATING

  const checkClose = () => {
    if (selectedActionedRecordIds.length || selectedUnactionedRecordIds.length) {
      setDirtyDialogVisible(true)
    } else if (anyUnactionedRecords) {
      setCloseDialogVisible(true)
    } else {
      close()
    }
  }

  const close = () => {
    dispatch(setCurrentEmployerJobDataId(undefined))
  }

  const isLoading = jobIsLoading || acknowledgeResultIsLoading || actionRecordsResultIsLoading || cancelJobIsLoading
  const error: any = jobError || acknowledgeResultError || actionRecordsResultError || cancelJobError

  const { colors: themeColors } = Paper.useAppTheme()

  const handleCheckStatus = async () => {
    setCheckDisabled(true)
    setTimeout(() => setCheckDisabled(false), 10000)
    checkStatus({
      groupSchemeId: currentScheme?.id,
      jobId: job?.id,
      currentStatus: job?.status,
    })
  }

  const handleActionRecords = async () => {
    const allActioned = union(selectedUnactionedRecordIds, actionedRecordIds)
    actionRecords({
      groupSchemeId: currentScheme?.id,
      jobId: job?.id,
      actionedRecordIds: allActioned,
    })
    setValue('selectedActionedRecordIds', [], { shouldValidate: true, shouldDirty: false })
    setValue('selectedUnactionedRecordIds', [], { shouldValidate: true, shouldDirty: false })
  }

  const handleUnactionRecords = async () => {
    const allActioned = unselectedActionedRecordIds
    actionRecords({
      groupSchemeId: currentScheme?.id,
      jobId: job?.id,
      actionedRecordIds: allActioned,
    })
    setValue('selectedActionedRecordIds', [], { shouldValidate: true, shouldDirty: false })
    setValue('selectedUnactionedRecordIds', [], { shouldValidate: true, shouldDirty: false })
  }

  const handleAcknowledge = async () => {
    acknowledgeResult({
      groupSchemeId: currentScheme?.id,
      jobId: job?.id
    })
    setAcknowledgeDialogVisible(false)
    close()
  }

  const handleCancel = async () => {
    cancelJob({
      groupSchemeId: currentScheme?.id,
      jobId: job?.id
    })
    setCancelDialogVisible(false)
  }

  const totalRecordCount = recordCount !== undefined ? recordCount : 0
  const failedRecordCount = failedRecords ? failedRecords?.length : 0
  const successRecordCount = recordCount - failedRecordCount

  const unselectUnactionedRecord = (id: string) => {
    setValue('selectedUnactionedRecordIds', pull(selectedUnactionedRecordIds, id), { shouldValidate: true, shouldDirty: true })
  }
  
  const selectUnactionedRecord = (id: string) => {
    setValue('selectedUnactionedRecordIds', union(selectedUnactionedRecordIds, [id]), { shouldValidate: true, shouldDirty: true })
  }

  const unselectAllUnactionedRecords = () => {
    setValue('selectedUnactionedRecordIds', [], { shouldValidate: true, shouldDirty: true })
  }

  const selectAllUnactionedRecords = () => {
    setValue('selectedUnactionedRecordIds', unactionedRecordIds, { shouldValidate: true, shouldDirty: true })
  }
  
  const unselectActionedRecord = (id: string) => {
    setValue('selectedActionedRecordIds', pull(selectedActionedRecordIds, id), { shouldValidate: true, shouldDirty: true })
  }
  
  const selectActionedRecord = (id: string) => {
    setValue('selectedActionedRecordIds', union(selectedActionedRecordIds, [id]), { shouldValidate: true, shouldDirty: true })
  }

  const unselectAllActionedRecords = () => {
    setValue('selectedActionedRecordIds', [], { shouldValidate: true, shouldDirty: true })
  }

  const selectAllActionedRecords = () => {
    setValue('selectedActionedRecordIds', actionedRecordIds, { shouldValidate: true, shouldDirty: true })
  }

  type RejectedRecordProps = {
    failedRecord: GroupSchemeJobRecordDto
  }
  const RejectedRecord = (props: RejectedRecordProps) => {
    const { failedRecord } = props
    const { id, lineNumber, errorMessage, statusReason, referenceDetails, actioned } = failedRecord || {}

    const nino = referenceDetails?.nationalInsuranceNo ? formatNationalInsuranceNumber(referenceDetails?.nationalInsuranceNo) : undefined
    const name = referenceDetails?.firstName && referenceDetails?.surname
      ? `${referenceDetails?.firstName} ${referenceDetails?.surname}` 
      : referenceDetails?.surname
      ? referenceDetails?.firstName
      : undefined

    const titleIdentifiers: string[] = compact([
      nino,
      name,
    ])
    const title = titleIdentifiers.join(` / `)

    const totalContribution = jobType === GroupSchemeJobType.CONTRIBUTION
      ? (referenceDetails?.employerContributionAmount || 0) + (referenceDetails?.employeeContributionAmount || 0)
      : 0

    const subTitleIdentifiers: string[] = jobType === GroupSchemeJobType.MEMBER
      ? compact([
        referenceDetails?.employeeId,
        referenceDetails?.employeeEmail,
      ])
      : jobType === GroupSchemeJobType.CONTRIBUTION
      ? compact([
        referenceDetails?.employerContributionAmount ? `Employer: ${formatCurrencyAmount(referenceDetails?.employerContributionAmount, 2)}` : undefined,
        referenceDetails?.employeeContributionAmount ? `Employee: ${formatCurrencyAmount(referenceDetails?.employeeContributionAmount)}` : undefined,
        totalContribution ? `Total: ${formatCurrencyAmount(totalContribution, 2)}` : undefined
      ])
      : []

    const subTitle = subTitleIdentifiers.join(` / `)
        
    const selected = actioned
      ? selectedActionedRecordIds.includes(id)
      : selectedUnactionedRecordIds.includes(id)

    const style = { color: themeColors.accent }

    return (
      <DetailedListItem
        selected={selected}
        icon={<Checkbox.Android
          color={themeColors.accent}
          uncheckedColor={themeColors.primary}
          status={selected ? 'checked' : 'unchecked'}
        />}
        onPress={userCanRectifyJobs
          ? actioned
            ? selected ? () => unselectActionedRecord(id) : () => selectActionedRecord(id)
            : selected ? () => unselectUnactionedRecord(id) : () => selectUnactionedRecord(id)
          : () => {}
        }
        title={title}
        titleRight={`Record: #${lineNumber}`}
        titleRightStyle={style}
        subTitle={subTitle}
        subTitleStyle={style}
        titleStyle={style}
        subTitleRight={errorMessage ? errorMessage : statusReason}
        subTitleRightStyle={style}
      />
    )
  }

  const identificationData: UnborderedTableRow[] = !job ? [] : compact([
    {
      label: `Identifier`,
      value: id,
      copyableValue: true,
    },
    description ? {
      label: `Description`,
      value: description,
    } : undefined,
    {
      label: `Job Type`,
      value: jobType === GroupSchemeJobType.MEMBER ? 'Enrolment' : 'Contribution',
    },
    {
      label: `Data Set Format`,
      value: dataSetFormat,
    },
    {
      label: `Created By`,
      value: creator ? getBestName(creator) : `API Connection`,
    },
  ])

  const statusData: UnborderedTableRow[] = !job ? [] : compact([
    {
      label: `Current Status`,
      value: status,
    },
    createdAt ? {
      label: `Uploaded`,
      value: getActionDate(createdAt),
    } : undefined,
    updatedAt
      ? isInProgressGroupSchemeJob(job)
        ? {
            label: `Estimated Completion`,
            value:
              status === GroupSchemeJobStatus.FINALISING
              ? `Soon`
              : expectedCompleteAt
              ? getActionDate(expectedCompleteAt)
              : `Unknown`,
          } 
        : !unfinished
            ? {
                label: `Finished Processing`,
                value: getActionDate(updatedAt),
              }
            : undefined
      : undefined,
    errorMessage || supportMessage
      ? status === GroupSchemeJobStatus.REJECTED
        ? {
            label: `Rejection Reason`,
            value: supportMessage || errorMessage,
          }
        : status === GroupSchemeJobStatus.COMPLETED_WITH_ERRORS
          ? {
              label: `Reason`,
              value: errorMessage,
            }
          : {
              label: `Details`,
              value: errorMessage,
            }
      : undefined,
    status === GroupSchemeJobStatus.INVESTIGATING
      ? {
          label: `More Information`,
          value: `The Jarvis Support Team are currently investigating this job - they will be in touch to discuss with you if necessary.`,
        }
      : undefined,
    !unfinished && !anyUnactionedRecords
      ? {
          label: `Archived`,
          value: resultAcknowledged ? 'Yes' : 'No',
        }
      : undefined,
    resultAcknowledged && acknowledger
      ? {
          label: `Archived By`,
          value: getBestName(acknowledger),
        }
      : undefined,
    status === GroupSchemeJobStatus.CANCELLED && canceller
      ? {
          label: `Cancelled By`,
          value: getBestName(canceller)
        }
      : undefined,
  ])
  
  const paymentData: UnborderedTableRow[] = !job ? [] : compact([
    {
      label: `Payment Identifier`,
      value: paymentId,
      copyableValue: true,
    },
    {
      label: 'Amount',
      value: formatCurrencyAmount(paymentAmount, 2),
    },
    {
      label: 'Reference',
      value: paymentReference,
      copyableValue: true,
    },
  ])

  const recordData: UnborderedTableRow[] = !job ? [] : [
    {
      label: 'Total Records',
      value: totalRecordCount,
    },
    {
      label: 'Successfully Processed',
      value: successRecordCount,
    },
    {
      label: 'Rejected Records',
      value: failedRecordCount,
    },
  ]

  return (
    <ModalEditScreen
      formTitle='Upload Details'
      onDismiss={() => dispatch(setCurrentEmployerJobDataId(undefined))}
      error={error}
      errorCancel={
        acknowledgeResultError ? acknowledgeResultReset
        : actionRecordsResultError ? actionRecordsResultReset
        : cancelJobError ? cancelJobReset
        : close
      }
      errorTryAgain={actionRecordsResultError ? undefined : acknowledgeResultError ? handleAcknowledge : refetchJob}
      isLoading={isLoading}
      loadingMessage={jobIsLoading ? undefined : ['Saving...']}
      buttonTitle={acknowledgeable && userCanManageJobs ? 'Archive Job' : 'Close'}
      buttonAction={
        finalized
          ? close
          : acknowledgeable && userCanManageJobs
            ? job?.status !== GroupSchemeJobStatus.COMPLETED
              ? () => setAcknowledgeDialogVisible(true) 
              : handleAcknowledge
            : checkClose
      }
      showButton={true}
      enableButton={true}
      allowTextButton={unfinished}
      textButtonTitle={
        cancellable
          ? userCanCancelJobs
            ? 'Cancel Investigation'
            : undefined
          : 'Check Progress'
      }
      textButtonAction={
        cancellable
          ? userCanCancelJobs
            ? () => setCancelDialogVisible(true)
            : undefined
          : handleCheckStatus
      }
      enableTextButton={!checkDisabled}
      footerInfo={
        finalized
          ? undefined
          : acknowledgeable && !userCanManageJobs
            ? <Text>{`You do not have permissions to manage jobs.`}</Text>
            : cancelDialogVisible && !userCanCancelJobs
              ? <Text>{`You do not have permissions to cancel jobs in investigation.`}</Text>
              : !userCanRectifyJobs && anyUnactionedRecords
                ? <Text>{`You do not have permissions to manage rejected records for jobs.`}</Text>
                : undefined
        }
      >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={identificationData}
        noContentDivider={true}
      />
      {
        fileDownloadUrl && originalFilename
          ? <>
              <ContentDivider />
              <Subheading style={{ color: themeColors.primary }}>{`Uploaded File`}</Subheading>
              <UnborderedTable
                data={[
                  {
                    label: `Original Filename`,
                    value: originalFilename,
                    copyableValue: originalFilename,
                  },
                ]}
                noContentDivider={true}
              />
              <ExternalLinkButton
                url={fileDownloadUrl}
                downloadAsFile={true}
                downloadFilename={originalFilename}
                labelStyle={{
                  textDecorationLine: 'none',
                  textTransform: 'none',
                }}
                color={Colors.brand.purple1}
                iconName={'file-download-outline'}
                iconColor={Colors.brand.purple1}
              >{'Download'}</ExternalLinkButton>
              </>
          : <></>
      }
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Status`}</Subheading>
      <UnborderedTable
        data={statusData}
        noContentDivider={true}
      />

      {
        jobType === GroupSchemeJobType.CONTRIBUTION && successful && paymentId
          ? <>
              <ContentDivider />
              <Subheading style={{ color: themeColors.primary }}>{`Related Payment`}</Subheading>
              <UnborderedTable
                data={paymentData}
                noContentDivider={true}
              />
            </>
          : <></>
      }

      {
        successful
          ? <>
              <ContentDivider />
              <Subheading style={{ color: themeColors.primary }}>{`Record Processing`}</Subheading>
              <UnborderedTable
                data={recordData}
                noContentDivider={true}
              />
              {
                failedRecordFileDownloadUrl
                  ? <ExternalLinkButton
                      url={failedRecordFileDownloadUrl}
                      downloadAsFile={true}
                      downloadFilename={`Jarvis Rejected Records - ${dataSetFormat} - ${id}.${dataSetFormat === GroupSchemeJobDataSetFormat.JSON ? 'json' : 'csv'}`}
                      labelStyle={{
                        textDecorationLine: 'none',
                        textTransform: 'none',
                      }}
                      color={Colors.brand.purple1}
                      iconName={'file-download-outline'}
                      iconColor={Colors.brand.purple1}
                    >
                      {`Download Rejected Records`}
                    </ExternalLinkButton>
                  : <></>
              }
            </>
          : <></>
      }

      {
        isFinished && unactionedRecords?.length
          ? <>
              <ContentDivider />
              <Subheading style={{ color: themeColors.primary }}>{`Unresolved Records`}</Subheading>
              {
                userCanRectifyJobs
                  ? <>
                      <Paragraph>{`When you have actioned these issues in your payroll system, please select records below to mark them as resolved.`}</Paragraph>              
                      <BulkSelectToolbar
                        alwaysEnabled={true}
                        enabled={true}
                        anySelected={anyUnactionedSelected}
                        enableFunction={() => {}}
                        cancelFunction={() => {}}
                        selectAllFunction={selectAllUnactionedRecords}
                        unselectAllFunction={unselectAllUnactionedRecords}
                        bulkActionFunction={handleActionRecords}
                        bulkActionTitle={'Mark As Resolved'}
                      />
                    </>
                  : <></>
              }
              {
                unactionedRecords.map((failedRecord, idx) => {
                  return (
                    <RejectedRecord
                      key={idx}
                      failedRecord={failedRecord}
                    />
                  )
                })
              }
            </>
          : <></>
      }

      {
        isFinished && actionedRecords?.length
          ? <>
              <ContentDivider />
              <Subheading style={{ color: themeColors.primary }}>{`Resolved Records`}</Subheading>
              {
                userCanRectifyJobs
                  ? <>
                      <Paragraph>{`If you have accidentally marked any as resolved, select records below to mark them as unresolved.`}</Paragraph>
                      <BulkSelectToolbar
                        alwaysEnabled={true}
                        enabled={true}
                        anySelected={anyActionedSelected}
                        enableFunction={() => {}}
                        cancelFunction={() => {}}
                        selectAllFunction={selectAllActionedRecords}
                        unselectAllFunction={unselectAllActionedRecords}
                        bulkActionFunction={handleUnactionRecords}
                        bulkActionTitle={'Mark As Unresolved'}
                        bulkActionIconName={'close-circle-outline'}
                      />
                      </>
                  : <></>
                }
              {
                actionedRecords.map((failedRecord, idx) => {
                  return (
                    <RejectedRecord
                      key={idx}
                      failedRecord={failedRecord}
                    />
                  )
                })
              }
            </>
          : <></>
      }
      <ConfirmationDialog
        visible={cancelDialogVisible}
        title={'Are you sure?'}
        content={'Cancelling the job will let the Jarvis Support Team know that you no longer wish for this job to be investigated or processed.\n\n'}
        onCancel={() => setCancelDialogVisible(false)}
        onConfirm={handleCancel}
        confirmLabel={'Confirm'}
        cancelLabel={'Go Back'}
      />
      <ConfirmationDialog
        visible={acknowledgeDialogVisible}
        title={'Are you sure?'}
        content={'This action cannot be undone!\n\nPlease confirm that you taken the necessary steps in your payroll software to action any rejected records.'}
        onCancel={() => setAcknowledgeDialogVisible(false)}
        onConfirm={handleAcknowledge}
        confirmLabel={'Confirm'}
        cancelLabel={'Go Back'}
      />
      <ConfirmationDialog
        visible={dirtyDialogVisible}
        title={'Are you sure?'}
        content={'You have selected records but have not saved them!'}
        onCancel={() => setDirtyDialogVisible(false)}
        onConfirm={close}
        confirmLabel={'Close Anyway'}
        cancelLabel={'Go Back'}
      />
      <ConfirmationDialog
        visible={closeDialogVisible}
        title={'Are you sure?'}
        content={'There are unactioned rejected records for this job.\n\nTo finalise this job and be able to archive it, you must mark all rejected records as actioned.'}
        onCancel={() => setCloseDialogVisible(false)}
        onConfirm={close}
        confirmLabel={'Close Anyway'}
        cancelLabel={'Go Back'}
      />
    </ModalEditScreen>
  )
}

