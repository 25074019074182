import { ProcessStackBuilder } from 'navigation/stacks/ProcessStackBuilder'
import React from 'react'
import { EnrolmentOptOut_01_Information, EnrolmentOptOut_02_VerifyByNino, EnrolmentOptOut_03_VerifyByEmployeeId, EnrolmentOptOut_04_Confirm, EnrolmentOptOut_99_Result } from './Screens'

export const EnrolmentOptOutProcessStack = ({ route, navigation }) => {

  return (
    <ProcessStackBuilder
      navigatorId='EnrolmentOptOut'
      processTitle='Pension Scheme Opt Out'
      previousRoute='EnrolmentOptOutIntroScreen'
      nextRoute='EnrolmentOptOutHeroScreen'
      initialRouteName={route?.params?.initialRouteName}
      initialParams={route?.params}
      processScreens={[
        { 
          navigationId: 'Information',
          component: EnrolmentOptOut_01_Information,
        },
        {
          navigationId: 'VerifyByNino',
          component: EnrolmentOptOut_02_VerifyByNino,
        },
        {
          navigationId: 'VerifyEmployeeId',
          component: EnrolmentOptOut_03_VerifyByEmployeeId,
        },
        {
          navigationId: 'Confirm',
          component: EnrolmentOptOut_04_Confirm,
        },
      ]}
      resultScreen={{
        navigationId: 'Results',
        component: EnrolmentOptOut_99_Result,
      }}
    />
  )
}
