import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph, Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { Button } from 'components/Utility/Button'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { PensionProviderModalContent, PensionProviderModalContentSection } from 'components/Utility/PensionProviderModalContent'
import React, { useState } from 'react'
import { View } from 'react-native'
import { useGetPensionBrandsQuery, useGetPensionProvidersQuery } from 'store/apiSlice'
import { Sizing } from 'styles'
import { ProviderInfoButton } from '../Components/ProviderInfoButton'
import { TransferLogModal } from '../Components/TransferLogModal'
import { Portal } from 'react-native-paper'
import { ContentDivider } from 'components/Layout/ContentDivider'

export const RetirementAssetTransfer_01_RetransferAssistance = ({ route, navigation }) => {
  const { nextScreen, asset }  = route?.params || {}
  const [showDialog, setShowDialog] = useState(false)

  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const { data: providers, error: providersError, isLoading: providersIsLoading, refetch: refetchProviders } = useGetPensionProvidersQuery()

  const error = brandsError || providersError
  const isLoading = brandsIsLoading || providersIsLoading

  const selectedProvider = providers ? providers.find(provider => {
    return provider.id === asset?.pensionProviderId
  }) : undefined

  const selectedProviderBrand = selectedProvider && brands ? brands.find(brand => {
    return brand.key === selectedProvider?.primaryBrandKey
  }) : undefined

  const providerImageSource = selectedProviderBrand?.logo ? { uri: selectedProviderBrand?.logo } : require('assets/icons/default_provider.png')

  const [showTransferLogModal, setShowTransferLogModal] = useState(false)

  const next = () => {
    setShowDialog(false)
    //Go to Provider List...
    navigation.navigate('ProviderList')
  }

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={brandsError ? refetchBrands : refetchProviders}
      buttonTitle={`Next`}
      buttonAction={() => setShowDialog(true)}
      enableButton={true}
      headline={`First, let's review what went wrong`}
      subHeading={'Check the Transfer Log for any clues as to why it was rejected'}
    >
      <View style={{ paddingVertical: Sizing.x20 }}>
        <Button mode='text' onPress={() => setShowTransferLogModal(true)}>{`View Transfer Log`}</Button>
      </View>
      <ContentDivider />
      <Subheading>{'Below are a few common reasons why pension transfers are rejected:'}</Subheading>
      <View style={{ alignSelf: 'center' }}>
        <BulletItem style={{ textAlign: 'left' }}>{`Wrong pension provider`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`Incorrect reference number`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`Old provider has a different address on record`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`Old provider has a different name on record`}</BulletItem>
      </View>
      <Paragraph>{`Please check recent documentation or call your provider direct to find the exact details`}</Paragraph>
      <ProviderInfoButton
        modalContent={<PensionProviderModalContent
          provider={selectedProvider}
          imageSource={providerImageSource}
          sections={[
            PensionProviderModalContentSection.CONTACT,
            PensionProviderModalContentSection.REFERENCE,
            PensionProviderModalContentSection.GENERAL,
          ]}
        />}
      >{`Contact Information`}</ProviderInfoButton>
      {/* <Paragraph>{`If your old provider has a different name on record, you will need to speak to them to update their records`}</Paragraph> */}
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'Please confirm'}
        content={'I understand that I need to modify information on the following screens, otherwise my transfer is likely to be rejected again'}
        onConfirm={next}
      />
      <Portal>
        <TransferLogModal
          asset={asset}
          visible={showTransferLogModal}
          onDismiss={() => setShowTransferLogModal(false)}
        />
      </Portal>
    </ModalProcessScreen>
  )
}
