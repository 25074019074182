import { GroupSchemeJobDataSetFormat, GroupSchemeJobDto, GroupSchemeJobStatus, GroupSchemeJobType } from 'store/dto/group-scheme.dto'
import { formatISODateOrUndefined, getActionDate } from './dateHelpers'

const SCHEME_JOB_ASYNC_INPROGESS_STATUS: GroupSchemeJobStatus[] = [
  GroupSchemeJobStatus.VALIDATING,
  GroupSchemeJobStatus.PENDING,
  GroupSchemeJobStatus.PROCESSING,
]

const SCHEME_JOB_INPROGESS_STATUS: GroupSchemeJobStatus[] = [
  GroupSchemeJobStatus.VALIDATING,
  GroupSchemeJobStatus.PENDING,
  GroupSchemeJobStatus.PROCESSING,
  GroupSchemeJobStatus.FINALISING,
]

const SCHEME_JOB_UNFINISHED_STATUS: GroupSchemeJobStatus[] = [
  GroupSchemeJobStatus.VALIDATING,
  GroupSchemeJobStatus.PENDING,
  GroupSchemeJobStatus.PROCESSING,
  GroupSchemeJobStatus.INVESTIGATING,
  GroupSchemeJobStatus.FINALISING,
]

const SCHEME_JOB_FINISHED_STATUS: GroupSchemeJobStatus[] = [
  GroupSchemeJobStatus.COMPLETED,
  GroupSchemeJobStatus.COMPLETED_WITH_ERRORS,
  GroupSchemeJobStatus.REJECTED,
  GroupSchemeJobStatus.CANCELLED,
]

const SCHEME_JOB_FOR_REVIEW_STATUS: GroupSchemeJobStatus[] = [
  GroupSchemeJobStatus.INVESTIGATING,
  GroupSchemeJobStatus.COMPLETED_WITH_ERRORS,
  GroupSchemeJobStatus.REJECTED,
  GroupSchemeJobStatus.CANCELLED,
]

const SCHEME_JOB_SUCCESS_STATUS: GroupSchemeJobStatus[] = [
  GroupSchemeJobStatus.COMPLETED,
  GroupSchemeJobStatus.COMPLETED_WITH_ERRORS,
]

export const isForReviewGroupSchemeJob = (job: GroupSchemeJobDto): boolean => {
  return SCHEME_JOB_FOR_REVIEW_STATUS.includes(job?.status)
}

export const isAsyncInProgressGroupSchemeJob = (job: GroupSchemeJobDto): boolean => {
  return SCHEME_JOB_ASYNC_INPROGESS_STATUS.includes(job?.status)
}
export const isInProgressGroupSchemeJob = (job: GroupSchemeJobDto): boolean => {
  return SCHEME_JOB_INPROGESS_STATUS.includes(job?.status)
}

export const isUnfinishedGroupSchemeJob = (job: GroupSchemeJobDto): boolean => {
  return SCHEME_JOB_UNFINISHED_STATUS.includes(job?.status)
}

export const isFinishedGroupSchemeJob = (job: GroupSchemeJobDto): boolean => {
  return SCHEME_JOB_FINISHED_STATUS.includes(job?.status)
}

export const isSuccessfulGroupSchemeJob = (job: GroupSchemeJobDto): boolean => {
  return SCHEME_JOB_SUCCESS_STATUS.includes(job?.status)
}

export const getGroupSchemeJobListInfo = (job: GroupSchemeJobDto): {
  iconName: string,
  description: string,
  uploadedByString: string,
  message: string
} => {
  return {
    iconName: getGroupSchemeJobIconName(job),
    description: getGroupSchemeJobDescription(job),
    uploadedByString: getGroupSchemeUploadInformation(job),
    message: getGroupSchemeJobActionMessage(job),
  }
}

export const getGroupSchemeUploadInformation = (job: GroupSchemeJobDto): string => {
  const { creator, createdAt, dataSetFormat } = job || {}
  const { firstName, surname, id } = creator || {}

  return dataSetFormat === GroupSchemeJobDataSetFormat.JSON
    ? id
      ? `Manual submission by ${firstName} ${surname} (${getActionDate(createdAt)})`
      : `Submitted via API connection (${getActionDate(createdAt)})`
    : id
      ? `${dataSetFormat} upload by ${firstName} ${surname} (${getActionDate(createdAt)})`
      : `${dataSetFormat} upload via API connection (${getActionDate(createdAt)})`
}

export const getGroupSchemeJobDescription = (job: GroupSchemeJobDto): string => {
  const { description, dataSetFormat, jobType, creator, originalFilename } = job || {}

  if (description) {
    return description
  }

  return dataSetFormat === GroupSchemeJobDataSetFormat.JSON
    ? jobType === GroupSchemeJobType.MEMBER
      ? 'Member Enrolment Submission'
      : 'Contribution Submission'
    : originalFilename
}

export const getGroupSchemeJobIconName = (job: GroupSchemeJobDto): string => {
  const { status, resultAcknowledged } = job || {}
  if (resultAcknowledged) {
    return status === GroupSchemeJobStatus.REJECTED ? 'archive-remove-outline'
      : status === GroupSchemeJobStatus.CANCELLED ? 'archive-cancel-outline'
      : 'archive-check-outline'
  }

  switch(status) {
    case GroupSchemeJobStatus.COMPLETED:
      return 'file-check-outline'
    case GroupSchemeJobStatus.REJECTED:
      return 'file-remove-outline'
    case GroupSchemeJobStatus.CANCELLED:
      return 'file-cancel-outline'
    case GroupSchemeJobStatus.INVESTIGATING:
      return 'file-question-outline'
    case GroupSchemeJobStatus.COMPLETED_WITH_ERRORS:
      return 'file-alert-outline'
    case GroupSchemeJobStatus.PROCESSING:
      return 'file-send-outline'
    case GroupSchemeJobStatus.PENDING:
      return 'file-clock-outline'
    default:
      return 'file-outline'
  }  
}

export const getGroupSchemeJobActionMessage = (job: GroupSchemeJobDto): string => {
  const { resultAcknowledged, expectedCompleteAt, createdAt, status } = job || {}

  //Archived
  if (resultAcknowledged) {
    return `Archived`
  }

  //In progress
  if (SCHEME_JOB_ASYNC_INPROGESS_STATUS.includes(status)) {
    return `Estimated Completion: ${expectedCompleteAt ? getActionDate(expectedCompleteAt) : `Unknown`}`
  }

  //Finalising
  if (status === GroupSchemeJobStatus.FINALISING) {
    return `Estimated Completion: Soon`
  }

  //For review
  if (SCHEME_JOB_FOR_REVIEW_STATUS.includes(status)) {
    return `Click for Details`
  }

  //Complete
  if (status === GroupSchemeJobStatus.COMPLETED) {
    return `Click for Results`
  }

  //Else
  return status
}