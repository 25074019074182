import { Headline, Paragraph } from 'components/Typography'
import { JAR_NAME_ALL, JAR_NAME_GROUP, JAR_NAME_PERSONAL } from 'lib/constants'
import { scaleNormalizer } from 'lib/scaleHelpers'
import React from 'react'
import { Image, View } from 'react-native'
import { useGetAssetQuery } from 'store/apiSlice'
import { AccountDto, AccountType } from 'store/dto/account.dto'
import { ClientMeDto } from 'store/dto/client.dto'
import { Sizing } from 'styles'
import { ActionCard } from './ActionCard'
import { GroupPortfolioModalContent } from './GroupPortfolioModalContent'
import { InvestmentAssetModalContent } from './InvestmentAssetModalContent'
import { InvestmentPlanModalContent } from './InvestmentPlanModalContent'
import { Loading } from './Loading'
import { PensionAssetAllocationModalContent } from './PensionAssetAllocationModalContent'
import { rootNavigate } from 'lib/RootNavigation'
import { AppIllustration } from './AppIllustration'

type PensionInvestmentInformationCardProps = {
  account: AccountDto
  investmentReferenceAccount: AccountDto
  showAsGlobalDecision: boolean
  client: ClientMeDto
  includePreamble?: boolean
}

export const PensionInvestmentInformationCard = (props: PensionInvestmentInformationCardProps) => {
  const { account, investmentReferenceAccount, showAsGlobalDecision, client, includePreamble } = props

  const isFollowingGroupPensionAccount = account.accountType === AccountType.GROUP_PENSION
    && account.usePersonalPensionInvestmentChoice
    && investmentReferenceAccount.id !== account.id

  const isGroupPortfolio = investmentReferenceAccount?.groupPortfolio
  const isInvestmentPlan = !isGroupPortfolio && investmentReferenceAccount?.investmentPlan
  const isSingleFund = !isInvestmentPlan && !isInvestmentPlan && investmentReferenceAccount?.assetAllocation?.length === 1
  const singleFundAssetId = isSingleFund ? investmentReferenceAccount?.assetAllocation[0]?.assetId : undefined

  const { data: singleFundAsset, isLoading: singleFundAssetIsLoading, error: singleFundAssetError, refetch: refetchSingleFundAsset } = useGetAssetQuery(singleFundAssetId, { skip: !singleFundAssetId })
  const singleFundName = singleFundAsset?.shortName

  const planTitle = isGroupPortfolio
    ? investmentReferenceAccount?.groupPortfolio?.name
    : isInvestmentPlan
      ? investmentReferenceAccount?.investmentPlan?.name
      : isSingleFund
        ?  singleFundName
        : 'Bespoke Portfolio'

  const accountDescriptor = showAsGlobalDecision
    ? JAR_NAME_ALL
    : account.accountType === AccountType.GROUP_PENSION ? JAR_NAME_GROUP
    : JAR_NAME_PERSONAL

  let planDescription = ''

  if (isFollowingGroupPensionAccount) {
    planDescription += `Your ${accountDescriptor} is following the investment strategy for your Personal Jar, and currently invested `
  } else {
    planDescription += `Your ${accountDescriptor} is currently invested `
  }
  
  planDescription += isGroupPortfolio
    ? `in our '${planTitle}' portfolio.`
    : isInvestmentPlan
      ? `in our '${planTitle}' plan.`
      : isSingleFund
        ? `in the '${singleFundName}' fund.`
        : `in a bespoke portfolio.`

        
  const imageSource = isGroupPortfolio
    ? { uri: investmentReferenceAccount?.groupPortfolio?.logo }
    : isInvestmentPlan
      ? { uri: investmentReferenceAccount?.investmentPlan?.logo }
      : isSingleFund
        ? { uri: singleFundAsset?.logo }
        : undefined

  const illustrationFilename = imageSource
    ? undefined
    : 'control_your_financial_future.png'


  //Identify the best segment to use
  const segment = account?.segment ? account?.segment : client?.segment

  const handleChangeInvestmentStrategy = () => {
    rootNavigate('InvestmentChoiceIntroScreen', { contextAccount: account, investmentReferenceAccount })
  }

  return (
    <>
      { singleFundAssetIsLoading ? <Loading size={Sizing.x20} /> : singleFundAssetError ? <></> :
        <>
          {
            includePreamble ?
              <View style={{
                alignSelf: 'center',
              }}>
                <Headline>{planTitle}</Headline>
                <Paragraph>{planDescription}</Paragraph>
                {
                  illustrationFilename ?
                    <AppIllustration filename={illustrationFilename} style={{
                      width: scaleNormalizer(150),
                      height: scaleNormalizer(150),
                      resizeMode: 'contain',
                      alignSelf: 'center',
                    }} />
                  : 
                    <Image source={imageSource} style={{
                      width: scaleNormalizer(150),
                      height: scaleNormalizer(150),
                      resizeMode: 'contain',
                      alignSelf: 'center',
                    }} />

                }
              </View>
            : <></> 
          }
          <ActionCard
            illustrationFilename={illustrationFilename}
            imageSource={imageSource}
            title={isGroupPortfolio
              ? `View Portfolio Details`
              : isInvestmentPlan
                ? `View Plan Details`
                : singleFundAssetId
                  ? `View Fund Details`
                  : `View Details`
            }
            description={`See the full details for your investment${isGroupPortfolio ? ' portfolio' : isInvestmentPlan ? ' plan' : ''}`}
            size={'small'}
            onPress={() => {}}
            onCancel={handleChangeInvestmentStrategy}
            // dismissalConfirmationMessage={`Change your investment strategy now?`}
            buttonLabel={'More Information'}
            confirmationModalContentFunction={isGroupPortfolio
              ? () => <GroupPortfolioModalContent
                accountDescriptor={accountDescriptor}
                groupPortfolio={investmentReferenceAccount?.groupPortfolio}
                segment={segment}
              />
              : isInvestmentPlan
                ? () => <InvestmentPlanModalContent
                accountDescriptor={accountDescriptor}
                  investmentPlan={investmentReferenceAccount?.investmentPlan}
                  segment={segment}
                />
                : singleFundAssetId
                  ? () => <InvestmentAssetModalContent
                  accountDescriptor={accountDescriptor}
                    asset={singleFundAsset}
                    segment={segment}
                  />
                  : () => <PensionAssetAllocationModalContent
                    assetAllocation={investmentReferenceAccount?.assetAllocation}
                    accountDescriptor={accountDescriptor}
                    segment={segment}
                  />
            }
            confirmationModalProceedLabel={`Close`}
            confirmationModalCancelLabel={'Change Strategy'}
          />
        </>
      }
    </>
  )
}
