import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { BulkSelectToolbar } from 'components/Utility/BulkSelectToolbar'
import { InformationButton } from 'components/Utility/InformationButton'
import { getActionDate } from 'lib/dateHelpers'
import { isWithinOptOutWindow } from 'lib/enrolmentHelpers'
import { userCanManageJobsForScheme } from 'lib/groupSchemeHelpers'
import { concat, orderBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { useAddGroupSchemeJobWithDataMutation, useGetGroupSchemeMembersQuery } from 'store/apiSlice'
import { GroupSchemeEnrolmentStatus } from 'store/dto/account.dto'
import { CreateGroupSchemeJobContributionDto, CreateGroupSchemeJobRecordContributionDto, GroupSchemeJobDataSetFormat, GroupSchemeJobType, GroupSchemeMemberDto } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setShowEmployerAddContributionJobVisible, setSnackbarData } from 'store/uxSlice'
import { Colors, Sizing } from 'styles'
import { EmployerContributionConfirmDialog } from './Components/EmployerContributionConfirmDialog'
import { EmployerContributionTable } from './Components/EmployerContributionTable'

export const ACTIVE_MEMBER_COUNT_ALERT = 950
export const ACTIVE_MEMBER_COUNT_MAX = 999

export enum EmployerContributionSetChoice {
  NIL = 'No Contributions',
  LAST = 'Use Last Amounts',
  NEW = 'Set New Amounts'
}

export type WrappedContributionRecord = {
  id: string,
  selected: boolean
  data: ContributionRecord
}

export type ContributionRecord = Pick<CreateGroupSchemeJobRecordContributionDto,
  'nationalInsuranceNo' |
  'employeeEmail' |
  'employeeId' |
  'firstName' |
  'surname' |
  'gender' |
  'birthDate' |
  'employerContributionAmount' |
  'employeeContributionAmount'
> & {
  lastEmployerContributionAmount: number
  lastEmployeeContributionAmount: number
  enrolmentEndDate: string
  autoEnrolmentOptOutDate: string
  autoEnrolmentWindowOptOutDate: string
  choice: EmployerContributionSetChoice
  inOptOutWindow: boolean
}

export const EmployerAddContributionJobModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
    const dispatch = useAppDispatch()

    const currentScheme = useAppSelector(currentGroupScheme)

    const userCanManageJobs = userCanManageJobsForScheme(currentScheme)

    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  
    const [addContributionJob, { data: addedJob, isLoading: addJobIsLoading, error: addJobError, reset: addJobReset }] = useAddGroupSchemeJobWithDataMutation()
  
    const { data: members, isLoading: membersIsLoading, error: membersError, isFetching: membersIsFetching, refetch: refetchMembers } = useGetGroupSchemeMembersQuery({
      groupSchemeId: currentScheme?.id,
      enrolmentStatus: GroupSchemeEnrolmentStatus.ACTIVE,
    })
    
    const mapMembersToContributionRecords = (members: GroupSchemeMemberDto[]): WrappedContributionRecord[] => {
      return members ? orderBy(members, [function(m: GroupSchemeMemberDto) { return `${m?.client?.firstName} ${m?.client?.surname}` }], ['asc']).map((member: GroupSchemeMemberDto): WrappedContributionRecord => {
        const {
          lastEmployeeContributionAmount,
          lastEmployerContributionAmount,
          client,
        } = member
        const { firstName, surname, gender, birthDate, nationalInsuranceNo } = client || {}
  
        const inOptOutWindow = isWithinOptOutWindow(member)
        const hasLastAmounts = lastEmployerContributionAmount || lastEmployeeContributionAmount
        const choice: EmployerContributionSetChoice =
          inOptOutWindow ? EmployerContributionSetChoice.NIL
          : hasLastAmounts ? EmployerContributionSetChoice.LAST
          : EmployerContributionSetChoice.NEW
        return {
          id: member?.id,
          selected: false,
          data: {
            nationalInsuranceNo,
            employeeEmail: member?.employeeEmail,
            employeeId: member?.employeeId,
            firstName,
            surname,
            gender,
            birthDate,
            lastEmployerContributionAmount: member?.lastEmployerContributionAmount || 0,
            lastEmployeeContributionAmount: member?.lastEmployeeContributionAmount || 0,
            employerContributionAmount: member?.lastEmployerContributionAmount || 0,
            employeeContributionAmount: member?.lastEmployeeContributionAmount || 0,
            enrolmentEndDate: member?.enrolmentEndDate,
            autoEnrolmentOptOutDate: member?.autoEnrolmentOptOutDate,
            autoEnrolmentWindowOptOutDate: member?.autoEnrolmentWindowOptOutDate,
            choice,
            inOptOutWindow,
          }
        }
      }) : []
    }
  
    const formObj = useForm<{
      wrappedContributionRecords: WrappedContributionRecord[]
    }>({
      mode: 'all',
      reValidateMode: 'onChange',
      defaultValues: {
        wrappedContributionRecords: [],
      },
    })
  
    const { handleSubmit, setValue, setError, trigger, reset, watch, formState: { isDirty, isValid, errors } } = formObj
  
    const noMembers = members && !members?.length
    const tooManyMembers = members && members?.length > ACTIVE_MEMBER_COUNT_MAX
    const memberCountAlert = !tooManyMembers && members && members?.length > ACTIVE_MEMBER_COUNT_ALERT
  
    useEffect(() => {
      if (members && !tooManyMembers) {
        reset({
          wrappedContributionRecords: mapMembersToContributionRecords(members),
        })
      }
    }, [members])
  
    const onSubmit = async attributes => {
      setShowConfirmDialog(false)
      const { wrappedContributionRecords }: { wrappedContributionRecords: WrappedContributionRecord[] } = attributes
      const contributionRecords: CreateGroupSchemeJobRecordContributionDto[] = wrappedContributionRecords.filter(wcr => {
        return wcr.data?.choice !== EmployerContributionSetChoice.NIL
      }).map(wcr => {
        const { data } = wcr
        const {
          nationalInsuranceNo,
          employeeEmail,
          employeeId,
          firstName,
          surname,
          employerContributionAmount,
          employeeContributionAmount,
        } = data || {}
        return {
          nationalInsuranceNo,
          employeeEmail,
          employeeId,
          firstName,
          surname,
          employerContributionAmount,
          employeeContributionAmount,
        }
      })
  
      const data: CreateGroupSchemeJobContributionDto = {
        jobType: GroupSchemeJobType.CONTRIBUTION,
        groupSchemeId: currentScheme.id,
        contributionRecords,
      }
      addContributionJob(data)
    }
  
    useEffect(() => {
      if (addedJob) {
        dispatch(setSnackbarData({
          message: `Contribution Job added!`,
          subMessage: `We'll send you a message when it has been processed${addedJob?.expectedCompleteAt ? ` (estimated completion at ${getActionDate(addedJob?.expectedCompleteAt )})` : ``}`,
          iconName: 'check-circle-outline',
          duration: 5000,
        }))
        close()
      }
    }, [addedJob])
  
    const close = () => {
      dispatch(setShowEmployerAddContributionJobVisible(false))
    }
  

    const isLoading = addJobIsLoading || membersIsLoading || membersIsFetching
    const error: any = addJobError || membersError
  
    
    const [bulkSelectMode, setBulkSelectMode] = useState(false)
    
    const enableBulkSelect = () => {
      setBulkSelectMode(true)
    }
  
    const cancelBulkSelect = () => {
      setBulkSelectMode(false)
      unselectAllRecords()
    }
  
    const wrappedContributionRecords = watch('wrappedContributionRecords')
    const anyContribution = wrappedContributionRecords.find(wcr => {
      return wcr.data?.choice !== EmployerContributionSetChoice.NIL
    })
  
    const handleBulkNilReturn = () => {
      wrappedContributionRecords.forEach((contributionRecord, index) => {
        if (contributionRecord.selected) {
          setValue(`wrappedContributionRecords.${index}.data.choice`, EmployerContributionSetChoice.NIL, { shouldDirty: true })
          setValue(`wrappedContributionRecords.${index}.data.employerContributionAmount`, 0, { shouldDirty: true })
          setValue(`wrappedContributionRecords.${index}.data.employeeContributionAmount`, 0, { shouldDirty: true })
          setValue(`wrappedContributionRecords.${index}.selected`, false, { shouldDirty: true })
        }
        //Validate all
        trigger(`wrappedContributionRecords`)
        //Leave bulk selectt mode
        setBulkSelectMode(false)
      })
    }
  
    const anySelected = !!wrappedContributionRecords.find(wcr => {
      return wcr.selected
    })
  
    const unselectAllRecords = () => {
      wrappedContributionRecords.forEach((contributionRecord, index) => {
        setValue(`wrappedContributionRecords.${index}.selected`, false, { shouldDirty: true })
      })
    }
  
    const selectAllRecords = () => {
      wrappedContributionRecords.forEach((contributionRecord, index) => {
        setValue(`wrappedContributionRecords.${index}.selected`, true, { shouldDirty: true })
      })
    }
  
    return (
      <ModalEditScreen
        formTitle={'Submit Contributions'}
        onDismiss={close}
        isDirty={isDirty}
        dismissDialogText={'Discard contribution submission?'}
        error={error}
        errorTryAgain={addJobError ? handleSubmit(onSubmit) : refetchMembers}
        errorCancel={addJobError ? addJobReset : close}
        isLoading={isLoading}
        loadingMessage={addJobIsLoading ? ['Creating contribution job...'] : ['Retrieving active members...']}
        buttonTitle={noMembers || tooManyMembers ? 'Close' : 'Submit Contributions'}
        buttonAction={noMembers || tooManyMembers ? close : () => setShowConfirmDialog(true)}
        showButton={true}
        enableButton={noMembers || tooManyMembers || (userCanManageJobs && isValid && !!anyContribution)}
        footerInfo={userCanManageJobs
          ? anyContribution
            ? undefined
            : <InputErrorMessages
                formObj={formObj}
                name={`wrappedContributionRecords`}
                informationMessage={`Please add contributions for at least one member`}
                informationMessageIsError={true}
              />
          : <Text>{`You do not have permissions to submit contributions for members.`}</Text>
        }
      >
        {
          noMembers
          ? <>
              <Subheading>{'You do not currently have any active members'}</Subheading>
              <View style={{
                flexDirection: 'row',
                justifyContent: 'center',
              }}>
                <AppIllustration
                  filename={'magnifying_glass.png'}
                  style={{
                    width: Sizing.x200,
                    height: Sizing.x200,
                  }}
                  resizeMode={'contain'}
                />
              </View>
              <Paragraph>{'If you have recently requested enrolment for new members, you may need to wait for those jobs to complete before you can submit contributions.'}</Paragraph>
            </>
          : <>
              {
                memberCountAlert || tooManyMembers
                ? <>
                    <InformationButton
                      buttonTitle={tooManyMembers
                        ? 'You have surpassed the member limit for manual submission'
                        : 'You are approaching the member limit for manual submission'
                      }
                      noStartCase={true}
                      title={'Manual Contribution Submission Limits'}
                      iconName={'alert-box-outline'}
                      iconColor={tooManyMembers ? Colors.brand.red2 : Colors.warning.s400}
                      texts={concat(
                        [
                          `Manual submission of contributions via the Jarvis Employer Portal is intended for use by small businesses with up to ${ACTIVE_MEMBER_COUNT_MAX} active members.`,
                        ],
                        tooManyMembers
                          ? [
                              `You currently have ${members?.length} active members in this pension scheme, which is over this limit.`,
                              `To continue using manual submissions, you musyt reduce the number of active members in your scheme.`,
                            ]
                          : [
                              `You currently have ${members?.length} active members in this pension scheme, and are therefore approaching this limit.`,
                              `You will be able to continue using manual submission up until you have ${ACTIVE_MEMBER_COUNT_MAX} active members. Beyond this, manual submission of contributions will not be available.`,
                            ],
                        [
                          
                          `You can upload submissions for larger numbers of active members using a supported CSV file (e.g. PAPDIS), or integrating your payroll system with our APIs.`,
                          `Please contact Jarvis support to discuss these options in more detail.`,
                        ]
                      )}
                    />
                  </>
                : <></>
              }
              {
                tooManyMembers
                  ? <>
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}>
                        <AppIllustration
                          filename={'support.png'}
                          style={{
                            width: Sizing.x200,
                            height: Sizing.x200,
                          }}
                          resizeMode={'contain'}
                        />
                      </View>
                      <Paragraph>{'Please click the link above for more information, or contact Jarvis Support.'}</Paragraph>
                    </>
                  : <>
                      <Subheading>{'Please enter contributions amounts below for your active scheme members.'}</Subheading>                    
                      <BulkSelectToolbar
                        enabled={bulkSelectMode}
                        anySelected={anySelected}
                        enableFunction={enableBulkSelect}
                        cancelFunction={cancelBulkSelect}
                        selectAllFunction={selectAllRecords}
                        unselectAllFunction={unselectAllRecords}
                        bulkActionFunction={handleBulkNilReturn}
                        bulkActionTitle={'Set No Contributions'}
                        bulkActionIconName={'account-multiple-remove-outline'}
                        hideSelectAllCheckbox={true}
                      />
                      <EmployerContributionTable
                        formObj={formObj}
                        name={'wrappedContributionRecords'}
                        bulkSelectMode={bulkSelectMode}
                        selectAllFunction={selectAllRecords}
                        unselectAllFunction={unselectAllRecords}
                      />
                    </>
              }
            </>  
        }
        {
          showConfirmDialog
            ? <EmployerContributionConfirmDialog
                formObj={formObj}
                name={'wrappedContributionRecords'}
                onSubmit={handleSubmit(onSubmit)}
                onCancel={() => setShowConfirmDialog(false)}
                visible={showConfirmDialog}
              />
            : <></>
        }
      </ModalEditScreen>
    )
  }
    
