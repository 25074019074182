import { Headline, Subheading, Paragraph } from 'components/Typography'
import { FooterButton } from 'components/Utility/FooterButton'
import { Logger } from 'lib/logger'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useGetStatusQuery, useLazyGetStatusQuery, useRefetchErroredQueriesMutation } from 'store/apiSlice'
import { networkUnavailable } from 'store/authSlice'
import { useAppSelector } from 'store/hooks'
import { Sizing } from "styles"
import { BottomBannerModal } from './BottomBannerModal'
import { Environment, envVariables } from 'environment'
import { Text } from 'components/Typography/Text'

const MAX_AUTO_RETRIES = 5
const RETRY_INTERVAL_MS = 5000

export const NetworkUnavailableModal = () => {
  const networkIsUnavailable = useAppSelector(networkUnavailable)

  const { apiUrl, envName } = envVariables

  const [checking, setChecking] = useState<boolean>(false)
  const [retryCount, setRetryCount] = useState<number>(0)

  const skip = !networkIsUnavailable
  const pollingInterval = retryCount < MAX_AUTO_RETRIES ? RETRY_INTERVAL_MS : undefined

  const { data: status, error: statusError, isLoading, isFetching, refetch: refetchStatus } = useGetStatusQuery(undefined, { skip, pollingInterval })
  const [refetchErrored] = useRefetchErroredQueriesMutation()

  const checkNetwork = async () => {
    Logger.debug('Checking status...')
    refetchStatus()
  }

  //Trigger refetch of fetch errors when network available
  useEffect(() => {
    if (!networkIsUnavailable) {
      Logger.debug(`Triggering refetch of errorred queries...`)
      setRetryCount(0)
      refetchErrored()
    }
  }, [networkIsUnavailable])

  useEffect(() => {
    if (isLoading || isFetching) {
      setChecking(true)
      setRetryCount(retryCount + 1)
    } else {
      setChecking(false)
    }
  }, [isLoading, isFetching])

  return (
    <BottomBannerModal
      visible={networkIsUnavailable}
      dismissable={false}
    >
      <View style={{
        paddingVertical: Sizing.x20,
        flexDirection: 'column',
        alignItems: 'center',
        paddingHorizontal: Sizing.x30,
      }}>
        <Headline style={{ fontSize: Sizing.x20 }}>{`Connection Error`}</Headline>
        {
          envName === Environment.PRODUCTION ? <></> : <Text>{apiUrl}</Text>
        }        
        <Subheading>{`Jarvis requires an active connection to the internet`}</Subheading>
        {
          retryCount < MAX_AUTO_RETRIES ?
            <Paragraph>
              {checking ? `Checking...(${retryCount}/${MAX_AUTO_RETRIES})` : `Retrying shortly...`}
            </Paragraph>
          :
            <FooterButton
              mode={'contained'}
              onPress={checkNetwork}
              disabled={checking}
            >
              {checking ? 'Checking...' : 'Check now'}
            </FooterButton>
        }
      </View>
    </BottomBannerModal>)
}