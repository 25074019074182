import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { linkParams } from 'store/authSlice'
import { ExtendedGender } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingCalculatorRequest, workingCalculatorRequest } from 'store/tempDataSlice'

export const Calculator_11_Gender = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId }  = route?.params || {}

  const linkConfig = useAppSelector(linkParams)

  //Temp state
  const dispatch = useAppDispatch()
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)

  //Setup form
  const formObj = useForm<{ gender: ExtendedGender }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      gender: workingCalculatorRequestData?.client?.gender
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingCalculatorRequest({
      client: {
        ...workingCalculatorRequestData?.client,
        gender: attributes.gender,
      }
    }))
    //PA-2201 - Removed
    // ampli.calculatorUsage({
    //   processStep: 'client_gender',
    //   loggedInUserId,
    //   affiliateCode: linkConfig?.affiliateCode,
    //   affiliateRef: linkConfig?.affiliateRef,
    // })
    navigation.navigate(nextScreen)
  }

  const genderOptions: ManagedSimpleChoiceItem[] = [
    {
      value: ExtendedGender.MALE,
      label: `Male`
    },
    {
      value: ExtendedGender.FEMALE,
      label: `Female`
    },
    {
      value: ExtendedGender.OTHER,
      label: `Other *`,
    },
  ]

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`And how would you describe your gender?`}
      subHeading={'Again, this helps us accurately determine your life expectancy'}
    >      
      <ManagedSimpleChoiceInput
        name={'gender'}
        formObj={formObj}
        options={genderOptions}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        informationMessage={`* For the purposes of life expectancy calculations, we will use Female mortality data`}
      />
    </ProcessScreen>
  )
}
