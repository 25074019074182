import { BaseDatedDto, Gender, Title } from "./base.dto"

export enum FeatureStatus {
  ENABLED = 'enabled',
  OPT_IN = 'opt_in',
  OPT_OUT = 'opt_out',
  DISABLED = 'disabled',
}


export enum FeatureSearchFeatureStatus {
  ENABLED = 'enabled',
  OPT_IN = 'opt_in',
  OPT_OUT = 'opt_out',
  DISABLED = 'disabled',
  ANY = 'Any'
}

export enum FeatureCode {
  TRACING = 'pension_tracing',
  FUND_CHOICE = 'fund_choice',
  GROUP_PENSION = 'group_pension',
  CLIENT_WEB_APP = 'client_web_app',
  ENROL_NO_NINO = 'enrol_no_nino',
}

export interface FeatureDto extends BaseDatedDto {
  code: string
  name: string
  description: string
  status: FeatureStatus
}

export interface FeatureFilterDto {
  search?: string
  status?: FeatureStatus
}

export interface UpdateFeatureDto {
  id: string
  // name?: string //Not allowed for now
  // description?: string //Not allowed for now
  status?: FeatureStatus
}

export interface UserFeatureDto extends BaseDatedDto {
  code: string
  name: string
  description: string
  status: FeatureStatus
  enabled: boolean
}
