import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { MainAppHeader } from 'components/Layout/MainAppHeader'
import { RefreshableScrollView } from 'components/ScreenTemplates/RefreshableScrollView'
import { Paragraph } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { NamedInformation } from 'components/Utility/InformationButton'
import { Loading } from 'components/Utility/Loading'
import { ManagedCarousel } from 'components/Utility/ManagedCarousel'
import { OnboardingProgress } from 'components/Utility/OnboardingProgress'
import { PensionInvestmentInformationCard } from 'components/Utility/PensionInvestmentInformationCard'
import { PieChartDataSet, PieChartWithLegend } from 'components/Utility/PieChartWithLegend'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { formatISO, isAfter, subDays, subMonths } from 'date-fns'
import { OnboardingGuard } from 'features/Onboarding/components/OnboardingGuard'
import { goToOnboardingTasks, mainAppNavigate, rootNavigate } from 'lib/RootNavigation'
import { getGroupPension, getInvestmentChoiceData, getPersonalPension } from 'lib/accountHelpers'
import { JAR_NAME_ALL, JAR_NAME_GROUP, JAR_NAME_PERSONAL } from 'lib/constants'
import { calculateAgeAtDate, getActionDate } from 'lib/dateHelpers'
import { isNotFoundError } from 'lib/errorHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { RetirementAssetCategory, filterNonTransferedAssets, getAssetCategory } from 'lib/retirementAssetHelpers'
import { getScreenAppWidth, scaleNormalizer } from 'lib/scaleHelpers'
import { getTransactionDescription, getTransactionIconName, getTransactionTitle } from 'lib/transactionHelpers'
import { concat, orderBy, sumBy } from 'lodash'
import { OnboardingPhase, OnboardingStateStepIdentifier, useGuidanceContext } from 'providers/guidance.context'
import React, { useEffect, useRef } from 'react'
import { View } from 'react-native'
import { ThemeProvider } from 'react-native-paper'
import { useGetCurrentUserQuery, useGetGroupSchemeEnrolmentsQuery, useGetMeQuery, useGetRetirementAssetsQuery, useGetRetirementProfileQuery, useGetSpouseQuery, useGetUserAccountsQuery, useGetUserContributionsQuery, useSyncUserAccountsMutation } from 'store/apiSlice'
import { AccountTransactionStatus, AccountType } from 'store/dto/account.dto'
import { AmlStatus } from 'store/dto/client.dto'
import { RetirementAssetDto, RetirementAssetTransferStatus } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { timelinePinned } from 'store/uxSlice'
import { Colors, Paper, Sizing, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { DashboardHeroItem } from '../Components/DashboardHeroItem'
import { DashboardSection } from '../Components/DashboardSection'
import { DashboardSectionSet } from '../Components/DashboardSectionSet'
import { DashboardSwipeableScreen } from '../Components/DashboardSwipeableScreen'
import { SuggestionCardSet } from '../Components/SuggestionCardSet'

// Some config
export const DashboardToday = ({ route, navigation }) => {

  const dispatch = useAppDispatch()

  const { currentViableAge } = useGuidanceContext()

  const timelineIsPinned = useAppSelector(timelinePinned)
  const scrollRef = useRef()
  useEffect(() => {
    if (timelineIsPinned && scrollRef?.current) {
      // @ts-ignore 
      scrollRef?.current?.scrollTo({ y: 0 })
    }
  }, [timelineIsPinned])

  const { data: user, isLoading: userIsLoading, error: userError } = useGetCurrentUserQuery()
  const { data: client, isLoading: clientIsLoading, error: clientError, isFetching: clientIsFetching, refetch: refetchClient } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, isFetching: spouseIsFetching } = useGetSpouseQuery()
  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, isFetching: accountsIsFetching, refetch: refetchPension } = useGetUserAccountsQuery()
  const { data: enrolments, error: enrolmentsError, isLoading: enrolmentsIsLoading, isFetching: enrolmentsIsFetching,  refetch: refetchEnrolments } = useGetGroupSchemeEnrolmentsQuery()
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, isFetching: rpIsFetching } = useGetRetirementProfileQuery()
  const { data: assets, isLoading: assetsIsLoading, error: assetsError, isFetching: assetsIsFetching, refetch: refetchAssets } = useGetRetirementAssetsQuery()

  const isLoading = userIsLoading || clientIsLoading || accountsIsLoading || enrolmentsIsLoading || assetsIsLoading || spouseIsLoading
  const error: any = userError || clientError || assetsError || enrolmentsError
  const hasAccounts = !!accounts?.length
  
  const personalPension = getPersonalPension(accounts)
  const groupPension = getGroupPension(accounts)

  let totalReservedBalance = 0
  if (enrolments) {
    enrolments.forEach(enrolment => {
      totalReservedBalance += enrolment?.reservedBalance?.currentValue || 0
    })
  }

  const totalGroupBalance = (groupPension?.currentValue || 0) + totalReservedBalance

  //Create a map for enrolment labels
  const employerCompanyRegNoMap = {}
  if (enrolments) {
    enrolments.forEach(enrolment => {
      if (enrolment?.groupScheme?.companyNo) {
        employerCompanyRegNoMap[enrolment?.groupScheme?.companyNo] = enrolment?.groupScheme?.organizationDisplayName
      }
    })
  }

  const needsPensionOnboarding = !personalPension
  const needsAssetsOnboarding = !client?.onboardingFlags?.assets

  const [syncUserAccounts, { isLoading: syncUserAccountsIsLoading }] = useSyncUserAccountsMutation()

  //Contributions
  const transactionDateFrom = formatISO(subMonths(new Date(), 3), { representation: 'date' })
  const transactionDateTo = formatISO(new Date(), { representation: 'date' })
  const { data: contributions, isLoading: contributionsIsLoading, error: contributionsError, isFetching: contributionsIsFetching, refetch: refetchContributions } = useGetUserContributionsQuery({ transactionDateFrom, transactionDateTo, })

  const recentCompletedContributions = contributions?.length ? contributions.filter(contribution => {
    if (![AccountTransactionStatus.PENDING, AccountTransactionStatus.COMPLETED].includes(contribution.status)) {
      return false
    }
    return isAfter(new Date(contribution.transactionDate), subDays(new Date(), 30))
  }) : []

  const clientAge = client ? calculateAgeAtDate(formatISO(new Date()), client?.birthDate) : 0

  const nonTransferredAssets = filterNonTransferedAssets(assets)
  const otherAssetsCurrentValue = sumBy(nonTransferredAssets, 'currentValue')
  const meCurrentValue = sumBy(accounts, 'currentValue')
  const totalCurrentValue = meCurrentValue + (spouse?.totalCurrentValue || 0)
  const mePendingContributionValue = sumBy(accounts, 'pendingContributionValue')
  const mePendingTaxReclaimValue = sumBy(accounts, 'pendingTaxReclaimValue')
  const totalPendingContributionValue = (mePendingContributionValue || 0) + (spouse?.totalPendingContributionValue || 0)
  const totalPendingTaxReclaimValue = (mePendingTaxReclaimValue || 0) + (spouse?.totalPendingTaxReclaimValue || 0)
  const totalPendingValue = totalPendingContributionValue + totalPendingTaxReclaimValue
  const totalCurrentAssetValue = otherAssetsCurrentValue + totalCurrentValue + totalPendingContributionValue + totalPendingTaxReclaimValue
  const bestRetirementAge = currentViableAge?.output?.viableRetirementAge || retirementProfile?.targetRetirementAge || client?.statePensionAge
  const yearsUntilRetirement = client ? bestRetirementAge - clientAge : 0

  function CurrentAssetsContent() {
    const currentAssetTableData: UnborderedTableRow[] = []

    //Personal pension
    currentAssetTableData.push({
      label: JAR_NAME_PERSONAL,
      value: <Text style={{ color: Colors.brand.red3 }}>
        {
          personalPension
            ? formatCurrencyAmount(personalPension.currentValue || 0, 2)
            : client?.amlStatus === AmlStatus.PASS
              ? `Choose Plan`
              : !client?.amlStatus
                ? 'Verify Identity'
                : ''
        }
      </Text>,
      iconSource: <MaterialCommunityIcons name={'piggy-bank'} size={Sizing.x20} color={Colors.neutral.white} />,
      isLoading: accountsIsLoading,
      isFetching: accountsIsFetching || syncUserAccountsIsLoading,
      linkFunction: personalPension
        ? () => mainAppNavigate(MainAppNavScreen.PERSONAL_PENSION)
        : client?.amlStatus === AmlStatus.PASS
          ? () => rootNavigate('InvestmentChoiceIntroScreen')
          : !client?.amlStatus
            ? () => rootNavigate('ClientVerificationIntroScreen')
            : undefined,
    })

    //Group pension
    if (totalGroupBalance > 0) {
      currentAssetTableData.push({
        label: JAR_NAME_GROUP,
        value: <Text style={{ color: Colors.brand.red3 }}>
          {formatCurrencyAmount(totalGroupBalance, 2)}
        </Text>,
        iconSource: <MaterialCommunityIcons name={'briefcase'} size={Sizing.x20} color={Colors.neutral.white} />,
        isLoading: accountsIsLoading || enrolmentsIsLoading,
        isFetching: accountsIsFetching || enrolmentsIsFetching || syncUserAccountsIsLoading,
        linkFunction: () => mainAppNavigate(MainAppNavScreen.GROUP_PENSION),
      })
    }

    //Spouse accounts
    if (spouse?.hasActiveAccounts) {
      currentAssetTableData.push({
        label: `${spouse?.firstName || 'Partner'}'s ${JAR_NAME_ALL}`,
        value: <Text style={{ color: Colors.brand.red3 }}>
          {formatCurrencyAmount(spouse?.totalCurrentValue || 0)}
        </Text>,
        iconSource: <MaterialIcons name={'group'} size={Sizing.x20} color={Colors.neutral.white} />,
        isLoading: spouseIsLoading,
        isFetching: spouseIsFetching,
      })
    }

    //Pending contributions
    if (totalPendingValue) {
      currentAssetTableData.push({
        label: 'Pending Contributions *',
        value: <Text style={{ color: Colors.brand.red3 }}>
          {totalPendingValue ? formatCurrencyAmount(totalPendingValue, 2) : '--'}
        </Text>,
        iconSource: <MaterialCommunityIcons name={'timer-sand'} size={Sizing.x20} color={Colors.neutral.white} />,
        isLoading: accountsIsLoading || spouseIsLoading,
        isFetching: accountsIsFetching || spouseIsFetching || syncUserAccountsIsLoading,
        linkFunction: () => mainAppNavigate(MainAppNavScreen.CONTRIBUTIONS),
      })
    }

    //Other assets
    currentAssetTableData.push({
      label: 'Other Assets',
      value: <Text style={{ color: Colors.brand.red3 }}>
        {needsAssetsOnboarding ? 'Add' : formatCurrencyAmount(otherAssetsCurrentValue)}
      </Text>,
      iconSource: <MaterialCommunityIcons name={'piggy-bank-outline'} size={Sizing.x20} color={Colors.neutral.white} />,
      isLoading: assetsIsLoading,
      isFetching: assetsIsFetching,
      linkFunction: needsAssetsOnboarding
        ? () => rootNavigate('RetirementAssetSetupIntroScreen')
        : () => mainAppNavigate(MainAppNavScreen.RETIREMENT_ASSETS),
    })
    
    //TOTAL
    currentAssetTableData.push({
      label: 'Total Current Assets',
      value: <Text style={{ color: Colors.brand.red3, fontFamily: 'LabGrotesque-Medium' }}>
        {needsAssetsOnboarding ? '?' : formatCurrencyAmount(totalCurrentAssetValue)}
      </Text>,
      iconSource: <MaterialCommunityIcons name={'calculator'} size={Sizing.x20} color={Colors.neutral.white} />,
      isLoading: accountsIsLoading || spouseIsLoading || assetsIsLoading,
      isFetching: accountsIsLoading || spouseIsFetching || assetsIsFetching,
      isTotal: true,
    })

    return (
      <View style={{ paddingHorizontal: Sizing.x30 }}>
        <UnborderedTable
          data={currentAssetTableData}
        />
        {
          totalPendingValue ?
            <Paragraph style={{ ...Typography.fontSize.x10, textAlign: 'right' }}>
              {spouse?.hasActiveAccounts
                ? `* Includes HMRC Tax Reclaims & ${spouse?.firstName}'s Contributions`
                : `* Includes HMRC Tax Reclaims`
              }
            </Paragraph>
            : <></>
        }
      </View>
    )
  }

  function AssetBreakdownContent() {
    const buildAssetPieChartData = (assets: RetirementAssetDto[]): PieChartDataSet => {
      //Pie chart categories
      let otherPensionTotal: number = 0
      let savingsTotal: number = 0
      let otherTotal: number = 0

      //Segregate other assets
      assets.forEach(asset => {
        const category = getAssetCategory(asset.assetType)
        if (category === RetirementAssetCategory.PENSIONS) {
          otherPensionTotal += asset.currentValue
        } else if (category === RetirementAssetCategory.SAVINGS) {
          savingsTotal += asset.currentValue
        } else {
          otherTotal += asset.currentValue
        }
      })

      const categories = []

      //Personal pension
      if (personalPension) {
        categories.push({
          name: JAR_NAME_PERSONAL, items: [{
            x: '',
            y: personalPension.currentValue
          }]
        })
      }

      //Group pension
      if (totalGroupBalance > 0) {
        categories.push({
          name: JAR_NAME_GROUP, items: [{
            x: '',
            y: totalGroupBalance
          }]
        })
      }

      //Spouse
      if (spouse?.hasActiveAccounts) {
        categories.push({
          name: `${spouse?.firstName || 'Partner'}'s ${JAR_NAME_ALL}`, items: [{
            x: '',
            y: spouse?.totalCurrentValue
          }]
        })
      }


      //Pending contributions
      categories.push({
        name: 'Pending Contributions', items: [{
          x: '',
          y: totalPendingContributionValue + totalPendingTaxReclaimValue
        }]
      })

      //Other pensions
      categories.push({
        name: 'Other Pensions', items: [{
          x: '',
          y: otherPensionTotal,
        }]
      })
      
      //Other savings
      categories.push({
        name: 'Other Savings', items: [{
          x: '',
          y: savingsTotal,
        }]
      })
      
      //Other assets
      categories.push({
        name: 'Other Assets', items: [{
          x: '',
          y: otherTotal,
        }]
      })

      return {
        categories,
      }
    }

    const noData = !hasAccounts && !spouse?.hasActiveAccounts && !nonTransferredAssets.length

    //Build pie chart data for non-transferred assets
    const dataSet: PieChartDataSet = buildAssetPieChartData(nonTransferredAssets)

    return (
      <>
        <OnboardingGuard
          explanation={'Understand how your current retirement savings are distributed in pensions and other assets.'}
          size={'small'}
          onboardingSteps={[
            OnboardingStateStepIdentifier.ASSETS,
          ]}
        >
          {
            noData
              ? <>
                  <View style={{ paddingVertical: Sizing.x20 }}>
                    <Paragraph>{`Add some assets to see how your current retirement savings are distributed.`}</Paragraph>
                  </View>
                  <View style={{ paddingHorizontal: Sizing.x30 }}>
                    <Button onPress={() => mainAppNavigate(MainAppNavScreen.RETIREMENT_ASSETS, { screen: MainAppNavScreen.RETIREMENT_ASSETS })}>{`Add Other Assets`}</Button>
                  </View>
                </>
              : <>
                  <PieChartWithLegend dataSet={dataSet} />
                  <View style={{ paddingHorizontal: Sizing.x30 }}>
                    <Button mode='text' onPress={() => mainAppNavigate(MainAppNavScreen.RETIREMENT_ASSETS, { screen: MainAppNavScreen.RETIREMENT_ASSETS })}>{`Manage Other Assets`}</Button>
                  </View>
                </>
          }

        </OnboardingGuard>
      </>
    )
  }

  function ContributionHistoryContent() {

    let tableData: UnborderedTableRow[] = []

    const { colors: themeColors } = Paper.useAppTheme()

    const hasContributions = recentCompletedContributions?.length

    tableData = concat(tableData, hasContributions
      ? orderBy(contributions, ['transactionDate'], ['desc'])
        .map(contribution => {
          const iconName = getTransactionIconName(contribution)
          const employerName = contribution.companyRegNo ? employerCompanyRegNoMap[contribution.companyRegNo] : undefined
          return {
            iconSource: <MaterialCommunityIcons name={iconName} size={Sizing.x20} color={themeColors.primary} />,
            label: getTransactionTitle(contribution),
            subLabel: getTransactionDescription(contribution, employerName),
            value: formatCurrencyAmount(contribution.amount, 2),
            subValue: `${getActionDate(contribution.transactionDate, true)}`,
          }
        })
      : [
        {
          label: `No recent contributions`,
          value: '--',
        }
      ]
    )

    const onboardingGuardSteps = groupPension ? [] : [
      OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
      OnboardingStateStepIdentifier.PLAN_CHOICE,
    ]

    return (
        <>
        <OnboardingGuard
          explanation={`Quickly start making contributions to save for your future by opening a ${JAR_NAME_PERSONAL}.`}
          size={'small'}
          onboardingSteps={onboardingGuardSteps}
        >
          <View style={{ paddingHorizontal: Sizing.x30 }}>
            {
              hasContributions ?
                <>
                  <UnborderedTable
                    isLoading={contributionsIsLoading}
                    error={error}
                    data={tableData}
                  />
                  <View style={{ paddingBottom: Sizing.x10 }}>
                    <Button mode='text' onPress={() => mainAppNavigate(MainAppNavScreen.CONTRIBUTIONS)}>{`View All Contributions`}</Button>
                  </View>
                </>

                : <>
                    <Paragraph style={{ paddingVertical: Sizing.x20 }}>{`No completed contributions yet!`}</Paragraph>
                  </>
            }
            {
              personalPension
              ? <View style={{ paddingBottom: Sizing.x10 }}>
                  <Button onPress={() => rootNavigate('ContributionsIntroScreen')}>{`Make a New Contribution`}</Button>
                </View>
              : <View style={{ paddingBottom: Sizing.x10 }}>
                  <Paragraph>{`To start making your own contributions to your ${JAR_NAME_ALL}, first open a ${JAR_NAME_PERSONAL}`}</Paragraph>
                  <Button onPress={() => goToOnboardingTasks(OnboardingPhase.CONTRIBUTE)}>{`Make a New Contribution`}</Button>
                </View>
            }
          </View>
        </OnboardingGuard>        
        <View style={{ height: Sizing.x100 }}></View>
      </>
    )
  }

  function InvestmentStrategyContent() {
    const sliderWidth = getScreenAppWidth() - Sizing.x60

    const investmentChoiceData = getInvestmentChoiceData(accounts)

    const renderInvestmentChoiceCard = ({ item, index }) => {
      const { account, investmentReferenceAccount, showAsGlobalDecision } = item
      return (
        <PensionInvestmentInformationCard
          account={account}
          investmentReferenceAccount={investmentReferenceAccount}
          showAsGlobalDecision={showAsGlobalDecision}
          client={client}
          includePreamble={true}
        />
      )
    }

    const onboardingGuardSteps = groupPension ? [] : [
      OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
      OnboardingStateStepIdentifier.PLAN_CHOICE,
    ]

    const realAccounts = accounts ? accounts.filter(account => {
      return account.accountType === AccountType.PERSONAL_PENSION || account.accountType === AccountType.GROUP_PENSION
    }) : []

    return (
      <OnboardingGuard
        explanation={`Start saving for your future by opening a ${JAR_NAME_PERSONAL} and picking an investment plan that suits you.`}
        size={'small'}
        onboardingSteps={onboardingGuardSteps}
      >
        <View style={{ paddingHorizontal: Sizing.x30 }}>
          {
            realAccounts.length
              ? <>
                  <ManagedCarousel
                    loop={false}
                    width={sliderWidth}
                    height={scaleNormalizer(380)}
                    data={investmentChoiceData}
                    renderItem={renderInvestmentChoiceCard}
                    isLoading={accountsIsLoading}
                    isFetching={accountsIsFetching}
                  />
                </>
              : <Paragraph>{`Could not load the investment details at this time`}</Paragraph>
          }
        </View>
      </OnboardingGuard>
    )
  }

  return (
    <>
      <MainAppHeader
        sectionTitle={'Today'}
        sectionTitleInfo={NamedInformation.SECTION_DASHBOARD_TODAY}
      />
        <DashboardSwipeableScreen
          rightNavigationFunction={() => mainAppNavigate(MainAppNavScreen.DASHBOARD, { screen: MainAppNavScreen.DASHBOARD_RETIREMENT })}
          rightNavigationLabel={'RETIREMENT'}
        >
          {
            isLoading ? <Loading useHolidayGraphics={true} /> : error ? <ErrorScreen error={error?.data} noDashboardButton={true} /> :
              <RefreshableScrollView
                ref={scrollRef}
                refreshFunctions={[
                  syncUserAccounts,
                  refetchPension,
                  refetchClient,
                ]}
              >
                <View style={layoutStyles.dashboardMainContent}>
                  <DashboardHeroItem
                    error={clientError}
                    errorTryAgain={refetchClient}
                    isLoading={clientIsLoading}
                    isFetching={clientIsFetching}
                    title={`Current Age`}
                    footer={needsAssetsOnboarding ? '' : formatCurrencyAmount(totalCurrentAssetValue)}
                    heroText={clientAge.toString()}
                    rightNavigationFunction={() => mainAppNavigate(MainAppNavScreen.DASHBOARD, { screen: MainAppNavScreen.DASHBOARD_RETIREMENT })}
                  />
                  <OnboardingProgress />
                  <SuggestionCardSet screen={MainAppNavScreen.DASHBOARD_TODAY} />
                </View>

                {
                  //First Dashboard section for financials
                  <ThemeProvider theme={Paper.lightThemeOnDarkerPurple}>
                    <DashboardSection
                      visible={true}
                      isLoading={accountsIsLoading || spouseIsLoading || assetsIsLoading || syncUserAccountsIsLoading}
                      isFetching={accountsIsFetching || spouseIsFetching || assetsIsFetching}
                      title={'Current Assets'}
                      titleInfoName={NamedInformation.DASHBOARD_TODAY_CURRENT_ASSETS}
                      content={<CurrentAssetsContent />}
                      previousColor={Colors.brand.purple0}
                    />
                  </ThemeProvider>
                }

                <DashboardSectionSet
                  sections={[
                    {
                      title: `Investment Strategy`,
                      titleInfoName: NamedInformation.DASHBOARD_TODAY_INVESTMENT_PLAN,
                      visible: true,
                      isLoading: accountsIsLoading,
                      isFetching: accountsIsFetching,
                      error: accountsError && !isNotFoundError(accountsError),
                      content: <InvestmentStrategyContent />
                    },
                    {
                      title: `Current Asset Breakdown`,
                      titleInfoName: NamedInformation.DASHBOARD_TODAY_ASSET_BREAKDOWN,
                      visible: true,
                      isLoading: accountsIsLoading || assetsIsLoading,
                      isFetching: accountsIsFetching || assetsIsFetching,
                      error: accountsError && !isNotFoundError(accountsError) || assetsError,
                      refreshFunction: refetchAssets,
                      content: <AssetBreakdownContent />
                    },
                    {
                      title: `Recent Contributions`,
                      titleInfoName: NamedInformation.DASHBOARD_TODAY_RECENT_CONTRIBUTIONS,
                      visible: true,
                      isLoading: contributionsIsLoading,
                      isFetching: contributionsIsFetching,
                      error: contributionsError && !isNotFoundError(contributionsError),
                      refreshFunction: refetchContributions,
                      content: <ContributionHistoryContent />
                    },
                  ]}
                />
              </RefreshableScrollView>
          }
        </DashboardSwipeableScreen>
    </>
  )
}
