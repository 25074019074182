import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/Layout'
import { Paragraph, Subheading } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { JAR_NAME_ALL, JAR_NAME_GROUP } from 'lib/constants'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image } from 'react-native'
import { useGetPensionBrandsQuery, useGetPensionProvidersQuery } from 'store/apiSlice'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { AmlStatus, ClientMeDto } from 'store/dto/client.dto'
import { PublicGroupSchemeInviteDto } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAcceptSchemeInvite, workingSetupSchemeEnrolment } from 'store/tempDataSlice'
import { Sizing } from 'styles'

export const SchemeEnrolmentSetup_30_PreviousPensionChoice = ({ route, navigation }) => {
  const { nextScreen, enrolment, client }: { nextScreen: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}

  const {
    groupScheme, 
    hasPreviousPensionProviderReference
  } = enrolment || {}
  const {
    previousPensionProviderId,
    organizationName,
  } = groupScheme || {}

  const dispatch = useAppDispatch()

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)

  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery(undefined, { skip: !previousPensionProviderId })
  const { data: providers, error: providersError, isLoading: providersIsLoading, refetch: refetchProviders } = useGetPensionProvidersQuery(undefined, { skip: !previousPensionProviderId })

  const previousProvider = providers && previousPensionProviderId ? providers.find(provider => {
    return provider.id === previousPensionProviderId
  }) : undefined

  const previousBrand = previousProvider && brands ? brands.find(brand => {
    return brand.key === previousProvider.primaryBrandKey
  }) : undefined

  const shouldHavePreviousPension = previousBrand && hasPreviousPensionProviderReference
  const mightHavePreviousPension = previousBrand
  
  const isLoading = brandsIsLoading || providersIsLoading
  const error: any = brandsError || providersError

  const [showDialog, setShowDialog] = useState(false)

  //Setup form
  const formObj = useForm<{ previousPensionChoice: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      previousPensionChoice: workingSetupSchemeEnrolmentData?.previousPensionChoice,
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isValid} } = formObj

  const softOnSubmit = attributes => {
    const { previousPensionChoice } = attributes
    dispatch(updateWorkingAcceptSchemeInvite({
      previousPensionChoice,
    }))
    //Do nothing for choosing no
    if (!previousPensionChoice) {
      //Do nothing, wait for user to click button
    } else {
      navigation.navigate(nextScreen)
    }
  }

  const onSubmit = attributes => {
    setShowDialog(false)
    const { previousPensionChoice } = attributes
    dispatch(updateWorkingAcceptSchemeInvite({
      previousPensionChoice,
    }))

    if (previousPensionChoice) {
      navigation.navigate(nextScreen)
    } else {
      navigation.navigate('Results')
    }
  }

  const previousPensionChoiceOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: shouldHavePreviousPension
        ? `Yes, that's correct`
        : `Yes, I did`,
    },
    {
      value: false,
      label: shouldHavePreviousPension
      ? `No, that's incorrect`
      : `No, I didn't`,
    },
  ]

  const previousPensionChoice = watch('previousPensionChoice')

  const next = () => {
    navigation.navigate('Results')
  }

  const handleConfirm = () => {
    setShowDialog(false)
    next()
  }

  return (
    <ProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={brandsError ? refetchBrands : refetchProviders}
      buttonTitle={previousPensionChoice === undefined ? 'Please Make A Choice' : previousPensionChoice ? 'Next' : 'Finish'}
      buttonAction={previousPensionChoice ? handleSubmit(onSubmit ) : () => setShowDialog(true)}
      enableButton={isValid}
      headline={shouldHavePreviousPension
        ? `It looks like you had a previous pension`
        : mightHavePreviousPension
        ? `Did you have a ${previousBrand.name} pension?`
        : `Did you have a previous pension with this employer?`
      }
      subHeading={shouldHavePreviousPension
        ? `Your employer told us that you had a pension with ${previousBrand.name}}`
        : mightHavePreviousPension
        ? `Your employer previously used them for their workplace pension`
        : `You may have if your employer has switched provider`
      }
    >
      <ManagedSimpleChoiceInput
        name={'previousPensionChoice'}
        formObj={formObj}
        options={previousPensionChoiceOptions}
        submitHandler={handleSubmit(softOnSubmit)}
        required={true}
      />
      {
        previousBrand
          ? <Image source={{ uri: previousBrand?.logo }} style={{
              width: Sizing.x100,
              height: Sizing.x100,
              resizeMode: 'contain',
              alignSelf: 'center',
            }} />
          : <></>
      }
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'Are you sure?'}
        content={`You can add other pensions at any time in the Jarvis app to improve predictions and help you consolidate when you are ready to do so.`}
        confirmLabel={`I'm sure, Finish`}
        onConfirm={handleConfirm}
      />
    </ProcessScreen>
  )
}
