import { identity, pickBy } from 'lodash'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { View } from 'react-native'
import { RadioButton } from 'react-native-paper'
import { Sizing, Paper, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { InputErrorMessages } from './InputErrorMessages'
import { ItemType } from 'react-native-dropdown-picker'
import { ContentDivider } from 'components/Layout/ContentDivider'

export type ManagedSimpleChoiceItem = ItemType<any>

export type ManagedSimpleChoiceInputProps = {
  formObj: UseFormReturn<any>
  name: string
  options: ManagedSimpleChoiceItem[]
  submitHandler?: any
  noSeparator?: boolean
  required?: boolean
  customRules?: any
  disabled?: boolean
  informationMessage?: string
  informationMessageIsError?: boolean
}

export const ManagedSimpleChoiceInput = (props: ManagedSimpleChoiceInputProps) => {
  const { formObj, submitHandler, options, name, noSeparator, required, customRules, disabled, informationMessage, informationMessageIsError } = props
  const { control } = formObj

  const { colors: themeColors } = Paper.useAppTheme()

  const safeRequired = (value: any) => {
    return value !== undefined && value !== null
  }

  const customRulesToUse = customRules ? customRules : {}

  const customValidatorObj: any = {
    ...pickBy({
      required: required ? safeRequired : undefined,  
    }, identity),
    ...customRulesToUse,
  }

  return (
    <View style={layoutStyles.inputContainer}>
      <Controller
        control={control}
        rules={{
          validate: customValidatorObj,
        }}
        render={({ field: { onChange, value } }) => (
          <>
            <RadioButton.Group
              onValueChange={event => {
                onChange(event)
                if(submitHandler) {
                  submitHandler(event)
                }
              }}
              value={value}>
                { 
                  noSeparator ? <></> : <ContentDivider style={{ marginVertical: Sizing.x2, marginHorizontal: Sizing.x0 }} />
                }
                {
                  options.map((option, idx) => {
                    const isDisabled = disabled || option.disabled
                    return (
                      <View key={idx}>
                        <RadioButton.Item
                          mode='android'
                          color={themeColors.accent}
                          uncheckedColor={isDisabled ? themeColors.disabled : themeColors.text}
                          labelStyle={{
                            ...Typography.defined.radioButtonLabel,
                            color: isDisabled ? themeColors.disabled : option.value === value ? themeColors.accent : themeColors.text
                          }}
                          // labelProps={{
                          //   adjustsFontSizeToFit: true,
                          //   numberOfLines: 1,
                          //   allowFontScaling: false,
                          // }}
                          label={option.label}
                          value={option.value}
                          disabled={isDisabled}
                        />
                        { 
                         noSeparator ? <></> : <ContentDivider style={{ marginVertical: Sizing.x2, marginHorizontal: Sizing.x0 }} />
                        }
                      </View>
                    )
                  })

                }                
            </RadioButton.Group>
            <InputErrorMessages formObj={formObj} name={name} informationMessage={informationMessage} informationMessageIsError={informationMessageIsError} />
          </>
        )}
        name={name}
      />
    </View>
  )
}