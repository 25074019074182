import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import React, { useState } from 'react'
import { View } from 'react-native'
import { Sizing } from 'styles'

export const EnrolmentOptOut_04_Confirm = ({ route, navigation }) => {
  const { nextScreen }: { nextScreen: string }  = route?.params || {}

  const [showDialog, setShowDialog] = useState(false)

  const next = () => {
    setShowDialog(false)
    navigation.navigate(nextScreen)
  }

  return (
    <ProcessScreen
      buttonTitle={`I understand`}
      buttonAction={() => setShowDialog(true)}
      enableButton={true}
      headline={`Finally, please review the following`}
      subHeading={`Important information regarding opting out of a workplace pension scheme`}
      subHeadingNumberOfLines={3}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.AUTOMATIC_ENROLMENT} buttonTitle={'About Automatic Enrolment'} />}
    >
      <View style={{ paddingVertical: Sizing.x30 }}>
        <AppIllustration
          filename={'info.png'}
          style={{
            width: Sizing.x200,
            height: Sizing.x200,
            resizeMode: 'contain',
            alignSelf: 'center',
          }} />
      </View>
      <Subheading>{`By choosing to opt out of this workplace scheme:`}</Subheading>
      <View style={{ alignSelf: 'center' }}>
        <BulletItem style={{ textAlign: 'left' }}>{`You will lose the right to pension contributions from your employer`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`You may have a lower income when you retire`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`Your employer must return any pension contributions you have made.`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`Your employer may need to periodically re-enrol you in the future due to UK Government regulations. `}</BulletItem>
      </View>
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'Are you sure?'}
        content={`Please confirm you wish to opt out from this workplace pension scheme`}
        cancelLabel={'Go back'}
        confirmLabel={'Confirm'}
        onConfirm={next}
      />
    </ProcessScreen>
  )
}
