import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import MultiSlider from '@ptomasroos/react-native-multi-slider'
import { Paragraph, Subheading } from 'components/Typography'
import { Loading } from 'components/Utility/Loading'
import { NotificationDot } from 'components/Utility/NotificationDot'
import { ShakeBox } from 'components/Utility/ShakeBox'
import { format, formatISO } from 'date-fns'
import { calculateAgeAtDate } from 'lib/dateHelpers'
import { getScreenAppHeight, getScreenAppWidth, scaleNormalizer, scaleUnnormalizer } from 'lib/scaleHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { first, floor, last, max, min } from 'lodash'
import Victory from 'platform-lib/victory'
import { OnboardingPhase, useGuidanceContext } from 'providers'
import React, { useEffect, useMemo, useState } from 'react'
import { Animated, Pressable, StyleSheet, TouchableOpacity, View } from 'react-native'
import { ThemeProvider } from 'react-native-paper'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ClientMeDto } from 'store/dto/client.dto'
import { ModelRepresentationTerm } from 'store/dto/model.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setTimelinePinned, setTimelineTerm, timelinePinned } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing, Typography } from 'styles'
import { getTabIcon } from '../Dashboard'
import { Text } from 'components/Typography/Text'

const SLIDER_AREA_HEIGHT = scaleNormalizer(30)

const VictoryAxis = Victory.VictoryAxis
const VictoryBar = Victory.VictoryBar
const VictoryChart = Victory.VictoryChart
const VictoryTheme = Victory.VictoryTheme

export type TabBarWithTimelineProps = {
  state: any
  navigation: any
  descriptors: any
  notifications: any
  client: ClientMeDto
  tabIndex: number
  tabIndexMap: MainAppNavScreen[]
}

export const TabBarWithTimeline = (props: TabBarWithTimelineProps) => {
  const { state, navigation, descriptors, notifications, client, tabIndex, tabIndexMap } = props

  const dispatch = useAppDispatch()
  
  const insets = useSafeAreaInsets()

  const { currentModel, currentViableAge, currentModelIsLoading, currentModelIsFetching , suggestedOnboardingPhase } = useGuidanceContext()

  //TODO - Should these 2 figures be using scaleNormalizer?
  // const TIMELINE_CONTAINER_HEIGHT =
  //   scaleNormalizer(getScreenAppHeight())
  //   - (scaleNormalizer(200 + 140) + (SLIDER_AREA_HEIGHT))
  //   - insets.top
  //   - insets.bottom

  const TIMELINE_CONTAINER_HEIGHT =
    getScreenAppHeight() //Overall screen height
      - (insets.top + insets.bottom) //Minus top/bottom insets
      - SLIDER_AREA_HEIGHT //Minus slider area
      - scaleUnnormalizer(Sizing.x70) //Minus tab bar
      - 270 //Space to allow age in circle to shop

  const timelineIsPinned = useAppSelector(timelinePinned)

  const getTermNumberFromPosition = (position: number): number => {
    if (!sliderConfig) {
      return
    }
    const {
      tab2Position,
      tab3Position,
      postRetirementStep,
      preRetirementTermCount,
      postRetirementTermCount
    } = sliderConfig

    let term
    if (position <= tab2Position) {
      term = floor(position / postRetirementTermCount)
    } else {
      const startPosition = tab3Position - tab2Position
      const postRetirementTerms = floor(
        (position - startPosition) / postRetirementStep
      )
      term = preRetirementTermCount + postRetirementTerms
    }
    return term
  }

  const pickCurrentTermData = (termNumber): ModelRepresentationTerm => {
    if (termNumber !== undefined && termData) {
      let currentTermData: ModelRepresentationTerm
      //If zero, take first
      if (termNumber === 0) {
        currentTermData = first(termData)
      } else {
        currentTermData = termData.find(term => {
          return term.termNumber === termNumber
        })
        if (!currentTermData) {
          currentTermData = last(termData)
        }
      }
      return currentTermData
    }
  }

  const buildSliderConfig = (): {
    tab1Position: number
    tab2Position: number
    tab3Position: number
    preRetirementTermCount: number
    postRetirementTermCount: number
    preRetirementStep: number
    postRetirementStep: number
    sliderOptions: number[]
  } => {

    const clientAge = client ? calculateAgeAtDate(formatISO(new Date()), client?.birthDate) : 0

    let preRetirementTermCount = 0
    let postRetirementTermCount = 0

    //Derive term count based on model, or the client 
    if (currentModel) {
      currentModel.terms.forEach(term => {
        if (term.age < currentModel.output.viableRetirementAge) {
          preRetirementTermCount++
        } else {
          postRetirementTermCount++
        }
      })
    } else {
      preRetirementTermCount = client?.statePensionAge - clientAge + 1
      postRetirementTermCount = client?.lastSurvivorDeathAge - client?.statePensionAge
    }

    const lowestTermCount = min([preRetirementTermCount, postRetirementTermCount])
    const highestTermCount = max([preRetirementTermCount, postRetirementTermCount])
    const totalScale = preRetirementTermCount * postRetirementTermCount * 2

    const sliderOptions = []
    const totalOptionCount = (highestTermCount * 2) + 1

    for (let i = 0; i < (totalOptionCount - 1); i++) { //Minus 1 because we don't want the very last option
      const option = i * lowestTermCount
      sliderOptions.push(option)
    }

    return {
      tab1Position: 0,
      tab2Position: totalScale / 2,
      tab3Position: totalScale - 1,
      preRetirementTermCount,
      postRetirementTermCount,
      preRetirementStep: postRetirementTermCount,
      postRetirementStep: preRetirementTermCount,
      sliderOptions
    }
  }

  const deriveSliderPositionFromTabIndex = () => {
    const index = tabIndex
    if (!sliderConfig) {
      return 0
    }
    const { tab1Position, tab2Position, tab3Position } = sliderConfig

    let position = tab2Position
    if (index === 0) {
      position = tab1Position
    } else if (index === 2) {
      position = tab3Position
    }
    return position
  }

  const chart = () => {
    return (
      <VictoryChart
        padding={{
          top: Sizing.x10,
          bottom: Sizing.x10,
          left: Sizing.x30,
          right: Sizing.x30,
        }}
        // style={{ parent: { marginLeft: -scaleNormalizer(Sizing.x30) } }}
        style={{ parent: { marginLeft: -Sizing.x30 } }}
        domainPadding={{ x: 5 }}
        height={TIMELINE_CONTAINER_HEIGHT - scaleNormalizer(240)}
        theme={VictoryTheme.material}
      >
        <VictoryBar
          data={termData}
          // animate={{
          //   duration: 200,
          //   onLoad: { duration: 200 }
          // }}
          style={{

            data: {
              fill: ({ datum }) => { return (currentModel ? datum?.age === currentTermData?.age ? Colors.brand.red3 : Colors.brand.purple1 : Colors.brand.grey2) }
            }
          }}
          x="age"
          y="startValue" />
        <VictoryAxis style={{
          axis: { stroke: 'none' },
          ticks: { stroke: 'none' },
          tickLabels: { fill: 'none' },
          grid: { stroke: 'transparent' }
        }} />
      </VictoryChart>
    )
  }

  const sliderConfig = useMemo(buildSliderConfig, [client, currentModel])
  const sliderPosition = useMemo(deriveSliderPositionFromTabIndex, [tabIndex, client, currentModel])

  const initialTermNumber = getTermNumberFromPosition(sliderPosition)
  const initialTerm = pickCurrentTermData(initialTermNumber)

  const [timelineViewed, setTimelineViewed] = useState(true)
  const [currentTermData, setCurrentTermData] = useState<ModelRepresentationTerm>(initialTerm)

  const timelinePeekingPosition = (scaleNormalizer(55)) * -1
  const timelineShownPosition = ((TIMELINE_CONTAINER_HEIGHT) * -1) + 2

  const termData = currentModel ? currentModel.terms : []

  //Set timelineViewed back to false when model changes
  useEffect(() => {
    if (currentModel) {
      setTimelineViewed(false)
    }
  }, [currentModel])

  //Set global state when currentTermData changes
  useEffect(() => {
    dispatch(setTimelineTerm(currentTermData?.termNumber))
  }, [currentTermData])

  const onValuesChange = position => {
    const newTerm = getTermNumberFromPosition(position)
    const newTermData = pickCurrentTermData(newTerm)
    if (newTermData) {
      setCurrentTermData(newTermData)
    }
  }

  useEffect(() => {
    const newTermNumber = getTermNumberFromPosition(sliderPosition)
    const newTerm = pickCurrentTermData(newTermNumber)
    setCurrentTermData(newTerm)
  }, [tabIndex, client, currentModel])

  const togglePinned = () => {
    const newPinned = !timelineIsPinned
    dispatch(setTimelinePinned(newPinned))
  }

  function TimelineContent() {
    return (
      <Animated.View
        style={[
          {
            ...localStyles.container,
            height: TIMELINE_CONTAINER_HEIGHT,
          },
          {
            // top: moveTimeline
            top: currentModel && timelineIsPinned ? timelineShownPosition : timelinePeekingPosition
          }
        ]}>
        <View style={localStyles.body}>
          <Pressable
            onPress={currentModel ? togglePinned : undefined}
            style={[localStyles.header,
              timelineIsPinned ? {
              borderBottomWidth: 0.5,
              borderBottomColor: Colors.brand.purple1,
            } : undefined
            ]}
          >
            <View style={{
              width: Sizing.x30,
              height: Sizing.x30,
              paddingTop: Sizing.x5,
              paddingRight: Sizing.x10,
            }}>
            </View>
            <Subheading style={{
              paddingTop: Sizing.x0,
              color: Colors.brand.purple1,
              textAlign: 'center',
            }}>{'Retirement Timeline'}</Subheading>
            <View
              style={{
                width: Sizing.x30,
                height: Sizing.x30,
                paddingLeft: Sizing.x10,
              }}>
                {
                  timelineIsPinned
                    ? <MaterialCommunityIcons name={'close'} size={Sizing.x20} color={Colors.brand.purple1} />
                    : currentModel
                      ? <></>
                      : <Loading size={Sizing.x20} />
                }
              
            </View>
          </Pressable>
          {
            currentModelIsLoading || currentModelIsFetching || !currentTermData ? 
            <ThemeProvider theme={Paper.lightTheme}>
              <Loading message={['Updating timeline...']} />
            </ThemeProvider>
            : currentModel ?
              <View style={localStyles.content}>
                <Subheading style={{
                  fontSize: Sizing.x15,
                }}>
                  {`${currentModel
                      ? currentTermData?.termNumber === 0
                        ? 'Total Assets at '
                        : 'Projected Total Assets at '
                      : ''}${format(new Date(currentTermData ? currentTermData?.startDate : undefined), 'MMMM yyyy')}`}
                </Subheading>
                <View>
                  {chart()}
                  <View style={{
                    position: 'absolute',
                    bottom: Sizing.x20,
                    ...Flex.row.center,
                  }}>
                    {
                      currentModel ? <></> :
                        <View style={{ backgroundColor: Colors.brand.grey3 }}>
                          <Subheading style={{ color: Colors.brand.grey2 }}>{'Example'}</Subheading>
                        </View>
                    }
                    </View>
                </View>
                <View style={{
                  height: Sizing.x50,
                }}>
                  <Subheading style={{
                    fontSize: Sizing.x30,
                    lineHeight: Sizing.x40,
                    color: Colors.brand.purple1,
                  }}>{currentModel ? formatCurrencyAmount(currentTermData?.startValue, 0) : '£?'}
                  </Subheading>
                  <Paragraph>{
                    currentModel
                      ? 'Drag the slider to see how your assets are projected to grow throughout your lifetime'
                      : `Complete 'Get Your Retirement Age' tasks to see your personalised asset growth chart`
                    }</Paragraph>
                </View>
              </View>
              : <></>
            }
        </View>
      </Animated.View>
    )
  }
  
  function SliderMarker() {
    return (
      <View
        style={localStyles.sliderMarkerOuter}
      >
        <Pressable
          onPress={togglePinned}
        >
          <View style={localStyles.sliderMarkerInner}>
            <MaterialIcons
              name="keyboard-arrow-left"
              size={Sizing.x30}
              color={Colors.neutral.white}
              style={localStyles.sliderMarkerIcon}
            />
            <MaterialIcons
              name="keyboard-arrow-right"
              size={Sizing.x30}
              color={Colors.neutral.white}
              style={localStyles.sliderMarkerIcon}
            />
          </View>
        </Pressable>
      </View>
      
    )
  }

  return (
    <>
      <View>
        { currentViableAge ?
          <>
            <View
              style={{
                position: 'absolute',
                width: '100%',
                top: 0,
              }}
            >
              <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
                { currentModel && !timelineViewed ?
                    <ShakeBox
                      disabled={timelineViewed}
                      initialDelayMs={5000}
                      loopDelayMs={5000}
                      distance={2}
                      duration={50}
                      iterations={2}
                      vertical={true}
                    >
                    <TimelineContent />
                  </ShakeBox>
                  : <TimelineContent />
                }
              </ThemeProvider>
            </View>
            <View
              style={{
                zIndex: 999,
                top: 1,
              }}
            >
              {
                timelineIsPinned ? <></> :
                <Pressable
                  onPress={timelineIsPinned || !currentModel ? undefined : togglePinned}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'transparent',
                    top: 0,
                    zIndex: 1000,
                  }}
                >
                </Pressable>
              }
              <View>
                <MultiSlider
                  values={[sliderPosition]}
                  enabledOne={timelineIsPinned}
                  onValuesChange={timelineIsPinned ? onValuesChange : undefined}
                  optionsArray={sliderConfig?.sliderOptions || []}
                  sliderLength={getScreenAppWidth() * 2 / 3}
                  customMarker={SliderMarker}
                  unselectedStyle={localStyles.sliderTrack}
                  selectedStyle={localStyles.sliderTrack}
                  containerStyle={localStyles.sliderContainer}
                />
              </View>
            </View>
          </>
          : <></>
        }
        <View style={[localStyles.tabBarContainer, {
          paddingBottom: Sizing.x10 + insets.bottom,
        }]}>
          {state.routes.map((route, index) => {
            const { options } = descriptors[route.key]
            const label =
              options.tabBarLabel !== undefined
                ? options.tabBarLabel
                : options.title !== undefined
                  ? options.title
                  : route.name

            let notificationCount = notifications[route.name]

            //Add one for onboarding progress
            if (route.name === MainAppNavScreen.DASHBOARD_TODAY && suggestedOnboardingPhase !== OnboardingPhase.COMPLETE) {
              notificationCount++
            }

            const isFocused = state?.index === index

            const onPress = () => {
              const event = navigation.emit({
                type: 'tabPress',
                target: route.key,
                canPreventDefault: true
              })

              if (!isFocused && !event.defaultPrevented) {
                // The `merge: true` option makes sure that the params inside the tab screen are preserved
                navigation.navigate({ name: route.name, merge: true })
              }
            }

            const onLongPress = () => {
              navigation.emit({
                type: 'tabLongPress',
                target: route.key
              })
            }

            return (
              <TouchableOpacity
                key={index}
                accessibilityRole="button"
                accessibilityState={isFocused ? { selected: true } : {}}
                accessibilityLabel={options.tabBarAccessibilityLabel}
                testID={options.tabBarTestID}
                onPress={onPress}
                onLongPress={onLongPress}
                style={{ flex: 1 }}>
                <View style={{ alignItems: 'center' }}>
                  {notificationCount ?
                    <NotificationDot
                      top={scaleNormalizer(-5)}
                      left={scaleNormalizer(65)}
                      count={notificationCount}
                    />
                    : <></>
                  }
                  {getTabIcon(route.name, isFocused)}
                </View>
                <Text
                  style={[
                    localStyles.tabBarLabel,
                    {
                      color: isFocused
                        ? Colors.brand.purple2
                        : Colors.brand.grey3
                    }
                  ]}>
                  {label}
                </Text>
              </TouchableOpacity>
            )
          })}
        </View>
      </View>
    </>
  )
}

const localStyles = StyleSheet.create({
  container: {
    ...Flex.column.center,
    borderTopLeftRadius: Sizing.x20,
    borderTopRightRadius: Sizing.x20,
    position: 'absolute',
    width: '100%',
    zIndex: 997,
    backgroundColor: Colors.brand.grey3,
  },
  body: {
    ...Flex.column.start,
    paddingTop: Sizing.x20,
    paddingHorizontal: Sizing.x30,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: scaleNormalizer(45),
  },
  content: {
    paddingVertical: Sizing.x10,
  },
  sliderOuterContainer: {
    position: 'absolute',
    height: SLIDER_AREA_HEIGHT,
    top: SLIDER_AREA_HEIGHT * -1,
    width: '100%',
    zIndex: 999,
    backgroundColor: Colors.brand.grey3,
  },
  sliderContainer: {
    width: '100%',
    height: SLIDER_AREA_HEIGHT,
    marginTop: 0,
    alignItems: 'center',
    backgroundColor: Colors.brand.grey3
  },
  sliderTrack: {
    backgroundColor: Colors.neutral.white,
    height: scaleNormalizer(8),
    borderRadius: scaleNormalizer(4)
  },
  sliderMarkerOuter: {
    marginTop: scaleNormalizer(7),
    ...Flex.row.center,
    alignItems: 'center',
    backgroundColor: Colors.brand.grey3,
    height: scaleNormalizer(50),
    width: scaleNormalizer(50),
    borderRadius: scaleNormalizer(25)
  },
  sliderMarkerInner: {
    ...Flex.row.center,
    alignItems: 'center',
    backgroundColor: Colors.brand.purple2,
    height: scaleNormalizer(38),
    width: scaleNormalizer(38),
    borderRadius: Sizing.x20,
  },
  sliderMarkerIcon: {
    padding: scaleNormalizer(-8),
    margin: scaleNormalizer(-8)
  },
  tabBarContainer: {
    zIndex: 998,
    backgroundColor: Colors.brand.grey4,
    flexDirection: 'row',
    paddingTop: Sizing.x15,
  },
  tabBar: {
    backgroundColor: Colors.brand.grey4,
    height: Sizing.x70,
  },
  tabBarItem: {},
  tabBarLabel: {
    ...Typography.defined.dashboardTabLabel
  },
})