import { ProcessScreen } from 'components/Layout'
import { Headline, Paragraph, Subheading } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { InformationButton, NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { SchemeEnrolmentChangeForecastTreatmentProcessStack } from 'features/ModalProcesses/SchemeEnrolmentChangeForecastTreatment/SchemeEnrolmentChangeForecastTreatmentProcessStack'
import { JAR_NAME_ALL, JAR_NAME_GROUP } from 'lib/constants'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { lowerCase } from 'lodash'
import React, { useState } from 'react'
import { Image } from 'react-native'
import { GroupSchemeEnrolmentContributionForecastTreatment, GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { GroupSchemeEnrolmentPayrollFrequency } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { workingSetupSchemeEnrolment } from 'store/tempDataSlice'
import { Paper, Sizing } from 'styles'

export const SchemeEnrolmentSetup_21_ForecastTreatment = ({ route, navigation }) => {
  const { nextScreen, enrolment }: { nextScreen: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto }  = route?.params || {}
  const { contributionForecastTreatment: existingContributionForecastTreatment, groupScheme } = enrolment || {}
  const { defaultContributionFrequency, organizationName } = groupScheme || {}

  const [showDialog, setShowDialog] = useState(false)

  const { colors: themeColors } = Paper.useAppTheme()

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)
  const { contributionForecastTreatment, customEmployerContributionAmount, customPayrollFrequency } = workingSetupSchemeEnrolmentData || {}

  const [configureIncome, setConfigureIncome] = useState(false)

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const notYetChosen = workingSetupSchemeEnrolmentData?.contributionForecastTreatment === undefined
  const currentForecastTreatment = notYetChosen
    ? existingContributionForecastTreatment
    : contributionForecastTreatment

  const handleConfigureIncome = () => {
    //Nothing to do - workingSetupSchemeEnrolment is updated directly in SchemeEnrolmentChangeForecastTreatmentProcessStack
  }

  const handleConfirm = () => {
    setShowDialog(false)
    next()
  }

  const getEachPhrase = (frequency: GroupSchemeEnrolmentPayrollFrequency): string => {
    return frequency === GroupSchemeEnrolmentPayrollFrequency.ANNUALLY ? ' each year'
      : frequency === GroupSchemeEnrolmentPayrollFrequency.BI_ANNUALLY ? ' every 6 months'
      : frequency === GroupSchemeEnrolmentPayrollFrequency.QUARTERLY ? ' each quarter'
      : frequency === GroupSchemeEnrolmentPayrollFrequency.MONTHLY ? ' each month'
      : frequency === GroupSchemeEnrolmentPayrollFrequency.FOUR_WEEKLY ? ' every 4 weeks'
      : frequency === GroupSchemeEnrolmentPayrollFrequency.FORTNIGHTLY ? ' each fortnight'
      : frequency === GroupSchemeEnrolmentPayrollFrequency.WEEKLY ? ' each week'
      : ''
  }

  const informationTexts =
    currentForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.NONE
      ? [
          `With your income configured as 'Ad-hoc', Jarvis will use the following approach when forecasting:`,
          `Pension contributions from this employer will be treated as ad-hoc.`,
          `Forcasting will not expect any future regular pension contributions from this employer.`,
          `Contributions will still increase the current balance of your ${JAR_NAME_GROUP}, which will be used to model investment growth`,

        ]
      : currentForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.LAST_PERIOD
      ? [
          `With your income configured as 'Regular', Jarvis will use the following approach when forecasting:`,
          `Pension contributions from this employer will be treated as regular contributions until you retire.`,
          `Forecasting will use the last contribution amount received from this employer as the regular amount being contributed ${getEachPhrase(defaultContributionFrequency)} for this employment.`,
          `If you leave the pension scheme in the future, pension contributions will be assumed to have ceased.`,
      ]
      : [
          `With your custom configuration, Jarvis will use the following approach when forecasting:`,
          `Pension contributions from this employer will be treated as regular contributions until you retire.`,
          `Forecasting will be use the contribution amount and frequency you have set to model future contributions.`,
          `If you leave the pension scheme in the future, pension contributions will be assumed to have ceased.`,
        ]

  return (
    <>
      {
        configureIncome ?
          <SchemeEnrolmentChangeForecastTreatmentProcessStack
            onDismiss={() => setConfigureIncome(false)}
            resultHandlerFunction={handleConfigureIncome}
            enrolment={enrolment}
          />
        :
          <ProcessScreen
            isLoading={false}
            error={undefined}
            buttonTitle={notYetChosen ? 'This looks correct' : 'Confirm'}
            buttonAction={notYetChosen ? () => setShowDialog(true) : next}
            enableButton={true}
            headline={`Jarvis helps you predict when you can retire`}
            subHeading={`To do this, we need to understand the type of income from this employer`}
            subHeadingInfo={<NamedInformationButton name={NamedInformation.FINANCIAL_FORECASTING_FOR_EMPLOYER} buttonTitle={'About Configuring Income'} />}
            allowTextButton={true}
            textButtonAction={() => setConfigureIncome(true)}
            textButtonTitle={`Change income type`}
          >
            <AppIllustration
              filename={'gamified_approach.png'} style={[
              {
                width: Sizing.x100,
                height: Sizing.x100,
                resizeMode: 'contain',
                alignSelf: 'center'
              },
            ]} />
            <Subheading>{'Current configuration:'}</Subheading>
            <Headline style={{ color: themeColors.accent }}>
              {
                currentForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.NONE
                ? `Ad-hoc Income`
                : currentForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.LAST_PERIOD
                ? `Regular ${defaultContributionFrequency} Income`
                : `${formatCurrencyAmount(customEmployerContributionAmount, 2)}${getEachPhrase(customPayrollFrequency)}*`
              }
            </Headline>
            {
              currentForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.CUSTOM
              ? <Paragraph>{`* Your custom configuration`}</Paragraph>
              : <></>
            }
            <InformationButton
              title={
                currentForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.NONE
                  ? `Ad-hoc Income`
                  : currentForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.LAST_PERIOD
                  ? `Regular Income`
                  : `Custom Configuration`
              }
              texts={informationTexts}
              illustrationFilename={'gamified_approach.png'}
              buttonTitle={`What does this mean?`}
            />

            <ConfirmationDialog
              visible={showDialog}
              onCancel={() => setShowDialog(false)}
              title={'Please confirm'}
              content={currentForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.NONE
                ? `This is ad-hoc income.\n\nJarvis forecasting will only consider pension contributions that have actually been received into your ${JAR_NAME_GROUP}.\n\nYou can change this at any time to refine your forecast.`
                : currentForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.LAST_PERIOD
                ? `This is fixed ${lowerCase(defaultContributionFrequency)} income.\n\nJarvis forecasting will assume it recurs until you retire.\n\nYou can change this at any time to refine your forecast.`
                : `Use the custom amount and frequency I have defined.\n\nJarvis forecasting will assume it recurs until you retire.\n\nYou can change this at any time to refine your forecast.`
              }
              confirmLabel={'Confirm'}
              onConfirm={handleConfirm}
            />
          </ProcessScreen>
      }
    </>
  )
}
