import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { getHeadlineAndSubHeading } from 'lib/contributionFlowHelpers'
import React from 'react'
import { ContributionSource } from 'store/dto/base.dto'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingContributionSetup } from 'store/tempDataSlice'
import { MakeContributionsStepIdentifier } from 'store/uxSlice'
import { TaskList } from '../Components/TaskList'
import { useGetUserAccountsQuery } from 'store/apiSlice'
import { getPersonalPension } from 'lib/accountHelpers'

export const MakeContributions_00_ContributionSetupIntro = ({route, navigation}) => {
  const {nextScreen, onDismiss, contributionsData }  = route?.params || {} 
  const dispatch = useAppDispatch()

  const { makeContributionSteps, newContributionSource } = contributionsData || {}

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery()

  const personalPension = getPersonalPension(accounts)

  const hasRegularContributions = personalPension?.regularContribution?.amount

  const next = () => {
    if (newContributionSource) {
      dispatch(updateWorkingContributionSetup({
        source: newContributionSource,
      }))
    }
    navigation.navigate(
      hasRegularContributions ? 'ExistingRegularCancelInformation'
      : newContributionSource === ContributionSource.EMPLOYER ? 'CompanyNumber'
      : newContributionSource === ContributionSource.PERSONAL ? 'Bank'
      : nextScreen
    )
  }

  const { headline, subHeading, buttonTitle } = getHeadlineAndSubHeading(makeContributionSteps, MakeContributionsStepIdentifier.CONTRIBUTION_SOURCE)
  
  return (
    <ModalProcessIntroScreen
      isLoading={accountsIsLoading}
      error={accountsError}
      errorTryAgain={refetchAccounts}
      onDismiss={onDismiss}
      buttonTitle={buttonTitle}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'task_list.png'}
      headline={headline}
      subHeading={subHeading}
    >
      <TaskList
        contributionsData={contributionsData}
        nextTask={MakeContributionsStepIdentifier.CONTRIBUTION_SOURCE}
        nextTaskAction={next}
      />
    </ModalProcessIntroScreen>
  )
}

