import { ProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { Logger } from 'lib/logger'
import { goToDashboard, rootNavigate } from 'lib/RootNavigation'
import React, { useEffect } from 'react'
import { useUpdateMeMutation, useVerifyGroupSchemeEnrolmentMutation } from 'store/apiSlice'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto, VerifySchemeEnrolmentDto } from 'store/dto/account.dto'
import { ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSetupSchemeEnrolment, workingSetupSchemeEnrolment } from 'store/tempDataSlice'
import { Paper, Sizing } from 'styles'

export const SchemeEnrolmentSetup_99_Result = ({ route, navigation }) => {
  const { nextRoute, enrolment, client }: { nextRoute: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}

  const dispatch = useAppDispatch()

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)

  const [ verifyEnrolment, { data: verifiedEnrolment, isLoading: verifyEnrolmentIsLoading, error: verifyEnrolmentError} ] = useVerifyGroupSchemeEnrolmentMutation()
  const [updateClient, { data: updatedClient, error: clientUpdateError, isLoading: clientUpdateIsLoading }] = useUpdateMeMutation()

  const isLoading = verifyEnrolmentIsLoading || clientUpdateIsLoading
  const error: any = verifyEnrolmentError || clientUpdateError

  //@ts-ignore
  const failedVerification = verifyEnrolmentError && verifyEnrolmentError?.status === 403

  //Save client onboardingFlags on enter
  useEffect(() => {
    if (workingSetupSchemeEnrolmentData) {
      saveClient()
    }
  }, [])

  const saveClient = () => {
    Logger.info(`Updating Client...`)
    updateClient({
      onboardingFlags: {
        setupscheme: true,
      },
    })
  }
  
  //Verify enrolment when client saved
  useEffect(() => {
    if (updatedClient) {
      verify()
    }
  }, [updatedClient])

  const verify = () => {
    Logger.info(`Verifying enrolment...`)
    const payload: VerifySchemeEnrolmentDto = {
      id: enrolment?.id,
      ...workingSetupSchemeEnrolmentData,
    }
    Logger.debug({ payload })
    verifyEnrolment(payload)
  }

  //End verified
  useEffect(() => {
    if (verifiedEnrolment) {
      end()
    }
  }, [verifiedEnrolment])

  const end = () => {
    const { requests } = workingSetupSchemeEnrolmentData || {}

    //Clear temp data
    dispatch(updateWorkingSetupSchemeEnrolment(undefined))
    rootNavigate(nextRoute, { enrolment, requests })
  }

  const cancel = () => {
    //Clear temp data
    dispatch(updateWorkingSetupSchemeEnrolment(undefined))
    goToDashboard()
  }

  const goBack = () => {
    navigation.goBack()
  }

  return (
    <ProcessScreen
      headline={failedVerification ? `Sorry, we couldn't verify you` : ''}
      subHeading={failedVerification
        ? `We couldn't verify you with the details provided`
        : ''
      }
      buttonTitle={failedVerification
        ? `Go back and change it`
        : ''
      }
      buttonAction={goBack}
      showButton={!isLoading && failedVerification}
      error={error && !failedVerification}
      errorTryAgain={verifyEnrolmentError ? verify : saveClient}
      isLoading={isLoading}
      loadingMessage={['Linking employer...']}
      allowTextButton={!isLoading && failedVerification}
      textButtonAction={cancel}
      textButtonTitle={'Cancel for now'}
    >
      {
        failedVerification ?
        <>
        <AppIllustration filename={'magnifying_glass.png'} style={[
          {
            width: Sizing.x100,
            height: Sizing.x100,
            resizeMode: 'contain',
            alignSelf: 'center'
          },
        ]} />
        <Paragraph>{`Your employer's record does match the details you entered - you may need to speak to your employer.`}</Paragraph>
      </>
          : <></>
      }
    </ProcessScreen>
  )
}
