import { Paragraph } from "components/Typography"
import { InformationButton } from "components/Utility/InformationButton"
import React from 'react'
import { View } from "react-native"
import { GroupSchemeStatus, GroupSchemeForUserDto } from "store/dto/group-scheme.dto"
import { Colors, Sizing } from "styles"

type EmployerAppHeaderProps = {
  currentScheme: GroupSchemeForUserDto
}

export const EmployerAppHeader = (props: EmployerAppHeaderProps) => {
  const { currentScheme } = props
  const { status } = currentScheme || {}

  const isOnboarding = status === GroupSchemeStatus.ONBOARDING

  const informationTexts: string[] = isOnboarding ? [
    `This scheme is currently in Onboarding Mode, enabling you to get your scheme prepared, before launching to your members.`,
    `This can be useful when you have large numbers of members to enrol, or want to ensure a smooth transition from a previous pension provider.`,
    `Whilst in Onboarding Mode, you can use most of the Employer Portal functionality enrol members, and invite other employees to join the scheme.`,
    `However, no notifications will be sent to members about their new Jarvis account - these will be released when your scheme is set fully live.`,
    `It is also not possible to submit contributions in Onboarding Mode - it is designed to allow initial enrolment activities to complete and any issues to be resolved, ahead of processing contributions from your payroll software.`,
    `Please contact the Jarvis Support Team if you are ready to launch your scheme to members.`,
  ]: []

  return (
    <>
    {
      isOnboarding
        ? <View style={{
            backgroundColor: Colors.brand.red3,
            height: Sizing.x30,
            justifyContent: 'center',
          }}>
            <InformationButton iconColor={Colors.neutral.white} title={'Onboarding Mode'} texts={informationTexts} illustrationFilename={'support.png'} buttonTitle={`Scheme in Onboarding Mode`} />
          </View>
        : <></>
    }
    </>
  )
}
