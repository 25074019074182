import { getEntryScreenForAppContext } from 'lib/navigationHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { ProcessStackBuilder } from 'navigation/stacks/ProcessStackBuilder'
import React from 'react'
import { Portal } from 'react-native-paper'
import { AppContext, loginParams, setAppContext } from 'store/authSlice'
import { Authentication_01_HandOffInitiation, Authentication_02_Choice, Authentication_03_Initiation, Authentication_04_Submission, Authentication_11_HandOffEmail, Authentication_12_HandOffLink, Authentication_99_Results } from './Screens'
import { useDispatch, useSelector } from 'react-redux'
import { Authentication_10_HandOffIntro } from './Screens/Authentication_10_HandOffIntro'
import { Logger } from 'lib/logger'
import { useAppSelector } from 'store/hooks'

const isWeb = platformIsWeb()

export const AuthenticationProcessStack = ({ route, navigation }) => {
    const { params } = route || {}
  
    const loginConfig = useAppSelector(loginParams)
    const { autoLogin } = loginConfig || {}

    const appContext = useSelector((state: any) => state.auth.appContext)

    const dispatch = useDispatch()
  
    const useWebHandOff = isWeb && appContext === AppContext.CLIENT

    //Force appContext to client for autoLogin
    if (autoLogin) {
      dispatch(setAppContext(AppContext.CLIENT))
    }
  
    return (
        <Portal.Host>
            <ProcessStackBuilder
                processTitle=''
                navigatorId='AuthenticationProcessStack'
                nextRoute=''
                previousRoute={getEntryScreenForAppContext(appContext)}
                removeProgressBar={true}
                initialParams={{
                  ...params,
                }}
                processScreens={useWebHandOff
                  ? [
                      {
                        component: Authentication_10_HandOffIntro,
                        navigationId: 'HandOffIntro',
                      },
                      {
                        component: Authentication_11_HandOffEmail,
                        navigationId: 'HandOffEmail',
                      },
                      {
                        component: Authentication_12_HandOffLink,
                        navigationId: 'HandOffLink',
                      },
                    ]
                  : autoLogin ? [
                    {
                      component: Authentication_01_HandOffInitiation,
                      navigationId: 'HandOffInitiation',
                    },
                    {
                      component: Authentication_04_Submission,
                      navigationId: 'Submission',
                    },
                  ]
                  : [
                      {
                        component: Authentication_02_Choice,
                        navigationId: 'Choice',
                      },
                      {
                        component: Authentication_03_Initiation,
                        navigationId: 'Initiation',
                      },
                      {
                        component: Authentication_04_Submission,
                        navigationId: 'Submission',
                      },
                      //Should this PasswordlessHandOff be removed?
                      {
                        component: Authentication_11_HandOffEmail,
                        navigationId: 'PasswordlessHandOff',
                      }
                    ]}
                resultScreen={{
                  navigationId: 'Results',
                  component: Authentication_99_Results
                }}
            />
        </Portal.Host>
    )
}

