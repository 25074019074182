import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/Layout'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingInvite, workingInvite } from 'store/tempDataSlice'

export const SpouseInvite_03_Surname = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingInviteData = useAppSelector(workingInvite)

  //Setup form
  const formObj = useForm<{ surname: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      surname: workingInviteData?.surname
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingInvite(attributes))
    navigation.navigate(nextScreen)
  }

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`And ${workingInviteData?.firstName}'s surname?`}
    >
      <ManagedTextInput
        name={'surname'}
        autoFocus={true}
        formObj={formObj}
        returnKeyType={"next"}
        placeholder={'Their legal surname'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={true}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
    </ProcessScreen>
  )
}
