import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { ButtonSet } from 'components/Utility/ButtonSet'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import { OnboardingGuard } from 'features/Onboarding/components/OnboardingGuard'
import { rootNavigate } from 'lib/RootNavigation'
import { getGroupPension, getGroupReserveAccounts, getPersonalPension } from 'lib/accountHelpers'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from 'lib/constants'
import { OnboardingStateStepIdentifier } from 'providers'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useGetGroupSchemeEnrolmentsQuery, useGetMeQuery, useGetUserAccountsQuery } from 'store/apiSlice'
import { useAppDispatch } from 'store/hooks'
import { MakeContributionsData, MakeContributionsStepIdentifier, setMakeContributionsData } from 'store/uxSlice'
import { Colors, Sizing, Flex } from 'styles'
import { ContributionsList } from '../Components/ContributionsList'
import { AmlStatus } from 'store/dto/client.dto'
import { Paragraph } from 'components/Typography'

type ContributionsScreenProps = {
  accountId?: any
}

const ALL_ACCOUNTS_ID = 'ALL'

export const ContributionsScreen = (props: ContributionsScreenProps) => {
  const { accountId } = props || {}

  const dispatch = useAppDispatch()

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery()
  const { data: enrolments, error: enrolmentsError, isLoading: enrolmentsIsLoading, refetch: refetchEnrolments } = useGetGroupSchemeEnrolmentsQuery()
  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()

  const [filterAccountId, setFilterfilterAccountId] = useState(accountId || ALL_ACCOUNTS_ID)

  //Update filterAccountId when props change
  useEffect(() => {
    setFilterfilterAccountId(accountId || ALL_ACCOUNTS_ID)
  }, [props])

  const personalPension = getPersonalPension(accounts)
  const groupPension = getGroupPension(accounts)
  const groupReservedAccounts = getGroupReserveAccounts(accounts)
  const bestGroupPensionId =
    groupPension ? groupPension.id
    : groupReservedAccounts.length === 1 ? groupReservedAccounts[0].id
    : undefined

  const hasBothAccounts = !!personalPension && !!bestGroupPensionId
  const workplaceIsActive = !!groupPension || enrolments?.length > 0

  const refetchAll = () => {
    refetchMe()
    refetchAccounts()
  }
  
  const isLoading = meIsLoading || accountsIsLoading || enrolmentsIsLoading
  const error: any = meError || accountsError || enrolmentsError

  const contributionSetupDone = me?.contributionConfiguration?.source
  
  const makeContributionsData: MakeContributionsData = contributionSetupDone
    ? {
      account: personalPension,
      existingContributionConfiguration: me?.contributionConfiguration,
      existingRegularContribution: personalPension?.regularContribution,
      makeContributionSteps: [
        MakeContributionsStepIdentifier.CONTRIBUTION_SOURCE
      ],
    }
    : undefined

  //Onboarding guard removed if workplaceIsActive
  const onboardingGuardSteps = workplaceIsActive ? [] : [
    OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
    OnboardingStateStepIdentifier.PLAN_CHOICE,
    OnboardingStateStepIdentifier.CONTRIBUTE,
  ]
  
  return (
    <MainAppScreen>
      {
        isLoading
        ? <Loading />
        : error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> :
        <OnboardingGuard
          explanation={`Your Contributions screen will show you the status of contributions you and/or your employers have made into your ${JAR_NAME_ALL}`}
          subExplanation={`You can start making contributions yourself once you have verified your identity and opened your ${JAR_NAME_PERSONAL}.`}
          onboardingSteps={onboardingGuardSteps}
          additionalContent={
            contributionSetupDone ? 
              <FooterButton
                mode='text'
                color={Colors.brand.red3}
                onPress={() => dispatch(setMakeContributionsData(makeContributionsData))}>
                {'Change Contribution Source'}
              </FooterButton>
              : <></>
          }
        >
            {
              hasBothAccounts ?
              <View style={{
                paddingTop: Sizing.x10,
                paddingHorizontal: Sizing.x20
              }}>
                <ButtonSet
                  value={filterAccountId}
                  onValueChange={setFilterfilterAccountId}
                  buttons={[
                    {
                      icon: 'all-inclusive',
                      value: ALL_ACCOUNTS_ID,
                      label: 'All Jars',
                    },
                    {
                      icon: 'account-outline',
                      value: personalPension.id,
                      label: 'Personal',
                    },
                    {
                      icon: 'briefcase-outline',
                      value: bestGroupPensionId,
                      label: 'Workplace'
                    },
                  ]}
                />
              </View>
              : <></>
          }
          <ContributionsList
            includeUpcomingRegularTransaction={true}
            includeReservedBalances={true}
            contributionsQuery={{
              accountId: filterAccountId === ALL_ACCOUNTS_ID ? undefined : filterAccountId,
            }}
          />
          {
            contributionSetupDone ? 
            <FooterButton
              mode='text'
              color={Colors.brand.red3}
              onPress={() => dispatch(setMakeContributionsData(makeContributionsData))}>
              {'Change Contribution Source'}
            </FooterButton>
            : <></>
          }
          {
            personalPension
              ? <FooterButton
                  onPress={() => rootNavigate('ContributionsIntroScreen')}
                >{`Make/Change Contributions`}
                </FooterButton>
              : workplaceIsActive
                ? me?.amlStatus === AmlStatus.PASS
                  ? <>
                      <View style={{
                        ...Flex.override.bottom,
                        paddingBottom: Sizing.x10,
                        paddingHorizontal: Sizing.x30,
                      }}>
                        <Paragraph>{`To start making contributions yourself, open your ${JAR_NAME_PERSONAL} and pick an investment plan that works for you.`}</Paragraph>
                      </View>
                      <FooterButton
                        onPress={() => rootNavigate('InvestmentChoiceIntroScreen')}
                      >{`Open ${JAR_NAME_PERSONAL}`}
                      </FooterButton>
                    </>
                  : !me?.amlStatus
                    ? <>
                        <View style={{
                          ...Flex.override.bottom,
                          paddingBottom: Sizing.x10,
                          paddingHorizontal: Sizing.x30,
                        }}>
                          <Paragraph>{`To start making contributions yourself, first verify your identity and open your ${JAR_NAME_PERSONAL}.`}</Paragraph>
                        </View>
                        <FooterButton
                            onPress={() => rootNavigate('ClientVerificationIntroScreen')}
                          >{`Verify Identity`}
                        </FooterButton>
                      </>
                  : <></>
              : <></>
          }
        </OnboardingGuard>
      }
    </MainAppScreen>
  )
}
