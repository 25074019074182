import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { goToMainAppStack } from 'lib/RootNavigation'
import { formatNationalInsuranceNumber, unformatNationalInsuranceNumber } from 'lib/clientHelpers'
import { NATIONAL_INSURANCE_NO_MASK, NINO_REGEX } from 'lib/constants'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useCheckNiNumberGroupSchemeInviteQuery } from 'store/apiSlice'
import { ClientMeDto } from 'store/dto/client.dto'
import { PublicGroupSchemeInviteDto } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAcceptSchemeInvite, workingAcceptSchemeInvite } from 'store/tempDataSlice'

export const SchemeInviteAccept_03_Verify = ({ route, navigation }) => {
  const { nextScreen, schemeInvite, client }: { nextScreen: string, schemeInvite: PublicGroupSchemeInviteDto, client: ClientMeDto }  = route?.params || {}

  const { groupScheme } = schemeInvite || {}
  const { organizationName } = groupScheme || {}

  const workingAcceptSchemeInviteData = useAppSelector(workingAcceptSchemeInvite)
  const [niNumberToCheck, setNiNumberToCheck] = useState(undefined)
  const [matches, setMatches] = useState(undefined)

  const dispatch = useAppDispatch()
  // const [cursorPosition, setCursorPosition] = useState(0)

  //Setup form
  const formObj = useForm<{ nationalInsuranceNo: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      nationalInsuranceNo: workingAcceptSchemeInviteData?.nationalInsuranceNo
        ? formatNationalInsuranceNumber(workingAcceptSchemeInviteData?.nationalInsuranceNo)
        : formatNationalInsuranceNumber(client?.nationalInsuranceNo)
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingAcceptSchemeInvite({
      nationalInsuranceNo: unformatNationalInsuranceNumber(attributes.nationalInsuranceNo)
    }))
    navigation.navigate(nextScreen)
  }

  //Check the NI number matches only if 9 characters
  const { data: niCheckResult, error, isLoading, isFetching, refetch } = useCheckNiNumberGroupSchemeInviteQuery({
    groupSchemeId: schemeInvite.groupScheme?.id,
    inviteId: schemeInvite.id,
    nationalInsuranceNo: niNumberToCheck,
  }, { skip: !niNumberToCheck})
  
  const nationalInsuranceNo = watch('nationalInsuranceNo')
  
  //Update niNumberToCheck when value changes
  useEffect(() => {  
    setMatches(undefined)
    setNiNumberToCheck(nationalInsuranceNo && nationalInsuranceNo.length === NATIONAL_INSURANCE_NO_MASK.length ? unformatNationalInsuranceNumber(nationalInsuranceNo) : undefined)
  }, [nationalInsuranceNo])

  //Force refetch when NI number to check changes
  //NOTE: Without doing this, there seems to be a race condition of some kind with form error
  //states becoming out of sync, resulting in the error messages not being displayed consistently
  useEffect(() => {  
    if (niNumberToCheck) {
      refetch()
    }    
  }, [niNumberToCheck])

  //Update available status based on check result
  useEffect(() => {  
    if (error || isLoading || isFetching) {
      setMatches(undefined)
    } else if (niCheckResult) {
      setMatches(niCheckResult.matches)
    }
  }, [niCheckResult, error, isLoading, isFetching])

  //Trigger validation when available changes
  useEffect(() => {
    trigger('nationalInsuranceNo')
  }, [matches])

  const isValidNiNumber = (value: string) => {
    const result = value.match(NINO_REGEX)
    return result ? true : 'Invalid NI Number - please double check'
  }

  const isMatchingNiNumber = () => {
    if (matches === undefined) {
      return 'Validating...'
    }
    return matches ? true : `No match.`
  }

  return (
    <ProcessScreen
      isLoading={false}
      error={undefined}
      buttonTitle={'Finish'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Verify your identity`}
      subHeading={`To accept this invitation, please enter your National Insurance Number`}
      allowTextButton={matches === false}
      textButtonTitle={`Back to Dashboard`}
      textButtonAction={goToMainAppStack}
    >
      <ManagedTextInput
        name={'nationalInsuranceNo'}
        autoFocus={true}
        formObj={formObj}
        // label={'National Insurance Number'}
        mask={{
          type: 'custom',
          options: {
            mask: NATIONAL_INSURANCE_NO_MASK,
          }
        }}
        // onSelectionChange={(event) => {
        //   if (event?.nativeEvent?.selection?.start) {
        //     setCursorPosition(event?.nativeEvent?.selection?.start)
        //   }
        // }}
        // keyboardType={cursorPosition < 2 || cursorPosition > 10 ? 'default' : 'numeric'}
        // autoCapitalize={cursorPosition < 2 || cursorPosition > 10 ? 'characters' : undefined}
        autoCapitalize={'characters'}
        forceCapitals={true}
        blurOnSubmit={true}
        submitHandler={handleSubmit(onSubmit)}
        rules={{
          required: true,
          minLength: {
            value: NATIONAL_INSURANCE_NO_MASK.length,
            message: 'Must be exactly 9 characters'
          },
          maxLength: {
            value: NATIONAL_INSURANCE_NO_MASK.length,
            message: 'Must be exactly 9 characters'
          },
          validate: {
            isValidNiNumber,
            isMatchingNiNumber,
          }
      }} />
      {
        matches === false ?
        <Paragraph>{`The National Insurance Number you have entered does not match the one provided by ${organizationName}. If you are sure that you have entered it correctly, please contact them to check their records.`}</Paragraph>
        : <></>
      }
    </ProcessScreen>
  )
}
