import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { Address } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'

export const RetirementAssetTransfer_23_PersonalDetailsSetAddress = ({ route, navigation }) => {
  const { nextScreen, asset, manualEntry, client, isRetransfer } = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  const formObj = useForm<Address>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      building: workingRetirementAssetData?.transferAddress?.building || asset?.transferAddress?.building,
      street1: workingRetirementAssetData?.transferAddress?.street1 || asset?.transferAddress?.street1,
      town: workingRetirementAssetData?.transferAddress?.town || asset?.transferAddress?.town,
      postCode: workingRetirementAssetData?.transferAddress?.postCode || asset?.transferAddress?.postCode,
      country: 'GB',
    },
  })
  const { handleSubmit, formState: { isValid, isDirty, errors } } = formObj

  // const { data: countries, error, isLoading } = useGetNationalitiesQuery()
  // const countryOptions = getNationalityAutocompleteOptions(countries)
  
  const onSubmit = attributes => {
    const transferAddress = attributes
    dispatch(updateWorkingRetirementAsset({
      transferAddress,
    }))
    //PA-2009 Analytics
    ampli.transferUsage({
      processStep: 'prep_address_confirm',
      isRetransfer,
      outcome: 'success',
      retirementAssetId: asset?.id
    })
    navigation.navigate(nextScreen)
  }
  const buildingRef = useRef()
  const street1Ref = useRef()
  const townRef = useRef()
  const postCodeRef = useRef()

  const goToAddressSearch = () => {
    navigation.navigate('PersonalDetailsTransferAddress')
  }

  return (
    <ModalProcessScreen
      buttonTitle={
        isRetransfer
        ? isDirty
          ? `Save Changes`
          : `It's Correct`
        : manualEntry
          ? 'Next'
          : 'Confirm'
      }
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={isRetransfer
        ? `Check the address we are using`
        : !workingRetirementAssetData?.transferAddress
          ? `Confirm your address at the time`
          : manualEntry
            ? `Enter your address at the time`
            : `Was this the place?`
      }
      subHeading={isRetransfer
        ? `This needs to match what old provider has on their records`
        : !workingRetirementAssetData?.transferAddress || manualEntry
          ? `The UK address that the old provider would have on their records`
          : `Check the details are correct and add any bits we've missed`
      }
      allowTextButton={!isRetransfer}
      textButtonTitle={'Start Search again'}
      textButtonAction={goToAddressSearch}
    >
      <>
        <ManagedTextInput
          ref={buildingRef}
          name={`building`}
          formObj={formObj}
          label={'Building Number/Name'}
          placeholder={'Enter building number/name'}
          returnKeyType={'next'}
          autoCapitalize={'words'}
          // @ts-ignore 
          submitHandler={() => street1Ref.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: true,
            maxLength: 35,
        }} />
        <ManagedTextInput
          ref={street1Ref}
          name={`street1`}
          formObj={formObj}
          label={'Street'}
          placeholder={'Enter street'}
          returnKeyType={'next'}
          autoCapitalize={'words'}
          // @ts-ignore 
          submitHandler={() => townRef.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: true,
            maxLength: 35,
        }} />
        <ManagedTextInput
          ref={townRef}
          name={`town`}
          formObj={formObj}
          label={'Town'}
          placeholder={'Enter town/locality'}
          returnKeyType={'next'}
          autoCapitalize={'words'}
          // @ts-ignore 
          submitHandler={() => postCodeRef.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: true,
            maxLength: 35,
        }} />
        <ManagedTextInput
          ref={postCodeRef}
          name={`postCode`}
          formObj={formObj}
          label={'Post Code'}
          placeholder={'Enter postcode'}
          autoCapitalize={'characters'}
          returnKeyType={'next'}
          rules={{
            required: true,
            maxLength: 10,
        }} />
      </>
    </ModalProcessScreen>
  )
}
