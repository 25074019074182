import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { Button } from 'components/Utility/Button'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { addDays, isAfter, startOfDay } from 'date-fns'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { formatUkDate } from 'lib/dateHelpers'
import { userCanManageJobsForScheme } from 'lib/groupSchemeHelpers'
import { compact } from 'lodash'
import { default as React } from 'react'
import { batch } from 'react-redux'
import { useGetGroupSchemeMemberQuery } from 'store/apiSlice'
import { GroupSchemeEnrolmentStatus } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentEmployerMemberDataId, currentGroupScheme, setCurrentEmployerMemberDataId, setShowEmployerAddNinoToEnrolmentJobDataId, setShowEmployerChangeEnrolmentStatusJobDataId, setShowEmployerUpdateEnrolmentJobDataId } from 'store/uxSlice'
import { Paper } from 'styles'

export const EmployerMemberViewModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {

  const dispatch = useAppDispatch()
  
  const currentScheme = useAppSelector(currentGroupScheme)
  
  const userCanManageJobs = userCanManageJobsForScheme(currentScheme)

  const currentMemberId = useAppSelector(currentEmployerMemberDataId)

  const { isLoading: memberIsLoading, error: memberError, data: member, refetch: refetchMember } = useGetGroupSchemeMemberQuery({ memberId: currentMemberId, id: currentScheme.id }, { skip: !currentMemberId } )

  const { id, client, employeeId, employeeEmail, autoEnrolmentOptOutDate, enrolmentEndDate, enrolmentStatus, employmentStartDate, enrolmentDate, enrolmentEndReason, autoEnrolmentWindowOptOutDate, clientOptOutDate } = member || {}
  const { firstName, surname, gender, birthDate, nationalInsuranceNo } = client || {}

  const close = () => {
    dispatch(setCurrentEmployerMemberDataId(undefined))
  }

  const handleUpdate = () => {
    batch(() => {
      dispatch(setCurrentEmployerMemberDataId(undefined))
      dispatch(setShowEmployerUpdateEnrolmentJobDataId(currentMemberId))
    })
  }

  const handleChangeStatus = () => {
    batch(() => {
      dispatch(setCurrentEmployerMemberDataId(undefined))
      dispatch(setShowEmployerChangeEnrolmentStatusJobDataId(currentMemberId))
    })
  }

  const handleAddNino = () => {
    batch(() => {
      dispatch(setCurrentEmployerMemberDataId(undefined))
      dispatch(setShowEmployerAddNinoToEnrolmentJobDataId(currentMemberId))
    })
  }

  const isLoading = memberIsLoading
  const error: any = memberError

  const { colors: themeColors } = Paper.useAppTheme()

  const now = new Date()
  const optOutBoundaryDate = autoEnrolmentWindowOptOutDate ? addDays(startOfDay(new Date(autoEnrolmentWindowOptOutDate)), 1) : undefined
  const withinOptOutWindow = !!optOutBoundaryDate && isAfter(optOutBoundaryDate, now)
  

  return (
    <ModalEditScreen
      formTitle='Member Details'
      onDismiss={() => dispatch(setCurrentEmployerMemberDataId(undefined))}
      error={error}
      errorTryAgain={refetchMember}
      errorCancel={close}
      isLoading={isLoading}
      buttonTitle={'Close'}
      buttonAction={close}
      showButton={true}
      enableButton={true}
      footerInfo={!userCanManageJobs
        ? <Text>{`You do not have permissions to manage enrolment for members.`}</Text>
        : undefined
      }
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Member Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Jarvis Identifier`,
            value: member?.id,
            copyableValue: true,
          },
          {
            label: `Employee Identifier`,
            value: employeeId || 'Not Provided',
            copyableValue: true,
          },
          {
            label: `Employee Email`,
            value: employeeEmail,
            copyableValue: true,
          },
        ]}
        noContentDivider={true}
      />
      <Button
          onPress={handleUpdate}
          mode={'text'}
          disabled={!userCanManageJobs}
        >
          {`Update Member Details`}
      </Button>

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Client Details`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `National Insurance Number`,
            value: nationalInsuranceNo ? formatNationalInsuranceNumber(nationalInsuranceNo) : 'Not Yet Provided',
            copyableValue: nationalInsuranceNo,
          },
          {
            label: `Name`,
            value: `${firstName} ${surname}`,
          },
          {
            label: `Gender`,
            value: gender,
          },
          {
            label: `Date of Birth`,
            value: birthDate ? formatUkDate(new Date(birthDate)) : '',
          },
        ]}
        noContentDivider={true}
      />
      {
        nationalInsuranceNo ? <></> :
        <Button
            onPress={handleAddNino}
            mode={'text'}
            disabled={!userCanManageJobs}
          >
            {`Add National Insurance Number`}
        </Button>
      }
      <Paragraph>{`Please note that client details are managed by the member within the Jarvis client app, and may not exactly match your payroll system.`}</Paragraph>

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Employment Details`}</Subheading>
      <UnborderedTable
        data={compact([
          employmentStartDate ? {
            label: `Employment Start Date`,
            value: formatUkDate(new Date(employmentStartDate)),
          } : undefined,
          enrolmentDate ? {
            label: `Enrolment Date`,
            value: formatUkDate(new Date(enrolmentDate)),
          } : undefined,
          {
            label: `Opt Out Window End Date`,
            value: autoEnrolmentWindowOptOutDate ? formatUkDate(new Date(autoEnrolmentWindowOptOutDate)) : ``,
          },
        ])}
        noContentDivider={true}
      />
      <Button
          onPress={handleUpdate}
          mode={'text'}
          disabled={!userCanManageJobs}
        >
          {`Update Employment Details`}
      </Button>

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Enrolment Status`}</Subheading>
      <UnborderedTable
        data={compact([
          {
            label: `Status`,
            value: enrolmentStatus,
          },
          enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT && autoEnrolmentOptOutDate ? {
            label: `Opt Out Date`,
            value: formatUkDate(new Date(autoEnrolmentOptOutDate)),
          } : enrolmentStatus === GroupSchemeEnrolmentStatus.OPT_OUT_DECLARED && clientOptOutDate ? {
            label: `Declared Date`,
            value: formatUkDate(new Date(clientOptOutDate)),
          } : enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE && enrolmentEndDate ? {
            label: `Enrolment End Date`,
            value: formatUkDate(new Date(enrolmentEndDate)),
          } : undefined,
          enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE ? {
            label: `End Reason`,
            value: enrolmentEndReason,
          } : undefined,
        ])}
        noContentDivider={true}
      />
      <Button
          onPress={handleChangeStatus}
          mode={'text'}
          disabled={!userCanManageJobs}
        >
          {
            member?.enrolmentStatus === GroupSchemeEnrolmentStatus.ACTIVE
              ? `Opt Out / De-Enroll Member`
              : `Re-Enrol Member / Change Status`
          }
      </Button>
    </ModalEditScreen>
  )
}

