import { platformIsWeb } from 'lib/platformHelpers'
import { getScreenWidthConstraints } from 'lib/scaleHelpers'
import React, { ComponentProps } from 'react'
import { StatusBar, StyleSheet, View } from 'react-native'
import { Modal, ThemeProvider } from "react-native-paper"
import { SafeAreaView } from 'react-native-safe-area-context'
import { Colors, Flex, Paper, Sizing } from "styles"
import { layoutStyles } from 'styles/common'

const viewConstraints: any = getScreenWidthConstraints()

export type LightModalProps = ComponentProps<typeof Modal> & {
  //@ts-ignore
  theme?: ReactNativePaper.Theme
}
export const LightModal = (props: LightModalProps) => {

  const { children, theme } = props

  const isWeb = platformIsWeb()

  // const extraTheme = isWeb ? {
  //   colors: {
  //     backdrop: 'transparent',
  //   },
  // } : undefined

  //@ts-ignore
  const activeTheme: ReactNativePaper.Theme = theme || Paper.darkThemeOnLightGrey

  return (
    <ThemeProvider theme={activeTheme}>
      <StatusBar barStyle={'dark-content'} />
      <Modal
        {...props}
        // theme={extraTheme}
        contentContainerStyle={localStyles.modalContentContainer}
        style={{
          marginTop: Sizing.x0,
        }}
      >
        <SafeAreaView style={{
            height: '100%',
            backgroundColor: isWeb ? Colors.neutral.white : activeTheme.colors.pageBackground,
            borderRadius: isWeb ? Sizing.x10 : undefined,
            marginHorizontal: isWeb ? Sizing.x20 : undefined,
            marginVertical: isWeb ? Sizing.x10 : undefined,
            ...Flex.column.start,
            ...viewConstraints,
            flex: 1,
          }}
        >
          {children}
        </SafeAreaView>
      </Modal>
    </ThemeProvider>
  )
}

const localStyles = StyleSheet.create({
  modalContentContainer: {
    ...Flex.column.start,
    flex: 1,
  },
})