import { MaterialCommunityIcons } from '@expo/vector-icons'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { max } from 'lodash'
import React from 'react'
import { View } from 'react-native'
import { useGetMeQuery, useGetSpouseQuery } from 'store/apiSlice'
import { RetirementAssetDto } from 'store/dto/retirement-asset.dto'
import { useAppSelector } from 'store/hooks'
import { onAssetSpouseView } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing } from 'styles'
import { AssetList } from './AssetList'

const Tab = createMaterialTopTabNavigator()

export enum AssetTabScreenName {
  CLIENT = 'CLIENT',
  SPOUSE = 'SPOUSE',
}

interface RetirementAssetsListProps {
  assets: RetirementAssetDto[]
  addAssetStateFunction: Function
  editAssetStateFunction: Function
  addSpouseStateFunction: Function
  onNavigateFunction: Function
}

export const RetirementAssetsList = (props: RetirementAssetsListProps) => {
  const { assets, addAssetStateFunction, editAssetStateFunction, addSpouseStateFunction, onNavigateFunction } = props

  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()

  const error: any = meError
  const isLoading = meIsLoading || spouseIsLoading

  const { colors: themeColors } = Paper.useAppTheme()

  const onSpouseTab = useAppSelector(onAssetSpouseView)

  const assetCount = assets?.length || 0

  const clientAssets = assets.filter(asset => {
    return asset.clientId === me?.id
  })

  const clientAssetCount = clientAssets?.length || 0
  const spouseAssetCount = assetCount - clientAssetCount
  const tableRows = max([clientAssetCount, spouseAssetCount]) + 1

  //Calculate navigator height
  //Tabs, table rows and top padding
  const rawNavigatorHeight = Sizing.x50 + (Sizing.x50 * tableRows) + Sizing.x20
  const navigatorHeight =
    error || isLoading ? Sizing.x400 //Loading
    : !spouse ? max([rawNavigatorHeight, Sizing.x300]) //Min height for spouse add
    : rawNavigatorHeight

  const ClientScreen = () => {
    
    const refetchAll = () => {
      refetchMe()
      refetchSpouse()
    }

    return (
      <>
        {
          error || isLoading ?
          <View style={{ height: Sizing.x140 }}>
            {
              error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> : isLoading ? <Loading message={getLookingLoadingMessages('Loading your assets...')} /> : <></>
            }
          </View>
          :
          <AssetList 
            client={me}
            assets={assets}
            addAssetStateFunction={addAssetStateFunction}
            editAssetStateFunction={editAssetStateFunction}
          />
        }
      </>
    )
  }

  const SpouseScreen = () => {

    const refetchAll = () => {
      refetchMe()
      refetchSpouse()
    }

    const spouseControlled = !!spouse?.userId
 
    return (
      <>
        {
          error || isLoading ?
          <View style={{ height: Sizing.x140 }}>
            {
              error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> : isLoading ? <Loading message={getLookingLoadingMessages('Loading your assets...')} /> : <></>
            }
          </View>
          :
          spouse ?
          <AssetList
            client={spouse}
            assets={assets}
            addAssetStateFunction={spouseControlled ? undefined : addAssetStateFunction}
            editAssetStateFunction={editAssetStateFunction}
          />
          :
          <View style={{...Flex.column.start }} >
            <View style={{
              marginTop: Sizing.x10,
              marginHorizontal: Sizing.x15,
            }}>
              <Paragraph style={{textAlign: 'center', paddingVertical: Sizing.x20}}>Add your partner to start creating your shared retirement universe together</Paragraph>
              <MaterialCommunityIcons style={{ alignSelf: 'center' }} onPress={() => addSpouseStateFunction(true)} name={'plus-circle'} size={Sizing.x60} color={Colors.brand.red3}/>
              <Button mode='text' onPress={() => addSpouseStateFunction(true)} color={Colors.brand.red3}>Add Partner</Button>              
            </View>
          </View>

        }
      </>
    )
  }

  return (
    <View style={{ height: navigatorHeight }}>
      <Tab.Navigator
        screenListeners={{
          state: (e) => onNavigateFunction(e.data)
        }}
        initialRouteName={onSpouseTab ? AssetTabScreenName.SPOUSE : AssetTabScreenName.CLIENT}
        sceneContainerStyle={{ backgroundColor: 'transparent' }}
        screenOptions={{
          tabBarActiveTintColor: themeColors.accent,
          tabBarInactiveTintColor: themeColors.primary,
          tabBarStyle: {
            backgroundColor: 'transparent',
          },
          tabBarIndicatorStyle: {
            backgroundColor: themeColors.accent,
          },
        }} 
      >
        <Tab.Screen name={AssetTabScreenName.CLIENT} component={ClientScreen} options={{ title: 'You'}} />
        <Tab.Screen name={AssetTabScreenName.SPOUSE} component={SpouseScreen} options={{ title: spouse?.firstName || 'Partner' }} />
      </Tab.Navigator>
    </View>
  )
}