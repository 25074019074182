import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from 'components/Typography/Text'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { formatUkDate } from 'lib/dateHelpers'
import React from 'react'
import { GroupSchemeInviteDto } from 'store/dto/group-scheme.dto'
import { InviteStatus } from 'store/dto/invite.dto'
import { Paper, Sizing } from 'styles'
import { startCase, compact } from 'lodash'

type EmployerInviteListItemProp = {
  invite: GroupSchemeInviteDto
  onPressFunction?: () => void
}
export const EmployerInviteListItem = (props: EmployerInviteListItemProp) => {
  const { invite, onPressFunction } = props
  const { status, firstName, surname, email, nationalInsuranceNo, createdAt } = invite

  const { colors: themeColors } = Paper.useAppTheme()

  const iconName = 
    status === InviteStatus.OPEN
    ? 'email-outline'
    : status === InviteStatus.ACCEPTED
    ? 'email-check-outline'
    : status === InviteStatus.REVOKED
    ? 'email-alert-outline'
    : status === InviteStatus.DECLINED
    ? 'email-remove-outline'
    : 'email-outline'
  const title = `${firstName} ${surname}`
  const titleRight = startCase(status)

  const identifiers: string[] = compact([
    nationalInsuranceNo,
    email,
  ])
  const subTitle = identifiers.join(` / `)
  const titleRightStyle = {
    color: themeColors.primary
  }
  const subTitleRight = `Invited: ${formatUkDate(new Date(createdAt))}`

  return (
    <DetailedListItem
      icon={<MaterialCommunityIcons name={iconName} size={Sizing.x25} color={themeColors.primary} />}
      onPress={onPressFunction}
      title={title}
      titleRight={titleRight}
      titleRightStyle={titleRightStyle}
      subTitle={subTitle}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      subTitleRight={<Text>{subTitleRight}</Text>}
    />
  )
}
