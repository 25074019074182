import { MaterialCommunityIcons } from '@expo/vector-icons'
import { addDays, differenceInYears, format, formatISO, isAfter, isBefore, isValid, parse, parseISO, subDays, subYears } from 'date-fns'
import { convertIsoDateStringToUkString, convertUkDateToIso } from 'lib/dateHelpers'
import { Logger } from 'lib/logger'
import { identity, pickBy } from 'lodash'
import React, { ComponentProps, forwardRef, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Keyboard, TextStyle, View } from 'react-native'
import { TextInputMask } from 'react-native-masked-text'
import { TextInput } from 'react-native-paper'
import { Flex, Sizing, Paper, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { InputErrorMessages } from './InputErrorMessages'
import { isNilOrEmptyString } from 'lib/generalHelpers'

export type ManagedDateInputProps = ComponentProps<typeof TextInput> & {
  formObj: UseFormReturn<any>
  name: string
  required?: boolean
  mustBeInPast?: boolean
  mustBeInFuture?: boolean
  notBeforeDate?: Date
  notAfterDate?: Date
  submitHandler?: any
  notOlderThanYears?: number
  notYoungerThanYears?: number
  showIncrementers?: boolean
  informationMessage?: string
  informationMessageIsError?: boolean
  tryParseTwoDigitYear?: boolean
  showCurrentAgeMessage?: boolean
  hideClearButton?: boolean
}

export const ManagedDateInput = forwardRef((props: ManagedDateInputProps, ref: any) => {
  const { showIncrementers, hideClearButton, disabled, name, label, informationMessage, informationMessageIsError, required, formObj, mustBeInFuture, mustBeInPast, notAfterDate, notBeforeDate, notOlderThanYears, notYoungerThanYears, style, submitHandler, tryParseTwoDigitYear, showCurrentAgeMessage } = props
  const { control, setValue, trigger, formState: { isValid: formIsValid, errors } } = formObj

  const { colors: themeColors } = Paper.useAppTheme()
  
  //Keep local state to cope with currently-editing, invalid date strings
  const [currentValue, setCurrentValue] = useState<string | undefined>(undefined)
  const [age, setAge] = useState<number | undefined>(undefined)

  //These need to be kept in line
  const maskFormat = 'DD/MM/YYYY'
  const formatString = 'dd/MM/yyyy' //Used by date-fns
  
  const fieldErrors = errors[name]
  const hasError = fieldErrors ? true : false

  const centered = !label

  const textInputStyles: TextStyle = centered ? {
    textAlign: 'center',
    width: showIncrementers ? '90%' : '100%',
    alignSelf: 'center',
  } : {    
    width: showIncrementers ? '90%' : '100%',
  }

  const textInputProps: ComponentProps<typeof TextInput> = {
    ...props,
    theme: { colors: { text: themeColors.inputText }},
    placeholder: maskFormat,
    mode: 'flat',
    style: [Typography.defined.input, textInputStyles, style],
    error: hasError,
    returnKeyType: formIsValid ? 'done' : undefined,
    render: renderProps => {
      //@ts-ignore
      return <TextInputMask
      {...renderProps}
      type={'datetime'}
        options={{
          format: maskFormat,
        }}
        refInput={ref}
      />
      }
  }

  const canBeDecremented = (value: string) => {
    if(!notBeforeDate) { return true}
    if (isAfter(parseISO(value),notBeforeDate))
     { return true }return false
  }
  const canBeIncremented = (value: string) => {
    if(!notAfterDate) { return true}
    if (isBefore(parseISO(value),notAfterDate))
     { return true }return false
  }
  const decrementDate = (value: string) => {
    if(isAfter(parseISO(value),notBeforeDate)){
    try {
      const newDate = subDays(parseISO(value), 1)
      setValue(name, formatISO(newDate, { representation: 'date'}), { shouldDirty: true, shouldValidate: true })
      trigger(name)
    } catch (e) {
    }}
  }

  const incrementDate = (value: string) => {
    if(isBefore(parseISO(value),notAfterDate)){
    try {
      const newDate = addDays(parseISO(value), 1)
      setValue(name, formatISO(newDate, { representation: 'date'}), { shouldDirty: true, shouldValidate: true })
      trigger(name)
    } catch (e) {
    }}
  }

  const clearValue = () => {
    setValue(name, '', { shouldDirty: true, shouldValidate: true })
    setAge(undefined)
    setCurrentValue('')
    trigger(name)
  }

  const isEmptyAndNotRequired = (dateString: string) => {
    return !required && isNilOrEmptyString(dateString)
  }

  const isValidDate = (dateString: string) => {
    return isEmptyAndNotRequired(dateString) || isValid(parseISO(dateString)) ? true : 'Please enter as DD/MM/YYYY'
  }
  
  const isNotBeforeDate = (dateString: string) => {
    return isEmptyAndNotRequired(dateString) ? true : isBefore(parseISO(dateString), notBeforeDate) ? `Must not be before ${format(notBeforeDate, formatString)}` : true
  }
  
  const isNotAfterDate = (dateString: string) => {
    return isEmptyAndNotRequired(dateString) ? true : isAfter(parseISO(dateString), notAfterDate) ? `Must not be after ${format(notAfterDate, formatString)}` : true
  }

  const isNotOlderThan = (dateString: string) => {
    return isEmptyAndNotRequired(dateString) ? true : isBefore(parseISO(dateString), subYears(new Date(), notOlderThanYears)) ? `Must be at most ${notOlderThanYears} years old` : true
  }

  const isNotYoungerThan = (dateString: string) => {
    return isEmptyAndNotRequired(dateString) ? true : isAfter(parseISO(dateString), subYears(new Date(), notYoungerThanYears)) ? `Must be at least ${notYoungerThanYears} years old` : true
  }
  
  const isInPast = (dateString: string) => {
    return isEmptyAndNotRequired(dateString) ? true : isBefore(parseISO(dateString), new Date()) ? true : 'Cannot be in the future'
  }
  
  const isInFuture = (dateString: string) => {
    return isEmptyAndNotRequired(dateString) ? true : isAfter(parseISO(dateString), new Date()) ? true : 'Cannot be in the past'
  }

  const customValidatorObj: any = pickBy({
    isValidDate,
    isNotBeforeDate: notBeforeDate ? isNotBeforeDate : undefined,
    isNotAfterDate: notAfterDate ? isNotAfterDate : undefined,
    isInPast: mustBeInPast ? isInPast : undefined,
    isInFuture: mustBeInFuture ? isInFuture : undefined,
    isNotOlderThan: notOlderThanYears ? isNotOlderThan : undefined,
    isNotYoungerThan: notYoungerThanYears ? isNotYoungerThan : undefined,
  }, identity)

  return (
    <View style={layoutStyles.inputContainer}>
      <Controller
        control={control}
        rules={{
          required: required || false,
          validate: customValidatorObj,
        }}
        render={({ field: { onChange, onBlur, value } }) => {
          const isoDate = parseISO(value)
          //Set to the new value, or retain the previous
          const formattedDate: string = isValid(isoDate) ? convertIsoDateStringToUkString(value) : currentValue
          return (
            <>
              <View style={{...Flex.row.between}}>
                  {
                    showIncrementers && !disabled ?
                      <MaterialCommunityIcons style={{paddingTop: 10}} name='minus-circle' onPress={() => {decrementDate(value)}} size={Sizing.x40} color={canBeDecremented(value)?themeColors.primary:themeColors.disabled} />
                      : <></>
                  }
                  <View style={{flex: 1}}>
                    <TextInput
                      {...textInputProps}
                      value={formattedDate}
                      onBlur={onBlur}
                      onChangeText={text => {
                        //Handle empty string
                        if (text === '') {
                          setCurrentValue('')
                          onChange(null)
                          setAge(undefined)
                          return
                        }
                        //Handle 6 digit dates
                        let processedText = text
                        if (tryParseTwoDigitYear && text.length === 8) {
                          const lastTwo = text.substring(6)
                          if (!['19','20'].includes(lastTwo)) {
  
                            //Set the reference date to 50 years ago + 18 (adult age)
                            //This causes 2-digit years with last 18 years ago to assume previous century
                            const referenceDate = subYears(new Date(), 50 + 18)
                            const probableDate = parse(text, 'dd/MM/yy', referenceDate)
                            if (isValid(probableDate)) {
                              const century = format(probableDate, 'yyyy').substring(0,2)
                              processedText = `${text.substring(0,6)}${century}${lastTwo}`
                            }
                          }
                        }
                        //Set local state to keep working date strings
                        setCurrentValue(processedText)
                        const isoDate = convertUkDateToIso(processedText)
                        if (isValid(isoDate)) {
                          onChange(formatISO(isoDate, { representation: 'date'})) //Convert date string)
                          setAge(differenceInYears(new Date(), isoDate))
                        } else {
                          //Set to invalid ISO date string
                          onChange('9999-99-99')
                          setAge(undefined)
                        }
                      }}
                      onSubmitEditing={() => {
                        if (submitHandler) {
                          try {
                            submitHandler()
                          } catch(e) {
                            Logger.error(`Submit handler failed`, e)
                          }
                        } else {
                          Keyboard.dismiss()
                        }
                      }}
                      right={
                        !disabled && value && !hideClearButton ?
                        //@ts-ignore
                          <TextInput.Icon tabIndex={-1} icon={'close-circle'} size={Sizing.x20} color={themeColors.primary} onPress={clearValue} forceTextInputFocus={false} style={{ backgroundColor: 'transparent' }}/>
                        : undefined
                      }
                    />
                  <InputErrorMessages
                    formObj={formObj}
                    name={name}
                    informationMessage={showCurrentAgeMessage && age ? `${age} years old` : informationMessage}
                    informationMessageIsError={showCurrentAgeMessage && age ? false : informationMessageIsError}
                  />
                </View>
                {
                  showIncrementers && !disabled ?
                  <MaterialCommunityIcons style={{paddingTop: Sizing.x10}} name='plus-circle' onPress={() => {incrementDate(value)}} size={Sizing.x40} color={canBeIncremented(value)?themeColors.primary:themeColors.disabled} />
                    : <></>
                }
              </View> 
            </>
          )}}
        name={name}
      />
    </View>
  )
})