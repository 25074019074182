import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { ButtonSet } from 'components/Utility/ButtonSet'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { LegalDocumentsModal } from 'components/Utility/LegalDocumentsModal'
import { Loading } from 'components/Utility/Loading'
import { OnboardingGuard } from 'features/Onboarding/components/OnboardingGuard'
import { getGroupPension, getGroupReserveAccounts, getPersonalPension } from 'lib/accountHelpers'
import { JAR_NAME_ALL, JAR_NAME_GROUP, JAR_NAME_PERSONAL } from 'lib/constants'
import { OnboardingStateStepIdentifier } from 'providers'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Portal } from 'react-native-paper'
import { useGetMeQuery, useGetUserAccountsQuery } from 'store/apiSlice'
import { Paper, Sizing } from 'styles'
import { MessageList } from '../Components/MessageList'

type MessagesScreenProps = {
  accountId?: string
}

const ALL_ACCOUNTS_ID = 'ALL'

export const MessagesScreen = (props: MessagesScreenProps) => {
  const { accountId } = props || {}

  const { colors: themeColors } = Paper.useAppTheme()

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, isFetching: accountsIsFetching, refetch: refetchAccounts } = useGetUserAccountsQuery()
  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()

  const isLoading = accountsIsLoading || meIsLoading
  const error: any = accountsError || meError

  const [filterAccountId, setFilterfilterAccountId] = useState(accountId || ALL_ACCOUNTS_ID)
  
  //Update filterAccountId when props change
  useEffect(() => {
    setFilterfilterAccountId(accountId || ALL_ACCOUNTS_ID)
  }, [props])

  const [showDocsModal, setShowDocsModal] = useState(false)

  const personalPension = getPersonalPension(accounts)
  const groupPension = getGroupPension(accounts)
  const groupReservedAccounts = getGroupReserveAccounts(accounts)
  const bestGroupPensionId =
    groupPension ? groupPension.id
    : groupReservedAccounts.length === 1 ? groupReservedAccounts[0].id
    : undefined

  const hasBothAccounts = !!personalPension && !!bestGroupPensionId

  const onboardingGuardSteps = groupPension ? [] : [
    OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
    OnboardingStateStepIdentifier.PLAN_CHOICE,
  ]

  return (
    <MainAppScreen>
      {
        isLoading ? <Loading /> : error ? <ErrorScreen error={error?.data} errorTryAgain={accountsError ? refetchAccounts : refetchMe} /> :
          <OnboardingGuard
            explanation={`Your Documents area will provide important legal documents and messages related to your ${JAR_NAME_ALL}`}
            subExplanation={`You will start receiving these once you have verified your identity and opened your ${JAR_NAME_PERSONAL}, or have a ${JAR_NAME_GROUP} by connecting an employer who has enrolled you with your National Insurance Number.`}
            onboardingSteps={onboardingGuardSteps}
          >
            {
              hasBothAccounts ?
              <View style={{
                paddingTop: Sizing.x10,
                paddingHorizontal: Sizing.x20
              }}>
                <ButtonSet
                  value={filterAccountId}
                  onValueChange={setFilterfilterAccountId}
                  buttons={[
                    {
                      icon: 'all-inclusive',
                      value: ALL_ACCOUNTS_ID,
                      label: 'All Jars',
                    },
                    {
                      icon: 'account-outline',
                      value: personalPension.id,
                      label: 'Personal',
                    },
                    {
                      icon: 'briefcase-outline',
                      value: bestGroupPensionId,
                      label: 'Workplace'
                    },
                  ]}
                />
              </View>
              : <></>
          }
            <MessageList
              messagesQuery={{
                accountId: filterAccountId === ALL_ACCOUNTS_ID ? undefined : filterAccountId,
              }}/>
            <FooterButton
              mode='text'
              color={themeColors.accent}
              onPress={() => setShowDocsModal(true)}>
              {'View Legal Documents'}
            </FooterButton>
            <Portal>
              <LegalDocumentsModal
                client={me}
                visible={showDocsModal}
                onDismiss={() => setShowDocsModal(false)}
              />
            </Portal>
          </OnboardingGuard>
      }
    </MainAppScreen>
  )
}
