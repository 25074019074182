import { ProcessStackBuilder } from 'navigation/stacks/ProcessStackBuilder'
import React from 'react'
import { SchemeEnrolmentSetup_01_About, SchemeEnrolmentSetup_02_VerifyByNino, SchemeEnrolmentSetup_03_NoNino, SchemeEnrolmentSetup_04_VerifyByEmployerId, SchemeEnrolmentSetup_11_AddNinoRequest, SchemeEnrolmentSetup_10_Adopt, SchemeEnrolmentSetup_20_Contributions, SchemeEnrolmentSetup_21_ForecastTreatment, SchemeEnrolmentSetup_30_PreviousPensionChoice, SchemeEnrolmentSetup_31_AddPreviousPension, SchemeEnrolmentSetup_40_Mismatch, SchemeEnrolmentSetup_99_Result } from './Screens'

export const SchemeEnrolmentSetupProcessStack = ({ route, navigation }) => {
  return (
    <ProcessStackBuilder
      navigatorId='SchemeEnrolmentSetup'
      processTitle='Set up Employer'
      previousRoute='SchemeEnrolmentSetupIntroScreen'
      nextRoute='SchemeEnrolmentSetupHeroScreen'
      initialParams={route?.params}
      processScreens={[
        { 
          navigationId: 'About',
          title: 'About',
          component: SchemeEnrolmentSetup_01_About,
        },
        {
          navigationId: 'VerifyByNino',
          title: 'VerifyByNino',
          component: SchemeEnrolmentSetup_02_VerifyByNino,
        },
        {
          navigationId: 'NoNino',
          title: 'NoNino',
          component: SchemeEnrolmentSetup_03_NoNino,
        },
        {
          navigationId: 'VerifyByEmployerId',
          title: 'VerifyByEmployerId',
          component: SchemeEnrolmentSetup_04_VerifyByEmployerId,
        },
        {
          navigationId: 'Adopt',
          title: 'Adopt',
          component: SchemeEnrolmentSetup_10_Adopt,
        },
        {
          navigationId: 'AddNinoRequest',
          title: 'AddNinoRequest',
          component: SchemeEnrolmentSetup_11_AddNinoRequest,
        },
        {
          navigationId: 'ContributionInfo',
          title: 'ContributionInfo',
          component: SchemeEnrolmentSetup_20_Contributions,
        },
        {
          navigationId: 'ForecastTreatment',
          title: 'ForecastTreatment',
          component: SchemeEnrolmentSetup_21_ForecastTreatment,
        },
        {
          navigationId: 'PreviousPensionChoice',
          title: 'PreviousPensionChoice',
          component: SchemeEnrolmentSetup_30_PreviousPensionChoice,
        },
        {
          navigationId: 'AddPreviousPension',
          title: 'AddPreviousPension',
          component: SchemeEnrolmentSetup_31_AddPreviousPension,
        },
        {
          navigationId: 'Mismatch',
          title: 'Mismatch',
          component: SchemeEnrolmentSetup_40_Mismatch,
        },
      ]}
      resultScreen={{
        navigationId: 'Results',
        component: SchemeEnrolmentSetup_99_Result,
      }}
    />
  )
}
  