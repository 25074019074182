import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ModalProcessScreen } from 'components/Layout'
import { Logger } from 'lib/logger'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { union, sortBy } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useGetBankConnectionsQuery } from 'store/apiSlice'
import { MoneyHubBankConnectionPaymentType } from 'store/dto/bank-connection.dto'
import { ContributionSource } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingContributionSetup, workingContributionSetup } from 'store/tempDataSlice'
import { Sizing, Flex, Colors } from 'styles'

export const MakeContributions_05_Bank = ({ route, navigation }) => {
  const dispatch = useAppDispatch()

  const { nextScreen, contributionsData } = route?.params || {}
  const { existingContributionConfiguration } = contributionsData || {}
  
  const workingContributionData = useAppSelector(workingContributionSetup)

  //Form refs for focussing
  const bankRef = useRef()

  //Setup form
  const formObj = useForm<{ bankConnectionId: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      bankConnectionId: workingContributionData?.bankConnectionId,
    },
  })
  const { handleSubmit, watch, formState: { isValid } } = formObj

  const onSubmit = attributes => {
    const { bankConnectionId } = attributes
    const bank = banks.find(bank => {
      return bank.id === bankConnectionId
    })
    const bankId = bank?.moneyHubId
    dispatch(updateWorkingContributionSetup({
      bankConnectionId,
      bankId,
    }))
    navigation.navigate(nextScreen)
  }

  //Build autocomplete options
  const { data: banks, error, isLoading, refetch } = useGetBankConnectionsQuery()

  const requiredPaymentTypes = [
    MoneyHubBankConnectionPaymentType.DOMESTIC,
  ]
  const filteredBanks = banks ? banks.filter(bank => {
    const { paymentTypes, contributionSources } = bank
    const supportsRequiredTypes = union(requiredPaymentTypes, paymentTypes).length
    const isNotBeta = bank.isBeta === false
    const supportsContributionSource = contributionSources.includes(workingContributionData?.source)
    return supportsContributionSource && supportsRequiredTypes && isNotBeta
  }) : []

  const bankOptions: AutoCompleteItem[] = sortBy(filteredBanks.map(bank => {
    return {
      value: bank.id,
      label: bank.name,
      description: bank.description,
      icon: bank?.logo
        ? () => <Image source={{ uri: bank?.logo}} style={{
          width: Sizing.x40,
          height: Sizing.x40,
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
        : () => <View style={{
            ...Flex.column.center,
            alignItems: 'center',
          }}>
            <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
        </View>
    }
  }), 'title')

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      loadingMessage={['Loading banks...']}
      error={error}
      errorTryAgain={refetch}
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isValid}
      headline={workingContributionData?.source === ContributionSource.PERSONAL ? `Which personal bank do you use?` : `Which business bank do you use?`}
      subHeading={workingContributionData?.source === ContributionSource.PERSONAL ? `This should be the bank holding your personal account` : `This should be the bank holding your limited company's account`}
    >
      <ManagedAutoCompleteInput
        name={'bankConnectionId'}
        formObj={formObj}
        modalTitle={'Find Bank'}
        dataSet={bankOptions}
        required={true}
      />
      {/* {
        selectedBank
          ? 
            <View style={{ paddingTop: Sizing.x20 }}>
              <Image source={selectedBank?.logo ? { uri: selectedBank?.logo} : require('assets/icons/default_bank.png')} style={{
                width: Sizing.x150,
                height: Sizing.x150,
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
            </View>
          : <></>
      } */}
    </ModalProcessScreen>
  )
}

