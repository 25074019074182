import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { FeatureBox, FeatureBoxRow } from 'components/Utility/FeatureBox'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { batch } from 'react-redux'
import { ampli } from 'src/ampli'
import { linkParams, setLoginParams } from 'store/authSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingCalculatorRequest, workingCalculatorRequest } from 'store/tempDataSlice'

export const Calculator_02_ReportEmail1 = ({ route, navigation }) => {
  const { nextScreen, loggedInUserId } = route?.params || {}

  const linkConfig = useAppSelector(linkParams)
    const dispatch = useAppDispatch()
  const workingCalculatorRequestData = useAppSelector(workingCalculatorRequest)

  const [showDialog, setShowDialog] = useState(false)

  //Setup form
  const formObj = useForm<{ email: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: workingCalculatorRequestData?.email
    },
  })
  const { handleSubmit, setValue, watch, formState: { isValid } } = formObj

  const onSubmit = attributes => {
    batch(() => {
      dispatch(updateWorkingCalculatorRequest({
        sendReport: true,
        ...attributes
      }))
      dispatch(setLoginParams({
        emailAddress: attributes?.email,
      }))
    })

    //PA-2201 - Removed
    // ampli.calculatorUsage({
    //   processStep: 'email1',
    //   outcome: 'provided',
    //   loggedInUserId,
    //   affiliateCode: linkConfig?.affiliateCode,
    //   affiliateRef: linkConfig?.affiliateRef,
    // })

    navigation.navigate(nextScreen)
  }

  const skip = () => {
    setShowDialog(false)

    //PA-2201 - Removed
    // ampli.calculatorUsage({
    //   processStep: 'email1',
    //   outcome: 'refused',
    //   loggedInUserId,
    //   affiliateCode: linkConfig?.affiliateCode,
    //   affiliateRef: linkConfig?.affiliateRef,
    // })

    navigation.navigate(nextScreen)
  }

  const email = watch('email')

  const features: FeatureBoxRow[] = [
    { label: `Complete retirement planning in a single app` },
    { label: `Know exactly when you can retire and how get there sooner` },
  ]

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      allowTextButton={!email}
      textButtonTitle={`Continue without email for now`}
      textButtonAction={() => setShowDialog(true)}
      headline={`We can also email your results at the end`}
      subHeading={`We'll show you everything here, but it's always good to have a record`}
      footerInfo={<FeatureBox data={features} />}
    >
      <ManagedTextInput
        name={'email'}
        placeholder={'Enter your email address'}
        autoFocus={false}
        formObj={formObj}
        keyboardType={'email-address'}
        autoCapitalize={'none'}
        autoCorrect={false}
        autoComplete={'email'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={true}
        rules={{
          required: true,
          pattern: {
            value: /^(([^<>()[\]\\.,:\s@\"]+(\.[^<>()[\]\\.,:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Invalid email address"
          }
        }}
      />
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'Are you sure?'}
        content={`We'll show your results on-screen but if you want a record, we can also send them to you immediately after.`}
        cancelLabel={'Go back'}
        confirmLabel={'Continue without email'}
        onConfirm={skip}
      />
    </ProcessScreen>
  )
}
