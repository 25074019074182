import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { UserDto, UserMetadata } from 'store/dto/user.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'

export const ClientSetup_02_FirstName = ({ route, navigation }) => {
  const { nextScreen, user, metadata }: { nextScreen: string, user: UserDto, metadata: UserMetadata } = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingClientData = useAppSelector(workingClient)
  
  //Setup form
  const formObj = useForm<{ firstName: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: workingClientData?.firstName || metadata?.firstName || user?.metadata.firstName,
    },
  })
  const { handleSubmit, setValue, formState: { isValid} } = formObj

  useEffect(() => {
    if (metadata) {
      setValue('firstName', metadata?.firstName)
    }
  }, [metadata])

  const onSubmit = attributes => {
    dispatch(updateWorkingClient(attributes))
    navigation.navigate(nextScreen)
  }

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={metadata?.firstName ? `Confirm your first name` : `What's your first name?`}
      subHeading={'Please use your legal name as it appears on your passport or ID'}
    >
      <ManagedTextInput
        name={'firstName'}
        autoFocus={true}
        returnKeyType="next"
        formObj={formObj}
        // label={'First Name'}
        placeholder={'Enter your legal first name'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
    </ProcessScreen>
  )
}
