import { ModalProcessScreen } from 'components/Layout'
import { Paragraph, Subheading } from 'components/Typography'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { addMonths, format, getDate, setDate } from 'date-fns'
import { getPersonalPension } from 'lib/accountHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import React, { useState } from 'react'
import 'react-native-get-random-values'; //this must always be before importing uuid
import { useGetUserAccountsQuery } from 'store/apiSlice'
import { Sizing } from 'styles'

export const MakeContributions_01_ExistingRegularCancelInformation = ({ route, navigation }) => {
  const { nextScreen, contributionsData, onDismiss } = route?.params || {}

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery()

  const personalPension = getPersonalPension(accounts)

  const { regularContribution } = personalPension || {}
  const { amount, dayOfMonth } = regularContribution || {}

  const today = new Date()
  const thisMonthDate = dayOfMonth ? setDate(today, dayOfMonth) : today
  const nextPaymentDate = dayOfMonth < getDate(today) ? addMonths(thisMonthDate, 1) : thisMonthDate

  const [showDialog, setShowDialog] = useState(false)
  
  const next = () => {
    setShowDialog(false)
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      isLoading={accountsIsLoading}
      error={accountsError}
      errorTryAgain={refetchAccounts}
      buttonTitle={`Continue`}
      buttonAction={() => setShowDialog(true)}
      enableButton={true}
      headline={`You are currently making regular contributions`}
      subHeading={'We need to cancel this in order to change contribution source'}
      showButton={true}
    >
      <Paragraph>{`If you want to make regular contributions from the new source, you will need to set this up again once you have made these changes.`}</Paragraph>
      <Subheading>{`Current Regular Contributions:`}</Subheading>
      <UnborderedTable
        itemContainerStyle={{
          paddingVertical: Sizing.x5,
        }}
        data={[
          {
            label: `Amount`,
            value: formatCurrencyAmount(amount),
          },
          {
            label: `Next Contribution`,
            value: format(new Date(nextPaymentDate), 'do MMMM'),
          },
        ]}
      />
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'Please confirm'}
        content={`Please confirm you wish to cancel your current regular contributions.`}
        onConfirm={next}
        cancelLabel={`Go back`}
        confirmLabel={`Yes, cancel`}
      />
    </ModalProcessScreen>
  )
}
