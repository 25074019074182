import { platformIsWeb } from 'lib/platformHelpers'
import React, { ComponentProps } from 'react'
import { View } from 'react-native'
import { Modal, ThemeProvider } from "react-native-paper"
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Colors, Paper } from "styles"
import { layoutStyles } from 'styles/common'

export type BottomBannerModalProps = ComponentProps<typeof Modal>
export const BottomBannerModal = (props: BottomBannerModalProps) => {
  const { children } = props
  const isWeb = platformIsWeb()
  const insets = useSafeAreaInsets()

  // const theme = isWeb ? {
  //   colors: {
  //     backdrop: 'transparent',
  //   },
  // } : undefined

  return (
    <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
      <Modal
        {...props}
        style={{ flexDirection: 'column', justifyContent: 'flex-end'}}
        contentContainerStyle={layoutStyles.bottomBannerModalContainer}
        // theme={theme}
      >
        <View
          style={{
              backgroundColor: Colors.brand.grey4,
              paddingBottom: insets.bottom,
            }
          }
          >
            {children}
        </View>
      </Modal>
    </ThemeProvider>
  )
}
