import { MaterialCommunityIcons } from '@expo/vector-icons'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { getActionDate } from 'lib/dateHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { getTransactionDescription, getTransactionIconName, getTransactionTitle } from 'lib/transactionHelpers'
import React from 'react'
import { AccountTransactionStatus, ContributionDto } from 'store/dto/account.dto'
import { Paper, Sizing } from 'styles'

type ContributionListItemProp = {
  contribution: ContributionDto
  regularContributionDayOfMonth?: number
  onPressFunction?: () => void
}
export const ContributionListItem = (props: ContributionListItemProp) => {
  const { contribution, onPressFunction } = props
  const { employerName, displayDate, amount, status } = contribution || {}

  const { colors: themeColors } = Paper.useAppTheme()

  const iconName = getTransactionIconName(contribution)
  const title = getTransactionTitle(contribution)  
  const description = getTransactionDescription(contribution, employerName)
  const amountString =
    status === AccountTransactionStatus.DELETED
      ? `${formatCurrencyAmount(amount, 2)}`
      : `+${formatCurrencyAmount(amount, 2)}`

  let dateValue: Date = new Date(displayDate)

  const dateString = getActionDate(dateValue, true)

  return (
    <DetailedListItem
      icon={<MaterialCommunityIcons name={iconName} size={Sizing.x25} color={themeColors.primary} />}
      onPress={onPressFunction}
      title={title}
      titleRight={amountString}
      titleRightStyle={status === AccountTransactionStatus.DELETED ? {textDecorationLine: 'line-through'} : {}}
      subTitle={description}
      titleStyle={{ color: themeColors.accent }}
      subTitleRight={dateString}
    />
  )
}
