import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Subheading } from 'components/Typography'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { orderBy } from 'lodash'
import { default as React, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useGetGroupOrganizationUserRolesQuery, useGetGroupOrganizationUsersQuery, useGetGroupSchemeQuery, useGetGroupSchemeUserRolesQuery, useSetGroupSchemeUserRolesMutation } from 'store/apiSlice'
import { SimpleUserDto } from 'store/dto/base.dto'
import { GroupSchemeRole } from 'store/dto/group-organization.dto'
import { SetGroupSchemeUserRoleDto } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminGroupSchemeSetRolesDataId, setCurrentAdminGroupSchemeDataId, setCurrentAdminGroupSchemeSetRolesDataId } from 'store/uxSlice'
import { Paper } from 'styles'
import { AdminGroupSchemeUserRolesTable } from './Components/AdminGroupSchemeUserRolesTable'

export type GroupSchemeUserRoleAssertion = {
  userId: string
  userName: string
  userEmail: string
  schemeRole: GroupSchemeRole
  orgSchemeRole: GroupSchemeRole
}

export const AdminGroupSchemeSetUserRolesModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {

  const dispatch = useAppDispatch()

  const currentGroupSchemeId = useAppSelector(currentAdminGroupSchemeSetRolesDataId)

  const [saveDialogVisible, setSaveDialogVisible] = useState(false)
  const [discardDialogVisible, setDiscardDialogVisible] = useState(false)

  const { data: groupScheme, isLoading: groupSchemeIsLoading, error: groupSchemeError, refetch: refetchSchemes } = useGetGroupSchemeQuery(currentGroupSchemeId, { skip: !currentGroupSchemeId } )
  const { data: groupOrgUsers, isLoading: groupOrgUsersIsLoading, error: groupOrgUsersError, refetch: refetchOrgUsers } = useGetGroupOrganizationUsersQuery(groupScheme?.organizationId, { skip: !groupScheme } )
  const { data: schemeRoles, isLoading: schemeRolesIsLoading, error: schemeRolesError, refetch: refetchSchemeRoles } = useGetGroupSchemeUserRolesQuery(currentGroupSchemeId, { skip: !currentGroupSchemeId } )
  const { data: orgSchemeRoles, isLoading: orgSchemeRolesIsLoading, error: orgSchemeRolesError, refetch: refetchOrgSchemeRoles } = useGetGroupOrganizationUserRolesQuery(groupScheme?.organizationId, { skip: !groupScheme?.organizationId } )
  const [setUserRoles, { data: updatedUserRoles, isLoading: userRoleUpdateIsLoading, error: userRoleUpdateError, reset: userRoleUpdateReset }] = useSetGroupSchemeUserRolesMutation()

  const mapUsersToGroupSchemeUserRoleAssertions = (): GroupSchemeUserRoleAssertion[] => {
    const { userRoles } = schemeRoles || {}
    const { userRoles: userOrgRoles } = orgSchemeRoles || {}
    return groupOrgUsers && userRoles && userOrgRoles ? groupOrgUsers.map((user: SimpleUserDto): GroupSchemeUserRoleAssertion => {
      const userRole = userRoles.find(ur => {
        return ur.userId === user.id
      })
      const userOrgRole = userOrgRoles.find(ur => {
        return ur.userId === user.id
      })
      return {
        userId: user.id,
        userName: `${user.firstName} ${user.surname}`,
        userEmail: user.email,
        schemeRole: userRole?.schemeRole || GroupSchemeRole.DEFAULT,
        orgSchemeRole: userOrgRole?.schemeRole || GroupSchemeRole.NONE
      }
    }) : []
  }

  const formObj = useForm<{
    userRoleAssertions: GroupSchemeUserRoleAssertion[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, setValue, reset, setError, watch, trigger, formState: { isDirty, isValid } } = formObj

  useEffect(() => {
    if (groupOrgUsers && schemeRoles && orgSchemeRoles) {
      reset({
        userRoleAssertions: mapUsersToGroupSchemeUserRoleAssertions()
      })
    }
  }, [groupOrgUsers, schemeRoles, orgSchemeRoles])

  const onSubmit = async attributes => {
    const {
      userRoleAssertions,
    } = attributes 

    const userRoles: SetGroupSchemeUserRoleDto[] = userRoleAssertions
      .filter((ur: GroupSchemeUserRoleAssertion) => {
        return ur.schemeRole !== GroupSchemeRole.DEFAULT
      })
      .map((ur: GroupSchemeUserRoleAssertion): SetGroupSchemeUserRoleDto => {
        return {
          userId: ur.userId,
          schemeRole: ur.schemeRole,
        }
      })

    await setUserRoles({
      schemeId: currentGroupSchemeId,
      organizationId: groupScheme?.organizationId,
      userRoles,
    })
    setSaveDialogVisible(false)
  }

  useEffect(() => {
    if (updatedUserRoles) {
      goToSchemeView()
    }
  }, [updatedUserRoles])

  const isLoading = groupSchemeIsLoading || groupOrgUsersIsLoading || schemeRolesIsLoading || orgSchemeRolesIsLoading || userRoleUpdateIsLoading
  const error: any = groupSchemeError || groupOrgUsersError || schemeRolesError  || orgSchemeRolesError || userRoleUpdateError

  const { colors: themeColors } = Paper.useAppTheme()

  const goToSchemeView = () => {
    dispatch(setCurrentAdminGroupSchemeSetRolesDataId(undefined))
    dispatch(setCurrentAdminGroupSchemeDataId(currentGroupSchemeId))
  }

  return (
    <ModalEditScreen
      formTitle={'Edit User Roles'}
      onDismiss={goToSchemeView}
      isDirty={isDirty}
      dismissDialogText={'Discard role changes?'}
      error={error}
      errorCancel={userRoleUpdateError ? userRoleUpdateReset : goToSchemeView}
      errorTryAgain={
        userRoleUpdateError ? handleSubmit(onSubmit)
        : groupSchemeError ? refetchSchemes
        : groupOrgUsersError ? refetchOrgUsers
        : schemeRolesError ? refetchSchemeRoles
        : undefined}
      isLoading={isLoading}
      loadingMessage={userRoleUpdateIsLoading ? ['Saving user roles...'] : undefined}
      buttonTitle={'Save Changes'}
      buttonAction={() => setSaveDialogVisible(true)}
      showButton={true}
      allowTextButton={true}
      textButtonTitle={'Return to Scheme View'}
      textButtonAction={isDirty ? () => setDiscardDialogVisible(true) : goToSchemeView}
      enableButton={isDirty && isValid}
    >
      <Subheading style={{ color: themeColors.primary }}>{`Scheme Details`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: groupScheme?.id,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: groupScheme?.name,
          },
        ]}
        noContentDivider={true}
      />
      <ContentDivider />

      <AdminGroupSchemeUserRolesTable
        groupScheme={groupScheme}
        formObj={formObj}
        name={'userRoleAssertions'}
      />

      <ConfirmationDialog
        visible={saveDialogVisible}
        title={'Are you sure?'}
        content={`Save changes to user roles for scheme?`}
        confirmLabel={'Save'}
        onCancel={() => setSaveDialogVisible(false)}
        onConfirm={handleSubmit(onSubmit)}
      />
      <ConfirmationDialog
        visible={discardDialogVisible}
        onCancel={() => setDiscardDialogVisible(false)}
        title={'Are you sure?'}
        content={`Discard changes and return to scheme view?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Discard Changes'}
        onConfirm={goToSchemeView}
      />
    </ModalEditScreen>
  )
}


