import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { OnboardingGuard } from 'features/Onboarding/components/OnboardingGuard'
import { OnboardingStateStepIdentifier } from 'providers'
import React from 'react'
import { useAppDispatch } from 'store/hooks'
import { setStatementRequestVisible } from 'store/uxSlice'
import { StatementList } from '../Components/StatementList'
import { getGroupPension } from 'lib/accountHelpers'
import { JAR_NAME_ALL, JAR_NAME_GROUP, JAR_NAME_PERSONAL } from 'lib/constants'
import { useGetUserAccountsQuery } from 'store/apiSlice'
import { Loading } from 'components/Utility/Loading'
import { ErrorScreen } from 'components/Utility/ErrorScreen'

export const StatementsScreen = () => {
  const dispatch = useAppDispatch()

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery()

  const groupPension = getGroupPension(accounts)

  const onboardingGuardSteps = groupPension ? [] : [
    OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
    OnboardingStateStepIdentifier.PLAN_CHOICE,
  ]

  return (
    <MainAppScreen>
       {
        accountsIsLoading
          //@ts-ignore
          ? <Loading /> : accountsError ? <ErrorScreen error={accountsError?.data} errorTryAgain={refetchAccounts} /> :
            <OnboardingGuard
              explanation={`Your Statements area will allow you to generate and view statements for your ${JAR_NAME_ALL}`}
              subExplanation={`You will start receiving statements once you have verified your identity and opened your ${JAR_NAME_PERSONAL}, or have a ${JAR_NAME_GROUP} by connecting an employer who has enrolled you with your National Insurance Number.`}
              onboardingSteps={onboardingGuardSteps}
            >
              <StatementList />
              <FooterButton
                onPress={() => dispatch(setStatementRequestVisible(true))}
                >{`Request a Statement`}
              </FooterButton>
            </OnboardingGuard>
      }
    </MainAppScreen>
  )
}
