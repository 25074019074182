import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ClientSignatureModal } from 'features/MenuSections/Profile/Components/ClientSignatureModal'
import { MAX_CLIENT_AGE, MIN_CLIENT_AGE, NATIONAL_INSURANCE_NO_MASK } from 'lib/constants'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { composeIntercomMessage } from 'lib/intercomHelpers'
import { Logger } from 'lib/logger'
import { getNationalityAutocompleteOptions } from 'lib/referenceDataHelpers'
import { getScreenAppWidth, scaleNormalizer } from 'lib/scaleHelpers'
import { pick } from 'lodash'
import { default as React, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image, StyleSheet, View } from 'react-native'
import { Portal } from 'react-native-paper'
import { ampli } from 'src/ampli'
import { useGetMeQuery, useGetNationalitiesQuery, useGetSignatureQuery, useUpdateMeMutation, useUpdateSignatureMutation } from 'store/apiSlice'
import { Gender, Title } from 'store/dto/base.dto'
import { AmlStatus, EmploymentStatus, MaritalStatus } from 'store/dto/client.dto'
import { useAppDispatch } from 'store/hooks'
import { setEditMeVisible } from 'store/uxSlice'
import { Paper, Sizing } from 'styles'

export const ClientEditModal = () => {
  return (
  <ModalEditWrap
    screen={<ScreenContent />}
  />
)
}

const ScreenContent = () => {
  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setEditMeVisible(false))

  const onDismiss = globalDismiss
  
  const contactSupport = async () => {
    await composeIntercomMessage()
  }
  
  const { data: me, error: meError, isLoading: meIsLoading } = useGetMeQuery()
  const { data: nationalities, error: nationalitiesError, isLoading: nationalitiesIsLoading } = useGetNationalitiesQuery()
  const nationalityOptions = getNationalityAutocompleteOptions(nationalities)

  const disabled = me?.amlStatus === AmlStatus.PASS
  
  const { data: meSignature, error: meSignatureError, isLoading: meSignatureIsLoading, isFetching: meSignatureIsFetching, refetch: refetchSignature } = useGetSignatureQuery()

  const [updateSignature, { data: updatedSignature, isLoading: signatureUpdateIsLoading, error: signatureUpdateError, reset: signatureUpdateReset } ] = useUpdateSignatureMutation()
  const [ updateMe, { data: updated, isLoading: updateIsLoading, error: updateError, reset: updateReset } ] = useUpdateMeMutation()

  const [showSignatureModal, setShowSignatureModal] = useState(false)

  const handleUpdateSignature = (signature) => {
    Logger.debug(`Saving signature...`)
    updateSignature({
      signature
    })
    setShowSignatureModal(false)
  }
  
  const { colors: themeColors } = Paper.useAppTheme()

  //Form refs for focussing
  const firstNameRef = useRef()
  const surnameRef = useRef()
  const birthDateRef = useRef()
  const buildingRef = useRef()
  const street1Ref = useRef()
  const townRef = useRef()
  const postCodeRef = useRef()

  //Setup form
  const formObj = useForm<{
    title: Title
    firstName: string
    surname: string
    gender: Gender
    birthDate: string
    nationalInsuranceNo: string
    nationality: string
    building: string
    street1: string
    town: string
    postCode: string
    country: string
    maritalStatus: MaritalStatus
    employmentStatus: EmploymentStatus
  }>({
    mode: 'all',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, setValue, trigger, reset, watch, formState: { isDirty, isValid} } = formObj

    //Re-initialize form when have asset
    useEffect(() => {
      if (me) {
        reset({
          title: me?.title,
          firstName: me?.firstName,
          surname: me?.surname,
          gender: me?.gender,
          birthDate: me?.birthDate,
          nationalInsuranceNo: me?.nationalInsuranceNo,
          nationality: me?.nationality,
          building: me?.addressDetail?.building,
          street1: me?.addressDetail?.street1,
          town: me?.addressDetail?.town,
          postCode: me?.addressDetail?.postCode,
          country: 'GB',
          maritalStatus: me?.maritalStatus,
          employmentStatus: me?.employmentStatus,
        })
      }
    }, [me])
  
  const onSubmit = attributes => {
    Logger.info(`Updating client...`)
    const fields = disabled ? pick(attributes, [
        'maritalStatus',
        'employmentStatus'
      ]) : attributes
    const { building, street1, town, postCode, country, ...rest } = fields
    const hasAddress = building && street1 && town && postCode && country
    updateMe({
      id: me.id,
      ...rest,
      addressDetail: hasAddress ? {
        building,
        street1,
        town,
        postCode,
        country
      } : undefined
    })
  }

  useEffect(() => {
    if (updated) {
      //Record amplitude event
      ampli.clientUpdate()
      close()
    }
  }, [updated])

  const close = () => {
    onDismiss()
  }

  const isLoading = nationalitiesIsLoading || updateIsLoading || meSignatureIsLoading || meSignatureIsFetching || signatureUpdateIsLoading
  const error: any = nationalitiesError || updateError || signatureUpdateError

  return (
      <ModalEditScreen
        formTitle={'Update Your Details'}
        onDismiss={onDismiss}
        isDirty={isDirty}
        dismissDialogText={'Discard changes to your profile?'}
        error={error}
        errorTryAgain={signatureUpdateError ? handleUpdateSignature : updateError ? handleSubmit(onSubmit) : undefined}
        errorCancel={updateError ? updateReset : signatureUpdateError ? signatureUpdateReset : close}
        isLoading={isLoading}
        loadingMessage={updateIsLoading ? ['Saving...'] : undefined}
        buttonTitle={'Save'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
        allowTextButton={disabled}
        textButtonAction={contactSupport}
        textButtonTitle={'Contact Support'}
      >
        {
          meSignature?.signature
            ? <>
                <Subheading style={{ color: themeColors.accent }}>{'Signature'}</Subheading>
                <Paragraph>{`This is your current signature we have on file:`}</Paragraph>
                <View style={localStyles.signatureContainer}>
                  <Image source={{ uri: meSignature?.signature }} style={{
                    width: getScreenAppWidth() - Sizing.x100,
                    height: scaleNormalizer(120),
                    resizeMode: 'contain',
                    alignSelf: 'center',
                  }} />
                </View>            
                <View style={localStyles.buttonContainer}>
                  <Button
                    mode='text'
                    color={themeColors.accent}
                    onPress={() => setShowSignatureModal(true)}
                  >
                    {'Update Signature'}
                  </Button>
                </View>
                <Paragraph>{`We will not use your signature for any purpose other than finding pensions.`}</Paragraph>
                <Portal>
                  <ClientSignatureModal
                    clientSignature={meSignature} 
                    visible={showSignatureModal}
                    onDismiss={() => setShowSignatureModal(false)}
                    onSave={handleUpdateSignature}
                  />
                </Portal>
                <ContentDivider />
              </>

            : <></>
        }
        <Subheading style={{ color: themeColors.accent }}>{'Account Identity'}</Subheading>
        <Paragraph style={{ fontWeight: 'bold' }}>{me?.email}</Paragraph>
        <Paragraph>{'To change your Email Address, please contact support.'}</Paragraph>
        <ContentDivider />

        <Subheading style={{ color: themeColors.accent }}>{'Personal Information'}</Subheading>
        {
          disabled
            ? <Paragraph>
                {
                  'To change your personal information, please contact support.'
                }
              </Paragraph>
            : <></>
        }
        <ManagedAutoCompleteInput
          name={'title'}
          formObj={formObj}
          label={'Title'}
          selectOnlyMode={true}
          disabled={disabled}
          dataSet={enumToAutocompleteOptions(Title)}
          required={true}
        />
        <ManagedTextInput
          ref={firstNameRef}
          name={'firstName'}
          formObj={formObj}
          label={'First Name'}
          placeholder={'Your legal first name'}
          returnKeyType={'next'}
          //@ts-ignore
          submitHandler={() => surnameRef.current?.focus()}
          blurOnSubmit={false}
          disabled={disabled}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 40,
        }} />
        <ManagedTextInput
          ref={surnameRef}
          name={'surname'}
          formObj={formObj}
          label={'Last Name'}
          placeholder={'Your legal surname'}
          returnKeyType={'next'}
          blurOnSubmit={true}
          disabled={disabled}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 40,
        }} />
        <ManagedAutoCompleteInput
          name={'gender'}
          formObj={formObj}
          label={'Gender'}
          selectOnlyMode={true}
          disabled={disabled}
          dataSet={enumToAutocompleteOptions(Gender)}
          required={true}
        />
        <ManagedDateInput
          ref={birthDateRef}
          name={'birthDate'}
          returnKeyType={"next"}
          formObj={formObj}
          label={'Date of Birth'}
          blurOnSubmit={false}
          disabled={disabled}
          required={true}
          mustBeInPast={true}
          notOlderThanYears={MAX_CLIENT_AGE}
          notYoungerThanYears={MIN_CLIENT_AGE}
          tryParseTwoDigitYear={true}
          showCurrentAgeMessage={true}
        />
        {
          me?.hasSecclClient ?
          <>
          <ManagedTextInput
            name={'nationalInsuranceNo'}
            formObj={formObj}
            label={'National Insurance Number'}
            mask={{
              type: 'custom',
              options: {
                mask: NATIONAL_INSURANCE_NO_MASK,
              }
            }}
            autoCapitalize={'characters'}
            disabled={true} //Always disabled for now
            forceCapitals={true}
            returnKeyType={'next'}
            blurOnSubmit={false}
            rules={{
              required: true,
          }} 
          />
          <ManagedAutoCompleteInput
            name={'nationality'}
            formObj={formObj}
            label={'Nationality'}
            modalTitle={'Find Nationality'}
            dataSet={nationalityOptions}
            disabled={disabled}
            required={true}
            
          />
          <ManagedTextInput
            ref={buildingRef}
            name={`building`}
            formObj={formObj}
            label={'Building Number/Name'}
            placeholder={'Enter building number/name'}
            returnKeyType={'next'}
            autoCapitalize={'words'}
            blurOnSubmit={false}
            disabled={disabled}
            rules={{
              required: true,
              maxLength: 35,
          }} />
          <ManagedTextInput
            ref={street1Ref}
            name={`street1`}
            formObj={formObj}
            label={'Street'}
            placeholder={'Enter street'}
            returnKeyType={'next'}
            autoCapitalize={'words'}
            blurOnSubmit={false}
            disabled={disabled}
            rules={{
              required: true,
              maxLength: 35,
          }} />
          <ManagedTextInput
            ref={townRef}
            name={`town`}
            formObj={formObj}
            label={'Town'}
            placeholder={'Enter town/locality'}
            returnKeyType={'next'}
            autoCapitalize={'words'}
            // submitHandler={() => postCodeRef.current?.focus()}
            blurOnSubmit={false}
            disabled={disabled}
            rules={{
              required: true,
              maxLength: 35,
          }} />
          <ManagedTextInput
            ref={postCodeRef}
            name={`postCode`}
            formObj={formObj}
            label={'Post Code'}
            placeholder={'Enter postcode'}
            autoCapitalize={'characters'}
            returnKeyType={'next'}
            disabled={disabled}
            rules={{
              required: true,
              maxLength: 10,
          }} />
          <ManagedAutoCompleteInput
            name={`maritalStatus`}
            formObj={formObj}
            label={'Marital Status'}
            dataSet={enumToAutocompleteOptions(MaritalStatus)}
            required= {true}
            selectOnlyMode={true}
          />
          <ManagedAutoCompleteInput
            name={`employmentStatus`}
            formObj={formObj}
            label={'Employment Status'}
            dataSet={enumToAutocompleteOptions(EmploymentStatus)}
            required= {true}
            selectOnlyMode={true}
          />
        </>
        : <></>
      }
      </ModalEditScreen>
  )
}

const localStyles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: Sizing.x30,
  },
  signatureContainer: {
    paddingHorizontal: Sizing.x30,
    paddingVertical: Sizing.x10,
    marginVertical: Sizing.x10,
    backgroundColor: 'white',
    borderRadius: Sizing.x10,
  }
})