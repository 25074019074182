import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { addMonths, formatISO, isAfter, subMonths } from 'date-fns'
import { getGroupPension, getPersonalPension } from 'lib/accountHelpers'
import { JAR_NAME_GROUP, JAR_NAME_PERSONAL } from 'lib/constants'
import { getNextInstanceDayOfMonth } from 'lib/dateHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { refreshUsingFunctions } from 'lib/refreshHelpers'
import React, { useState } from 'react'
import { View } from 'react-native'
import { Portal } from 'react-native-paper'
import { useGetGroupSchemeEnrolmentsQuery, useGetMeQuery, useGetUserAccountsQuery, useGetUserContributionsQuery } from 'store/apiSlice'
import { AccountTransactionStatus, AccountType, ContributionDto, ContributionsQueryDto, PaymentMethod, TransactionMovementType, TransactionSubType, TransactionType } from 'store/dto/account.dto'
import { ContributionSource } from 'store/dto/base.dto'
import { ContributionInfoModal } from './ContributionInfoModal'
import { ContributionListItem } from './ContributionListItem'

interface ReturnType {
  title: string,
  data: ContributionDto[]
}

type ContributionsListProps = {
  includeUpcomingRegularTransaction: boolean
  includeReservedBalances: boolean
  contributionsQuery: ContributionsQueryDto
}

type ReservedBalanceContribution = {
  enrolmentId: string
  currentValue: number
  employerName: string
  isLinked: boolean
}

export const ContributionsList = (props: ContributionsListProps) => {
  const { includeUpcomingRegularTransaction, includeReservedBalances, contributionsQuery } = props || {}
  const { accountId } = contributionsQuery || {}
  
  const [refreshing, setRefreshing] = useState(false)

  const { data: contributions, isLoading: contributionsIsLoading, error: contributionsError, refetch: refetchContributions } = useGetUserContributionsQuery(contributionsQuery || {})
  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery()
  const { data: enrolments, error: enrolmentsError, isLoading: enrolmentsIsLoading, refetch: refetchEnrolments } = useGetGroupSchemeEnrolmentsQuery()
  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()

  const personalPension = getPersonalPension(accounts)
  const groupPension = getGroupPension(accounts)

  const enrolmenntsWithReservedBalance = enrolments ? enrolments.filter(enrolment => {
    return enrolment.reservedBalance?.currentValue > 0
  }) : []

  const reservedBalancesToDisplay: ReservedBalanceContribution[] = enrolmenntsWithReservedBalance.map(enrolment => {
    const { id: enrolmentId, name: employerName, reservedBalance, isLinked } = enrolment
    const { currentValue } = reservedBalance || {}
    return {
      enrolmentId,
      employerName,
      currentValue,
      isLinked,
    }
  })

  const onRefresh = async () => {
    await refreshUsingFunctions(
      [refetchContributions, refetchAccounts, refetchEnrolments],
      refreshing,
      setRefreshing,
    )
  }

  const isLoading = contributionsIsLoading || meIsLoading || accountsIsLoading || enrolmentsIsLoading
  const error: any = contributionsError || accountsError || meError || enrolmentsError

  const bestContributionSource = me?.contributionConfiguration?.source || ContributionSource.PERSONAL

  const nextRegularContributionDate = personalPension?.regularContribution?.dayOfMonth ? getNextInstanceDayOfMonth(personalPension?.regularContribution?.dayOfMonth) : undefined
  const lastRegularContributionDate = nextRegularContributionDate ? subMonths(nextRegularContributionDate, 1) : undefined

  const [modalItem, setModalItem] = useState(undefined)

  //Create a map for account labels
  const accountNameMap = {
    [AccountType.PERSONAL_PENSION]: JAR_NAME_PERSONAL,
    [AccountType.GROUP_PENSION]: JAR_NAME_GROUP,
    [AccountType.GROUP_RESERVED]: JAR_NAME_GROUP,
  }
  
  //Create a map for enrolment labels
  const employerCompanyRegNoMap = {}
  if (enrolments) {
    enrolments.forEach(enrolment => {
      if (enrolment?.groupScheme?.companyNo) {
        employerCompanyRegNoMap[enrolment?.groupScheme?.companyNo] = enrolment?.groupScheme?.organizationDisplayName
      }
    })
  }

  const contributionsList = (): ReturnType[] => {
    let upcoming = []
    let pending = []
    let completed = []
    let deleted = []

    //Show reserved balances, if requested and not filtered out
    if (includeReservedBalances
      && (!accountId || accountId === groupPension?.id)
      && reservedBalancesToDisplay?.length > 0) {
        reservedBalancesToDisplay.forEach(reservedBalance => {
          const { enrolmentId, employerName, currentValue, isLinked } = reservedBalance
          const today = new Date()
          const reservedTransaction: ContributionDto = {
            id: null,
            accountId: null,
            enrolmentId,
            employerName,
            accountName: accountNameMap[AccountType.GROUP_PENSION],
            accountType: AccountType.GROUP_RESERVED,
            amount: currentValue || 0,
            displayDate: formatISO(today),
            transactionDate: formatISO(today),
            currency: 'GBP',
            movementType: TransactionMovementType.IN,
            transactionType: TransactionType.PAYMENT,
            transactionSubType: TransactionSubType.EMPLOYER,
            method: PaymentMethod.BANK_TRANSFER, //Note this is bank transfer, not standing order as using open banking
            status: AccountTransactionStatus.RESERVED,
            isRecurring: false,
          }
          completed.push(reservedTransaction)
        })

    }

    //Show future regular contribution, if requested and not filtered out
    if (includeUpcomingRegularTransaction
          && personalPension?.regularContribution?.dayOfMonth
          && (!accountId || accountId === personalPension?.id)
        ) {

      //Look for a real transaction that is:
      //1. recurring
      //2. matches the regular contribution amount
      //3. is within 7 days of the closest regular contribution instance date
      const mostRecentRegularContributionTransaction = contributions.find(contribution => {
        return contribution.isRecurring === true
          && contribution.amount === personalPension?.regularContribution?.amount
      })

      //Work out whether to show "this" regular contribution or the "next" one
      let monthOffset: number = 0
      if (mostRecentRegularContributionTransaction) {
        if (isAfter(new Date(mostRecentRegularContributionTransaction?.transactionDate), lastRegularContributionDate || new Date())) {
          monthOffset = 1
        }
      }
      const upcomingTransactionDate = nextRegularContributionDate ? addMonths(nextRegularContributionDate, monthOffset) : new Date()

      const upcomingTransaction: ContributionDto = {
        id: null,
        accountId: personalPension.id,
        accountName: accountNameMap[AccountType.PERSONAL_PENSION],
        accountType: AccountType.PERSONAL_PENSION,
        amount: personalPension?.regularContribution?.amount,
        displayDate: formatISO(upcomingTransactionDate),
        transactionDate: formatISO(upcomingTransactionDate),
        currency: 'GBP',
        movementType: TransactionMovementType.IN,
        transactionType: TransactionType.PAYMENT,
        transactionSubType: bestContributionSource === ContributionSource.EMPLOYER ? TransactionSubType.EMPLOYER : TransactionSubType.EMPLOYEE,
        method: PaymentMethod.BANK_TRANSFER, //Note this is bank transfer, not standing order as using open banking
        status: AccountTransactionStatus.FUTURE,
        isRecurring: true,
      }
      upcoming.push(upcomingTransaction)
    }

    //Actual transactions
    if (contributions) {
      contributions.map((rawData, id) => {
        const employerName = rawData.companyRegNo ? employerCompanyRegNoMap[rawData.companyRegNo] : undefined
        const data = {
          ...rawData,
          accountName: accountNameMap[rawData.accountType],
          employerName,
        }

        if (data.status === AccountTransactionStatus.PENDING) {
          pending.push(data)
        } else if (data.status === AccountTransactionStatus.DELETED) {
          deleted.push(data)
        } else {
          completed.push(data)
        }
      })
      return [
        { title: 'Upcoming', data: upcoming },
        { title: 'Pending', data: pending },
        { title: 'Completed', data: completed },
        { title: 'Cancelled', data: deleted }
      ]
    }
    return []
  }
  const Item = ({ item }) => {
    return (
      <ContributionListItem
        contribution={item}
        regularContributionDayOfMonth={personalPension?.regularContribution?.dayOfMonth}
        onPressFunction={() => setModalItem(item)}
      />
    )
  }

  return (
    <>
      {
        isLoading
          ? <Loading message={getLookingLoadingMessages('Loading your contributions...')} messageNoLoop={true} />
          : error ? <ErrorScreen errorTryAgain={onRefresh} error={error?.data}/> :
        <>
          <View style={{ flex: 1 }} >
            <OptionalSectionList
              sections={contributionsList()}
              renderItem={({item})=><Item item={item}/>}
              onRefresh={onRefresh}
              refreshing={refreshing}
              noDataMessage={`You haven't yet made or planned any contributions`}
            />
          </View>
          <Portal>
            <ContributionInfoModal
              visible={!!modalItem}
              contribution={modalItem}
              onDismiss={() => setModalItem(undefined)}
            />
          </Portal>
        </>
      }
    </>
  )
}

