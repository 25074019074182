import { AccountDto, AccountType } from 'store/dto/account.dto'
import { uniqBy } from 'lodash'
import { JAR_NAME_GROUP, JAR_NAME_PERSONAL } from './constants'

export interface AccountInvestmentChoiceData {
  account: AccountDto
  investmentReferenceAccount: AccountDto
  showAsGlobalDecision: boolean
} 

export const getJarName = (account: AccountDto): string => {
  switch (account?.accountType) {
    case AccountType.GROUP_PENSION:
      return JAR_NAME_GROUP
    case AccountType.PERSONAL_PENSION:
      return JAR_NAME_PERSONAL
    default:
      return 'Other Jar'
  }
}

export const getPersonalPension = (accounts: AccountDto[]): AccountDto => {
  return accounts ? accounts.find(account => {
    return account.accountType === AccountType.PERSONAL_PENSION
  }) : undefined
}

export const getGroupPension = (accounts: AccountDto[]): AccountDto => {
  return accounts ? accounts.find(account => {
    return account.accountType === AccountType.GROUP_PENSION
  }) : undefined
}

export const getGroupReserveAccounts = (accounts: AccountDto[]): AccountDto[] => {
  return accounts ? accounts.filter(account => {
    return account.accountType === AccountType.GROUP_RESERVED
  }) : []
}

export const getInvestmentChoiceDataForAccount = (targetAccount: AccountDto, accounts: AccountDto[]): AccountInvestmentChoiceData => {
  if (targetAccount.accountType === AccountType.PERSONAL_PENSION) {
    return {
      account: targetAccount,
      investmentReferenceAccount: targetAccount,
      showAsGlobalDecision: false,
    }
  }

  const personalPension = getPersonalPension(accounts)
  const usePersonalPensionInvestmentChoice =
    personalPension
    && targetAccount.accountType === AccountType.GROUP_PENSION
    && targetAccount.usePersonalPensionInvestmentChoice === true

  let showAsGlobalDecision = false
  if (accounts?.length === 1) {
    showAsGlobalDecision = true
  }

  return {
    account: targetAccount,
    investmentReferenceAccount: usePersonalPensionInvestmentChoice ? personalPension : targetAccount,
    showAsGlobalDecision: false,
  }
}

export const getInvestmentChoiceData = (accounts: AccountDto[]): AccountInvestmentChoiceData[] => {
  const investmentChoiceData = accounts ? accounts.map(targetAccount => {
    return getInvestmentChoiceDataForAccount(targetAccount, accounts)     
  }) : []

  let showAsGlobalDecision = false
  if (investmentChoiceData.length === 1) {
    showAsGlobalDecision = true
  } else if (investmentChoiceData.length === 2) {
    const investmentReferenceAccounts = uniqBy(investmentChoiceData, function(data: AccountInvestmentChoiceData) {
      return data.investmentReferenceAccount.id
    })
    if (investmentReferenceAccounts.length === 1) {
      showAsGlobalDecision = true
    }
  }

  if (showAsGlobalDecision) {
    const dataToUse = investmentChoiceData[0]
    return [
      {
        account: dataToUse.investmentReferenceAccount,
        investmentReferenceAccount: dataToUse.investmentReferenceAccount,
        showAsGlobalDecision: true,
      }
    ]
  }

  return investmentChoiceData
}