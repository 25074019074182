import { MaterialCommunityIcons } from '@expo/vector-icons'
import { LightModal } from 'components/Layout'
import { ProcessHeaderTitle } from 'components/Typography'
import { envVariables } from 'environment'
import { Logger } from 'lib/logger'
import { truncate, intersection, isObject } from 'lodash'
import React, { ComponentProps, useRef, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Platform, StyleSheet, TextStyle, View, useWindowDimensions } from 'react-native'
import { GooglePlaceData, GooglePlaceDetail, GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete'
import { Portal, TextInput } from 'react-native-paper'
import { Address } from 'store/dto/base.dto'
import { Colors, Paper, Flex, Sizing, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { InputErrorMessages } from './InputErrorMessages'
import { buildAddressDescription } from 'lib/addressHelpers'

//Google Place Detail sub types
interface AddressComponent {
  long_name: string;
  short_name: string;
  types: PlaceType[];
}
type PlaceType =
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'administrative_area_level_3'
  | 'administrative_area_level_4'
  | 'administrative_area_level_5'
  | 'archipelago'
  | 'colloquial_area'
  | 'continent'
  | 'country'
  | 'establishment'
  | 'finance'
  | 'floor'
  | 'food'
  | 'general_contractor'
  | 'geocode'
  | 'health'
  | 'intersection'
  | 'locality'
  | 'natural_feature'
  | 'neighborhood'
  | 'place_of_worship'
  | 'plus_code'
  | 'point_of_interest'
  | 'political'
  | 'post_box'
  | 'postal_code'
  | 'postal_code_prefix'
  | 'postal_code_suffix'
  | 'postal_town'
  | 'premise'
  | 'room'
  | 'route'
  | 'street_address'
  | 'street_number'
  | 'sublocality'
  | 'sublocality_level_1'
  | 'sublocality_level_2'
  | 'sublocality_level_3'
  | 'sublocality_level_4'
  | 'sublocality_level_5'
  | 'subpremise'
  | 'town_square'

export type ManagedAddressInputProps = ComponentProps<typeof TextInput> & {
  formObj: UseFormReturn<any>
  googlePlacesApiKey: string
  name: string
  label?: string
  required: boolean,
  countryRestriction?: string
  onSelect: () => void
}

const findAndJoinAddressComponent = (addressComponents: AddressComponent[], findTypes: PlaceType[], dataField: 'short_name' | 'long_name'): string => {
  const matches = addressComponents.filter(component => {
    return intersection(component.types, findTypes).length
  })
  if (!matches.length) {
    return undefined
  }
  let output: string = ''
  matches.forEach((component, idx) => {
    output += component[dataField]
    if (idx < matches.length - 1) {
      output += ', '
    }
  })
  return output
}

export const createAddress = (data: GooglePlaceData, detail: GooglePlaceDetail): Address => {
  const building = truncate(findAndJoinAddressComponent(detail.address_components, ['street_number'], 'long_name'), 35)
  const street1 = truncate(findAndJoinAddressComponent(detail.address_components, ['route'], 'long_name'), 35)
  const town = truncate(findAndJoinAddressComponent(detail.address_components, ['postal_town'], 'long_name'), 35)
  const postCode = truncate(findAndJoinAddressComponent(detail.address_components, ['postal_code'], 'long_name'), 10)
  const country = truncate(findAndJoinAddressComponent(detail.address_components, ['country'], 'short_name'), 35)

  const address = {
    building,
    street1,
    town,
    postCode,
    country,
  }
  return address

}

export const ManagedAddressInput = (props: ManagedAddressInputProps) => {
  const { formObj, googlePlacesApiKey, onSelect, label, name, style, required, disabled, placeholder, countryRestriction } = props
  const { control, trigger, setValue, setFocus, watch, formState: { errors, isValid } } = formObj

  const { colors: themeColors } = Paper.useAppTheme()

  const [currentValue, setCurrentValue] = useState(undefined)
  const [showModal, setShowModal] = useState(null)

  const textInputRef = useRef()
  const addressTextInputRef = useRef()

  const { height, width } = useWindowDimensions()
  const suggestionListMaxHeight = height - Sizing.x50 - Sizing.x100

  const fieldErrors = errors[name]
  const hasError = fieldErrors ? true : false

  const textInputStyles: TextStyle = {
    flex: 1,
    width: '100%',
  }

  const textInputProps: ComponentProps<typeof TextInput> = {
    ...props,
    // theme: { colors: { text: themeColors.inputText } },
    style: [Typography.defined.input, textInputStyles, style],
    mode: 'flat',
    error: hasError,
  }

  const getBestReturnKeyType = (textInputProps: ComponentProps<typeof TextInput>, isValid: boolean) => {
    const { returnKeyType, keyboardType } = textInputProps
    //Always use 'done' for numeric keypad on iOS when valid
    if (Platform.OS === 'ios' && keyboardType === 'numeric') {
      return isValid ? 'done' : undefined
    }
    return returnKeyType ? returnKeyType : 'done'
  }

  const handleModalOpen = () => {
    setShowModal(true)
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  return (
    <View style={layoutStyles.inputContainer}>
      <Controller
        control={control}
        rules={{
          required: required || false,
        }}
        render={({ field: { onChange, onBlur, value } }) => {
          const addressDetail: Address = value
          if (addressDetail && isObject(addressDetail)) {
            setCurrentValue(buildAddressDescription(addressDetail, !countryRestriction) || '')
          }

          return (
            <>
              <View style={{ flex: 1 }}>
                <Portal>
                  <LightModal
                    visible={showModal}
                    dismissable={false}
                  >
                    <View style={{
                      ...Flex.column.start, paddingTop: Sizing.x10,
                    }}>
                      <View style={localStyles.headerContainer}>
                        <View style={localStyles.headerRow}>
                          <View style={localStyles.headerIconContainer}>
                            { disabled ? <></> :
                              <MaterialCommunityIcons name="home-search-outline" size={Sizing.x30} color={Colors.brand.grey2} />
                            }
                          </View>
                          <View style={localStyles.headerTitleContainer}>
                            <ProcessHeaderTitle>{'Find Address'}</ProcessHeaderTitle>
                          </View>
                          <View style={localStyles.headerIconContainer}>
                            <MaterialCommunityIcons name="close" size={Sizing.x30} onPress={handleModalClose} color={Colors.brand.grey2} />
                          </View>
                        </View>
                      </View>
                      <View style={{ flex: 1 }}>
                        <GooglePlacesAutocomplete
                          ref={addressTextInputRef}
                          placeholder={placeholder || 'Start typing your address...' }
                          minLength={2} // minimum length of text to search
                          fetchDetails={true}
                          keepResultsAfterBlur
                          keyboardShouldPersistTaps='always'
                          enablePoweredByContainer={false}
                          query={{
                            key: googlePlacesApiKey,
                            language: 'en', // language of the results
                            // types: 'address',
                            components: countryRestriction ? `country:${countryRestriction}` : undefined
                          }}
                          onPress={(data, details = null) => {
                            const address = createAddress(data, details)
                            onChange(address)
                            handleModalClose()
                            onSelect()
                          }}
                          onFail={(error) => Logger.error('GooglePlacesAutocomplete failed')}
                          textInputProps={{
                            InputComp: TextInput,
                            ...textInputProps,
                            //@ts-ignore
                            right: <TextInput.Icon size={Sizing.x20} icon={'close-circle'} color={Colors.brand.grey2} onPress={() => addressTextInputRef.current?.setAddressText('')} forceTextInputFocus={true} />,
                          }}
                          styles={{
                            listView: {
                              height: suggestionListMaxHeight,
                              marginTop: Sizing.x2,
                            },
                            row: {
                              ...Flex.column.center,
                              backgroundColor: Colors.brand.grey4,
                              height: Sizing.x60,
                            },
                            description: {
                              // textAlign: 'center',
                              ...Typography.defined.input,
                              color: Colors.brand.grey2,
                            }
                          }}
                        />
                      </View>
                    </View>
                  </LightModal>
                </Portal>

                <TextInput
                  placeholder={'Tap to find address...'}
                  label={label}
                  style={{
                    ...Typography.defined.input,
                  }}
                  theme={{ colors: { text: themeColors.inputText }}}
                  autoCorrect={false}
                  autoCapitalize={'none'}
                  ref={textInputRef}
                  returnKeyType={getBestReturnKeyType(textInputProps, isValid)}
                  value={currentValue}
                  onFocus={() => {
                    handleModalOpen()
                  }}
                  showSoftInputOnFocus={false}
                  right={
                    <TextInput.Icon size={Sizing.x20} icon={'home-search-outline'} color={themeColors.primary} onPress={handleModalOpen} forceTextInputFocus={false} />
                  }
                />

                <InputErrorMessages formObj={formObj} name={name} />
              </View>
            </>
          )
        }}
        name={name}
      />
    </View>
  )
}

const localStyles = StyleSheet.create({
  addressCardButtonContainer: {
    ...Flex.row.end
  },
  addressCardButton: {
    marginHorizontal: Sizing.x10,
  },
  headerContainer: {
    ...Flex.column.center,
    height: Sizing.x50,
  },
  headerRow: {
    alignContent: 'center',
    ...Flex.row.between,
    paddingHorizontal: Sizing.x10,
  },
  headerIconContainer: {
    ...Flex.column.center,
    width: Sizing.x50,
    alignItems: 'center'
  },
  headerTitleContainer: {
    ...Flex.column.center,
    flex: 1,
    paddingHorizontal: Sizing.x20,
  },
})