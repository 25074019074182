import { AddUsersModal } from 'features/ModalProcesses/AdminUserAdd/AddUsersModal'
import { AddAffiliatesModal } from 'features/ModalProcesses/AdminAffiliateAdd/AddAffiliatesModal'
import { AddBeneficiaryProcessStack } from 'features/ModalProcesses/BeneficiaryAdd/AddBeneficiaryProcessStack'
import { ClientEditModal } from 'features/ModalProcesses/ClientEdit/ClientEditModal'
import { RetirementAssetsAddAssetProcessStack } from 'features/ModalProcesses/RetirementAssetAdd/RetirementAssetsAddAssetProcessStack'
import { ChangeRetirementBudgetProcessStack } from 'features/ModalProcesses/RetirementBudgetChange/ChangeRetirementBudgetProcessStack'
import { RetirementIncomesAddIncomeProcessStack } from 'features/ModalProcesses/RetirementIncomeAdd/RetirementIncomesAddIncomeProcessStack'
import { RetirementTargetAgeEditModal } from 'features/ModalProcesses/RetirementTargetAgeEdit/RetirementTargetAgeEditModal'
import { SpouseAddProcessStack } from 'features/ModalProcesses/SpouseAdd/SpouseAddProcessStack'
import React from 'react'
import { View } from 'react-native'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  addBeneficiaryData,
  editBeneficiaryData,
  adjustBeneficiaryProportionData,
  addRetirementAssetClient,
  addRetirementIncomeClient,
  addSpouseVisible,
  addStatePensionClient,
  editAvatarClient,
  editCurrentUserVisible,
  editMeVisible,
  editRetirementAgeVisible,
  editRetirementAsset,
  editRetirementBudgetVisible,
  editRetirementIncome,
  editSpouseVisible,
  editStatePensionClient,
  makeContributionsData,
  prepareForTransferRetirementData,
  setMakeContributionsData,
  showAdminAddUserVisible,
  currentAdminUserDataId,
  currentAdminClientDataId,
  currentAdminTransferDataId,
  showAdminAddAffiliateVisible,
  currentAdminAffiliateDataId,
  showAdminAddAssetVisible,
  currentAdminAssetDataId,
  showAdminAddAssetCategoryVisible,
  currentAdminAssetCategoryDataId,
  showAdminAddInvestmentPlanVisible,
  currentAdminInvestmentPlanDataId,
  showAdminAddGroupPortfolioVisible,
  currentAdminGroupPortfolioDataId,
  showAdminAddGroupOrganizationVisible,
  currentAdminGroupOrganizationDataId,
  currentAdminGroupOrganizationSetRolesDataId,
  showAdminAddGroupSchemeVisible,
  currentAdminGroupSchemeDataId,
  currentAdminGroupSchemeSetRolesDataId,
  showAdminAddBankConnectionVisible,
  currentAdminBankConnectionDataId,
  currentAdminFeatureDataId,
  showAdminAddPensionBrandVisible,
  currentAdminPensionBrandDataId,
  showAdminAddPensionProviderVisible,
  currentAdminPensionProviderDataId,
  currentEmployerMemberDataId,
  currentEmployerPaymentDataId,
  currentEmployerRequestDataId,
  currentEmployerJobDataId,
  currentEmployerInviteDataId,
  showEmployerAddInviteVisible,
  showEmployerImportInvitesVisible,
  showEmployerAddEnrolmentJobVisible,
  showEmployerUpdateEnrolmentJobDataId,
  showEmployerAddNinoToEnrolmentJobDataId,
  showEmployerChangeEnrolmentStatusJobDataId,
  showEmployerAddContributionJobVisible,
  statementRequestVisible,
  currentAdminExceptionDataId,
} from 'store/uxSlice'
import { Flex, Paper } from 'styles'
import { AdminClientEditModal } from './AdminClientView/AdminClientEditModal'
import { AdminEditUsersModal } from './AdminUserEdit/AdminEditUsersModal'
import { AdminEditAffiliatesModal } from './AdminAffiliateEdit/AdminEditAffiliatesModal'
import { AdjustProportionModal } from './BeneficiaryAdjustProportion/AdjustProportionModal'
import { EditBeneficiaryEditModal } from './BeneficiaryEdit/EditBeneficiaryEditModal'
import { MakeContributionsProcessStack } from './MakeContributions/MakeContributionsProcessStack'
import { RetirementAssetsEditAssetModal } from './RetirementAssetEdit/RetirementAssetsEditAssetModal'
import { RetirementAssetTransferProcessStack } from './RetirementAssetTransfer/RetirementAssetTransferProcessStack'
import { RetirementIncomesEditIncomeModal } from './RetirementIncomeEdit/RetirementIncomesEditIncomeModal'
import { SpouseEditModal } from './SpouseEdit/SpouseEditModal'
import { StatementRequestProcessStack } from './StatementRequest/StatementRequestProcessStack'
import { RetirementIncomesAddStatePensionProcessStack } from './StatePensionAdd/RetirementIncomesAddStatePensionProcessStack'
import { RetirementIncomesEditStatePensionModal } from './StatePensionEdit/RetirementIncomesEditStatePensionModal'
import { ClientAvatarEditModal } from './ClientAvatarEdit/ClientAvatarEditModal'
import { AdminEditAssetsModal } from './AdminAssetEdit/AdminEditAssetsModal'
import { AdminEditAssetCategoriesModal } from './AdminAssetCategoryEdit/AdminEditAssetCategoriesModal'
import { AddAssetsModal } from './AdminAssetAdd/AddAssetsModal'
import { AddInvestmentPlansModal } from './AdminInvestmentPlanAdd/AddInvestmentPlansModal'
import { AdminEditInvestmentPlansModal } from './AdminInvestmentPlanEdit/AdminEditInvestmentPlansModal'
import { AddGroupPortfoliosModal } from './AdminGroupPortfolioAdd/AddGroupPortfoliosModal'
import { AdminEditGroupPortfoliosModal } from './AdminGroupPortfolioEdit/AdminEditGroupPortfoliosModal'
import { AddGroupOrganizationsModal } from './AdminGroupOrganizationAdd/AddGroupOrganizationsModal'
import { AdminEditGroupOrganizationsModal } from './AdminGroupOrganizationEdit/AdminEditGroupOrganizationsModal'
import { AddGroupSchemesModal } from './AdminGroupSchemeAdd/AddGroupSchemesModal'
import { AdminEditGroupSchemesModal } from './AdminGroupSchemeEdit/AdminEditGroupSchemesModal'
import { AddAssetCategoriesModal } from './AdminAssetCategoryAdd/AddAssetCategoriesModal'
import { AdminTransferEditModal } from './AdminTransferEdit/AdminTransferEditModal'
import { AddBankConnectionsModal } from './AdminBankConnectionAdd/AddBankConnectionsModal'
import { AdminEditBankConnectionsModal } from './AdminBankConnectionEdit/AdminEditBankConnectionsModal'
import { AdminFeatureEditModal } from './AdminFeatureEdit/AdminFeatureEditModal'
import { AddPensionBrandsModal } from './AdminPensionBrandAdd/AddPensionBrandsModal'
import { AdminEditPensionBrandsModal } from './AdminPensionBrandEdit/AdminEditPensionBrandsModal'
import { AddPensionProvidersModal } from './AdminPensionProviderAdd/AddPensionProvidersModal'
import { AdminEditPensionProvidersModal } from './AdminPensionProviderEdit/AdminEditPensionProvidersModal'
import { UserEditModal } from './UserEdit/UserEditModal'
import { EmployerPaymentEditModal } from './EmployerPaymentView/EmployerPaymentEditModal'
import { EmployerRequestEditModal } from './EmployerRequestView/EmployerRequestEditModal'
import { EmployerJobEditModal } from './EmployerJobView/EmployerJobEditModal'
import { EmployerInviteEditModal } from './EmployerInviteView/EmployerInviteEditModal'
import { EmployerAddInviteModal } from './EmployerAddInvite/EmployerAddInviteModal'
import { AdminExceptionEditModal } from './AdminExceptionEdit/AdminExceptionEditModal'
import { EmployerImportInvitesModal } from './EmployerImportInvites/EmployerImportInvitesModal'
import { EmployerAddEnrolmentJobModal } from './EmployerAddEnrolmentJob/EmployerAddEnrolmentJobModal'
import { EmployerUpdateEnrolmentJobModal } from './EmployerUpdateEnrolmentJob/EmployerUpdateEnrolmentJobModal'
import { EmployerChangeEnrolmentStatusJobModal } from './EmployerChangeEnrolmentStatusJob/EmployerChangeEnrolmentStatusJobModal'
import { EmployerAddContributionJobModal } from './EmployerAddContributionJob/EmployerAddContributionJobModal'
import { EmployerMemberViewModal } from './EmployerMemberView/EmployerMemberViewModal'
import { AdminGroupOrganizationSetUserRolesModal } from './AdminGroupOrganizationSetUserRoles/AdminGroupOrganizationSetUserRolesModal'
import { AdminGroupSchemeSetUserRolesModal } from './AdminGroupSchemeSetUserRoles/AdminGroupSchemeSetUserRolesModal'
import { EmployerAddNinoToEnrolmentJobModal } from './EmployerAddNinoToEnrolmentJob/EmployerAddNinoToEnrolmentJobModal'

export const ModalProcesses = () => {
  const dispatch = useAppDispatch()

  //Beneficiaries
  const addBeneficiary = useAppSelector(addBeneficiaryData)
  const editBeneficiary = useAppSelector(editBeneficiaryData)
  const adjustBeneficiary = useAppSelector(adjustBeneficiaryProportionData)
  const beneficiaryView = addBeneficiary || editBeneficiary || adjustBeneficiary

  //Account
  const avatarClient = useAppSelector(editAvatarClient)

  const editCurrentUser = useAppSelector(editCurrentUserVisible)
  const editMe = useAppSelector(editMeVisible)
  const addSpouse = useAppSelector(addSpouseVisible)
  const editSpouse = useAppSelector(editSpouseVisible)
  const accountView = avatarClient || editCurrentUser || editMe || addSpouse || editSpouse

  //Admin
  const showAdminAddUser = useAppSelector(showAdminAddUserVisible)
  const currentAdminUserVisible = useAppSelector(currentAdminUserDataId)
  const currentAdminClientVisible = useAppSelector(currentAdminClientDataId)
  const currentAdminTransferVisible = useAppSelector(currentAdminTransferDataId)
  const currentAdminAffiliateVisible = useAppSelector(currentAdminAffiliateDataId)
  const showAdminAddAffiliate = useAppSelector(showAdminAddAffiliateVisible)
  const showAdminAddAsset = useAppSelector(showAdminAddAssetVisible)
  const currentAdminAssetVisible = useAppSelector(currentAdminAssetDataId)
  const showAdminAddAssetCategory = useAppSelector(showAdminAddAssetCategoryVisible)
  const currentAdminAssetCategoryVisible = useAppSelector(currentAdminAssetCategoryDataId)
  const showAdminAddInvestmentPlan = useAppSelector(showAdminAddInvestmentPlanVisible)
  const currentAdminInvestmentPlanVisible = useAppSelector(currentAdminInvestmentPlanDataId)
  const showAdminAddGroupPortfolio = useAppSelector(showAdminAddGroupPortfolioVisible)
  const currentAdminGroupPortfolioVisible = useAppSelector(currentAdminGroupPortfolioDataId)
  const showAdminAddGroupOrganization = useAppSelector(showAdminAddGroupOrganizationVisible)
  const currentAdminGroupOrganizationVisible = useAppSelector(currentAdminGroupOrganizationDataId)
  const currentAdminGroupOrganizationSetRolesVisible = useAppSelector(currentAdminGroupOrganizationSetRolesDataId)
  const showAdminAddGroupScheme = useAppSelector(showAdminAddGroupSchemeVisible)
  const currentAdminGroupSchemeVisible = useAppSelector(currentAdminGroupSchemeDataId)
  const currentAdminGroupSchemeSetRolesVisible = useAppSelector(currentAdminGroupSchemeSetRolesDataId)
  const currentAdminBankConnectionVisible = useAppSelector(currentAdminBankConnectionDataId)
  const showAdminAddBankConnection = useAppSelector(showAdminAddBankConnectionVisible)
  const currentAdminFeatureVisible = useAppSelector(currentAdminFeatureDataId)
  const currentAdminPensionBrandVisible = useAppSelector(currentAdminPensionBrandDataId)
  const showAdminAddPensionBrand = useAppSelector(showAdminAddPensionBrandVisible)
  const currentAdminPensionProviderVisible = useAppSelector(currentAdminPensionProviderDataId)
  const showAdminAddPensionProvider = useAppSelector(showAdminAddPensionProviderVisible)
  const currentAdminExceptionVisible = useAppSelector(currentAdminExceptionDataId)
  const adminView =
    currentAdminUserVisible
    || showAdminAddUser
    || currentAdminClientVisible
    || currentAdminTransferVisible
    || showAdminAddAffiliate
    || currentAdminAffiliateVisible
    || showAdminAddAsset
    || currentAdminAssetVisible
    || showAdminAddAssetCategory
    || currentAdminAssetCategoryVisible
    || showAdminAddInvestmentPlan
    || currentAdminInvestmentPlanVisible
    || showAdminAddGroupPortfolio
    || currentAdminGroupPortfolioVisible
    || showAdminAddGroupOrganization
    || currentAdminGroupOrganizationVisible
    || currentAdminGroupOrganizationSetRolesVisible
    || showAdminAddGroupScheme
    || currentAdminGroupSchemeVisible
    || currentAdminGroupSchemeSetRolesVisible
    || showAdminAddBankConnection
    || currentAdminBankConnectionVisible
    || currentAdminFeatureVisible
    || showAdminAddPensionBrand
    || currentAdminPensionBrandVisible
    || showAdminAddPensionProvider
    || currentAdminPensionProviderVisible
    || currentAdminExceptionVisible

  //Employer
  const currentEmployerMemberVisible = useAppSelector(currentEmployerMemberDataId)
  const currentEmployerPaymentVisible = useAppSelector(currentEmployerPaymentDataId)
  const currentEmployerRequestVisible = useAppSelector(currentEmployerRequestDataId)
  const currentEmployerJobVisible = useAppSelector(currentEmployerJobDataId)
  const currentEmployerInviteVisible = useAppSelector(currentEmployerInviteDataId)
  const showEmployerAddInvite = useAppSelector(showEmployerAddInviteVisible)
  const showEmployerImportInvites = useAppSelector(showEmployerImportInvitesVisible)
  const showEmployerAddEnrolmentJob = useAppSelector(showEmployerAddEnrolmentJobVisible)
  const showEmployeUpdateEnrolmentJob = useAppSelector(showEmployerUpdateEnrolmentJobDataId)
  const showEmployeAddNinoToEnrolmentJob = useAppSelector(showEmployerAddNinoToEnrolmentJobDataId)
  const showEmployeEndEnrolmentJob = useAppSelector(showEmployerChangeEnrolmentStatusJobDataId)
  const showEmployerAddContributionJob = useAppSelector(showEmployerAddContributionJobVisible)
  
  const employerView =
  currentEmployerMemberVisible || currentEmployerPaymentVisible || currentEmployerRequestVisible || currentEmployerJobVisible || currentEmployerInviteVisible || showEmployerAddInvite || showEmployerAddEnrolmentJob || showEmployeUpdateEnrolmentJob || showEmployeAddNinoToEnrolmentJob || showEmployeEndEnrolmentJob || showEmployerAddContributionJob || showEmployerImportInvites

  //Budget
  const showEditRetirementBudget = useAppSelector(editRetirementBudgetVisible)
  const budgetView = showEditRetirementBudget

  //Retirement Profile
  const showEditRetirementAge = useAppSelector(editRetirementAgeVisible)
  const retirementProfileView = showEditRetirementAge

  //Retirement Assets
  const addAssetClient = useAppSelector(addRetirementAssetClient)
  const editAsset = useAppSelector(editRetirementAsset)
  const prepareForTransferAssetData = useAppSelector(prepareForTransferRetirementData)
  const retirementAssetsView = addAssetClient || editAsset || prepareForTransferAssetData

  //Retirement Incomes
  const addIncomeClient = useAppSelector(addRetirementIncomeClient)
  const editIncome = useAppSelector(editRetirementIncome)
  const addSpClient = useAppSelector(addStatePensionClient)
  const editSpClient = useAppSelector(editStatePensionClient)
  const retirementIncomesView = addIncomeClient || editIncome || addSpClient || editSpClient

  //Contributions
  const makeContributions = useAppSelector(makeContributionsData)
  const contributionsView = makeContributions

  //Other
  const statementRequest = useAppSelector(statementRequestVisible)
  const otherView = statementRequest

  const showModal = beneficiaryView || budgetView || accountView || adminView || employerView || retirementIncomesView || retirementAssetsView || retirementProfileView || otherView || contributionsView
  return (
    <View>
      {showModal ?
        <View style={{ ...Flex.column.start }}>
          {
            //Beneficaries
            addBeneficiary ? <AddBeneficiaryProcessStack />
              : editBeneficiary ? <EditBeneficiaryEditModal />
                : adjustBeneficiary ? <AdjustProportionModal />
                    //Account/Client/Spouse
                    : avatarClient ? <ClientAvatarEditModal />
                    : editCurrentUser ? <UserEditModal />
                    : editMe ? <ClientEditModal />
                        : addSpouse ? <SpouseAddProcessStack />
                          : editSpouse ? <SpouseEditModal />
                                //Admin
                                : showAdminAddUser ? <AddUsersModal />
                                : currentAdminUserVisible ? <AdminEditUsersModal />
                                : currentAdminClientVisible ? <AdminClientEditModal />
                                : currentAdminTransferVisible ? <AdminTransferEditModal />
                                : showAdminAddAffiliate ? <AddAffiliatesModal />
                                : currentAdminAffiliateVisible ? <AdminEditAffiliatesModal />
                                : showAdminAddAsset ? <AddAssetsModal />
                                : currentAdminAssetVisible ? <AdminEditAssetsModal />
                                : showAdminAddAssetCategory ? <AddAssetCategoriesModal />
                                : currentAdminAssetCategoryVisible ? <AdminEditAssetCategoriesModal />
                                : showAdminAddInvestmentPlan ? <AddInvestmentPlansModal />
                                : currentAdminInvestmentPlanVisible ? <AdminEditInvestmentPlansModal />
                                : showAdminAddGroupPortfolio ? <AddGroupPortfoliosModal />
                                : currentAdminGroupPortfolioVisible ? <AdminEditGroupPortfoliosModal />
                                : showAdminAddGroupOrganization ? <AddGroupOrganizationsModal />
                                : currentAdminGroupOrganizationVisible ? <AdminEditGroupOrganizationsModal />
                                : currentAdminGroupOrganizationSetRolesVisible ? <AdminGroupOrganizationSetUserRolesModal />
                                : showAdminAddGroupScheme ? <AddGroupSchemesModal />
                                : currentAdminGroupSchemeVisible ? <AdminEditGroupSchemesModal />
                                : currentAdminGroupSchemeSetRolesVisible ? <AdminGroupSchemeSetUserRolesModal />
                                : showAdminAddBankConnection ? <AddBankConnectionsModal />
                                : currentAdminBankConnectionVisible ? <AdminEditBankConnectionsModal />
                                : currentAdminFeatureVisible ? <AdminFeatureEditModal />
                                : showAdminAddPensionBrand ? <AddPensionBrandsModal />
                                : currentAdminPensionBrandVisible ? <AdminEditPensionBrandsModal />
                                : showAdminAddPensionProvider ? <AddPensionProvidersModal />
                                : currentAdminPensionProviderVisible ? <AdminEditPensionProvidersModal />
                                : currentAdminExceptionVisible ? <AdminExceptionEditModal />
                                //Employer
                                : currentEmployerMemberVisible ? <EmployerMemberViewModal />
                                : currentEmployerPaymentVisible ? <EmployerPaymentEditModal />
                                : currentEmployerRequestVisible ? <EmployerRequestEditModal />
                                : currentEmployerJobVisible ? <EmployerJobEditModal />
                                : currentEmployerInviteVisible ? <EmployerInviteEditModal />
                                : showEmployerAddInvite ? <EmployerAddInviteModal />
                                : showEmployerAddEnrolmentJob ? <EmployerAddEnrolmentJobModal />
                                : showEmployeUpdateEnrolmentJob ? <EmployerUpdateEnrolmentJobModal />
                                : showEmployeAddNinoToEnrolmentJob ? <EmployerAddNinoToEnrolmentJobModal />
                                : showEmployeEndEnrolmentJob ? <EmployerChangeEnrolmentStatusJobModal />
                                : showEmployerAddContributionJob ? <EmployerAddContributionJobModal />
                                : showEmployerImportInvites ? <EmployerImportInvitesModal />
                                //Retirement Profile
                                : showEditRetirementAge ? <RetirementTargetAgeEditModal />
                                : showEditRetirementBudget ? <ChangeRetirementBudgetProcessStack />
                                //Retirement Assets
                                : addAssetClient ? <RetirementAssetsAddAssetProcessStack client={addAssetClient}/>
                                : editAsset ? <RetirementAssetsEditAssetModal asset={editAsset}/>
                                : prepareForTransferAssetData ? <RetirementAssetTransferProcessStack asset={prepareForTransferAssetData.asset} client={prepareForTransferAssetData.client}/>
                                //Retirement Incomes
                                : addIncomeClient ? <RetirementIncomesAddIncomeProcessStack client={addIncomeClient}/>
                                : editIncome ? <RetirementIncomesEditIncomeModal income={editIncome}/>
                                : addSpClient ? <RetirementIncomesAddStatePensionProcessStack client={addSpClient}/>
                                : editSpClient ? <RetirementIncomesEditStatePensionModal client={editSpClient}/>
                                //Other
                                : statementRequest ? <StatementRequestProcessStack />
                                //Contributions
                                : makeContributions ? <MakeContributionsProcessStack contributionsData={makeContributions} onCompletion={() => dispatch(setMakeContributionsData(undefined))}/>
                                : <></>
          }
        </View>
        : <></>
      }
    </View>
  )
}

