import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedAutoCompleteMultipleInput } from 'components/Inputs/ManagedAutoCompleteMultipleInput'
import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph } from 'components/Typography'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { getAffliliateAutoCompleteOptions } from 'lib/referenceDataHelpers'
import { orderBy, startCase } from 'lodash'
import { default as React, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useAddUserMutation, useGetAffiliatesQuery, useGetCurrentUserQuery, useGetFeaturesQuery, useGetGroupOrganizationsQuery } from 'store/apiSlice'
import { AffiliateDto } from 'store/dto/affiliate.dto'
import { Gender, Title } from 'store/dto/base.dto'
import { FeatureStatus } from 'store/dto/feature.dto'
import { CreateUserDto } from 'store/dto/user.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddUserVisible, showAdminAddUserVisible } from 'store/uxSlice'
import { Colors, Flex, Sizing } from 'styles'

export enum NewUserBlockType {
  BLOCKED = 'Blocked',
  NOT_BLOCKED = 'Unblocked',
  NEEDS_DECISION = 'Hold'
}

export const AddUsersModal = () => {  return (
  <ModalEditWrap
    screen={<ScreenContent />}
  />
)
}

const ScreenContent = () => {
    const dispatch = useAppDispatch()
    const visible = useAppSelector(showAdminAddUserVisible)

    const [addUserMutation, { data: addedUser, isLoading: addUserIsLoading, error: addUserError, reset: addUserReset }] = useAddUserMutation()
    const { data: adminUser, isLoading: adminUserIsLoading, error: adminUserError } = useGetCurrentUserQuery()
    const { data: affiliates, isLoading: affiliatesIsLoading, error: affiliatesError } = useGetAffiliatesQuery()
    const affiliateOptions = getAffliliateAutoCompleteOptions(affiliates)
  
    const { data: features, isLoading: featuresIsLoading, error: featuresError, refetch: refetchFeatures } = useGetFeaturesQuery()
    const { data: organizations, isLoading: organizationsIsLoading, error: organizationsError, refetch: refetchGroupOrganizations } = useGetGroupOrganizationsQuery()
  
    //Show required fields when brands, orgs and portfolios loaded
    useEffect(() => {
      if (affiliates && features && organizations) {
        trigger()
      }
    }, [affiliates, features, organizations])
    const getInternalAffiliate = () => {
      if(!affiliates) { return undefined }
      return affiliates.find(affiliate => {
        return affiliate.isInternal
      })
    }
  
    const formObj = useForm<{
      affiliateId: string,
      affiliateRef: string,
      email: string,
      blockType: NewUserBlockType,
      overrideFeatureCodes: string[]
      groupOrganizationIds: string[]
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    })

    const { handleSubmit, setValue, trigger, reset, watch, formState: { isDirty, isValid } } = formObj
  
    //Form refs for focussing
    const firstNameRef = useRef(null)
    const surnameRef = useRef(null)
  
    //Set affiliate when loaded
    useEffect(() => {
      if (affiliates) {
        reset({
          affiliateId: getInternalAffiliate()?.id,
          affiliateRef: 'Admin Invite',
          blockType: NewUserBlockType.NOT_BLOCKED,
          overrideFeatureCodes: [],
          groupOrganizationIds: [],
        })
      }
    }, [affiliates])
  
    const onSubmit = async attributes => {
      const selectedAffiliate: AffiliateDto = affiliates && attributes?.affiliateId ? affiliates.find(affiliate => {
        return affiliate.id === attributes?.affiliateId
      }) : undefined
      const data: CreateUserDto = {
        email: attributes.email,
        affiliateCode: selectedAffiliate?.code,
        affiliateRef: selectedAffiliate ? adminUser?.id : undefined,
        overrideFeatureCodes: attributes.overrideFeatureCodes,
        groupOrganizationIds: attributes.groupOrganizationIds,
        isBlocked:
          attributes.blockType === NewUserBlockType.BLOCKED
            ? true
            : attributes.blockType === NewUserBlockType.NOT_BLOCKED
              ? false
              : undefined,
        metadata: {
          firstName: attributes.firstName,
          surname: attributes.surname,
          title: attributes.title,
          gender: attributes.gender
        }
      }
      addUserMutation(data)
    }
  
    useEffect(() => {
      if (addedUser) {
        close()
      }
    }, [addedUser])
  
    const close = () => {
      dispatch(setShowAdminAddUserVisible(false))
    }
  
    const isLoading = adminUserIsLoading || addUserIsLoading || affiliatesIsLoading || featuresIsLoading || organizationsIsLoading
    const error: any = adminUserError || addUserError || affiliatesError || featuresError || organizationsError
  
    const affiliateWatch = watch('affiliateId')
  
    useEffect(() => {
      if (affiliateWatch) {
        trigger('affiliateRef')
      }
    }, [affiliateWatch])
  
    const overrideFeatureOptions: ManagedMultipleChoiceInputOption[] = features ? features.map(feature => {
      const actionDescription =
        feature.status === FeatureStatus.OPT_IN ? 'Select to enable for user' :
        feature.status === FeatureStatus.OPT_OUT ? 'Select to disable for user' :
        'Setting not relevant'
      return {
        value: feature.code,
        label: `${feature.name} (${startCase(feature.status)} - ${actionDescription})`,
      }
    }) : []
  
    const organizationOptions: AutoCompleteItem[] = organizations ? orderBy(organizations, ['name'], ['asc']).map(organization => {
      return {
        value: organization?.id,
        label: organization?.name,
        icon: organization?.logo
        ? () => <Image source={{ uri: organization?.logo}} style={{
          width: Sizing.x40,
          height: Sizing.x40,
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
        : () => <View style={{
            ...Flex.column.center,
            alignItems: 'center',
          }}>
            <MaterialCommunityIcons name={'office-building'} size={Sizing.x30} color={Colors.neutral.black} />
        </View>
      }
  
    }) : []
  
    return (
      <ModalEditScreen
        formTitle='Add/invite New User'
        onDismiss={() => dispatch(setShowAdminAddUserVisible(false))}
        isDirty={isDirty}
        dismissDialogText={'Discard new user?'}
        error={error}
        errorTryAgain={addUserError ? handleSubmit(onSubmit) : featuresError ? refetchFeatures : refetchGroupOrganizations}
        errorCancel={addUserError ? addUserReset : close}
        isLoading={isLoading}
        loadingMessage={addUserIsLoading ? ['Saving user...'] : undefined}
        buttonTitle={'Create User'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        <ManagedAutoCompleteInput
          name={'affiliateId'}
          formObj={formObj}
          label={'Affiliate'}
          required={false}
          dataSet={affiliateOptions}
        />
        <ManagedTextInput
          name={'email'}
          keyboardType='email-address'
          formObj={formObj}
          label={'Email Address'}
          placeholder={'Their email address'}
          autoCapitalize={'none'}
          autoCorrect={false}
          autoComplete={'email'}
          returnKeyType={'next'}
          submitHandler={() => firstNameRef.current?.focus()}
          blurOnSubmit={false}
          autoFocus={true}
          rules={{
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Invalid email address"
            },
            required: true,
            minLength: 2,
            maxLength: 40,
          }} />
        <ManagedTextInput
          ref={firstNameRef}
          name={'firstName'}
          formObj={formObj}
          label={'First Name'}
          placeholder={'Their legal first name'}
          returnKeyType={'next'}
          submitHandler={() => surnameRef.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 40,
          }} />
        <ManagedTextInput
          ref={surnameRef}
          name={'surname'}
          formObj={formObj}
          label={'Last Name'}
          placeholder={'Their legal surname'}
          returnKeyType={'next'}
          blurOnSubmit={true}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 40,
          }} />
        <ManagedAutoCompleteInput
          name={'title'}
          formObj={formObj}
          label={'Title'}
          selectOnlyMode={true}
          dataSet={enumToAutocompleteOptions(Title)}
          required={false}
        />
        <ManagedAutoCompleteInput
          required={false}
          formObj={formObj}
          name={'gender'}
          label={'Gender'}
          selectOnlyMode={true}
          dataSet={enumToAutocompleteOptions(Gender)}
        />
        <ManagedAutoCompleteInput
          name={'blockType'}
          formObj={formObj}
          label={'Blocked Status'}
          selectOnlyMode={true}
          required={true}
          dataSet={enumToAutocompleteOptions(NewUserBlockType)}
        />
        <Paragraph>{'Group Organisations'}</Paragraph>
        <ManagedAutoCompleteMultipleInput
          name={'groupOrganizationIds'}
          formObj={formObj}
          label={'Organisation Access (as Standard User)'}
          modalTitle={'Select Group Organisations'}
          placeholder={'Organisations the user can access as a standard user'}
          searchPlaceholder={'Search for group organisations'}
          dataSet={organizationOptions}
          required={false}
        />
        <Paragraph>{'Feature Override'}</Paragraph>
        <ManagedMultipleChoiceInput
          formObj={formObj}
          name={'overrideFeatureCodes'}
          options={overrideFeatureOptions}
        />
  
      </ModalEditScreen>
    )
  }
  
  