import { Dimensions, PixelRatio, Platform } from "react-native";
import { min, mean } from 'lodash'
import { platformIsWeb } from "./platformHelpers";
import { MIN_WIDTH_FOR_PERMANENT_MENU } from "./constants";
import { Orientation } from 'expo-screen-orientation'

const isWeb = platformIsWeb()
  
export const scaleNormalizer=(size: number)=> {
  return Math.round(PixelRatio.roundToNearestPixel(size * scale))
}
  
export const scaleUnnormalizer=(size: number)=> {
  return Math.round(PixelRatio.roundToNearestPixel(size / scale))
}

export const getScreenAppWidth = () => {
  return Platform.select({
    web: min([screenWidth, webMaxWidth]),
    default: screenWidth,
  })
}

export const getScreenAppHeight = () => {
  return Platform.select({
    web: min([screenHeight, webMaxHeight]),
    default: screenHeight,
  })
}

export const getScreenSizeConstraints = () => {
  return {
    ...getScreenWidthConstraints(),
    ...getScreenHeightConstraints(),
  }
}

export const getScreenWidthConstraints = () => {
  return Platform.select({
    web: {
      alignSelf: 'center',
      maxWidth: min([screenWidth, webMaxWidth]),
    },
    default: {},
  })
}

export const getScreenHeightConstraints = () => {
  return Platform.select({
    web: {
      maxHeight: min([screenHeight, webMaxHeight]),
    },
    default: {},
  })
}

export const isNarrowWebScreen = () => {
  return narrowWebScreen
}

export const isShallowWebScreen = () => {
  return shallowWebScreen
}


// export const isShallowScreen = () => {
//   return getScreenAppHeight() < 650
// }

const {
  width: screenWidth,
  height: screenHeight
} = Dimensions.get('window')


// based on iphone13 mini's scale (for width)
const referenceWidth = 375
const referenceHeight = 770

const webMinWidthForScaling = 600
const webMinHeightForScaling = 750

const maxAllowedScale = 1.5

const narrowWebScreen = screenWidth < webMinWidthForScaling
const shallowWebScreen = screenHeight < webMinHeightForScaling

const webHorizontalScale = narrowWebScreen ? screenWidth / webMinWidthForScaling : 1
const webVerticalScale = shallowWebScreen ? screenHeight / webMinHeightForScaling : 1

const horizontalScale = Platform.OS === 'web' ? webHorizontalScale : screenWidth / referenceWidth
const verticalScale = Platform.OS === 'web' ? webVerticalScale : screenHeight / referenceHeight
const scale = min([mean([horizontalScale, verticalScale]), maxAllowedScale])

export const getHorizontalScale = () => {
  return horizontalScale
}

export const getVerticalScale = () => {
  return verticalScale
}

export const getScale = () => {
  return scale
}

export const webMaxWidth = isWeb ? scaleNormalizer(1200) : undefined
export const webMaxHeight = isWeb ? scaleNormalizer(1000) : undefined

const normalization = {
  screenWidth,
  screenHeight,
  referenceWidth,
  referenceHeight,
  webMaxWidth,
  webMaxHeight,
  webHorizontalScale,
  webVerticalScale,
  horizontalScale,
  verticalScale,
  maxAllowedScale,
  scale,
}

export const getAllNormalizationParams = () => {
  return normalization
}

export const deriveDrawerType = (orientation: Orientation): 'front' | 'permanent' | 'slide' | 'back' => {
  return (
    isWeb
      ? screenWidth < MIN_WIDTH_FOR_PERMANENT_MENU
        ? 'front'
        : 'permanent'
    : [Orientation.LANDSCAPE_LEFT, Orientation.LANDSCAPE_RIGHT].includes(orientation)
      ? 'permanent'
      : 'slide'
  )
}

