import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { Button } from 'components/Utility/Button'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { addDays, addYears } from 'date-fns'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { formatUkDate, getActionDate } from 'lib/dateHelpers'
import { mapMemberToEnrolmentJobMember } from 'lib/enrolmentHelpers'
import { userCanManageJobsForScheme } from 'lib/groupSchemeHelpers'
import { EmployerAppNavScreen } from 'lib/navigationHelpers'
import { employerAppNavigate } from 'lib/RootNavigation'
import { default as React, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Paragraph } from 'react-native-paper'
import { useAddGroupSchemeJobWithDataMutation, useGetGroupSchemeMemberQuery } from 'store/apiSlice'
import { CreateGroupSchemeJobMemberDto, GroupSchemeJobType } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentEmployerMemberDataId, setShowEmployerUpdateEnrolmentJobDataId, setSnackbarData, showEmployerUpdateEnrolmentJobDataId } from 'store/uxSlice'
import { Paper } from 'styles'

export const EmployerUpdateEnrolmentJobModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
    const dispatch = useAppDispatch()
 
    const [noOptOutWindowDateMode, setNoOptOutWindowMode] = useState(false)
    const [showNoOptOutWindowTransitionDialog, setShowNoOptOutWindowTransitionDialog] = useState(false)
    const [showDialog, setShowDialog] = useState(false)

    const currentScheme = useAppSelector(currentGroupScheme)
  
    const userCanManageJobs = userCanManageJobsForScheme(currentScheme)
  
    const currentMemberId = useAppSelector(showEmployerUpdateEnrolmentJobDataId)
  
    const { isLoading: memberIsLoading, error: memberError, data: member, refetch: refetchMember } = useGetGroupSchemeMemberQuery({ memberId: currentMemberId, id: currentScheme.id }, { skip: !currentMemberId } )
  
    const { client } = member || {}
    const { firstName, surname, title, gender, birthDate, nationalInsuranceNo, addressDetail } = client || {}
  
    const [addMemberJob, { data: addedJob, isLoading: addJobIsLoading, error: addJobError, reset: addJobReset }] = useAddGroupSchemeJobWithDataMutation()
    
    const formObj = useForm<{
      employeeEmail: string
      employeeId?: string
      employmentStartDate?: string
      enrolmentDate?: string
      autoEnrolmentWindowOptOutDate?: string
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    })
  
    const { handleSubmit, setValue, setError, reset, trigger, watch, formState: { isDirty, isValid } } = formObj
  
    useEffect(() => {
      if (member) {
        reset({
          employeeEmail: member?.employeeEmail,
          employeeId: member?.employeeId,
          employmentStartDate: member?.employmentStartDate,
          enrolmentDate: member?.enrolmentDate,
          autoEnrolmentWindowOptOutDate: member?.autoEnrolmentWindowOptOutDate,
        })
      }
    }, [member])
  
    //Form refs for focussing
    const employeeEmailRef = useRef(null)
    const employeeIdRef = useRef(null)
    const employmentStartDateRef = useRef(null)
    const enrolmentDateRef = useRef(null)
    const autoEnrolmentWindowOptOutDateRef = useRef(null)
  
    const onSubmit = async attributes => {
      const { autoEnrolmentWindowOptOutDate } = attributes
      const memberUpdate = {
        ...member,
        ...attributes,
        autoEnrolmentWindowOptOutDate: noOptOutWindowDateMode ? undefined : autoEnrolmentWindowOptOutDate,
      }
      const data: CreateGroupSchemeJobMemberDto = {
        jobType: GroupSchemeJobType.MEMBER,
        groupSchemeId: currentScheme.id,
        memberRecords: [mapMemberToEnrolmentJobMember(memberUpdate)]
      }
      addMemberJob(data)
    }

    const autoEnrolmentWindowOptOutDate = watch('autoEnrolmentWindowOptOutDate')
    const enrolmentDate = watch('enrolmentDate')

    //Trigger validation when noOptOutWindowDateMode changes
    useEffect(() => {
      trigger()
    }, [noOptOutWindowDateMode])
  
    useEffect(() => {
      if (addedJob) {
        dispatch(setSnackbarData({
          message: `Member Update Enrolment Job added!`,
          subMessage: `We'll send you a message when it has been processed${addedJob?.expectedCompleteAt ? ` (estimated completion at ${getActionDate(addedJob?.expectedCompleteAt )})` : ``}`,
          iconName: 'check-circle-outline',
          duration: 5000,
        }))
        closeAndGoToJobs()
      }
    }, [addedJob])

    const handleNoOptOutWindowTransitionConfirm = () => {
      setShowNoOptOutWindowTransitionDialog(false)
      setNoOptOutWindowMode(true)
    }
  
    const close = () => {
      dispatch(setShowEmployerUpdateEnrolmentJobDataId(undefined))
    }
    
    const goToMemberView = () => {
      dispatch(setShowEmployerUpdateEnrolmentJobDataId(undefined))
      dispatch(setCurrentEmployerMemberDataId(currentMemberId))
    }
  
    const closeAndGoToJobs = () => {
      employerAppNavigate(EmployerAppNavScreen.ENROLMENTS)
      close()
    }
  
    const isLoading = memberIsLoading || addJobIsLoading
    const error: any = memberError || addJobError
  
    const { colors: themeColors } = Paper.useAppTheme()
  
    const newEmail = watch('employeeEmail')
    const emailChanged = newEmail !== member?.employeeEmail
  
    return (
      <ModalEditScreen
        formTitle={'Update Employment Details'}
        onDismiss={() => dispatch(setShowEmployerUpdateEnrolmentJobDataId(undefined))}
        isDirty={isDirty}
        dismissDialogText={'Discard employment changes for this member?'}
        error={error}
        errorTryAgain={addJobError ? handleSubmit(onSubmit) : refetchMember}
        errorCancel={addJobError ? addJobReset : close}
        isLoading={isLoading}
        loadingMessage={['Creating enrolment job...']}
        buttonTitle={'Submit Change'}
        buttonAction={handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid && userCanManageJobs}
        allowTextButton={true}
        textButtonTitle={'Return to Member Details'}
        textButtonAction={isDirty ? () => setShowDialog(true) : goToMemberView}
        footerInfo={userCanManageJobs ? undefined : <Text>{`You do not have permissions to update enrolled members.`}</Text>}
      >
        <ContentDivider />
        <Subheading style={{ color: themeColors.primary }}>{`Member Identification`}</Subheading>
        <UnborderedTable
          data={[
            {
              label: `Jarvis Identifier`,
              value: member?.id,
              copyableValue: member?.id,
            },
          ]}
          noContentDivider={true}
        />

        <ContentDivider />
        <Subheading style={{ color: themeColors.primary }}>{`Client Details`}</Subheading>
        <UnborderedTable
          data={[
            {
              label: `National Insurance Number`,
              value: nationalInsuranceNo ? formatNationalInsuranceNumber(nationalInsuranceNo) : 'Not Yet Provided',
              copyableValue: nationalInsuranceNo,
            },
            {
              label: `Name`,
              value: `${firstName} ${surname}`,
            },
            {
              label: `Gender`,
              value: gender,
            },
            {
              label: `Date of Birth`,
              value: birthDate ? formatUkDate(new Date(birthDate)) : '',
            },
          ]}
          noContentDivider={true}
        />
      <Paragraph>{`Please note that client details are managed by the member within the Jarvis client app, and may not exactly match your payroll system.`}</Paragraph>

        <ContentDivider />
        <Subheading style={{ color: themeColors.primary }}>{'Change Member/Employment Details'}</Subheading>
        <ManagedTextInput
          ref={employeeEmailRef}
          name={'employeeEmail'}
          keyboardType='email-address'
          formObj={formObj}
          label={'Email Address'}
          placeholder={'Their email address'}
          autoCapitalize={'none'}
          autoCorrect={false}
          autoComplete={'email'}
          returnKeyType={'next'}
          submitHandler={() => employeeIdRef.current?.focus()}
          blurOnSubmit={false}
          informationMessage={emailChanged
            ? `If the member has not yet logged in, they will be sent a new invite`
            : undefined
          }
          rules={{
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Invalid email address"
            },
            required: true,
            minLength: 2,
            maxLength: 100,
        }} />
        <ManagedTextInput
          ref={employeeIdRef}
          name={'employeeId'}
          formObj={formObj}
          label={'Employee ID'}
          placeholder={'Your payroll system identifier'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          disabled={!nationalInsuranceNo}
          informationMessage={nationalInsuranceNo ? undefined : `You can modify the Employee ID once you have added a National Insurance Number to this member.`}
          rules={{
            required: false,
            maxLength: 35,
        }}/>
        <ManagedDateInput
          ref={employmentStartDateRef}
          name={'employmentStartDate'}
          formObj={formObj}
          label={'Employment Start Date'}
          notBeforeDate={new Date('1900-01-01')}
          notAfterDate={addYears(new Date(), 1)}
          blurOnSubmit={false}
          required={false}
          submitHandler={() => enrolmentDateRef.current?.focus()}
        />
        <ManagedDateInput
          ref={enrolmentDateRef}
          name={'enrolmentDate'}
          formObj={formObj}
          label={'Pension Scheme Enrolment Date'}
          blurOnSubmit={false}
          required={noOptOutWindowDateMode}
          notBeforeDate={new Date('1900-01-01')}
          notAfterDate={addYears(new Date(), 1)}
          submitHandler={noOptOutWindowDateMode ? undefined : () => autoEnrolmentWindowOptOutDateRef.current?.focus()}
          informationMessage={noOptOutWindowDateMode && enrolmentDate
            ? `Auto Enrolment Opt Out Window will end after 32 days on ${formatUkDate(addDays(new Date(enrolmentDate), 32))}`
            : undefined
          }
        />
        {
          noOptOutWindowDateMode ? <></> : 
            <ManagedDateInput
              ref={autoEnrolmentWindowOptOutDateRef}
              name={'autoEnrolmentWindowOptOutDate'}
              formObj={formObj}
              label={'Auto Enrolment Opt-Out Window End Date'}
              blurOnSubmit={false}
              required={!noOptOutWindowDateMode}
              notBeforeDate={new Date('1900-01-01')}
              notAfterDate={addYears(new Date(), 1)}
            />
        }
        {
          noOptOutWindowDateMode
            ? <Button
                onPress={() => setNoOptOutWindowMode(false)}
                mode={'text'}
              >
                {`Set Opt Out Window End Date`}
              </Button>
            : autoEnrolmentWindowOptOutDate
            ? undefined
            : <Button
                onPress={() => setShowNoOptOutWindowTransitionDialog(true)}
                mode={'text'}
              >
                {`I don't know the Opt Out Window End Date`}
              </Button>
        }
        <ConfirmationDialog
          visible={showNoOptOutWindowTransitionDialog}
          onCancel={() => setShowNoOptOutWindowTransitionDialog(false)}
          title={'Are you sure?'}
          content={`If you do not know the end date for the member's auto enrolment Opt Out period, we can automatically calculate it for you from the Pension Scheme Enrolment Date.\n\nYWe will calculate the end of their Opt Out period to be 32 days after the enrolment date you enter.\n\nYou should ensure that you continue to meet your legal requirements and remain compliant with Auto Enrolment legislation regarding the length of time that an enrolled member is provided during which they can choose to Opt Out.`}
          cancelLabel={'Cancel'}
          confirmLabel={'I understand'}
          onConfirm={handleNoOptOutWindowTransitionConfirm}
        />
        <ConfirmationDialog
          visible={showDialog}
          onCancel={() => setShowDialog(false)}
          title={'Are you sure?'}
          content={`Discard changes and return to member view?`}
          cancelLabel={'Cancel'}
          confirmLabel={'Discard Changes'}
          onConfirm={goToMemberView}
        />
      </ModalEditScreen>
    )
  }
    
