import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { Paragraph } from 'components/Typography'
import { COMPANY_MASK } from 'lib/constants'
import { getNationalityAutocompleteOptions } from 'lib/referenceDataHelpers'
import React, { ComponentProps, useEffect, useRef } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Platform, ReturnKeyTypeOptions, ScrollView, View } from 'react-native'
import { TextInput } from 'react-native-paper'
import { useGetNationalitiesQuery } from 'store/apiSlice'
import { CompanyDto } from 'store/dto/client.dto'
import { Colors, Paper, Flex, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { ManagedAutoCompleteInput } from './ManagedAutoCompleteInput'
import { ManagedTextInput } from './ManagedTextInput'
import { isValidCompanyNumber } from 'lib/generalHelpers'

export type ManagedCompanyInputProps = ComponentProps<typeof TextInput> & {
  formObj: UseFormReturn<any>
  name?: string
  companySearchComplete: boolean,
  companyNotFound: boolean
  updatedCompanyData?: CompanyDto,
  informationMessage?: string,
  informationMessageIsError?: boolean
}
export const ManagedCompanyNumberInput = (props: ManagedCompanyInputProps) => {
  const { formObj, onSubmitEditing, informationMessage, informationMessageIsError, companySearchComplete, updatedCompanyData, companyNotFound } = props

  const { control, setValue, formState: { errors, isValid } } = formObj

  const { colors: themeColors } = Paper.useAppTheme()

  useEffect(() => {
    if (updatedCompanyData) {
      setValue('name', updatedCompanyData?.name, { shouldDirty: true })
      setValue('building', updatedCompanyData?.addressDetail?.building, { shouldDirty: true })
      setValue('street1', updatedCompanyData?.addressDetail?.street1, { shouldDirty: true })
      setValue('town', updatedCompanyData?.addressDetail?.town, { shouldDirty: true })
      setValue('postCode', updatedCompanyData?.addressDetail?.postCode, { shouldDirty: true })
      setValue('country', updatedCompanyData?.addressDetail?.country, { shouldDirty: true })
      setValue(props.name, {
        companyReg: updatedCompanyData?.id,
      }, { shouldDirty: true })
    }
  }, [updatedCompanyData])
  const countryRef = useRef()


  //Build autocomplete options, putting GB first
  const { data: countries, error, isLoading } = useGetNationalitiesQuery()
  const countryOptions = getNationalityAutocompleteOptions(countries)

  const getBestReturnKeyType = (isValid: boolean): ReturnKeyTypeOptions => {
    //Always use 'done' for numeric keypad on iOS
    if (Platform.OS === 'ios') {
      return isValid ? 'done' : undefined
    }
    return 'done'
  }

  return (
    <View style={layoutStyles.inputContainer}>
      <Controller
        control={control}

        render={({ field: { onChange, onBlur, value } }) => {

          return (
            <ScrollView
              horizontal
              contentContainerStyle={{ flex: 1, width: '100%', height: '100%', padding: 0 }}
              scrollEnabled={false}
              keyboardShouldPersistTaps='always'
              showsVerticalScrollIndicator={true}
            >
              <View style={{ ...Flex.column.start, flex: 1 }}>
                {companySearchComplete &&
                  <>
                    <ManagedTextInput
                      name={`name`}
                      formObj={formObj}
                      label={'Company Name'}
                      placeholder={'Enter Company Name'}
                      returnKeyType={'next'}
                      autoCapitalize={'words'}
                      blurOnSubmit={false}
                      rules={{
                        required: true,
                        minLength: 1,
                        maxLength: 40,
                      }}
                    />
                    <ManagedTextInput
                      name={`building`}
                      formObj={formObj}
                      label={'Building Number/Name'}
                      placeholder={'Enter building number/name'}
                      returnKeyType={'next'}
                      autoCapitalize={'words'}
                      blurOnSubmit={false}
                      rules={{
                        required: true,
                        maxLength: 35,
                      }} />
                    <ManagedTextInput
                      name={`street1`}
                      formObj={formObj}
                      label={'Street'}
                      placeholder={'Enter street'}
                      returnKeyType={'next'}
                      autoCapitalize={'words'}
                      blurOnSubmit={false}
                      rules={{
                        required: true,
                        maxLength: 35,
                      }} />
                    <ManagedTextInput
                      name={`town`}
                      formObj={formObj}
                      label={'Town'}
                      placeholder={'Enter town/locality'}
                      returnKeyType={'next'}
                      autoCapitalize={'words'}
                      blurOnSubmit={false}
                      rules={{
                        required: true,
                        maxLength: 35,
                      }} />
                    <ManagedTextInput
                      name={`postCode`}
                      formObj={formObj}
                      label={'Postcode'}
                      returnKeyType={"next"}
                      placeholder={'Enter postcode'}
                      autoCapitalize={'characters'}
                      rules={{
                        required: true,
                        maxLength: 10,
                      }} />
                    <ManagedAutoCompleteInput
                      name={`country`}
                      formObj={formObj}
                      label={'Country'}
                      modalTitle={'Find Country'}
                      dataSet={countryOptions}
                      required={true}
                    />
                  </>}

                {!companySearchComplete && <>
                  <ManagedTextInput
                    // left={<TextInput.Icon size={Sizing.x20} icon={'briefcase-search'} color={themeColors.primary} style={{ backgroundColor: 'transparent' }}/>}
                    name={'companyReg'}
                    autoFocus={true}
                    submitHandler={onSubmitEditing}
                    formObj={formObj}
                    returnKeyType={getBestReturnKeyType(isValid)}
                    informationMessage={informationMessage}
                    informationMessageIsError={informationMessageIsError}
                    mask={{
                      type: 'custom',
                      options: {
                        mask: COMPANY_MASK,
                      }
                    }}
                    placeholder={'Enter company number'}
                    autoCapitalize={'characters'}
                    // submitHandler={handleSubmit(onSubmit)}
                    blurOnSubmit={true}
                    rules={{
                      required: true,
                      minLength: {
                        value: 8,
                        message: 'Must be 8 characters, include leading zeros'
                      },
                      maxLength: {
                        value: 8,
                        message: 'Must be at most 8 characters'
                      },
                      validate: {
                        isValidCompanyNumber
                      }
                    }} />
                </>}
                {
                  companyNotFound
                  ? <View style={Flex.column.start}>
                      <View style={Flex.row.center}>
                        <MaterialCommunityIcons name={'alert-rhombus'} size={Sizing.x50} color={Colors.warning.s400}/>
                      </View>
                      <Paragraph>{`Company number not found - please duoble check.`}</Paragraph>
                    </View>
    
                  : <></>
                }

              </View>
            </ScrollView>
          )
        }}
        name={props.name}
      />
    </View>
  )
}

