import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Logger } from 'lib/logger'
import { rootNavigate } from 'lib/RootNavigation'
import React, { useEffect } from 'react'
import { ampli } from 'src/ampli'
import { useCreateAccountMutation, useUpdateAccountMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAccount, workingAccount } from 'store/tempDataSlice'
import { pick } from 'lodash'
import { AccountDto } from 'store/dto/account.dto'
import { JAR_NAME_PERSONAL } from 'lib/constants'

export const InvestmentChoice_99_Result = ({ route, navigation }) => {
  const { nextRoute, contextAccount }: { nextRoute: string, contextAccount: AccountDto }  = route?.params || {}
  const dispatch = useAppDispatch()

  const workingAccountData: any = useAppSelector(workingAccount)
  const [ createAccount, { data: newAccount, error: newError, isLoading: newIsLoading } ] = useCreateAccountMutation()
  const [ updateAccount, { data: updatedAccount, error: updateError, isLoading: updateIsLoading } ] = useUpdateAccountMutation()

  const isLoading = newIsLoading || updateIsLoading
  const error: any = newError || updateError

  //Create or update on enter
  useEffect(() => {
    if (contextAccount) {
      doUpdateAccount()
    } else {
      doCreateAccount()
    }
  }, [])

  //Navigate if successful
  useEffect(() => {
    if (newAccount) {
      ampli.planSetup({
        investmentChoice:
          newAccount?.investmentPlanId
            ? 'plan'
            : 'custom',
        investmentPlanId: newAccount?.investmentPlanId,
        investmentAssetCount: newAccount?.assetAllocation ? newAccount?.assetAllocation.length : 1,
        investmentPlanName: workingAccountData?.planName,
        pensionOptOut: workingAccountData?.pensionOptOut
      })
      end()
    } else if (updatedAccount) {
      ampli.planChange({
        investmentChoice:
          updatedAccount?.investmentPlanId
            ? 'plan'
            : 'custom',
        investmentPlanId: updatedAccount?.investmentPlanId,
        investmentAssetCount: updatedAccount?.assetAllocation ? updatedAccount?.assetAllocation.length : 1,
        investmentPlanName: workingAccountData?.planName,
      })
      end()
    }
  }, [newAccount, updatedAccount])

  const doCreateAccount = () => {
    Logger.info(`Creating Account...`)
    createAccount(pick(workingAccountData, [
      'investmentPlanId',
      'groupPortfolioId',
      'assetAllocation',
      'usePersonalPensionInvestmentChoice',
      'termsAgreed',
      'pensionOptOut',
    ]))
  }

  const doUpdateAccount = () => {
    Logger.info(`Updating pension plan...`)
    updateAccount({
      id: workingAccountData?.accountId,
      ...pick(workingAccountData, [
        'investmentPlanId',
        'groupPortfolioId',
        'assetAllocation',
        'usePersonalPensionInvestmentChoice',
      ])
    })
  }

  const end = () => {
    //Clear temp data
    const heroParams = pick(workingAccountData, [
      'accountDescriptor',
      'usePersonalPensionInvestmentChoice'
    ])
    dispatch(updateWorkingAccount(undefined))
    rootNavigate(nextRoute, {
      ...route?.params,
      accountId: newAccount ? newAccount?.id : updatedAccount ? updatedAccount?.id : undefined,
      ...heroParams,
    })
  }

  return (      
    <ProcessScreen
      buttonTitle={''}
      error={error}
      errorTryAgain={contextAccount ? doUpdateAccount : doCreateAccount}
      isLoading={isLoading}
      loadingMessage={contextAccount ? [
          `Applying your choice...`,
        ]
      : [
          `Opening ${JAR_NAME_PERSONAL}...`,
          `Finalizing the details...`,
        ]
      }
      buttonAction={() => {}}
      showButton={false}
    />
  )
}
