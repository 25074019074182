import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { formatUkDate } from 'lib/dateHelpers'
import React from 'react'
import { Image } from 'react-native'
import { GroupSchemeEnrolmentDto, GroupSchemeEnrolmentStatus, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { Paper, Sizing } from 'styles'

type EnrolmentListItemProp = {
  enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto
  onPressFunction?: () => void
}
export const EnrolmentListItem = (props: EnrolmentListItemProp) => {
  const { enrolment, onPressFunction } = props
  
  const {
    groupScheme,
    isVerified,
    enrolmentStatus,
    employmentStartDate,
    enrolmentDate,
    enrolmentEndDate,
    autoEnrolmentOptOutDate,
    clientOptOutDate,
    isLinked,
  } = enrolment || {}
  const { organizationDisplayName, logo } = groupScheme || {}

  const { colors: themeColors } = Paper.useAppTheme()

  const titleRight = enrolmentStatus

  const subTitle =
    //Inactive
    enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE
      ? enrolmentEndDate
        ? `Left scheme on: ${formatUkDate(new Date(enrolmentEndDate))}`
        : `Previously left scheme`
    //Actioned opt out
    : enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT
      ? autoEnrolmentOptOutDate
        ? `Opted out on: ${formatUkDate(new Date(autoEnrolmentOptOutDate))}`
        : `Previously opted out`
    //Declared (but not actioned) opt out
    : enrolmentStatus === GroupSchemeEnrolmentStatus.OPT_OUT_DECLARED
      ? `Declared on: ${formatUkDate(new Date(clientOptOutDate))}`
    //Not verified
    : !isVerified
      ? `Requires Set up`
    //Needs NINO
    : !isLinked
      ? `Requires NI Number`
    //Active
    : enrolmentDate
      ? `Enrolled: ${formatUkDate(new Date(enrolmentDate))}`
      : employmentStartDate
        ? `Started: ${formatUkDate(new Date(employmentStartDate))}`
        : `Previously enrolled`

  const style = enrolmentStatus === GroupSchemeEnrolmentStatus.OPT_OUT_DECLARED || enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT || enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE
    ? { color: themeColors.disabled }
    : !isVerified || !isLinked
    ? { color: themeColors.accent }
    : undefined

  return (
    <DetailedListItem
      icon={<Image source={{ uri: logo }} style={{
        width: Sizing.x25,
        height: Sizing.x25,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />}
      onPress={onPressFunction}
      title={organizationDisplayName}
      titleStyle={style}
      subTitleStyle={style}
      titleRight={titleRight}
      titleRightStyle={style}
      subTitle={subTitle}
    />
  )
}
