import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { Text } from 'components/Typography/Text'
import { ExternalLinkButton } from 'components/Utility/ExternalLinkButton'
import { FileUploadArea, FileUploadStatus } from 'components/Utility/FileUploadArea'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { userCanManageInvitesForScheme } from 'lib/groupSchemeHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { getScreenAppWidth } from 'lib/scaleHelpers'
import { concat } from 'lodash'
import { default as React, useEffect, useState } from 'react'
import { View } from 'react-native'
import { useGetSampleFileQuery, useImportGroupSchemeInvitesMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setShowEmployerImportInvitesVisible, setSnackbarData, showEmployerImportInvitesVisible } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing } from 'styles'

const isWeb = platformIsWeb()

export const EmployerImportInvitesModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
    const dispatch = useAppDispatch()

    const currentScheme = useAppSelector(currentGroupScheme)

    const userCanManageInvites = userCanManageInvitesForScheme(currentScheme)

    const screenWidth = getScreenAppWidth()
    const useHorizontalLayout = isWeb && screenWidth >= 1000
  
    const { colors: themeColors } = Paper.useAppTheme()
  
    const { data: inviteSampleFile, isLoading: inviteSampleFileIsLoading, error: inviteSampleFileError } = useGetSampleFileQuery('jarvis_sample_scheme_invite_import.csv')
    const [importInvites, { data: importInviteResult, isLoading: importInviteIsLoading, error: importInviteError }] = useImportGroupSchemeInvitesMutation()
  
    const [inviteFile, setInviteFile] = useState<File>(undefined)
    const [inviteFileUploadStatus, setInviteFileUploadStatus] = useState<FileUploadStatus>(FileUploadStatus.IDLE)
   
    const handleInviteFileChange = (file: File) => {
      setInviteFile(file)
      setInviteFileUploadStatus(FileUploadStatus.IDLE)
    }
  
    const handleInviteFileUpload = () => {
      setInviteFileUploadStatus(FileUploadStatus.IDLE)
      const formData = new FormData()
      formData.append('file', inviteFile)
      importInvites({
        groupSchemeId: currentScheme?.id,
        formData,
      })
    }
  
    //Sync invite upload status
    useEffect(() => {
      setInviteFileUploadStatus(
        importInviteResult ? FileUploadStatus.COMPLETE
        : importInviteError ? FileUploadStatus.ERROR_UPLOAD
        : importInviteIsLoading ? FileUploadStatus.UPLOADING
        : FileUploadStatus.IDLE
      )
    }, [importInviteResult, importInviteIsLoading, importInviteError])
  
    //Show Snackbar when uploaded/error - contributions
    useEffect(() => {
      if (importInviteResult || importInviteError) {
        dispatch(setSnackbarData({
          message: importInviteError ? `File Upload Failed` : `File Uploaded Successfully`,
          subMessage: importInviteError ? `Please check that the file exists and is a valid CSV file mathing the sample` : `Please review the results below`,
          iconName: importInviteError ? 'file-alert-outline' : 'file-check-outline',
          isError: !!importInviteError,
          duration: 5000,
        }))
      }
    }, [importInviteResult, importInviteError])
      
    const close = () => {
      dispatch(setShowEmployerImportInvitesVisible(false))
    }
  
    const failedRecordTableData: UnborderedTableRow[] = importInviteResult?.details ?
      importInviteResult?.details.filter(result => {
        return result.action === 'error'
      }).map(result => {
        return {
          label: `CSV Line: ${result.csvLine}`,
          value: `Error: ${result.errorMessage}`,
        }
      })
      : []
  
    const created = importInviteResult?.create || 0
    const errored = importInviteResult?.error || 0
    const total = created + errored
    const importStatus =
      importInviteError ? 'File not processed'
      : total === 0 ? 'No records found to process'
      : total === created ? 'Success'
      : total === errored ? 'All records failed'
      : 'Partial Success'
  
    return (
      <ModalEditScreen
        formTitle={'Bulk Import Invites'}
        onDismiss={() => dispatch(setShowEmployerImportInvitesVisible(false))}
        isLoading={inviteSampleFileIsLoading}
        buttonTitle={importInviteResult ? 'Finish' : 'Close'}
        buttonAction={close}
        showButton={true}
        enableButton={!importInviteIsLoading}
        footerInfo={userCanManageInvites ? undefined : <Text>{`You do not have permissions to manage invites.`}</Text>}
      >
        {
          userCanManageInvites ?
          <>
            <View style={{
              flexDirection: useHorizontalLayout ? 'row' : 'column',
              width: useHorizontalLayout ? '100%' : undefined,
              justifyContent: useHorizontalLayout ? 'space-between' : 'flex-start',
            }}>
              <View style={{
                ...Flex.column.start,
                width: useHorizontalLayout ? '50%' : '100%',
                height: useHorizontalLayout ? '100%' : undefined,
                paddingHorizontal: Sizing.x10,
                paddingTop: Sizing.x10,
              }}>
                <View style={{ flex: 1, paddingTop: Sizing.x5 }}>
                  <Subheading>
                    {'Upload CSV files to bulk invite employees to join your scheme.'}
                  </Subheading>
                  <Paragraph>
                    {'Select a single CSV file, exactly matching the required format'}
                  </Paragraph>
                  <ExternalLinkButton
                    url={inviteSampleFile?.downloadUrl}
                    downloadAsFile={true}
                    downloadFilename={inviteSampleFile?.id || 'sample'}
                    labelStyle={{
                      textDecorationLine: 'none',
                      textTransform: 'none',
                    }}
                    iconName={'file-eye-outline'}
                    color={Colors.brand.purple1}
                    iconColor={Colors.brand.purple1}
                  >
                    {'Download Sample CSV File'}
                  </ExternalLinkButton>
                  <Subheading>{'Data Handling'}</Subheading>
                  <View style={{ alignSelf: 'center' }}>
                    <BulletItem style={{ textAlign: 'left' }}>{'For valid records, scheme invitations will be sent to the email addresses provided'}</BulletItem>
                    <BulletItem style={{ textAlign: 'left' }}>{'No invites will be sent if one already exists for this scheme, based on National Insurance Number'}</BulletItem>
                    <BulletItem style={{ textAlign: 'left' }}>{'No invites will be sent if a member already exists in this scheme, based on National Insurance Number'}</BulletItem>
                  </View>
                </View>
              </View>
              <View style={{
                ...Flex.column.center,
                width: useHorizontalLayout ? '50%' : '100%',
                height: useHorizontalLayout ? '100%' : undefined,
                paddingHorizontal: Sizing.x10,
                paddingTop: Sizing.x10,
              }}>
                <FileUploadArea
                  title={'Scheme Invitations'}
                  dragDropMessage={'Drag and drop a CSV file'}
                  handleChange={handleInviteFileChange}
                  handleRemove={() => handleInviteFileChange(undefined)}
                  types={['csv']}
                  multiple={false}
                  hoverTitle={'Release to add file'}
                  fileOrFiles={inviteFile}
                  submitHandler={handleInviteFileUpload}
                  fileUploadStatus={inviteFileUploadStatus}
                />
                <View style={{ flex: 1, paddingTop: Sizing.x5 }}>
                </View>
              </View>
            </View>
            {
              importInviteResult || importInviteError
                ? <>
                    <ContentDivider />
                    <Subheading style={{ color: themeColors.primary }}>{`Last Import Result`}</Subheading>
                    <UnborderedTable
                      data={concat([
                        {
                          label: `Import Status`,
                          value: importStatus,
                        },
                      ],
                      importInviteError ?
                        [
                          {
                            label: `Failure Reason`,
                            value: `The file could not be read or some records failed validation`,
                          },
                        ]
                      : [
                          {
                            label: `Records Processed`,
                            value: total,
                          },
                          {
                            label: `Invites Sent`,
                            value: created,
                          },
                          {
                            label: `Failed Records`,
                            value: errored,
                          },
                        ]
                      )}
                      noContentDivider={true}
                    />
                    {
                      !importInviteError && errored
                        ? <>
                            <ContentDivider />
                            <Subheading style={{ color: themeColors.primary }}>{`Failed Records`}</Subheading>
                            <UnborderedTable
                              data={failedRecordTableData}
                              noContentDivider={true}
                            />
                          </>
                        : <></>
                    }
                  </>
                : <></>
      
              }
            </>
          : <></>
        }

      </ModalEditScreen>
    )  
}

