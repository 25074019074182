export interface MessageDto {
  type: string
  id: string
  messageType: MessageType
  status: MessageStatus
  subject: string
  payload: MessagePayload
  created: string
  documentUrl?: string
  accountId?: string
}

export enum MessageStatus {
  UNREAD = 'unread',
  READ = 'read',
  ARCHIVED = 'archived',
}

export enum MessageType {
  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
  ADVANCE_DIRECT_DEBIT_MANDATE_CONFIRMATION = 'ADVANCE_DIRECT_DEBIT_MANDATE_CONFIRMATION',
  ADVANCE_DIRECT_DEBIT_MANDATE_NOTICE = 'ADVANCE_DIRECT_DEBIT_MANDATE_NOTICE',
  AML_RESEND_DATA = 'AML_RESEND_DATA',
  // CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CLIENT_UPDATE = 'CLIENT_UPDATE',
  CONTRACT_NOTE = 'CONTRACT_NOTE',
  // GAUDI_COMMS = 'GAUDI_COMMS',
  // GAUDI_INTERNAL_COMMS = 'GAUDI_INTERNAL_COMMS',
  ILLUSTRATION = 'ILLUSTRATION',
  NEW_ACCOUNT = 'NEW_ACCOUNT',
  NEW_ACCOUNT_REPLAY = 'NEW_ACCOUNT_REPLAY',
  PENSION_CANCELLATION_LETTER = 'PENSION_CANCELLATION_LETTER',
  PENSION_TRANSFER_CANCELLATION_LETTER = 'PENSION_TRANSFER_CANCELLATION_LETTER',
  RETIREMENT_PACK = 'RETIREMENT_PACK',
  SMPI_ANNUAL_STATEMENT = 'SMPI_ANNUAL_STATEMENT',
  TAX_STATEMENT = 'TAX_STATEMENT',
  // PENSION_PAYSLIP_NOTIFICATION = 'PENSION_PAYSLIP_NOTIFICATION',
  QUARTERLY_STATEMENT = 'QUARTERLY_STATEMENT',
  VALUATION_STATEMENT = 'VALUATION_STATEMENT',
  // WITHDRAWAL = 'WITHDRAWAL',
}

export interface MessagePayload {
  body: string
  accountId: string
  id?: string
  documentUrl?: string
}

export interface UpdateMessageDto {
  id: string
  status?: MessageStatus
  accountId: string //Not required by backend, but used for frontend cache invalidation
}

export interface MessageQueryDto {
  accountId?: string | string[]
  messageType?: MessageType
  status?: MessageStatus
  fromDate?: Date
  toDate?: Date
  pageSize?: number
  page?: number
}

export interface ValuationStatementRequestQueryDto {
  fromDate: string
  toDate: string
}