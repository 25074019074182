import ModalProcessStackBuilder from "navigation/stacks/ModalProcessStackBuilder"
import React, { useEffect } from 'react'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from "store/dto/account.dto"
import { useAppDispatch } from 'store/hooks'
import { updateWorkingSchemeEnrolmentRequest } from "store/tempDataSlice"
import { SchemeEnrolmentAddNinoRequestAdd_00_Intro, SchemeEnrolmentAddNinoRequestAdd_01_Nino, SchemeEnrolmentAddNinoRequestAdd_02_Confirm, SchemeEnrolmentAddNinoRequestAdd_99_Result } from "./Screens"
import { ClientMeDto } from "store/dto/client.dto"

export type SchemeEnrolmentAddNinoRequestAddProcessStackProps = {
  enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto
  client: ClientMeDto
  onDismiss: any
  resultHandlerFunction?: any  
}

export const SchemeEnrolmentAddNinoRequestAddProcessStack = (props: SchemeEnrolmentAddNinoRequestAddProcessStackProps) => {
  const { enrolment, client, onDismiss, resultHandlerFunction } = props

  const dispatch = useAppDispatch()

  //Clear on start
  useEffect(() => {
    dispatch(updateWorkingSchemeEnrolmentRequest(undefined))
  }, []
  )

  return (
    <ModalProcessStackBuilder
      visible={!!enrolment}
      onDismiss={onDismiss}
      dismissConfirmationMessage={`Discard this request?`}
      processTitle='Request to Add NI Number'
      initialRouteName='Intro'
      initialParams={{
        onDismiss,
        resultHandlerFunction,
        enrolment,
        client,
      }}
      screens={[
        {
          navigationId: 'Intro',
          title: 'Add Request',
          component: SchemeEnrolmentAddNinoRequestAdd_00_Intro,
        },
        {
          navigationId: 'Nino',
          title: 'Nino',
          component: SchemeEnrolmentAddNinoRequestAdd_01_Nino,
        },
        {
          navigationId: 'Confirm',
          title: 'Confirm',
          component: SchemeEnrolmentAddNinoRequestAdd_02_Confirm,
        },
        {
          navigationId: 'Results',
          title: '',
          component: SchemeEnrolmentAddNinoRequestAdd_99_Result,
        },
      ]}
    />
  )
}

